
export class AgencyComment {
    displayName: string
    description: string
    storageDate: Date
}

class AgencyCommentsController {

    comments: AgencyComment[];

    static $inject = ['$rootScope', '$scope', 'Auth', 'Agency', 'agencyId', '$uibModal'];
    constructor(private $rootScope, private $scope, private Auth, private Agency, private agencyId, private $uibModal) {
        this.loadComments();
    }

    addComment() {
        this.$uibModal.open({
            templateUrl: 'areas/agency/AgencyDetails/modals/addCommentModal.html',
            controller: 'AgencyCommentModalController as ctrl',
            backdrop: false,
            resolve: {
                agencyComment: () => new AgencyComment(),
                agencyId: () => this.agencyId
            }
        }).result.then(() => {
            this.loadComments();
        });
    }

    loadComments() {
        this.Agency.agencyComments({ agencyId: this.agencyId }, resp => this.comments = resp);
    }
}

angular
    .module('app')
    .controller('AgencyCommentsController', AgencyCommentsController)