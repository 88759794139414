 

class SectionSummaryController {

    summary;

    static $inject = ['$scope', '$state', 'SectionSummary'];
    constructor($scope, $state, SectionSummary){
        $scope.$on('submitted', (event, data) => {
            this.summary = SectionSummary($scope.name);
        });
        this.summary = SectionSummary($scope.name);
    }
}

function SectionSummaryDirectiveFactory(): ng.IDirective {
    return {
        restrict: 'AE',
        scope: {
            name: '@sectionSummary'
        },
        controller: SectionSummaryController,
        controllerAs: 'sectSumCtrl',
        templateUrl: 'controls/sectionSummary/sectionSummary.html'
    };
}


angular
    .module('app')
    .directive('sectionSummary', SectionSummaryDirectiveFactory);