import { DataAccessFactory } from './DataAccessFactory'
import { Auth, Resource } from './dataAccess'

class ReferenceFactory extends DataAccessFactory {

    constructor() {
        super();

        this.defaultActions = {
            query: { method: 'GET', params: this.queryParams },
            save: { method: 'POST', params: { applicationId: '@applicationId' } },
            update: { method: 'PUT', params: { referenceId: '@id' } },
            remove: { method: 'DELETE', params: { referenceId: '@id' } }
        }
        this.server = 'references'
        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'Reference',
                url: `${this.server}/:referenceId`,
                actions: {
                    save: {
                        method: 'POST',
                        url: `${this.server}/application/:applicationId/save`,
                        params: { applicationId: '@applicationId' }
                    },
                    summary: {
                        method: 'GET',
                        url: `${this.server}/application/:applicationId/summary`
                    },
                    query: {
                        method: 'GET',
                        url: `${this.server}/application/:applicationId`,
                        isArray: true
                    },
                    create: {
                        method: 'POST',
                        url: `${this.server}/application/:applicationId`,
                        params: { applicationId: '@applicationId' }
                    },
                    getForReferee: {
                        method: 'GET',
                        url: `${this.server}/referee/:token`,
                        auth: Auth.None
                    },
                    saveForReferee: {
                        method: 'POST',
                        url: `${this.server}/referee/:token`,
                        auth: Auth.None
                    },
                    resendReferenceRequest: {
                        method: 'POST',
                        url: `${this.server}/:referenceId/resend`,
                        params: { referenceId: '@id' }
                    },
                    updateReferenceStatus: {
                        method: 'POST',
                        url: `${this.server}/:referenceId/updateReferenceStatus`,
                        params: { referenceId: '@id' }
                    },
                    referenceToken: {
                        method: 'GET',
                        url: `${this.server}/referenceToken/:userId`,
                        params: { userId: '@id' }
                    },
                    saveTransaction: {
                        method: 'POST',
                        url: `${this.server}/reference/:referenceId/translation`,
                        params: { referenceId: '@referenceId' }
                    }
                }
            },
            {
                name: 'TelephoneReferenceCheck',
                url: `${this.server}/:referenceId/check`,
                params: {
                    referenceId: '@referenceId'
                },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'refereeContactDate',
                                'dateRefereeContactNextUpdated',
                                'reviewDate'
                            )
                        )
                    }
                }
            }
        ]

        this.process(resources)
    }
}

new ReferenceFactory()
