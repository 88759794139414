 

export class FindApplication{

    static $inject = ['$uibModal']
    constructor(private $uibModal) {

    }

    openDialog = (title) => {
        return this.$uibModal.open({
            templateUrl: 'controls/findApplication/findApplicationModal.html',
            controller: FindApplicationModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                title: () => title
            }
        }).result;
    }
}

class FindApplicationModalController {
    public searchText: string;
    public results: any[];

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'SearchApplicant', 'Auth'];
    constructor(private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private SearchApplicant, private Auth) {

    }

    search() {
        if (this.searchText) {
            this.SearchApplicant.query({
                contactId: this.Auth.user.roleDetails.contactId,
                searchTerm: this.searchText
            }, (response) => {
                this.results = response.applicantData;
            });
        }
    }

    select(application) {
        this.$uibModalInstance.close(application);
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}

angular
    .module('app')
    .service('FindApplication', FindApplication);