 
import _ from 'lodash-es'
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';
import { ResourceGroupEnum } from '../../models/ResourceGroup';
import { FeesController } from '../../areas/tools/maintainFees/FeesController';
import { PromotionsController } from '../../areas/tools/maintainPromotions/PromotionsController';
import { MaintenanceMessagesController } from '../../areas/tools/communications/maintenanceMessages/MaintenanceMessagesController';
import { SystemAnnouncementsController } from '../../areas/tools/communications/systemAnnouncements/SystemAnnouncementsController';
import { MaintainCountryController } from '../../areas/tools/maintainCountry/MaintainCountryController';
import { ReviewPaymentsController } from '../../areas/tools/payments/ReviewPaymentsController';
import { FinancialPeriodsController } from '../../areas/tools/financialPeriods/FinancialPeriodsController';
import { FlightMessagesController } from '../../areas/tools/maintainFlights/FlightMessagesController';

import { ITepImports } from '../../areas/tools/iTep/iTepImports';
import { ITepImportDetailsController } from '../../areas/tools/iTep/iTepImportDetailsController';
import { ITepAvailableTests } from '../../areas/tools/iTep/iTepAvailableTests';


import { Kind } from '../../areas/tools/maintainPromotions/kind';
import { ReviewPaymentsKind } from '../../areas/tools/payments/ReviewPaymentsKind';
import { FlightsController } from '../../areas/tools/maintainFlights/FlightsController';
import { AirportsController } from 'app/areas/tools/maintainFlights/AirportsController';
import { GatewaysController } from 'app/areas/tools/maintainFlights/GatewaysController';
import { UsersController } from '../../areas/tools/maintainUsers/UsersController';

import { MaintainAgreementsController } from '../../areas/tools/maintainAgreements/MaintainAgreementsController';
import { PreviewAgreementController } from '../../areas/tools/maintainAgreements/PreviewAgreementController';
import { BusinessArea } from '../../services/AuthService';

// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************


class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        const tools: state<AccessLevelView> = {
            name: 'tools',
            url: '/tools',
            resolve: {
                resourceGroupId: [function () {
                    return ResourceGroupEnum.Applicant;
                }],
                $title: () => 'Tools'
            },
            views: {
                content: {
                    staff: {
                        controller: 'ToolsController as ctrl',
                        template: 'areas/tools/staff.html'
                    }
                }
            },
            states: [
                {
                    name: 'fees',
                    url: '/fees',
                    resolve: {
                        financialPeriods: ['FeeRegions', (FeeRegions) => FeeRegions.financialPeriods().$promise],
                        invoiceRegions: ['FeeRegions', (FeeRegions) => FeeRegions.invoiceRegions().$promise],
                        feeList: ['FinanceLookup', (FinanceLookup) => FinanceLookup.feeNames().$promise.then((data) => {
                            return _.reduce(data, (r, v: any) => {
                                r[v.code] = v;
                                return r
                            }, {})
                        })],
                        countryNames: ['ApplicationLookup', (ApplicationLookup) => ApplicationLookup.country().$promise.then((data) => {
                            return _.reduce(data, (r, v: any) => {
                                r[v.code] = v.name;
                                return r
                            }, {})
                        })]
                    },
                    views: {
                        '': {
                            controllerAs: 'ctrl',
                            staff: {
                                controller: FeesController,
                                template: 'areas/tools/maintainFees/fees.html',
                                businessArea: BusinessArea.FINANCE
                            }
                        }
                    }
                },
                {
                    name: 'promotions',
                    url: '/promotions',
                    resolve: {
                        kind: () => Kind.Promotion
                    },
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: PromotionsController,
                                template: 'areas/tools/maintainPromotions/promotions.html',
                                businessArea: BusinessArea.FINANCE
                            }
                        }
                    }
                },
                {
                    name: 'discounts',
                    url: '/discounts',
                    resolve: {
                        kind: () => Kind.Discount
                    },
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: PromotionsController,
                                template: 'areas/tools/maintainPromotions/promotions.html',
                                businessArea: BusinessArea.FINANCE
                            }
                        }
                    }
                },
                {
                    name: 'maintenanceMessages',
                    url: '/maintenanceMessages',
                    resolve: {},
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: MaintenanceMessagesController,
                                template: 'areas/tools/communications/maintenanceMessages/maintenanceMessages.html',
                                businessArea: BusinessArea.COMMUNICATIONS
                            }
                        }
                    }
                },
                {
                    name: 'systemAnnouncements',
                    url: '/systemAnnouncements',
                    resolve: {},
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: SystemAnnouncementsController,
                                template: 'areas/tools/communications/systemAnnouncements/systemAnnouncements.html',
                                businessArea: BusinessArea.COMMUNICATIONS
                            }
                        }
                    }
                },
                {
                    name: 'commissionPayments',
                    url: '/commissionPayments',
                    resolve: {
                        kind: () => ReviewPaymentsKind.Commission
                    },
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: ReviewPaymentsController,
                                template: 'areas/tools/payments/commissionPayments.html',
                                businessArea: BusinessArea.FINANCE
                            }
                        }
                    }
                },
                {
                    name: 'marketingPayments',
                    url: '/marketingPayments',
                    resolve: {
                        kind: () => ReviewPaymentsKind.Maketing
                    },
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: ReviewPaymentsController,
                                template: 'areas/tools/payments/marketingPayments.html',
                                businessArea: BusinessArea.FINANCE
                            }
                        }
                    }
                },
                {
                    name: 'financialPeriods',
                    url: '/financialPeriods',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: FinancialPeriodsController,
                                template: 'areas/tools/financialPeriods/financialPeriods.html',
                                businessArea: BusinessArea.FINANCE
                            }
                        }
                    }
                },
                {
                    name: 'country',
                    url: '/country',
                    resolve: {
                    },
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: MaintainCountryController,
                                template: 'areas/tools/maintainCountry/maintainCountry.html',
                                businessArea: BusinessArea.COUNTRY_MANAGEMENT
                            }
                        }
                    }
                },
                {
                    name: 'maintainFlights',
                    url: '/flights',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: FlightsController,
                                template: 'areas/tools/maintainFlights/flights.html',
                                businessArea: BusinessArea.FLIGHT_MANAGEMENT
                            }
                        }
                    }
                },
                {
                    name: 'maintainAirports',
                    url: '/airports',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: AirportsController,
                                template: 'areas/tools/maintainFlights/airports.html',
                                businessArea: BusinessArea.FLIGHT_MANAGEMENT
                            }
                        }
                    }
                },
                {
                    name: 'maintainGateways',
                    url: '/gateways',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: GatewaysController,
                                template: 'areas/tools/maintainFlights/gateways.html',
                                businessArea: BusinessArea.FLIGHT_MANAGEMENT
                            }
                        }
                    }
                },
                {
                    name: 'flightMessages',
                    url: '/flightMessages',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: FlightMessagesController,
                                template: 'areas/tools/maintainFlights/flightMessages.html',
                                businessArea: BusinessArea.FLIGHT_MANAGEMENT
                            }
                        }
                    }
                },
                {
                    name: 'iTepImports',
                    url: '/iTepImports',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: ITepImports,
                                template: 'areas/tools/iTep/iTepImports.html',
                                businessArea: BusinessArea.ITEP_ADMINS
                            }
                        }
                    }
                },
                {
                    name: 'iTepImportsDetails',
                    url: '/iTepImports/{id:int}',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: ITepImportDetailsController,
                                template: 'areas/tools/iTep/iTepImportDetails.html',
                                businessArea: BusinessArea.ITEP_ADMINS
                            }
                        }
                    },
                    resolve: {
                        importfile: ['iTepImports', '$stateParams', (iTepImports, $stateParams) => iTepImports.get({ id: $stateParams.id }).$promise]
                    }
                },
                {
                    name: 'iTepAvailableTests',
                    url: '/iTepAvailableTests',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: ITepAvailableTests,
                                template: 'areas/tools/iTep/iTepAvailableTests.html',
                                businessArea: BusinessArea.ITEP_ADMINS
                            }
                        }
                    }
                },
                {
                    name: 'users',
                    url: '/users',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: UsersController,
                                template: 'areas/tools/maintainUsers/users.html',
                                businessArea: BusinessArea.OFFICE_USERS_MANAGEMENT
                            }
                        }
                    }
                },
                {
                    name: 'maintainAgreements',
                    url: '/maintainAgreements',
                    resolve: {},
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: MaintainAgreementsController,
                                template: 'areas/tools/maintainAgreements/maintainAgreements.html',
                                businessArea: BusinessArea.AGREEMENTS_MANAGEMENT
                            }
                        }
                    }
                },
                {
                    name: 'agreementPreview',
                    url: '/agreement/preview/{id:int}',
                    resolve: {
                        agreement: ['MaintainAgreements', '$stateParams', (MaintainAgreements, $stateParams) => MaintainAgreements.get({ id: $stateParams.id }).$promise]
                    },
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: PreviewAgreementController,
                                template: 'areas/tools/maintainAgreements/previewAgreement.html',
                                businessArea: BusinessArea.AGREEMENTS_MANAGEMENT
                            }
                        }
                    }
                }
            ]
        };

        RoutingProvider.addState(tools, 'app');
    }
}

angular
    .module('app')
    .config(RoutingConfig);
