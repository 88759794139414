 

function DecimalTextboxFactory(): ng.IDirective {
    var directive = {
        restrict: 'A',
        scope: {
            ngModel: '=ngModel'
        },
        link: link
    };

    return directive;

    function link(scope, element, attrs) {
        scope.$watch('ngModel', function (newVal, oldVal) {
            var arr = String(newVal).split('');
            if (arr.length === 0) return;
            if (arr.length === 1 && (arr[0] === '-' || arr[0] === '.')) return;
            if (isNaN(newVal)) {
                scope.ngModel = oldVal;
            }
        });
    }
};

angular
.module('app')
.directive('decimalTextbox', DecimalTextboxFactory);