
function DateConverterValidatorLink(scope, element, attrs, ngModelCtrl) {

    if (!attrs['dateConverter'])
        return;

    var datepickerFormat = attrs['uibDatepickerPopup'].toUpperCase();
    var formats = attrs['dateConverter'].split('|');

    //ngModelCtrl.$validators.date = function (modelValue, viewValue) {
    //    return ngModelCtrl.$isEmpty(viewValue) || angular.isDate(modelValue);
    //};

    //workaround for datepicker

    ngModelCtrl.$parsers.unshift(function (viewValue) {

        if (!viewValue) {
            ngModelCtrl.$setValidity('aifsdate', true);
            if (attrs.required)
                ngModelCtrl.$setValidity('required', false);
            return viewValue;
        }

        if (attrs.required)
            ngModelCtrl.$setValidity('required', true);

        var isValueADate = (viewValue === null) ? false : angular.isDate(viewValue);
        var parsedMoment = (isValueADate) ? moment(viewValue) : moment(viewValue, formats, true);

        if (parsedMoment.isValid()) {
            ngModelCtrl.$setValidity('aifsdate', true);
            return parsedMoment.format(datepickerFormat);
        } else {
            ngModelCtrl.$setValidity('aifsdate', false);
            return undefined;
        }
    });
}


angular
    .module("aifs.metaform")
    .directive("dateConverter", function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: DateConverterValidatorLink,
            priority: 1
        };
    });

