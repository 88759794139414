 
import { DocumentUploadConfig, MultiDocumentUploader } from '../DocumentUploadConfig'

class DocumentsUploadController{
        
    config: DocumentUploadConfig<MultiDocumentUploader>
    applicationStatus

    static $inject = ['$uibModal']
    constructor(private $uibModal) {
    }

    displayDocumentsList = () => {

        this.$uibModal.open({
            templateUrl: 'controls/documentsUpload/multiDocumentUpload/documentsListModal.html',
            controller: 'DocumentsListModalController as uploadCtrl',
            backdrop: false,
            resolve: {
                documentTypes: this.config.uploader.getDocumentTypes(),
                config: () => this.config,
                applicationStatus: () => this.applicationStatus
            }
        }).result
    }
}
    
var DocumentsUploadDirectiveFactory = ['$state', '$window', 'dialogs', function DocumentsUploadDirectiveFactory($state, $window, dialogs): ng.IDirective {

    function link(scope, elem, attr, ctrl) {
        elem.bind('click', ctrl.displayDocumentsList)
    }


    return {
        restrict: 'AE',
        scope: {
            config: '=documentsUpload',
            applicationStatus: '=applicationStatus'
        },
        bindToController: true,
        controllerAs: 'docUploadCtrl',
        link: link,
        controller: DocumentsUploadController
    }
}]

angular
    .module('app')
    .directive('documentsUpload', DocumentsUploadDirectiveFactory)