 
import { SingleDocumentUploader, MultiDocumentUploader } from '../controls/documentsUpload/DocumentUploadConfig';

class ContactUploaderProvider implements ng.IServiceProvider {

    $get = ['ContactDocument',
        function (ContactDocument) {

            class ContactUploader implements SingleDocumentUploader, MultiDocumentUploader {
                constructor(
                    private contactId: number,
                    private documentType: number) {
                }

                getDocumentType() {
                    return this.getDocumentTypes()
                        .then((documentTypes) => {
                            if (angular.isArray(documentTypes) && documentTypes.length > 0) {
                                return documentTypes[0];                                    
                            }
                        });
                }

                getDocumentTypes() {                       
                    return ContactDocument.query({
                            documentType: this.documentType,
                            contactId: this.contactId
                        }).$promise
                }

                prepareUpload(documentType: number) {
                    return ContactDocument.prepareUpload({
                        documentType: this.documentType,
                        contactId: this.contactId
                    }).$promise;
                }

                uploadSuccess(document, uploadDescription) {
                    return ContactDocument.uploadSuccess({
                        contactId: this.contactId,
                        documentId: uploadDescription.documentId,                            
                        files: document.files,
                        description: document.description,
                        documentTypeId: this.documentType
                    }).$promise;
                }

                uploadFailed(uploadDescription, error) {
                    return ContactDocument.uploadFailed({
                        documentId: uploadDescription.documentId,
                        errorCode: error.code,
                        errorMessage: error.message
                    }).$promise;
                }
            }

            return ContactUploader;
        }];
}

angular
    .module('app')
    .provider('ContactUploader', ContactUploaderProvider);

