 
import { DocumentUploadConfig, MultiDocumentUploader } from '../DocumentUploadConfig'

class DocumentsListModalController {

    isApplicant: boolean
    isOfficeUser: boolean
    hasDocuments: boolean
    showApproveButton: boolean

    static $inject = ['$q', '$rootScope', '$uibModal', '$uibModalInstance', 'DocumentUpload', 'documentTypes', 'Auth', 'config', 'applicationStatus'];
    constructor(private $q, private $rootScope, private $uibModal, private $uibModalInstance, private DocumentUpload, public documentTypes, private Auth, private config: DocumentUploadConfig<MultiDocumentUploader>, private applicationStatus) {
        this.isApplicant = (Auth.user.role == 'APP');
        this.isOfficeUser = (Auth.user.role == 'OFF');

        this.hasDocuments = documentTypes.documents ? true : false;

        if (this.documentTypes && this.documentTypes.length > 0 && this.documentTypes[0].documentType === 49) {
            this.showApproveButton = false;
        }
    }

    statUploadProcess(documentType) {

        this.$uibModal.open({
            templateUrl: 'controls/documentsUpload/documentModal/multiPage/documentUploadModal.html',
            controller: 'DocumentUploadModalController as dumCtrl',
            backdrop: false,
            resolve: {
                documentType: () => documentType,
                documentDefinition: () => this.DocumentUpload.documentDefinition({ documentType: documentType }),
                uploader: () => this.config.uploader,
                files: () => [],
                hideDescription: () => this.config.hideDescription,
                onUpdateHandler: () => this.config.onUpdateHandler
            }
        }).result
        .then(() => this.loadPhoto())
        .then((documentTypes) => {
            this.documentTypes = documentTypes
        })
    }

    openDocument(document) {
        this.$uibModal.open({
            templateUrl: 'controls/documentsUpload/documentModal/multiPage/documentDisplayModal.html',
            controller: 'DocumentDisplayModalController as ddmCtrl',
            backdrop: false,
            resolve: {
                documentType: () => document.documentType,
                documentDefinition: () => this.DocumentUpload.documentDefinition({ documentType: document.documentType }),
                uploader: () => this.config.uploader,

                document: () => document,
                onDeleteHandler: () => this.config.onDeleteHandler,
                onUpdateHandler: () => this.config.onUpdateHandler,
                onApproveHandler: () => this.config.onApproveHandler,

                applicationStatus: () => this.applicationStatus
            }
        }).result
        .then(() => this.loadPhoto())
        .then((documentTypes) => {
            this.documentTypes = documentTypes
        })

    }

    private loadPhoto() {
        return this.config.uploader.getDocumentTypes();
    }


    stickPreview(document) {
        this.$rootScope.$broadcast('document:selected', document)
    }


    close() {
        this.$uibModalInstance.dismiss('cancel')
    }

    statusName(document) {

        if (!this.showApproveButton && (document.documentType === 49 || document.documentType === 50)) {
            return 'uploaded';
        }
            
        switch (document.status) {
            case 1:
                return 'approved'
            case 2:
                return 'awaiting approval'
            case 3:
                return 'rejected'
            case 4:
                return 'replaced'
            case 5:
                return 'deleted'
            case 6:
                return 'approved by Interviewer/Agent'
            case 7:
                return 'reviewed by interviewer'
            case 8:
                return 'reviewed by agent'
            default:
                return ''
        }
    }
}

angular
    .module('app')
    .controller('DocumentsListModalController', DocumentsListModalController)