import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert'

export class EditApplicationController {

    applicant: any
    application: any

    program: any;
    qualifications: any;
    passport: any;
    visa: any;

    applicationId: number
    applicantUserId: number
    applicationAgencyId: number
    applicationInterviewerId: number


    $dirty: boolean = false

    alerts: IAlertConfig[] = []

    static $inject = ['$scope', 'applicationId', 'ApplicationReviewItemsAny'];
    constructor(protected $scope, applicationId: number, protected ApplicationReviewItemsAny) {
        this.applicationId = applicationId
    }
    public CheckAnyReviewPending(applicationId:number) {
        this.ApplicationReviewItemsAny.get({ applicationId: this.applicationId })
            .$promise
            .then(resp => {
                if (resp == null || !_.isBoolean(resp.result) || resp.result === false) {
                    return;
                }

                this.alerts.push({
                    type: 'warning',
                    msg: 'Applicant changes must be approved to update Share',
                    dismissOnTimeout: 10000
                });
            });
    }
}