 

class AgencyTerritoryFeeDirective implements ng.IDirective {
    restrict = 'AE'
    controller = AgencyTerritoryFeeController
    controllerAs = 'ctrl'
    bindToController = true
    templateUrl = 'controls/agencyContracts/agencyTerritoryFee.html'
    scope = {
        data: '=',
        agencyContractData: '=',
        forceReadOnly: '='
    }
}

class AgencyTerritoryFeeController {
    data: any;
    localAgreementDetails: any;
    currencyList: any[];
    isEditable: boolean;
    forceReadOnly: boolean;

    agencyContractData: any;


        agencyId: number;
        contractId: number;
        $dirty: boolean = false;
        isOfficeUser: boolean = false;

    static $inject = ['$rootScope', '$scope', 'Auth', '$q', 'FinanceLookup', 'AgencyContract', 'dialogs','AgencyTerritory'];
    constructor(private $rootScope, private $scope, private Auth, private $q, FinanceLookup, private AgencyContract, private dialogs, private AgencyTerritory) {

        this.isOfficeUser = this.Auth.user.isOfficer;

        FinanceLookup.allCurrency(resp => {
            this.currencyList = resp;
        });        
    }

    $onInit() {
        this.data.currencyCode = this.data.currencyCode || "";

        if (this.forceReadOnly) {
            this.isEditable = false;
        }
        else {
            if (this.isOfficeUser) {
                //Office users can edit fees after the agent has submitted them, until the contract is accepted 
                //removing agencyContractData.isAgentInformationSubmited because
                //P4-2030 - Office users should be able to edit Fees unless the contract is accepted(remove the condition where agent fees must be submitted).This makes the logic easier(screenshot - 1)
                this.isEditable = !this.agencyContractData.isAccepted;
            }
            else {
                this.isEditable = (this.agencyContractData.isAgentEmailed && !this.agencyContractData.isAgentInformationSubmited) && this.agencyContractData.isNewContract;   //result.showSubmitFees;
            }
        }
    }
    addNewFee(contractFeeCategoryId) {
        var nwFee = {
            isNewFee: true,
            contractFeeTypeId: 0,
            contractFeeType: '',
            contractFeeCategoryId: contractFeeCategoryId,
            minAmount: 0,
            maxAmount: 0,
            amount: 0
        };
        this.data.localFees.push(nwFee);
    }
    editFees() {
        this.isEditable = true;
        this.agencyContractData.canEditable = true;
    }
    completedFeesEntry() {
        //todo add displog box
        this.AgencyTerritory.completedFeesEntry(this.data)
            .$promise
            .then((results) => {
                this.isEditable = false;
                this.agencyContractData.canEditable = false;

                this.data.requiredFeesUpdate = false;
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while saving your Agency Territory Fees information. Please try again after a while.')
            });        
    }
}

angular
    .module('app')
    .directive('agencyTerritoryFee', () => new AgencyTerritoryFeeDirective());