 

class MenuDirective implements ng.IDirective {
    restrict = 'AE';
    controllerAs = 'menuCtrl';
    controller = MenuController;
    bindToController = true;
    templateUrl = 'controls/menu/menu.html';
}

class MenuController {
        
    menu;
    name;
    unreadMessages;
    menuCount: number;

    unsubscribe;

    static $inject = ['$injector', '$scope', '$state', 'Menu', 'Communication', 'Auth', 'AgencyContract'];
    constructor(private $injector, private $scope, private $state, private Menu, private Communication, private Auth, private AgencyContract) {


        $scope.$watch(() => this.name, (newValue) => {
            this.menu = Menu(newValue);

            if (this.unsubscribe) {
                this.unsubscribe();
            }

            this.unsubscribe = $scope.$on(`update_menu:${newValue || ''}`, () => {
                this.resolve();
            });
            this.resolve();
        });




           


        //$scope.$on('commun:update', () => {
        //    this.updateTotalUnreadMessages();
        //});

        //$scope.$on('commun:contract-update', () => {
        //    this.getAgencyContractToApproveCount()
        //});

        //this.updateTotalUnreadMessages();

        //this.getAgencyContractToApproveCount();
    }


    resolve() {
        for (let item of this.menu.items) {
            this.resolveValues(item);
            if (item.items) {
                for (let subitem of item.items) {
                    this.resolveValues(subitem);
                }
            }
                
        }
    }

    resolveValues(item) {
        this.getValue('name', item);
        this.getValue('param', item);
        this.getValue('badge', item, 0);
        this.getValue('visibility', item, true);
    }

        
    getValue(prop, item, def?) {
        var dest = `_${prop}`;
        var value = item[prop];

        if (angular.isFunction(value) || angular.isArray(value)) {
            var result = this.$injector.invoke(value, item, { $scope: this.$scope });
            if (result && (typeof result.then === 'function')) {
                result.then((v) => item[dest] = v);
            } else {
                item[dest] = result;
            }
        } else if (value === undefined && def !== undefined) {
            item[dest] = def;
        } else {
            item[dest] = value;
        }
    }

    isActive(item) {
        return this.$state.includes(item.linkStage || item.state);
    }

    //private updateTotalUnreadMessages() {
    //    if (this.Auth.user.isOfficer || !this.Auth.user.isLoggedIn) {
    //        return;
    //    }

    //    this.Communication
    //        .promise
    //        .then((communication) => {
    //            this.unreadMessages = communication.totalUnreadMessages();
    //        });
    //}
    //private getAgencyContractToApproveCount() {
    //    if (!this.Auth.user.isLoggedIn && !this.Auth.user.isAgent) {
    //        return;
    //    }
    //    var agencyId = this.Auth.user.roleDetails.agencyId;

    //    if (agencyId) {
    //        this.AgencyContract.getContractInformationForMenu({ agencyId }, (result) => {
    //            this.menuCount = result.menuCount;
    //        });
    //    }
    //}
}



angular
    .module('app')
    .directive('menu', () => new MenuDirective());

