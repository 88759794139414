 
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';



class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        var workQueues: state<AccessLevelView> = {
            name: 'share',
            url: '/share',
            abstract: true,
            views: {
                content: {
                    everyone: {
                        template: 'templates/empty.html'
                    }
                }
            },
            states: [
                {
                    name: 'application',
                    url: '/application/{applicationGuid}',
                    views: {
                        '@': {
                            everyone: {
                                template: 'areas/share/application/application.html',
                                controller: 'ShareApplicationController as ctrl'
                            }
                        }
                    },
                    resolve: {
                        applicationGuid: ['$stateParams', function ($stateParams) {
                            return $stateParams.applicationGuid
                        }],
                        application: ['ShareApplication', 'applicationGuid', function (ShareApplication, applicationGuid) {
                            return ShareApplication.get({ applicationGuid: applicationGuid }).$promise
                        }]
                    }
                },
                {
                    name: 'backgroundQuestions',
                    url: '/backgroundQuestions/{backgroundQuestionsGuid}',
                    views: {
                        '@': {
                            everyone: {
                                template: 'areas/share/backgroundQuestions/backgroundQuestions.html',
                                controller: 'ShareBackgroundQuestionsController as ctrl'
                            }
                        }
                    },
                    resolve: {
                        backgroundQuestionsGuid: ['$stateParams', function ($stateParams) {
                            return $stateParams.backgroundQuestionsGuid
                        }],
                        backgroundQuestions: ['ShareBackgroundQuestions', 'backgroundQuestionsGuid', function (ShareBackgroundQuestions, backgroundQuestionsGuid) {
                            return ShareBackgroundQuestions.get({ backgroundQuestionsGuid: backgroundQuestionsGuid }).$promise
                        }]
                    }
                },
                {
                    name: 'childcareExperience',
                    url: '/childcareExperience/{childcareExperienceGuid}',
                    views: {
                        '@': {
                            everyone: {
                                template: 'areas/share/childcareExperience/childcareExperience.html',
                                controller: 'ShareChildcareExperienceController as ctrl'
                            }
                        }
                    },
                    resolve: {
                        childcareExperienceGuid: ['$stateParams', function ($stateParams) {
                            return $stateParams.childcareExperienceGuid
                        }],
                        childcareExperience: ['ShareChildcareExperience', 'childcareExperienceGuid', function (ShareChildcareExperience, childcareExperienceGuid) {
                            return ShareChildcareExperience.get({ childcareExperienceGuid: childcareExperienceGuid }).$promise
                        }]
                    }
                },
                {
                    name: 'interviewReport',
                    url: '/interviewReport/{interviewReportGuid}',
                    views: {
                        '@': {
                            everyone: {
                                template: 'areas/share/interviewReport/interviewReport.html',
                                controller: 'ShareInterviewReportController as ctrl'
                            }
                        }
                    },
                    resolve: {
                        interviewReportGuid: ['$stateParams', function ($stateParams) {
                            return $stateParams.interviewReportGuid
                        }],
                        interviewReport: ['ShareInterviewReport', 'interviewReportGuid', function (ShareInterviewReport, interviewReportGuid) {
                            return ShareInterviewReport.get({ interviewReportGuid: interviewReportGuid }).$promise
                        }]
                    }
                },
                {
                    name: 'telephoneReferenceCheck',
                    url: '/telephoneReferenceCheck/{telephoneReferenceCheckGuid}',
                    views: {
                        '@': {
                            everyone: {
                                template: 'areas/share/telephoneReferenceCheck/telephoneReferenceCheck.html',
                                controller: 'ShareTelephoneReferenceCheckController as ctrl'
                            }
                        }
                    },
                    resolve: {
                        telephoneReferenceCheckGuid: ['$stateParams', function ($stateParams) {
                            return $stateParams.telephoneReferenceCheckGuid
                        }],
                        telephoneReferenceCheck: ['ShareTelephoneReferenceCheck', 'telephoneReferenceCheckGuid', function (ShareTelephoneReferenceCheck, telephoneReferenceCheckGuid) {
                            return ShareTelephoneReferenceCheck.get({ telephoneReferenceCheckGuid: telephoneReferenceCheckGuid }).$promise
                        }]
                    }
                },
                {
                    name: 'shareApplication',
                    url: '/shareApplication/{shareApplicationGuid}',
                    views: {
                        '@': {
                            everyone: {
                                template: 'areas/share/shareApplication/shareApplication.html',
                                controller: 'ShareShareApplicationController as ctrl'
                            }
                        }
                    },
                    resolve: {
                        shareApplicationGuid: ['$stateParams', function ($stateParams) {
                            return $stateParams.shareApplicationGuid
                        }],
                        shareApplication: ['ShareShareApplication', 'shareApplicationGuid', function (ShareShareApplication, shareApplicationGuid) {
                            return ShareShareApplication.get({ shareApplicationGuid: shareApplicationGuid }).$promise
                        }]
                    }
                },
                {
                    name: 'feeSheet',
                    url: '/feeSheet/{feeSheetGuid}',
                    views: {
                        '@': {
                            everyone: {
                                template: 'areas/share/feeSheet/shareFeeSheet.html',
                                controller: 'ShareFeeSheetController as ctrl'
                            }
                        }
                    },
                    resolve: {
                        feeSheetGuid: ['$stateParams', function ($stateParams) {
                            return $stateParams.feeSheetGuid
                        }],
                        feeSheetInfo: ['ShareFeeSheet', 'feeSheetGuid', function (ShareFeeSheet, feeSheetGuid) {
                            return ShareFeeSheet.get({ feeSheetGuid: feeSheetGuid }).$promise
                        }]
                    }
                }
            ]
        }

        RoutingProvider.addState(workQueues, 'app')
    }
}

angular
    .module('app')
    .config(RoutingConfig)