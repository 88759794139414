 

var StopEventDirectiveFactory = ['$state', '$window', '$parse', 'dialogs', function StopEventDirectiveFactory($state, $window, $parse, dialogs): ng.IDirective {
    function link(scope, element, attr) {
        element.bind('click', function (e) {
            e.stopPropagation();
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}]

angular
    .module('app')
    .directive('stopEvent', StopEventDirectiveFactory);