
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        var extension: state<AccessLevelView> = {
            name: 'extension',
            url: '/extension',                
            views: {
                content: {
                    applicant: {
                        controller: 'ExtensionController as ctrl',
                        template: 'areas/extension/applicant.html'
                    }
                }
            },
            resolve: {
                $title: () => 'Extension'
            },
            states: [
                {
                    name: 'extendOption',
                    url: '/',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtendOptionController as ctrl',
                                template: 'areas/extension/extendOption/extendOption.html'
                            }
                        }
                    }
                },
                {
                    name: 'contactInfo',
                    url: '/contactInfo',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtensionContactInfoController as ctrl',
                                template: 'areas/extension/contactInfo/contactInfo.html'
                            }
                        }
                    }
                },
                {
                    name: 'childAgeWilling',
                    url: '/childAgeWilling',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtensionChildAgeWillingController as ctrl',
                                template: 'areas/extension/childAgeWilling/childAgeWilling.html'
                            }
                        }
                    }
                },
                {
                    name: 'extensionProfile',
                    url: '/extensionProfile',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtensionProfileController as ctrl',
                                template: 'areas/extension/profile/extensionProfile.html'
                            }
                        }
                    }
                },
                {
                    name: 'extensionTerm',
                    url: '/extensionTerm',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtensionTermController as ctrl',
                                template: 'areas/extension/extensionTerm/extensionTerm.html'
                            }
                        }
                    }
                },
                {
                    name: 'childcareExperience',
                    url: '/childcareExperience',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtensionChildcareExperienceController as ctrl',
                                template: 'areas/extension/childcareExperience/childcareExperience.html'
                            }
                        }
                    }
                },
                {
                    name: 'extensionDriving',
                    url: '/extensionDriving',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtensionDrivingController as ctrl',
                                template: 'areas/extension/extensionDriving/extensionDriving.html'
                            }
                        }
                    }
                },
                {
                    name: 'confirmPlacement',
                    url: '/confirmPlacement',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtensionConfirmPlacementController as ctrl',
                                template: 'areas/extension/confirmPlacement/confirmPlacement.html'
                            }
                        }
                    }
                },
                {
                    name: 'educationStatus',
                    url: '/educationStatus',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ExtensionEducationStatusController as ctrl',
                                template: 'areas/extension/educationStatus/educationStatus.html'
                            }
                        }
                    }
                }

            ]
        };

        RoutingProvider.addState(extension, 'app');
    }
}

angular
    .module('app')
    .config(RoutingConfig);