 

var ScrollToTopOnDirectiveFactory = ['$timeout', '$anchorScroll', function ScrollToInvalidDirectiveFactory($timeout, $anchorScroll): ng.IDirective {

    function link(scope, elem, attrs, ctrls) {

        function temp() {
            $timeout(function () {
                angular.element(elem)[0].scrollTop = 0;
            });
        }

        var unsubscribe = scope.$on(attrs.scrollToTopOn, temp);
        scope.$on('$destroy', function () {
            unsubscribe();
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}]

    


angular
    .module('app')
    .directive('scrollToTopOn', ScrollToTopOnDirectiveFactory);

