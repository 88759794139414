import _ from 'lodash-es'

class ColumnsSettingsModalController {

    title = "Select columns";
    sortedColumns = [];
        
    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'columns', 'columnsSettings'];
    constructor(private $scope, private dialogs, private $uibModalInstance, columns, private columnsSettings) {

        columns = _.orderBy(columns, 'displayName')
            
        let col, row, i = 0;
        let rows = Math.ceil(columns.length / 2);
        for (row = 0; row < rows; ++row) {
            for (col = 0; col < 2; ++col) {
                if (columns[row + col * rows]) {
                    this.sortedColumns[i++] = columns[row + col * rows];
                }
            }
        }
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    save() {
        this.$scope.$broadcast('submitted', true);
            
        this.$uibModalInstance.close(this.columnsSettings);
    }
}

angular
    .module('app')
    .controller('ColumnsSettingsModalController', ColumnsSettingsModalController);
