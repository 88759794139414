
class LockScreenDirective implements ng.IDirective {
    restrict = 'AE';
    controllerAs = 'ctrl';
    controller = LockScreenController;
    replace = true;
    bindToController = true;
    templateUrl = 'controls/lockScreen/lockScreen.html';
}


class LockScreenController {
        
    private showClass = 'show'; 

    sidebar;
    expanded: boolean;

    static $inject = ['$element', '$scope'];
    constructor(private $element, $scope) {
        $scope.$on('appLocked', () => {
            this.$element.addClass(this.showClass);
        })
    }
}



angular
    .module('app')
    .directive('lockScreen', () => new LockScreenDirective());