import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService';
import _ from 'lodash-es';

interface WorkQueueParams {
    id: number;
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}

export class AgencyCommissionsController {
    agencyCommissions;

    editFrom;

    selectedAgencyCommission;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public agencyCommissionsPage: {
        agencyCommissions: any[];
        totalCount: number;
    };
    params: WorkQueueParams;
    loading: boolean = false;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;
    financeArea: boolean;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    static $inject = ['$scope', 'Auth', '$q', '$state', 'dialogs', '$uibModal', 'GridExport', 'AgencyCommission','agencyId'];
    constructor(private $scope, private Auth, private $q, private $state, private dialogs, private $uibModal, private GridExport, private AgencyCommission, private agencyId) {

        this.financeArea = Auth.isInBusinessArea(BusinessArea.FINANCE);
        this.selections = [];
        this.selectedGridItems = [];

        this.params = {
            id: agencyId,
            page: 0,
            pageSize: 50,
            orderBy: 'commissionId',
            descending: false
        };

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {

                this.selectedGridItems = [];
            }
        }, true); 
    }

    getAgencyCommissions(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'commissionId';
            this.params.descending = false;
        }

        this.refresh();
    }

    refresh() {
        this.loading = true;

        new this.AgencyCommission.all(this.params, (result) => {
            this.agencyCommissionsPage = result;

            this.lastPage = Math.floor(this.agencyCommissionsPage.totalCount / this.params.pageSize);

            this.selections = [];

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
        }, () => {
            this.loading = false;
        });
    }

    public export() {
        this.GridExport.saveAsCSV('.agencyCommissionsTable', 'export.csv', this.selections.length != 0)
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.agencyCommissionsPage.agencyCommissions) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.agencyCommissionsPage.agencyCommissions) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                commissionId: item.commissionId
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.agencyCommissionsPage.agencyCommissions) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.commissionId === item.commissionId);
        }
    }

    changePageSize() {
        this.lastPage = Math.floor(this.agencyCommissionsPage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }
}


angular
    .module('app')
    .controller('AgencyCommissionsController', AgencyCommissionsController);