
class TermsOfBusinessController {

    agreementData: string

    static $inject = ['$rootScope', 'Auth', '$uibModal', '$q', 'dialogs', 'agencyId', 'contractId', 'AgencyContract'];
    constructor(private $rootScope, private Auth, private $uibModal, private $q, private dialogs, agencyId, contractId, private AgencyContract) {
        if (contractId) {
            var data = {
                agencyId: agencyId,
                contractid: contractId
            };

            this.AgencyContract.getAgencyTermsOfBusiness({ agencyId: agencyId }, data)
                .$promise
                .then((results) => {
                    this.agreementData = results;
                }, () => {
                    this.dialogs.error('internal error', 'error occured while getting terms of business. please try again after a while.')
                });
        }
    }
}

angular
    .module('app')
    .controller('TermsOfBusinessController', TermsOfBusinessController);