
class HostFamilyFeedbackModalController {

    public familyEmail: any;
    public selectedReason: any;
    public reasons: any[];
    public wasInterviewed: any;
    public interestedHFamilyProposal: any[];
    public reasonToDeclineCode: any;
    public code: any;
    public hasInterviewed: any = true;
    public hasReason: any = true;

    public data: any;

    static $inject = ['$scope', 'applicationId', '$uibModalInstance', 'DeclineInterestedHostFamilyReasons', 'InterestedHostFamilyFeedback', 'proposalId', 'interested', 'requestId', 'hostFamilyId', '$state', 'email', 'Auth', 'dialogs', 'InterestedHostFamilyDocuments']
    constructor(private $scope, public applicationId, private $uibModalInstance, public DeclineInterestedHostFamilyReasons, public InterestedHostFamilyFeedback, public proposalId, public interested, public requestId, public hostFamilyId, private $state, public email, private Auth, private dialogs, public InterestedHostFamilyDocuments) {

        this.familyEmail = email;

        this.data = {
            applicationId: this.applicationId,
            interested: this.interested,
            hostFamilyId: this.hostFamilyId,
            requestId: this.requestId,
            proposalId: this.proposalId
        }
    }

    close() {
        this.$uibModalInstance.close();
    }

    saveInformation() {

        this.$scope.$broadcast('submitted', true)
            
        if (!this.interested) {
            if (this.data.$validation()) {

                this.dialogs.confirm('Host Family Feedback - Not Interested', 'Are you sure that you want to reject this family?')
                    .result
                    .then(() => {
                        this.save();
                    });

                this.close();
            }
        } else {
            this.save();
            this.close();
        }
    }

    save() {
        this.InterestedHostFamilyFeedback.save(
            this.data, (result) => {
                if (this.Auth.user.roleDetails.applicationId == this.applicationId) {
                    var url = this.$state.go('app.placement.interestedHF');
                } else {
                    var url = this.$state.go('app.applications.placement.interestedHF');
                }
            }).$promise
    }
}

angular
    .module('app')
    .controller('HostFamilyFeedbackModalController', HostFamilyFeedbackModalController)