import { BusinessArea } from '../../../services/AuthService';


export class FlightLegDetailsModalController {



    static $inject = ['$uibModalInstance', 'dialogs', 'flightLeg', 'departAirport', 'arrivalAirport', 'airline'];
    constructor(public $uibModalInstance, private dialogs, private flightLeg, departAirport, arrivalAirport, airline) {
        flightLeg.departAirportName = departAirport.airportName;
        flightLeg.arrivalAirportName = arrivalAirport.airportName;
        flightLeg.airlineName = airline.name;
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}