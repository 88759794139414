
class ResetPasswordController {
        
    redirectionDeley: number = 5;
    isInvalid: boolean;
    isError: boolean = false;
    passwordChanged: boolean;
    reason: string;
    user: any;

    static $inject = ['$scope', '$timeout', '$state', 'dialogs', 'Auth', 'token', 'validToken'];
    constructor(private $scope, private $timeout, private $state, private dialogs, private Auth, token, validToken) {
        this.user = {
            token: token
        };

        this.isInvalid = validToken.isInvalid;

        if (validToken && validToken.statusCode == 500) {
            this.isError = true;
            this.reason = 'Internal error: Error occured while reset password. Please try again after a while.';
        }
        else if (validToken.isInvalid) {

            switch (validToken.reason) {
                case 'expired':
                    this.reason = 'Token has expired.';
                    break;
                case 'invalid':
                default:
                    this.reason = 'Token is invalid.';
            }
        }
    }

    reset() {
        this.$scope.$broadcast('submitted', true);

        if (this.$scope.resetForm.$dirty) {
            this.Auth.resetPassword(this.user)
                .then((data) => 
                    this.Auth.signin({
                        login: data,
                        password: this.user.newPassword
                    })
                )
                .then(() => {
                    this.passwordChanged = true;
                    this.$timeout(() => {
                        //$state.transitionTo('app.home', {}, { reload: true, inherit: true, notify: true });
                    }, this.redirectionDeley * 1000);

                }, (error) => {
                    this.dialogs.error('Error', error);
                });
        };
    }
}

angular
    .module('app')
    .controller('ResetPasswordController', ResetPasswordController);