
class TermsAndConditionsController {

    public name: string
    termsAndConditions: any

    static $inject = ['Auth', 'acceptedTC'];
    constructor( Auth, public acceptedTC) {

        Auth.termsAndConditions({ acceptedTC: acceptedTC })
            .then((result) => {
                this.termsAndConditions = result;
                var user = Auth.user;
                this.name = user.firstName + ' '  + user.lastName;
            });
    }
}

angular
    .module('app')
    .controller('TermsAndConditionsController', TermsAndConditionsController);