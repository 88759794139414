
class ExtensionChildcareExperienceModalController {

    static $inject = ['$uibModalInstance', 'USAuPairs', 'title', 'childcareExp', '$scope'];
    constructor(private $uibModalInstance, private USAuPairs, public title: string, public childcareExp, private $scope) {

    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    save() {            
        this.$scope.$broadcast('submitted', true);
        var isValid = this.childcareExp.usChildcareExperience.$validation();
        this.childcareExp.usChildcareExperience.matchId = this.childcareExp.matchId;
            
        if (isValid) {
            this.USAuPairs.saveChildcareExp({ matchId: this.childcareExp.matchId }, this.childcareExp.usChildcareExperience)            
                .$promise
                .then((data) => {
                    this.$uibModalInstance.close(this.childcareExp);
                });
        }
    }
}

angular
    .module('app')
    .controller('ExtensionChildcareExperienceModalController', ExtensionChildcareExperienceModalController);
