 

class ChildcareItemDirective implements ng.IDirective {
    restrict = 'AE'
    controllerAs = 'ctrl'
    controller = ChildcareItemController
    bindToController = true
    templateUrl = 'controls/childcare/childcareItem.html'
    transclude = true
    link = function (scope, element, attrs, ctrl, transclude) {
            
    }
    scope = {
        model: '=',
        snapshot: '='
    }
}

class ChildcareItemController {

    public model

    static $inject = ['$injector', '$scope']
    constructor(private $injector, private $scope) {
    }

}

angular
    .module('app')
    .directive('apChildcareItem', () => new ChildcareItemDirective())