 

class SectionSummary {


    type: string;
    content: string;

    constructor(name) {
        this.type = 'alert-warning';
        this.content = '<strong>Changes Required</strong> - Please can you add some more information for your <a class="alert-link" href="#additional">Additional Information</a> question, it needs more detail about your free time.';
    }

}

class SectionSummaryProvider implements ng.IServiceProvider {
    $get () {
        return SectionSummary;
    }
}

angular
    .module('app')
    .provider('SectionSummary', SectionSummaryProvider);
