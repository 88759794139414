import { InsuranceFees } from '../../../models/finance/IInsuranceFee';
import { IInsurance } from '../../../models/finance/IInsurance';
import { IInsuranceCombination } from '../../../models/finance/IInsuranceCombination';
import { InsuranceType } from '../../../models/finance/InsuranceType';

class ApplicantPaymentsController {

    public payment: any = {}

    public InsuranceFees = InsuranceFees
    invoice: any;
    feeSheetUploadConf: any;
    applicationId: number;
    appAgencyId: number;
    appUserId: number;
    isPaymentDue: boolean;
    showInvoiceDetails: boolean;
    isOnlineOrAwaitingOrToBeMatch: boolean;
    isOnlineOrAwaitingOrRegister: boolean;
    isProgramFeesEntered: boolean = false;
    isGerman2018: boolean;
    hasPaidAndStatusAct: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    applicationInstance;
    isPXInsuranceRequired: boolean;

    useMedicalInsuranceInsteadOfPX: boolean;
    isAgencyGB: boolean;
    isAgencyDE: boolean;
    isPLC: boolean;
    isAPACTorREM: boolean;
    InsuranceURL;

    static $inject = ['$scope', '$rootScope', 'Invoice', 'applicationObservable', 'secureTrading',
        'ApplicationUploader', 'applicationId', 'placementInfo', 'paymentOptions', 'Auth', 'applicationStatus',
        'ExternalResource']
    constructor(private $scope: angular.IScope, private $rootScope, public Invoice, public applicationObservable, secureTrading,
        private ApplicationUploader, applicationId, placementInfo, private paymentOptions, private Auth, private applicationStatus,
        public ExternalResource) {

        this.applicationId = applicationId;

        applicationObservable.subscribe(applicationInstance => {
            this.applicationInstance = applicationInstance;

            this.appAgencyId = applicationInstance.agencyId;
            this.appUserId = applicationInstance.userId;

            this.isOnlineOrAwaitingOrToBeMatch = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA' || applicationInstance.status === 'TBM');

            this.isOnlineOrAwaitingOrRegister = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA');

            this.isGerman2018 = applicationInstance.agencyId === 1210 && placementInfo.arrivalDate >= new Date(2018, 0);

            this.showInvoiceDetails = !this.isGerman2018 && (applicationInstance.status === 'PLC' || applicationInstance.status === 'ACT' || applicationInstance.status === 'REM' || applicationInstance.status === 'INA' || applicationInstance.status === 'APD' || applicationInstance.status === 'AWL');

            this.isOfficeUser = Auth.user.isOfficer;
            this.isApplicant = Auth.user.isApplicant;


            if (this.showInvoiceDetails) {
                this.Invoice.current({ applicationId: applicationId }).$promise
                    .then((data) => {
                        this.invoice = data;
                        this.isPaymentDue = (this.invoice.invoiceStatus === 'DUE');
                        this.isProgramFeesEntered = (this.invoice.id > 0);
                        this.hasPaidAndStatusAct = applicationInstance.status === 'ACT' && this.invoice.invoiceStatus === 'PAID';
                    });
            }
            this.useMedicalInsuranceInsteadOfPX = placementInfo.arrivalDate < new Date(2021, 0);

            this.isAgencyGB = applicationInstance.countryCode === 'GB';
            this.isAgencyDE = (applicationInstance.countryCode === 'DE' || applicationInstance.countryCode === 'AT' || applicationInstance.countryCode === 'CH');
            this.isPLC = applicationInstance.status === 'PLC';
            this.isAPACTorREM = (applicationInstance.status === 'ACT' || applicationInstance.status === 'REM');

            if (this.isAPACTorREM || (!(this.isAgencyDE) && this.isPLC)) {
                this.ExternalResource.preArrivalInsuranceURL({
                    applicationId: applicationId
                })
                    .$promise
                    .then((result) => {
                        this.InsuranceURL = result.returnurl;
                    });
            }
        })

        this.payment.siteReference = secureTrading.siteReference;

        this.feeSheetUploadConf = {
            uploader: new ApplicationUploader(applicationId, 19),
            hideDescription: true,
            msg: {
                upload: 'Upload Fee Sheet',
                processing: 'Show Fee Sheet',
                uploaded: 'Show Fee Sheet',
                approved: 'Approved Fee Sheet'
            }
        }


        $scope.$watch(() => this.invoice && this.invoice.unpaidInsurances, (newValue, oldValue) => {
            if (newValue) {
                var selectedInsurances = newValue.filter(insurance => insurance.selected) as any[];
                //var payingInsurance: models.finance.IInsurance = null // the insurance that we're actually paying for

                // set the paying invoice accordingly
                // there can be only one    type of payment as long as paying insurance is concerned and surprisingly it's not a flag so we need to calculate it manualy :/
                if (selectedInsurances.length > 1) { // this is where the fun starts

                    var x = this.invoice
                        .unpaidInsuranceCombinations
                        .filter(i =>
                            i.containingFees.every(fee => selectedInsurances.some(ins => ins.feeType == fee)) &&
                            selectedInsurances.every(ins => i.containingFees.some(fee => ins.feeType == fee))
                        );
                    if (x.length == 1) {
                        selectedInsurances = x;
                    }
                }

                this.invoice.insuranceType = selectedInsurances.length > 0 ? selectedInsurances.map(i => i.insuranceType).join() : InsuranceType.None
                var selectedInsurancesDue: number = selectedInsurances.reduce((sum, obj) => sum + obj.amount, 0)
                this.invoice.amountDue = this.invoice.totalDue + selectedInsurancesDue - this.invoice.discount
                this.invoice.totalOutstanding = this.invoice.amountDue - this.invoice.totalPaid - this.invoice.refundAmount
            }
        }, true)
    }

    isGerman = () => this.applicationInstance.countryCode == "DE"

    toggleInsurance(insurance: IInsurance) {
        if (this.isApplicant) {

            // skip if insurance was already paid
            if (this.hasPaidAndStatusAct) {
                return;
            }

            // add medical insurance if required
            if (this.isPXInsuranceRequired && (this.isMedInsurance(insurance) || this.isPXInsurance(insurance))) {


                if (insurance.selected) {
                    return;
                }
                else {
                    for (const i of this.invoice.unpaidInsurances) {
                        if (i === insurance) {
                            continue;
                        }
                        if (this.isMedInsurance(i) || this.isPXInsurance(i)) {
                            i.selected = false;
                        }
                    }
                }
            }
            insurance.selected = !insurance.selected;
        }
    }

    

    isComboSelected(combo: IInsuranceCombination) {
        var selectedInsurances = this.invoice.unpaidInsurances.filter(i => i.selected).map(i => i.feeType)
        return combo.containingFees.every(cf => selectedInsurances.indexOf(cf) != -1)
    }

    showOldFeeSheetUpload() {
        return this.applicationInstance && this.applicationInstance.isFeeSheetDocExists && this.applicationInstance.status != 'OLA';
    }

    isPXInsurance(insurance) {
        return insurance.insuranceType === 8 && insurance.feeType === 7;
    }

    isMedInsurance(insurance) {
        return insurance.insuranceType === 2 && insurance.feeType === 3;
    }
}

angular
    .module('app')
    .controller('ApplicantPaymentsController', ApplicantPaymentsController)
