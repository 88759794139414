import _ from 'lodash-es'
import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class ApplicantFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {
            query: { method: 'GET', params: this.queryParams },
            update: { method: 'PUT', params: { applicantId: '@applicantId' } },
            remove: { method: 'DELETE', params: { applicantId: '@applicantId' } }
        }
        this.server = 'applicant'

        this.initialize()
    }


    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'Applicant',
                url: 'applicant/:applicantId',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('birthDate')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicantId: '@applicantId' }
                    }
                }
            },
            {
                name: 'ContactDetails',
                url: 'applicant/:applicantId/contact',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('preferredMethodContact')
                        ),
                        params: { applicantId: '@applicantId' }
                    }
                }
            },
            {
                name: 'EmergencyContact',
                url: 'applicant/:applicantId/emergency_contact',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicantId: '@applicantId' }
                    }
                }
            },
            {
                name: 'Hobby',
                url: 'applicant/:applicantId/hobby',
                params: { applicantId: '@applicantId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertArrayToCheckbox('hobbies')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.convertCheckboxToArray('hobbies')
                        )
                    },

                    availableHobbies: {
                        method: 'GET',
                        url: 'applicant/:applicantId/hobby/available',
                        //transformRequest: this.trans.exclude('note'),
                        params: { applicantId: '@applicantId' },
                        isArray: true
                    }
                }
            }
        ]

        this.process(resources)
    }
}

new ApplicantFactory()
