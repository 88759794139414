///<reference types="@types/angular" />
import _ from 'lodash-es'

class Subscriber {
    group: string
    key: string;
    callback: Function;
}

export class Language {
    constructor(public code, public name) { }

}

export class Translation {

    lang: string = 'en';
    subscribers: Subscriber[] = [];
    currentValues: any = {};

    /*
    Afrikaans (Suid-Afrika) ‎
    አማርኛ (ኢትዮጵያ) ‎
    العربية (المملكة العربية السعودية) ‏
    অসমীয়া (ভাৰত) ‎
    Azərbaycan dili (Azərbaycan) ‎
    Беларуская (Беларусь) ‎
    български (България) ‎
    বাংলা (বাংলাদেশ) ‎
    বাংলা (ভারত) ‎
    bosanski (Bosna i Hercegovina) ‎
    català (català) ‎
    valencià (Espanya) ‎
    ᏣᎳᎩ (ᏣᎳᎩ) ‎
    čeština (Česká republika) ‎
    Cymraeg (Y Deyrnas Unedig) ‎
    dansk (Danmark) ‎
    Deutsch (Deutschland) ‎
    Ελληνικά (Ελλάδα) ‎
    English (United Kingdom) ‎
    English (United States) ‎
    español (España, alfabetización internacional) ‎
    español (México) ‎
    eesti (Eesti) ‎
    euskara (euskara) ‎
    فارسى (ایران) ‏
    suomi (Suomi) ‎
    Filipino (Pilipinas) ‎
    français (Canada) ‎
    français (France) ‎
    Gaeilge (Éire) ‎
    Gàidhlig (An Rìoghachd Aonaichte) ‎
    galego (galego) ‎
    ગુજરાતી (ભારત) ‎
    עברית (ישראל) ‏
    हिंदी (भारत) ‎
    hrvatski (Hrvatska) ‎
    magyar (Magyarország) ‎
    Հայերեն (Հայաստան) ‎
    Indonesia (Indonesia) ‎
    íslenska (Ísland) ‎
    italiano (Italia) ‎
    日本語 (日本) ‎
    ქართული (საქართველო) ‎
    қазақ тілі (Қазақстан) ‎
    ភាសាខ្មែរ (កម្ពុជា) ‎
    ಕನ್ನಡ (ಭಾರತ) ‎
    कोंकणी (भारत) ‎
    한국어(대한민국) ‎
    Кыргыз (Кыргызстан) ‎
    Lëtzebuergesch (Lëtzebuerg) ‎
    lietuvių (Lietuva) ‎
    ລາວ (ລາວ) ‎
    latviešu (Latvija) ‎
    Reo Māori (Aotearoa) ‎
    македонски (Република Македонија) ‎
    മലയാളം (ഇന്ത്യ) ‎
    монгол (Монгол) ‎
    मराठी (भारत) ‎
    Bahasa Melayu (Malaysia) ‎
    Malti (Malta) ‎
    norsk bokmål (Norge) ‎
    नेपाली (नेपाल) ‎
    Nederlands (Nederland) ‎
    nynorsk (Noreg) ‎
    ଓଡ଼ିଆ (ଭାରତ) ‎
    ਪੰਜਾਬੀ (ਭਾਰਤ) ‎
    polski (Polska) ‎
    درى (افغانستان) ‏
    português (Brasil) ‎
    português (Portugal) ‎
    runasimi (Peru) ‎
    română (România) ‎
    русский (Россия) ‎
    سنڌي (پاکستان) ‏
    සිංහල (ශ්‍රී ලංකාව) ‎
    slovenčina (Slovensko) ‎
    slovenščina (Slovenija) ‎
    shqip (Shqipëri) ‎
    српски (Босна и Херцеговина) ‎
    српски (Србија) ‎
    srpski (Srbija) ‎
    svenska (Sverige) ‎
    Kiswahili (Kenya) ‎
    தமிழ் (இந்தியா) ‎
    తెలుగు (భారత దేశం) ‎
    ไทย (ไทย) ‎
    Türkmen dili (Türkmenistan) ‎
    Türkçe (Türkiye) ‎
    Татар (Россия) ‎
    ئۇيغۇرچە (جۇڭخۇا خەلق جۇمھۇرىيىتى) ‏
    українська (Україна) ‎
    اُردو (پاکستان) ‏
    o‘zbek (Oʻzbekiston) ‎
    Tiếng Việt (Việt Nam) ‎
    中文(中国) ‎
    中文(台灣) ‎

    */
        
    public availableLang = [
        new Language('cz', 'Český'),
        new Language('da', 'Dansk'),
        new Language('de', 'Deutsch'),
        new Language('ee', 'Eesti'),
        new Language('en', 'English'),
        new Language('es', 'Español'),
        new Language('fr', 'Français'),
        new Language('hr', 'Hrvatski'),
        new Language('it', 'Italiano'),
        new Language('lv', 'Latviešu'),
        new Language('hu', 'Magyar'),
        new Language('nl', 'Nederlands'),
        new Language('pl', 'Polski'),
        new Language('pt', 'Português'),
        new Language('sk', 'Slovenský'),
        new Language('se', 'Svenska'),
        new Language('yi', 'עִבְרִית'),
        new Language('th', 'ภาษาไทย'),
        new Language('cn', '中文'),
    ];


    constructor(private $http: ng.IHttpService, private $q: ng.IQService) {
    }

    private UpdateSubscriber(subscriber: Subscriber) {
        this.GetGroup(subscriber.group).then((group) => {
            var value = group[subscriber.key]
            subscriber.callback(value);
        });
    }

    GetGroup(group): ng.IPromise<any> {
        if (this.currentValues[group])
            return this.$q.when(this.currentValues[group]);

        return this.$http.get('/translations/' + group + '_' + this.lang + '.json', { cache: true })
            .then((response) => {
                this.currentValues[group] = response.data;
                return response.data;
            });
    }

    Subscribe(subscriber: Subscriber) {
        this.subscribers.push(subscriber);

        this.UpdateSubscriber(subscriber);
    }

    Unsubscribe(subscriber: Subscriber) {
        var index = this.subscribers.indexOf(subscriber);
        if (index >= 0) {
            this.subscribers.splice(index, 1);
        }
    }
        

    ChangeLang(lang) {
        this.lang = lang;
        // reset local cache
        this.currentValues = {};
        // update all subscribers
        _.forEach(this.subscribers,(subscriber: Subscriber) => {
            this.UpdateSubscriber(subscriber);
        });
    }
}



class TranslationProvider implements ng.IServiceProvider {

    translation: Translation;

    handle: any = (key: string, callback: Function) : any => {

        var keys = key.split('.');

        var group = 'default';
        var trans = keys[0];
        if (keys.length > 1) {
            group = keys[0];
            trans = keys[1];
        }

        if (callback) {
            var subs = new Subscriber;
            subs.group = group;
            subs.key = trans;
            subs.callback = callback;

            this.translation.Subscribe(subs);
            return () => {
                this.translation.Unsubscribe(subs);
            };
        } else {
            return this.translation.GetGroup(group);
        }
    }
        
    constructor() {
        this.handle.changeLang = (lang) => {
            this.translation.ChangeLang(lang);
        }

        this.handle.lang = () => this.translation.lang;
        this.handle.availableLang = () => this.translation.availableLang;


    }

    $get = ['$http', '$q', ($http, $q) => {
        if (!this.translation)
            this.translation = new Translation($http, $q);

        return this.handle;
    }];
}

angular
    .module('app')
    .provider('Translation', TranslationProvider);
