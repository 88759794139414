import { IAction } from '../IAction'


export class ArchiveApplicant implements IAction {

    static $inject = ['$uibModal', '$rootScope', 'applicationId']
    constructor(private $uibModal, private $rootScope, private applicationId) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/archiveApplicant/archiveApplicantModal.html',
            controller: ArchiveApplicantModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId,
                rootScope: () => this.$rootScope
            }
        }).result;
    }
}

class ArchiveApplicantModalController {
    public selectedArchiveReason: any;
    public archiveReasonList: any[];
    public commentText: string;

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'applicationId', 'ArchiveApplicant', 'rootScope', 'ApplicationStatus'];
    constructor(private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private applicationId, private ArchiveApplicant, private rootScope, private ApplicationStatus) {
        this.loadArchiveApplicantList();
    }

    reasonDescription = (inter) => `${inter.archiveReason}`;

    loadArchiveApplicantList() {
        this.ArchiveApplicant.query({}, (response) => {
            this.archiveReasonList = response;
        });
    }

    archiveApplicant() {

        if (angular.isObject(this.selectedArchiveReason)) {

            var data = {
                ApplicationId: this.applicationId,
                ArchiveReasonId: this.selectedArchiveReason.archiveReasonId,
                ArchiveReason: this.selectedArchiveReason.archiveReason,
                ArchiveComment: this.commentText
            };

            if (this.selectedArchiveReason.archiveReasonId != 56 || this.selectedArchiveReason.archiveReasonId == 56 && this.commentText) {
                this.ApplicationStatus.archive({ applicationId: this.applicationId}, data)
                    .$promise
                    .then(() => {
                        this.rootScope.$broadcast('archiveApplication');  
                        this.rootScope.$broadcast('applicationCommentsChange', null);
                        this.close();
                });
            } else if (this.selectedArchiveReason.archiveReasonId == 56 && !this.commentText) {
                this.dialogs.error('Validation Error', 'Please provide us more details about the reason you want to archive this application.');
            }

        }
        else {
            this.dialogs.error('Validation Error', 'Please choose your reason for archiving this application.');
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
