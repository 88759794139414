import { IAction } from '../IAction'

export class InvalidActionCall implements IAction {

    static $inject = ['$uibModal']
    constructor(private $uibModal, private headerTitle, private errorMessages) { }

    execute = () => {

        return this.$uibModal.open({
            templateUrl: 'areas/action/invalidActionCall/invalidActionCallModal.html',
            controller: AssignApplicationModalController,
            controllerAs: 'iacCtrl',
            backdrop: false,
            resolve: {
                headerTitle: () => this.headerTitle,
                errorMessages: () => this.errorMessages
            }
        }).result
    }
}

class AssignApplicationModalController {

    static $inject = ['dialogs', '$uibModalInstance', 'headerTitle', 'errorMessages']
    constructor(private dialogs, private $uibModalInstance, public headerTitle, public errorMessages) { }

    close() {
        this.$uibModalInstance.dismiss()
    }

}
