import _ from "lodash-es";

export class BusinessRule {
    components
    combinator;
    lastValue;

    checkIfChanged(model) {
        var oldValue = this.lastValue;
        this.lastValue = this.valid(model);
        return oldValue !== this.lastValue;
    };

    validComponent(component, value) {

        if (_.isObject(value)) {

            var values = _.reduce(value, function (result, val, key) {
                if (val) result.push(key);
                return result;
            }, []);
            return this.validArray(component, values);
        }

        if (_.isArray(value)) {
            return this.validArray(component, value);
        }

        if (_.isUndefined(value)) {
            return this.validPrimitive(component, null);
        }

        return this.validPrimitive(component, value);
    }

    validPrimitive(component, value) {
        var array;
        var matchValue = component.matchValue;

        switch (component.type) {
            case "=":
                return value == (_.isUndefined(matchValue) ? null : matchValue);

            case "!=":
                return value !== (_.isUndefined(matchValue) ? null : matchValue);

            case "contains":
                array = matchValue.split(",");
                return $.inArray(value, array) >= 0;

            case "does not contain":
                array = matchValue.split(",");
                return $.inArray(value, array) < 0;
            default:
                return false;
        }
    }

    validArray(component, values) {
        switch (component.type) {
            case "=":
                if (!component.matchValue) return values.length === 0;
                return values.length === 1 && values[0] === component.matchValue;

            case "!=":
                if (!component.matchValue) return values.length > 0;
                return values.length !== 1 || values[0] !== component.matchValue;

            case "contains":
                var array = component.matchValue.split(",");
                return _.intersection(values, array).length > 0;

            case "does not contain":
                var array = component.matchValue.split(",");
                return _.intersection(values, array).length === 0;
            default:
                return false;
        }
    }

    valid(model) {
        var isAlternative = this.combinator == "or";
        var result = !isAlternative;

        angular.forEach(this.components, (component) => {
            var value = model[component.field];
            var isValid = this.validComponent(component, value);

            if (isAlternative) {
                if (isValid) {
                    result = true;
                    return true;
                }
            } else {
                if (!isValid) {
                    result = false;
                    return false;
                }
            }
            return undefined;
        });
        return result;
    }
}


