 

export class Message {
    id: number
    author: string
    other: any
    date: Date

    constructor(msg: any) {
        angular.extend(this, msg)
    }
}
