import { IAlertConfig } from '../../../models/bootstrap/Alert' 

interface IAirportScope extends angular.IScope {
    iTepImportForm: any
}
export class ITepImportDetailsController {

    alerts: IAlertConfig[] = []
        
    static $inject = ['$scope', 'iTepImports', 'importfile'];
    constructor(private $scope: IAirportScope, private iTepImports, public importfile) {
    }

}
