 
import _ from 'lodash-es'
import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class WorkQueueFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'application'
        this.initialize()
    }

    sampleProgressValue = () => _.sample(['incomplete', 'need-action', 'complete']); 

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'WorkQueue',
                url: 'application',
                actions:
                {
                    all: {
                        method: 'POST',
                        //url: 'application/applicantList'
                        //transformResponse: (data) => {
                        //    if (angular.isString(data)) data = JSON.parse(data);
                        //    for (let app of data.result) {
                        //        app.progress = {
                        //            video: this.sampleProgressValue(),
                        //            references: this.sampleProgressValue(),
                        //            trc: this.sampleProgressValue(),
                        //            interview: this.sampleProgressValue(),
                        //            application: this.sampleProgressValue()
                        //        };
                        //    }
                        //    return data;
                        //}
                    },
                    unassigned: {
                        url: 'application/unassigned',
                        method: 'GET'
                    },
                    export: {
                        url: '/application/export',
                        method: 'POST',
                        responseType: 'arraybuffer',
                        transformResponse: function (data) {
                            return {
                                data: new Blob([data], { type: 'text/csv;charset=utf-8;' })
                            };
                        }
                    }
                }
            }
        ];


        this.process(resources)
    }

}

new WorkQueueFactory()

