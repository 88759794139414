AifsFormController.$inject = ['$scope', '$element', '$attrs', 'MetaForm']
function AifsFormController($scope, $element, $attrs, MetaForm) {
    var self = this;

    var subscription = {};
    this.subscribeField = function (fieldName, callback) {
        if (!subscription[fieldName]) {
            subscription[fieldName] = [];

            $scope.$watch('model.' + fieldName, function (newValue, oldValue) {
                angular.forEach(subscription[fieldName], function (callback) {
                    callback();
                });
            }, true);
        }
        subscription[fieldName].push(callback);
    };

    var mapRulesToQuestion = {}; // for each BusinessQueston we have a list of Question that used the BusinessQueston
    var questions = {};

    var metaForm = new MetaForm($attrs.id);
    metaForm.getBusinessRules(init);



    this.Id = $attrs.id;



    this.$addQuestion = function (question) {
        questions[question.$id] = question;

        if (question.questionRule && question.questionRule.businessRules) {
            angular.forEach(question.questionRule.businessRules, function (rule) {
                if (!mapRulesToQuestion[rule.id])
                    mapRulesToQuestion[rule.id] = [];

                mapRulesToQuestion[rule.id].push(question);
            });
        }
    };

    function init(businessRules) {
        self.businessRules = businessRules;

        // for each of field make a list of rules that will be affected when the field change
        angular.forEach(businessRules, function (rule, ruleName) {
            angular.forEach(rule.components, function (component) {

                self.subscribeField(component.field, function () {
                    if (rule.checkIfChanged.call(rule, $scope.model)) {

                        angular.forEach(mapRulesToQuestion[rule.id], function (question) {

                            if (question && question.$updateRule)
                                question.$updateRule();
                        });
                    }
                });
            });
        });
    }


    $scope.formId = $attrs.id;
    $scope.model.$validation = function () {
        var result = true;
        angular.forEach(questions, function (question: any, questionName) {
            if (!question.$isValid()) {
                result = false;
                return true;
            }
            return undefined;
        });
        return result;
    };

    $scope.$on('submitted', function(event, value) {
        $scope.submitted = value;
    });
}

function AifsFormLink(scope, element, attrs, ctrls) {
    var formCtrl = ctrls[0];
    formCtrl.$metaformVersion = attrs.aifsForm;
}

angular
    .module("aifs.metaform")
    .directive("aifsForm", function () {
        return {
            restrict: 'A',
            require: ['^form'],
            controller: AifsFormController,
            link: AifsFormLink
        };
    });
