import { BaseView, Component } from '../services/RoutingService';
    
type UserRole = 'PUB' | 'APP' | 'INT' | 'BRA' | 'AGY' | 'OFF' | 'PRE' | 'PRE_INT';

export interface AccessLevel<T> {
    controllerAs?: string

    everyone?: T
    anon?: T
    applicant?: T
    interviewer?: T
    agency?: T
    branch?: T
    office?: T
    customer?: T
    staff?: T
    preloggedin?: T
    loggedin?: T
    loggedinNonSocial?: T
    applicantSocial?: T
    invalidApplicant?: T

    [name: string]: T|string
}

export interface AccessLevelView extends AccessLevel<Component>, BaseView {

}


interface AccessLevelDefinition {
    roles?: UserRole[],
    condition?: any[]
}

var definition: AccessLevel<AccessLevelDefinition> = {
    everyone: {},
    anon: { roles: ['PUB'] },
    applicant: { roles: ['APP'] },
    interviewer: { roles: ['INT'] },
    agency: { roles: ['AGY'] },
    branch: { roles: ['BRA'] },
    office: { roles: ['OFF'] },
    customer: { roles: ['PUB', 'APP', 'PRE', 'PRE_INT'] },
    staff: { roles: ['INT', 'BRA', 'AGY', 'OFF'] },
    preloggedin: { roles: ['PRE'] },
    preInterview: { roles: ['PRE_INT'] },
    loggedin: { roles: ['APP', 'INT', 'BRA', 'AGY', 'OFF', 'PRE', 'PRE_INT'] },
    loggedinNonSocial: {
        roles: ['APP', 'INT', 'BRA', 'AGY', 'OFF', 'PRE', 'PRE_INT'],
        condition: ['Auth', function (Auth) {
            return !Auth.user.isSocial;
        }]
    },
    applicantSocial: {
        roles: ['APP'],
        condition: ['Auth', function (Auth) {
            return Auth.user.isSocial;
        }]
    },
    awlApplicant: {
        roles: ['APP'],
        condition: ['ApplicationInstance', 'Auth', function (ApplicationInstance, Auth) {
            return ApplicationInstance(Auth.user.roleDetails.applicationId)
                .toPromise()
                .then(function (data) {
                    var invalidStatus = ['AWL'];
                    if (invalidStatus.indexOf(data.status) >= 0) {
                        console.log('application status is AWL')
                        return true;
                    }

                    return false;
                });
        }]
    },
    invalidApplicant: {
        roles: ['APP'],
        condition: ['ApplicationInstance', 'Auth', function (ApplicationInstance, Auth) {
            return ApplicationInstance(Auth.user.roleDetails.applicationId)
                .toPromise()
                .then(function (data) {
                    var invalidStatus = ['DNQ','REJ','CAN','FAI','TXP','APD','ARC'];
                    if (invalidStatus.indexOf(data.status) >= 0) {
                        console.log('application status is indeed refreshed for invalid')
                        return true;
                    }

                    return false;
                });
        }]
    },
    extensionFlightApplicant: {
        roles: ['APP'],
        condition: ['Auth', 'USAuPairs', function (Auth, USAuPairs) {
            if (Auth.user.usAccessToken.length) {
                return USAuPairs.canAupairExtend({ aupairId: Auth.user.usAuPairId }).$promise.then((response) => {
                    if (response.data && response.data.isOkToShowExtensionMenu && (response.data.isOkToShowHomeFlight || response.data.isOkToShowExtensionFlight)) {
                        console.log('status good to display Extension and flight Menu');
                        return true;
                    }
                    else
                        return false;
                });
            }
        }]
    },
    extensionApplicant: {
        roles: ['APP'],
        condition: ['Auth', 'USAuPairs', function (Auth, USAuPairs) {                   
            if (Auth.user.usAccessToken.length) {                    
                return USAuPairs.canAupairExtend({ aupairId: Auth.user.usAuPairId }).$promise.then((response) => {
                    if (response.data && response.data.isOkToShowExtensionMenu && !(response.data.isOkToShowHomeFlight || response.data.isOkToShowExtensionFlight)) {
                        console.log('status good to display Extension Menu');
                        return true;
                    }
                    else
                        return false;
                });
            }
        }]
    },
    flightApplicant: {
        roles: ['APP'],
        condition: ['Auth', 'USAuPairs', function (Auth, USAuPairs) {
            if (Auth.user.usAccessToken.length) {
                return USAuPairs.canAupairExtend({ aupairId: Auth.user.usAuPairId }).$promise.then((response) => {
                    if (response.data && (response.data.isOkToShowHomeFlight || response.data.isOkToShowExtensionFlight)  && !response.data.isOkToShowExtensionMenu) {
                        console.log('status good to display flights Menu');
                        return (response.data.isOkToShowHomeFlight || response.data.isOkToShowExtensionFlight);
                    }
                    else
                        return false;
                });
            }
        }]
    }
}

class AccessLevelConfig {
    static $inject = ['AccessLevelProvider']
    constructor(AccessLevelProvider) {
        for (let name of Object.keys(definition)) {
            AccessLevelProvider.accesslevel(name, definition[name]);
        }
    }
}

angular
    .module('app')
    .config(AccessLevelConfig);