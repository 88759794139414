import moment from "moment";

export class AgencyFeeSheetController {

    contractsDates;
    selectedContract;

    territories;
    territory;

    financialPeriodId;

    feeSheetInformation: any = {};
    agencyContractFees: any[];
    otherFees: any[];

    static $inject = ['$scope', 'AgencyLookup', 'AgencyContract', 'FeeRegions', 'dialogs', 'year', 'agencyId'];
    constructor(private $scope, private AgencyLookup, private AgencyContract, private FeeRegions, private dialogs, private year, private agencyId) {

        let currentYear = new Date().getFullYear();
        let today = moment().startOf('day').toDate();

        var startDate = (year == currentYear) ? today :  new Date(year, 0, 1);
        var endDate = new Date(year + 1, 0, 0);


        this.FeeRegions.financialPeriods((result: any[]) => {
            for (let p of result) {
                if (p.startDate <= startDate && endDate <= p.endDate) {
                    this.financialPeriodId = p.id;
                }
            }
        })

        this.AgencyLookup.agencyTerritories({ agencyId: this.agencyId }, (result:any[]) => {
            this.territories = result;
            if (result.length > 0) {
                this.territory = result[0];
            }
        })

        this.AgencyContract.getScheduledOfferedAndNewContracts({ agencyId: this.agencyId }, (result) => {

            this.contractsDates = []
            for (let c of result) {
                if (startDate <= c.endDate && c.startDate <= endDate) {
                    this.contractsDates.push(c);
                }
            }

            if (this.contractsDates.length > 0) {
                this.selectedContract = this.contractsDates[0];
            } else {
                this.selectedContract = undefined;
            }
        });

    } 

}

angular
    .module('app')
    .controller('AgencyFeeSheetController', AgencyFeeSheetController)