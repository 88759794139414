import { IAction } from '../IAction'

export class TerminateContract implements IAction{

    static $inject = ['$uibModal', 'agencyContractData']
    constructor(private $uibModal, private agencyContractData) {
    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/terminateContract/TerminateContractModal.html',
            controller: TerminateContractModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                agencyContractData: () => this.agencyContractData,
            }
        }).result;
    }
}

interface ITerminateContractModalScope extends angular.IScope {
    terminateContractForm: any
}

export interface terminateModel {
    agencyId: number
    contractEndDate: Date
    terminateReason: string
    createNewContract: boolean
}

class TerminateContractModalController {
    public alerts: Array<Object> = [];
    showMessageText: boolean = false;
    terminateContractData: any = {};
    minDate: Date;
    maxDate: Date;

    static $inject = ['$rootScope', '$scope', '$timeout', 'dialogs', '$uibModalInstance', 'agencyContractData', 'AgencyContract'];
    constructor(private $rootScope, private $scope: ITerminateContractModalScope, private $timeout, private dialogs, private $uibModalInstance, private agencyContractData, private AgencyContract) {

        this.minDate = new Date();
        this.maxDate = this.agencyContractData.endDateMaxDate;

        this.terminateContractData = {
            agencyId: this.agencyContractData.agencyId,
            terminationDate: this.minDate,
            createNewContract: false,
            copyPreviousContractFees: true,
            terminateReason: '',
            contractId: this.agencyContractData.id
        };
    }

    terminateContract() {
        this.$scope.$broadcast('submitted', true)

        var form = this.$scope.terminateContractForm;
        if (form.$dirty && this.terminateContractData.terminationDate && this.terminateContractData.terminateReason) {
            this.AgencyContract.terminate(this.terminateContractData)
                .$promise
                .then(() => {
                    this.$rootScope.$broadcast('contract-terminated', this.terminateContractData);
                }, () => {
                        this.$rootScope.$broadcast('contract-terminated-error', this.terminateContractData);
                })
                .finally(() => {
                    this.$scope.terminateContractForm.$setPristine();
                    this.$uibModalInstance.close();
                });
        }        
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}

