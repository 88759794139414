import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'

class BootstrapApp {
    public title: string;

    allowStatesForInvalidApplicant = ['app.home', 'app.logout', 'app.contact.interviewer', 'app.contact.agency', 'app.contact.london', 'app.settings.marketing', 'app.settings.password', 'app.termsAndConditions', 'app.messages']

    static $inject = ['$rootScope', '$location', '$window', '$state', '$stateParams', 'Menu', 'Auth', 'AccessLevel', 'PushHub', 'ApplicationInsights', '$anchorScroll', 'environment','localStorageService'];
    constructor($rootScope, $location, $window, $state, $stateParams, Menu, Auth, AccessLevel, PushHub, ApplicationInsights, $anchorScroll, environment, localStorageService) {

        //const appInsights = new ApplicationInsights({
        //    config: {
        //        connectionString: environment.appInsightsConnectionString
        //    }
        //});
        //appInsights.loadAppInsights();
        //appInsights.trackPageView();


		$rootScope.$state = $state;
		$rootScope.$stateParams = $stateParams;
        $rootScope.Menu = Menu;
        $rootScope.environment = environment;
		$rootScope.$on('auth:user-change', (event, user) => {
            AccessLevel.apply(user.role).then(() => {
                let targetState = $state.params.state;
                let targetParams = $state.params.params;

                if (!targetState) {
                    if (Auth.user.role === 'OFF') {
                        targetState = 'app.workQueue';
                        targetParams = { queueName: '' };
                    }
                    else {
                        targetState = 'app.home';
                        targetParams = {logInRedirect: true};
                    }
                }
                $state.transitionTo(targetState, targetParams, { reload: true, inherit: true, notify: true });
			});
		});

		$rootScope.$on('commun:new-message', function (event, message) {
			//if (message.userId != Auth.user.userId)
			//    toaster.pop('info', "You got new message from " + message.userName, message.text);
		});

        $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, error) => {

            let targetState, targetParams;

            ApplicationInsights.trackException({
                exception: error,
                properties: {
                    toState,
                    toParams,
                    fromState,
                    fromParams
                }
            });


            if (error && error.reason === 'state_access_denied') {

                if (toState.name !== 'app.login') {
                     targetState = 'app.login';
                } else {
                    targetState = 'app.logout'
                }

                targetParams = {
                    state: (toParams && toParams.state) || toState.name,
                    params: (toParams && toParams.params) || toParams
                };
            } else if (error && error.reason === 'business_area_required') {

                targetState = 'app.accessDenied';

                targetParams = {
                    reason: error.reason,
                    businessArea: error.businessArea
                };
            } else {
                if (Auth.user.role === 'OFF') {
                    targetState = 'app.workQueue';
                    targetParams = { queueName: '' };
                } else {
                    targetState = 'app.home';
                    targetParams = {};
                }
            }
                
            if (toState.name !== targetState) {
                $state.transitionTo(targetState, targetParams, { reload: true, inherit: true, notify: true });
            }
        });

        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams, options) =>
        { 
            ApplicationInsights.trackPageView({
                name: toState?.name,
                properties: {
                    params: toParams,
                    fromState: fromState?.name,
                    fromParams
                }
            });

            AccessLevel.userLevelsPromise.then((userLevels) => {
                if (userLevels.invalidApplicant) {
                    if (!this.allowStatesForInvalidApplicant.includes(toState.name)) {
                        event.preventDefault();
                        $state.go('app.home');
                    }
                }
            });
        });

        var defaultTitle = $window.document.title;
        var dataLayer = $window.dataLayer = $window.dataLayer || [];
        ///$window.ga('create', 'UA-2289997-7', 'auto');
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

            ApplicationInsights.trackTrace({
                message: '$stateChangeSuccess',
                severityLevel: SeverityLevel.Information,
                properties: {
                    params: toParams,
                    fromState: fromState?.name,
                    fromParams
                }
            });

            $anchorScroll();
            //$('html, body').animate({ scrollTop: 0 }, 200);
            dataLayer.push({
                event: 'ngRouteChange',
                attributes: {
                    route: $location.path()
                }
            });
            $window.document.title = $state.$current.locals.globals.$title || defaultTitle;

            
		});
        AccessLevel.apply(Auth.user.role);
    }

    redirectToHome() {
            
    }
}


export let app = angular
    .module('app', [
        'aifs.metaform',
        'ngResource',
        'ngMessages',
        'ui.router',
        'ui.bootstrap',
        'LocalStorageModule',
        'dialogs.main',
        'trNgGrid',
        'ngFileUpload',
        'ngSanitize',
        'trNgGrid',
        'ngclipboard',
        'com.2fdevs.videogular',
        "com.2fdevs.videogular.plugins.controls",
        'com.2fdevs.videogular.plugins.overlayplay',
        'com.2fdevs.videogular.plugins.poster',
        //'toaster'
        //'pascalprecht.translate'
        'app.reference',
        'mwl.calendar',
        'mwl.calendar.custom',
        'selector',
        'summernote'
    ])
    .run(BootstrapApp);
