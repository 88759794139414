import { IAlertConfig } from '../../../models/bootstrap/Alert'
class InterviewerTrainingController {

    isOfficeUser: boolean = false;

    trainingDetails: any = {};
    $dirty: boolean = false
    alerts: IAlertConfig[] = [];

    static $inject = ['$scope', '$q', 'InterviewerTraining', 'Auth', 'dialogs', 'contactId'];
    constructor(private $scope, private $q: ng.IQService, private InterviewerTraining, private Auth, private dialogs, private contactId) {
        this.isOfficeUser = Auth.user.isOfficer;

        this.fillInterviewTraining();

        $scope.$watch(() => this.$scope.interviewerTrainingForm.$dirty, () => {
            this.$dirty = this.$scope.interviewerTrainingForm.$dirty;
        })
    }
    fillInterviewTraining() {
        this.InterviewerTraining.query({
            interviewerId: this.contactId
        })
        .$promise
        .then((result) => {
            this.trainingDetails = result;
            if (this.isOfficeUser && !this.trainingDetails.confirmTrainingHub) {
                this.trainingDetails.confirmTrainingHub = null;
            }
        });
    }

    save() {
        this.alerts = [];
        this.$scope.$broadcast('submitted', true);
            
        var form = this.$scope.interviewerTrainingForm;

        if (form['InterviewerTrainingDetails'].$dirty) {
            this.trainingDetails.metaform = form['InterviewerTrainingDetails'].$metaformVersion;
            this.InterviewerTraining.save({
                interviewerId: this.contactId
            }, this.trainingDetails)
            .$promise
            .then(() => {
                form.$setPristine();
                this.alerts.push({
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
            });
        }
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}

angular
    .module('app')
    .controller('InterviewerTrainingController', InterviewerTrainingController)