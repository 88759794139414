import { IAction } from '../IAction'


export class archiveApplication implements IAction {

    static $inject = ['$uibModal', 'Auth', 'selections']
    constructor(private $uibModal, private Auth, private selections) {

	}

    execute = () => {
		return this.$uibModal.open({
            templateUrl: 'areas/action/archiveApplication/archiveApplicationModal.html',
            controller: ArchiveApplicationModalController,
			controllerAs: 'ctrl',
			backdrop: false,
            resolve: {
                userId: () => this.Auth.user.userId,
                selections: () => this.selections
			}
		}).result;
    }
        
}

class ArchiveApplicationModalController {
    public reason: string;

    public applications: any[];

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'selections', 'userId', 'ApplicationStatus', '$state'];
    constructor(private dialogs, private $q, private $sce, private $uibModalInstance, private selections, private userId, private ApplicationStatus, private $state) {
        this.applications = selections.filter(a => a.status === 'REG' || a.status === 'OLA');
    }

    archive() {

        var promisses = [];

        for (let a of this.applications) {
            var promisse = this.ApplicationStatus.archive({
                applicationId: a.applicationId
            }).$promise;

            promisses.push(promisse);
        }

        this.$q.all(promisses)
            .then(null, () => {
                this.displayError();
            })
            .finally(() => {
				this.$uibModalInstance.close();
			});
	}

    displayError() {

        var message = 'Applicants cannot archive due to some technical problem, please try again shortly:</br>';
        return this.dialogs
            .error('Issue occur while sending messages', this.$sce.trustAsHtml(message))
            .result
    }

	close() {
		this.$uibModalInstance.dismiss();
	}

}
    