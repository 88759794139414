import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { FeeModalController } from './FeeModal';

export class FeesController {
    financialPeriod: any
    invoiceRegion: any;

    availableCountries;
    selectedCountries;
    fees;

    editFrom;

    data = {};
    pendingChanges = [];

    selectedFee;

    alerts: IAlertConfig[] = []

    static $inject = ['$q', 'Spinner', 'FeeRegions', 'Fee', 'financialPeriods', 'invoiceRegions', 'countryNames', 'dialogs', '$uibModal', 'feeList'];
    constructor(private $q, private Spinner, private FeeRegions, private Fee, private financialPeriods, private invoiceRegions, private countryNames, private dialogs, private $uibModal, private feeList) {

        var now = new Date();
            
        var upcominPeriod: any = _.minBy(financialPeriods.filter(p => p.startDate > now), p => p.startDate);
        if (upcominPeriod) {
            this.editFrom = upcominPeriod.endDate;
        }
        else {
            this.editFrom = _.maxBy(financialPeriods, (p:any) => p.startDate).endDate;
        }
    }

    loadData() {
        if (this.financialPeriod && this.invoiceRegion) {
            if (!this.data[this.financialPeriod.id]) {
                this.data[this.financialPeriod.id] = {};
            }

            if (!this.data[this.financialPeriod.id][this.invoiceRegion.id]) {
                let params = {
                    financialPeriodId: this.financialPeriod.id,
                    invoiceRegionId: this.invoiceRegion.id
                }

                this.$q.all([
                    this.FeeRegions.query(params).$promise,
                    this.FeeRegions.available(params).$promise,
                    this.Fee.query(params).$promise
                ]).then((response) => {
                    this.data[this.financialPeriod.id][this.invoiceRegion.id] = {
                        selectedCountries: response[0],
                        availableCountries: response[1],
                        fees: response[2]
                    };

                    this.displayRegion(this.data[this.financialPeriod.id][this.invoiceRegion.id]);
                });
            }
            else {
                this.displayRegion(this.data[this.financialPeriod.id][this.invoiceRegion.id]);
            }
        }
    }

    displayRegion(data) {

        this.selectedCountries = data.selectedCountries;
        this.availableCountries = data.availableCountries;
        this.fees = data.fees;

        this.selectedFee = undefined;
    }

    selectPeriod(period) {
        this.financialPeriod = period;
        this.invoiceRegion = undefined;
    }

    selectRegion(region) {
        this.invoiceRegion = region;
        this.loadData();
    }

    getCountryName = (code) => {
        return this.countryNames[code];

    }

    getFeeName = (fee) => {
        return this.feeList[fee.feeId].name;

    }

    getFeeOrder = (fee) => {
        return this.feeList[fee.feeId].order;
    }

    importCountries() {

        this.dialogs.confirm('Confirmation', `Importing previous year's Invoice Regions will clear any changes you might have made in this year's setup.`)
            .result
            .then(() => {
                this.FeeRegions.import({ financialPeriodId: this.financialPeriod.id })
                    .$promise
                    .then(() => {
                        this.data[this.financialPeriod.id] = {};
                        this.loadData();
                    })
            });
    }

    importFees() {
        var prevPeriods = _.filter(this.financialPeriods, (p: any) => p.endDate <= this.financialPeriod.startDate)
        var prevPeriod = _.maxBy(prevPeriods, (p:any) => p.startDate);

        if (!prevPeriod) {
            this.dialogs.error('Error', 'There is no previous financial period.');
            return;
        }


        let params = {
            financialPeriodId: prevPeriod.id,
            invoiceRegionId: this.invoiceRegion.id
        }

        this.Fee.query(params).$promise.then(fees => {

            for (let fee of fees) {
                if (!_.some(this.fees, (f: any) =>
                    (f.feeId == fee.feeId)
                    &&
                    (
                        (f.programAP && fee.programAP) ||
                        (f.programED && fee.programED) ||
                        (f.programEX && fee.programEX)

                    ))
                ) {
                    var newFee = angular.copy(fee);
                    newFee.financialPeriodId = this.financialPeriod.id
                    newFee.startDate = this.financialPeriod.startDate
                    newFee.endDate = this.financialPeriod.endDate

                    this.pendingChanges.push({ action: 'addFee', fee: newFee })
                    this.fees.push(newFee)
                }
            }
        });
    }

    addCountry(country) {
        this.availableCountries.splice(this.availableCountries.indexOf(country),1);
        this.selectedCountries.splice(_.sortedIndex(this.selectedCountries, country), 0, country);
        this.saveCountries();

        //var index = _.findIndex(this.pendingChanges, { action: 'removeCountry', object: country });
        //if (index >= 0) {
        //    this.pendingChanges.splice(index, 1);
        //} else {
        //    this.pendingChanges.push({ action: 'addCountry', object: country})
        //}
    }

    removeCountry(country) {
        if (this.financialPeriod.isCurrentPeriod) {
            this.selectedCountries.splice(this.selectedCountries.indexOf(country), 1);
            this.availableCountries.splice(_.sortedIndex(this.availableCountries, country), 0, country);
            this.saveCountries();

            //var index = _.findIndex(this.pendingChanges, { action: 'addCountry', object: country });
            //if (index >= 0) {
            //    this.pendingChanges.splice(index, 1);
            //} else {
            //    this.pendingChanges.push({ action: 'removeCountry', object: country })
            //}
        }
    }

    saveCountries() {
        let data = {
            financialPeriodId: this.financialPeriod.id,
            invoiceRegionId: this.invoiceRegion.id
        }

        this.FeeRegions
            .save(data, this.selectedCountries);
        //.$promise
        //.then(() => {
        //    this.alerts.push({ // TODO should I be able to display the same message twice?
        //        type: 'success',
        //        msg: 'Save complete',
        //        dismissOnTimeout: 5000
        //    })
        //});
    }


    selectFee(fee) {
        this.selectedFee = (this.selectedFee == fee) ? undefined : fee;
    }

    addFee() {
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainFees/feeModal.html',
            controller: FeeModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                fee: () => <any>{
                    currencyCode: this.invoiceRegion.currencyCode,
                    financialPeriodId: this.financialPeriod.id,
                    invoiceRegionId: this.invoiceRegion.id,
                },
                financialPeriod: () => this.financialPeriod,
                invoiceRegion: () => this.invoiceRegion, 
                editFee: false,
                fees: () => this.fees
            }
        }).result.then((fee) => {
            this.pendingChanges.push({ action: 'addFee', fee: fee })
            this.fees.push(fee)

        });
    }

    editFee() {
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainFees/feeModal.html',
            controller: FeeModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                fee: () => angular.copy(this.selectedFee),
                financialPeriod: () => this.financialPeriod,
                invoiceRegion: () => this.invoiceRegion,
                editFee: true,
                fees: () => this.fees
            }
        }).result.then((fee) => {

            var index = _.findIndex(this.pendingChanges, (c) => c.object == this.selectedFee);
            if (index >= 0) {
                this.pendingChanges[index].object = fee;
            } else {
                this.pendingChanges.push({ action: 'editFee', fee: fee })
            }

            var index2 = this.fees.indexOf(this.selectedFee);
            this.fees[index2] = fee;
            this.selectedFee = undefined;
        });
    }

    deleteFee() {
        this.dialogs.confirm('Delete', `Delete this fee?`)
            .result
            .then(() => {

                var index = _.findIndex(this.pendingChanges, (c) => c.object == this.selectedFee);
                if (index >= 0) {
                    this.pendingChanges.splice(index, 1);
                }

                this.pendingChanges.push({ action: 'deleteFee', fee: { id: this.selectedFee.id }})

                var index2 = this.fees.indexOf(this.selectedFee);
                this.fees.splice(index2, 1);
            });
    }
    saveFeesChanges() {

        this.Spinner.show();
        this.Fee
            .saveChanges(this.pendingChanges)
            .$promise
            .then(() => {

                this.pendingChanges = []; this.Spinner.hide()
            }, () => {
                this.Spinner.hide()
                this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
            });
    }

        


    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}


angular
    .module('app')
    .controller('FeesController', FeesController);

