
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
 
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';
import { WorkqueueType } from '../../areas/queue/QueueController';

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {


        let workQueues: state<AccessLevelView> = {
            name: 'workQueue',
            url: '/workQueue/{queueName}',
            resolve: {
                displayTypes: [function () {
                    return [WorkqueueType.Applicants, WorkqueueType.Participants, WorkqueueType.All];
                }],
                queueName: ['$stateParams', function ($stateParams) {
                    return $stateParams.queueName;
                }],
                quickSerch: ['$stateParams', function ($stateParams) {
                    return $stateParams.quickSerch;
                }],
                columnSelections: ['WorkQueueColumnSelection', 'Auth', function (WorkQueueColumnSelection, Auth) {
                    return WorkQueueColumnSelection.get({ userId: Auth.user.userId }).$promise
                }],
                drivingLicenceOptions: ['ApplicationLookup', function (ApplicationLookup) {
                    return ApplicationLookup.drivingLicenseType().$promise;
                }],
                programOptions: ['ApplicationLookup', function (ApplicationLookup) {
                    return ApplicationLookup.programType().$promise;
                }],
                countryOptions: ['ApplicationLookup', function (ApplicationLookup) {
                    return ApplicationLookup.country().$promise;
                }],
                visaOptions: ['ApplicationLookup', function (ApplicationLookup) {
                    return ApplicationLookup.visaStatus().$promise;
                }],
                applicationStatuses: ['ApplicationStatus', function (ApplicationStatus) {
                    return ApplicationStatus.getAll().$promise;
                }],
                interviewersList: ['Interviewer', 'Auth', function (Interviewer, Auth) {
                    if (Auth.user.isOfficer) {
                        return Interviewer.query().$promise;
                    } else if (Auth.user.isAgent) {
                        return Interviewer.agencyActive({ agencyId: Auth.user.roleDetails.agencyId }).$promise;
                    } else if (Auth.user.isBranchManager) {
                        return Interviewer.branchActive({ branchId: Auth.user.roleDetails.branchId }).$promise;
                    } else if (Auth.user.isInterviewer) {
                        return Interviewer.get({ id: Auth.user.roleDetails.contactId }).$promise.then((i) => [i]);
                    }
                }],
                activeApplicationsPromotionList: ['Promotions', 'Auth', function (Promotions, Auth) {
                    if (Auth.user.isOfficer) {
                        return Promotions.activeApplicationsPromotions().$promise;
                    }
                }],
                userSettings: ['UserSettings', 'Auth', function (UserSettings, Auth) {
                    return UserSettings.get({ userId: Auth.user.userId }).$promise
                }],
                queues: ['Workqueues', 'displayTypes', function (Workqueues, displayTypes) {
                    return Workqueues.query().$promise.then(qs => {
                        return qs.filter(q => displayTypes.indexOf(q.workqueueType) !== -1);
                    });
                }],
                $title: () => 'Applications'
            },
            params: {
                quickSerch: null
            },
            views: {
                content: {
                    staff: {
                        controller: 'QueueController as ctrl',
                        template: 'areas/queue/Queue.html'
                    }
                }
            }
            //,
            //states: [
            //    {
            //        name: 'list',
            //        url: '',
            //        resolve: {
            //        },
            //        views: {
            //        }
            //    },
            //    {
            //        name: 'unassigned',
            //        url: '/unassigned',
            //        //resolve: {
            //        //},
            //        views: {
            //            '': {
            //                interviewer: {
            //                    controller: 'UnassignedApplicationsController as unassignedCtrl',
            //                    template: 'areas/queue/unassigned/UnassignedApplications.html'
            //                }
            //            }
            //        }
            //    }
            //]
        };


        RoutingProvider.addState(workQueues, 'app');
    }
}

angular
    .module('app')
    .config(RoutingConfig);