
class ContactCommentsController {

    static $inject = ['contactId'];
    constructor(private contactId) {
    }

}

angular
    .module('app')
    .controller('ContactCommentsController', ContactCommentsController)