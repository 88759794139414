
class TokenController {

    static $inject = ['$state', 'dialogs', 'Auth', 'token'];
    constructor($state, dialogs, Auth, token) {

        Auth.tokenSignin(token)
            .then((data) => {
                $state.transitionTo('app.home', {}, { reload: true, inherit: true, notify: true });
            }, (data) => {
                dialogs.error('Invalid Token', 'Your token is invalid!');
            });
    }
}

angular
    .module('app')
    .controller('TokenController', TokenController);