 

export class ReviewTranslationDataSourceController {
    model
    show: boolean

    static $inject = []
    constructor() {
    }
}


class ReviewTranslationDirectiveFactory implements ng.IDirective {
    restrict = 'A'
    controllerAs = 'revCtrl'
    controller = ReviewTranslationDataSourceController
    bindToController = true
    scope = {
        model: '=apReviewTranslationDs',
        show: '=apReviewTranslationDsShow'
    }
}

angular
    .module('app')
    .directive('apReviewTranslationDs', () => new ReviewTranslationDirectiveFactory())