import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class ContactFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'agency'
        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'ContactWorkQueue',
                url: 'agency',
                actions:
                {
                    all: {
                        method: 'POST'
                    }
                }
            },
            {
                name: 'ContactBasicDetailsPage',
                url: 'interviewer/:contactId/getBasicDetails',
                actions: {}
            },
            {
                name: 'ContactBasicDetails',
                url: '',
                actions: {
                    saveBasicDetails: {
                        url: 'interviewer/:contactId/saveBasicDetails',
                        method: 'POST'
                    }
                }
            },
            {
                name: 'ContactAddressPage',
                url: 'interviewer/:contactId/getAddress',
                actions: {}
            },
            {
                name: 'ContactAddress',
                url: '',
                actions: {
                    saveAddress: {
                        url: 'interviewer/:contactId/saveAddress',
                        method: 'POST'
                    }
                }
            },
            {
                name: 'ContactLookup',
                url: '',
                actions: {
                    getJobTitle: {
                        url: `interviewer/getJobTitle`,
                        method: 'GET',
                        isArray: true,
                        cache: true
                    }
                }
            }
        ]

        this.process(resources)
    }

}

new ContactFactory()
