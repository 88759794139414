
class SharedFlightContactDetailsDirective implements angular.IDirective {
    restrict = 'AE'
    templateUrl = 'areas/placement/flightDetails/sharedFlightContactDetail.html'
    scope = {
        fields: '=',
        leg: '='
    }       
}
    
angular
    .module('app')
    .directive('shareFlightSublist', () => new SharedFlightContactDetailsDirective())
