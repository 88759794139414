import { BusinessArea } from "../../services/AuthService";

class ProfileController {

    profilePhotoUploadConfig: any;
    isAgencyUser: boolean;
    isOfficeUser: boolean;
    showContactCommissions: boolean;
    financeArea: boolean;
      
    static $inject = ['$uibModal', 'dialogs', 'Auth', 'ContactUploader', 'contactPaymentDetails', 'Interviewer']
    constructor(private $uibModal, private dialogs, private Auth, private ContactUploader, contactPaymentDetails, Interviewer) {
        this.isAgencyUser = this.Auth.user.isAgent;
        this.isOfficeUser = this.Auth.user.isOfficer;

        this.financeArea = Auth.isInBusinessArea(BusinessArea.FINANCE);

        if (this.Auth.user.isInterviewer && contactPaymentDetails.creditorId) {

            Interviewer.get({ id: Auth.user.roleDetails.contactId})
                .$promise
                .then((result) => {
                    this.showContactCommissions = (result && result.isActive);
                });

        }

        let uploader = new ContactUploader(Auth.user.roleDetails.contactId, 39);

        this.profilePhotoUploadConfig = {
            uploader: uploader,                
            hideDescription: true,
            msg: {
                upload: 'Add profile picture'
            }
        }      
    }
}

angular
    .module('app')
    .controller('ProfileController', ProfileController)