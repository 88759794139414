
class PersonalDetailsController {

    saved: boolean;

    static $inject = ['$scope', '$q', 'dialogs', 'HelpTexts', 'availability', 'contactDetails', 'emergencyContact', 'drivingDetails'];
    constructor(private $scope, private $q, private dialogs, private HelpTexts, private availability, private contactDetails, private emergencyContact, private drivingDetails) {

    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form['Availability'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'Availability' }, this.availability).$promise);
        }
        if (form['ContactDetails'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'ContactDetails' }, this.contactDetails).$promise);
        }
        if (form['EmergencyContactDetails'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'EmergencyContactDetails' }, this.emergencyContact).$promise);
        }
        if (form['DrivingDetails'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'DrivingDetails' }, this.drivingDetails).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                $('html, body').animate({ scrollTop: 860 }, 200);
                this.saved = true;
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
            });
    }
}

angular
    .module('app')
    .controller('HelpText.PersonalDetailsController', PersonalDetailsController);