var AuPairRequestInterceptorFactory = ['$q', '$injector', 'buildVersion', function AuPairRequestInterceptorFactory($q, $injector, buildVersion) {
        
    var _auth;

    var lut = []; for (var i = 0; i < 256; i++) { lut[i] = (i < 16 ? '0' : '') + (i).toString(16); }

    function guid() {
        var d0 = Math.random() * 0xffffffff | 0;
        var d1 = Math.random() * 0xffffffff | 0;
        var d2 = Math.random() * 0xffffffff | 0;
        var d3 = Math.random() * 0xffffffff | 0;
        return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
            lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
            lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
            lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
    }

    function getAuth() {
        if (!_auth) {
            _auth = $injector.get('Auth');
        } 
        return _auth;
    }

    return {
        request: function (config) {

            let auth = getAuth();
            if (auth.isLocked) {
                return $q.reject();
            }

            var user = auth.user;
            if (!user || !user.isLoggedIn) {
                return $q.reject();
                //return config;
            }

            if (!config.headers) config.headers = {};

            config.headers['X-AIFS-Client-Version'] = buildVersion;
            config.headers['X-AIFS-CorrelationId'] = guid();

            return auth
                .getAccessToken()
                .then((token) => {
                    if (token) {
                        config.headers.Authorization = 'Bearer ' + token;
                        return config;
                    } else {
                        auth.signout();
                        return $q.reject();
                    }
                });
        },
        responseError: function (response) {
            var method = response.config.method || '';
            var url = response.config.url || '';
            var correlationId = response.config.headers['X-AIFS-CorrelationId'] || '';
            (console.error || console.log)(`Request failed.\nMethod:${method}\nUrl: ${url}\nCorrelationId: ${correlationId}\nTime: ${new Date().toJSON()}`);
                
            if (response.status === 401) {
                var auth = getAuth();

                var data = response.data;
                if (data && angular.isString(data)) data = JSON.parse(data);

                if (data.error === "token_expired") {
                    var $http = $injector.get('$http');
                    return auth.refreshToken()
                        .then(() => {
                            return $http(response.config);
                        }, () => {
                            return $q.reject(response);
                        });
                } else {
                    auth.signout();
                    return $q.reject(response);
                }
            }
            return $q.reject(response);
        }
    }
}]


angular
    .module('app')
    .factory('AuPairRequestInterceptor', AuPairRequestInterceptorFactory);