 

class AgencyTerritoryDocumentDirective implements ng.IDirective {
        restrict = 'AE'
        controller = AgencyTerritoryDocumentController
        controllerAs = 'acCtrl'
        bindToController = true
        templateUrl = 'controls/agencyContracts/agencyTerritoryDocument.html'    
        scope = {
            contractData: '=',
            agencyTerritoryId: '=',
            viewMode: '='
        }               
}

class AgencyTerritoryDocumentController {
    contractData: any
    agencyTerritoryId: number
    viewMode: boolean

    localAgreementUploadConfig: any;
    uploadState: string;
    hasDocumentExists: boolean;
    documentCategory: number = 22;
    showUploadButton: boolean = true;

    static $inject = ['ContractUploader', 'ContractDocument'];
    constructor(private ContractUploader, private ContractDocument) {

    }


    $onInit() {
        var contractId = null;

        if (this.viewMode && this.contractData.isAccepted) {

            contractId = this.contractData.id;
        }
        var canViewOnly = this.viewMode && this.contractData && !this.contractData.isAccepted;

        let uploader = new this.ContractUploader(contractId, this.agencyTerritoryId, this.documentCategory, canViewOnly);

        var data = {
            contractId: contractId,
            agencyTerritoryId: this.agencyTerritoryId
        };
        this.ContractDocument.hasDocumentExists(data)
            .$promise
            .then((result) => {
                this.hasDocumentExists = result.hasDocumentExists;
                //this.hasDocumentExists = true;
                this.showUploadButton = (!this.viewMode) || (this.viewMode && this.hasDocumentExists)
            });

        this.localAgreementUploadConfig = {
            uploader: uploader,
            hideDescription: true,
            onUpdateHandler: () => {
                this.ContractDocument.hasDocumentExists(data)
                    .$promise
                    .then((result) => {
                        this.hasDocumentExists = result.hasDocumentExists;
                    });
            },
            onDeleteHandler: () => {
                this.ContractDocument.hasDocumentExists(data)
                    .$promise
                    .then((result) => {
                        this.hasDocumentExists = result.hasDocumentExists;
                    });
            },
            //onApproveHandler: (uploadResult) => {
            //    this.uploadState = uploadResult;
            //},
            msg: {
                upload: 'Upload Documents',
                processing: 'Show Documents',
                uploaded: 'Show Documents',
                approved: 'Approved Documents',
                notUploaded: 'document not exists'
            }
        }

        
    }
}


angular
    .module('app')
    .directive('agencyTerritoryDocument', () => new AgencyTerritoryDocumentDirective());
