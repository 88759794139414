
class HostFamilyController {
        

    static $inject = ['placementDetails']
    constructor(public placementDetails) {
    }
}
    
angular
    .module('app')
    .controller('HostFamilyController', HostFamilyController)