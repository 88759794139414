import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class CountrySettingsController {

    alerts: IAlertConfig[] = []

    settings: any = {};
    $dirty: boolean = false

    static $inject = ['$scope', '$state', 'dialogs', 'Auth', 'UserSettings'];
    constructor(private $scope, private $state, private dialogs, private Auth, private UserSettings) {
        this.settings = this.UserSettings.get({ userId: this.Auth.user.userId });

        $scope.$watch(() => this.$scope.userSettingsForm.$dirty, () => {
            this.$dirty = this.$scope.userSettingsForm.$dirty;
        })

    };

    submit() {
        this.$scope.$broadcast('submitted', true);

        var form = this.$scope.userSettingsForm;   
            
        this.UserSettings.save(this.settings)
            .$promise
            .then(() => {
                this.alerts.push({
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                });

                form.$setPristine();

            }, () => {
                this.dialogs.error('Internal error', 'Error occured while saving user countries. Please try again after a while.')
        });
    };

    closeAlert = function (alert) {
        var index = this.alerts.indexOf(alert);
        if (index > -1) {
            this.alerts.splice(index, 1);
        };
    }
}

angular
    .module('app')
    .controller('CountrySettingsController', CountrySettingsController);