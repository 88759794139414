
class ShareChildcareExperienceController {

    static $inject = ['childcareExperience']
    constructor(public childcareExperience) {
    }
        
}

angular
    .module('app')
    .controller('ShareChildcareExperienceController', ShareChildcareExperienceController)