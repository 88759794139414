 

class DateTimeCustomDirective implements ng.IDirective {
    restrict = 'AE'
    controller = DateTimeCustomController
    controllerAs = 'cdt'
    bindToController = true
    templateUrl = 'controls/dateTimeCustom/dateTimeCustom.html'
    scope = {
        validDateTime: '=',
        dateLabel: '@',
        isEditable: '=',
        timeZone: '='
    }
}

class DateTimeCustomController {
    validDateTime: Date;
    isEditable: boolean;
    dateLabel: string;

    $dirty: boolean = false;

    minDate: Date = new Date();
    maxDate: Date;
    showError: boolean = false;

    timeZone: any;
        
    static $inject = ['$rootScope','$scope'];
    constructor(private $rootScope, $scope) {
        $scope.hstep = 1;
        $scope.mstep = 15;

        $scope.$watch(() => this.validDateTime, (newValue, oldValue) => {
            if (newValue && newValue !== oldValue) {
                this.$rootScope.$broadcast('date-time-changed');
            }
        });

        $scope.$on('submitted', (event, data) => {
            this.showError = true;
        });
    }
}

angular
    .module('app')
    .directive('dateTimeCustom', () => new DateTimeCustomDirective());