import { IAction } from '../IAction'
import { ActionType, ActionTypeItems } from '../../../models/userActions/ActionType'


export class CancelInvoice implements IAction{

    static $inject = ['$rootScope', 'dialogs', 'Invoice', 'applicationId', 'invoiceId']
    constructor(private $rootScope, private dialogs, private Invoice, private applicationId, private invoiceId) {

    }

    public execute = () => {
        var title = ActionTypeItems[ActionType.CANCEL_INVOICE].name;
        return this.dialogs.confirm(title, `Are you sure that you want to cancel this applicant's invoice?`)
            .result
            .then(() => this.Invoice.cancel({
                applicationId: this.applicationId,
                invoiceId: this.invoiceId
            }).$promise)
            .then(() => {
                this.$rootScope.$broadcast('invoice:changed');
            });

        //return this.$uibModal.open({
        //    templateUrl: 'controls/actions/confirmModal.html',
        //    controller: controls.actions.ActionController,
        //    controllerAs: 'ctrl',
        //    backdrop: false,
        //    resolve: {
        //        Service: () => this.Invoice,
        //        serviceMethod: () => 'cancel',
        //        data: () => data,
        //        title: () => models.userActions.ActionTypeItems[models.userActions.ActionType.CANCEL_INVOICE].name
        //    }
        //}).result
    }
}