 
import { DocumentUploadMessages, DocumentUploadConfig, SingleDocumentUploader } from '../DocumentUploadConfig'

class DocumentUploadController {

    msg: DocumentUploadMessages
    config: DocumentUploadConfig<SingleDocumentUploader>
    document
    documentType
    isApplicant: boolean
    isOfficeUser: boolean
    canDeleteDocument: boolean
    applicationStatus

    static $inject = ['$scope', '$rootScope', '$uibModal', 'Auth', 'DocumentUpload', 'ApplicationDocument', 'dialogs'];
    constructor(private $scope, private $rootScope, private $uibModal, private Auth, private DocumentUpload, private ApplicationDocument, private dialogs) {
            
        this.isApplicant = Auth.user.isApplicant;
        this.isOfficeUser = Auth.user.isOfficer;

        $scope.$on('sideMenu:uploadDataStateChanged', (event, data) => {
            this.loadPhoto();
        });
    }

    $onInit() {
        this.loadPhoto();

        this.msg = angular.extend({
            upload: 'Upload media',
            processing: 'Show media',
            uploaded: 'Show media',
            sideMenu: 'Show in side menu'
        }, this.config.msg)
    }

    private loadPhoto() {

        // TODO get file
        return this.config.uploader.getDocumentType()
            .then((documentType) => {
                this.document = documentType.documents[0];
                this.documentType = documentType.documentType;
            });
        //if (this.config.contactId) {
        //    promise = this.ContactDocument.query({
        //        contactId: this.config.contactId,
        //        filter: this.config.category
        //    }).$promise;
        //} else {
        //    promise = this.ApplicationDocument.query({
        //        applicationId: this.config.applicationId,
        //        filter: this.config.category
        //    }).$promise;
        //}

        //return promise.then((documentTypes) => {
        //    if (angular.isArray(documentTypes) && documentTypes.length > 0) {
        //        this.documentType = documentTypes[0].id
        //        var documents = documentTypes[0].documents

        //        if (angular.isArray(documents) && documents.length > 0) {
        //            // If its Hobbies and Interest document
        //            if (documentTypes[0].id == 37) {
        //                var matchingDocument = _.filter(documents, { extraReference: this.config.extraReference })
        //                if (matchingDocument.length) {
        //                    this.document = matchingDocument[0]
        //                }
        //            }
        //            else {
        //                this.document = documents[0]
        //            }
        //        }
        //    }
        //});
    }

    fileSelected($files, $event) {
        if (!angular.isArray($files) || $files.length === 0)
            return
        this.DocumentUpload.documentDefinition({ documentType: this.documentType }, (documentDefinition) => {
            var template, controller

            if (documentDefinition.maximumNumberOfPages == 1) {
                template = 'controls/documentsUpload/documentModal/singlePage/documentUploadModal.html'
                controller = 'DocumentUploadModalController as dumCtrl'
            } else {
                template = 'controls/documentsUpload/documentModal/multiPage/documentUploadModal.html'
                controller = 'DocumentUploadModalController as dumCtrl'
            }

            this.$uibModal.open({
                templateUrl: template,
                controller: controller,
                backdrop: false,
                resolve: {
                    documentType: () => this.documentType,
                    documentDefinition: () => documentDefinition,
                    uploader: () => this.config.uploader,
                    files: () => $files,
                    hideDescription: (): boolean => this.config.hideDescription,
                    onUpdateHandler: (): boolean => this.config.onUpdateHandler
                }
            }).result.then((document) => {
                this.document = document
            })
        })
    }

    openDialog(document) {  
        this.DocumentUpload.documentDefinition({ documentType: document.documentType }, (documentDefinition) => {
            var template;

            if (document.documentType == 39) {
                template = 'controls/documentsUpload/documentModal/singlePage/documentContactDisplayModal.html'
            } else if (documentDefinition.maximumNumberOfPages == 1) {
                template = 'controls/documentsUpload/documentModal/singlePage/documentDisplayModal.html'
            } else {
                template = 'controls/documentsUpload/documentModal/multiPage/documentDisplayModal.html'
            }

            this.$uibModal.open({
                templateUrl: template,
                controller: 'DocumentDisplayModalController as ddmCtrl',
                backdrop: false,
                resolve: {
                    documentType: () => document.documentType,
                    documentDefinition: () => documentDefinition,
                    uploader: () => this.config.uploader,

                    document: () => document,
                    onDeleteHandler: () => this.config.onDeleteHandler,
                    onUpdateHandler: () => this.config.onUpdateHandler,
                    onApproveHandler: () => this.config.onApproveHandler,

                    applicationStatus: () => this.applicationStatus
                }
            }).result.then((document) => {
                this.document = document
                //this.loadMedia()
            })

        })
    }

    removeDocumentAndFileSelected($files, $event, document) {
        if (document == null || typeof document !== 'object') {
            return;
        }

        this.ApplicationDocument.remove({ documentId: document.id }, {})
            .$promise
            .then(() => {
                this.document = undefined;
                if (this.config.onDeleteHandler) {
                    this.config.onDeleteHandler();
                }
                this.fileSelected($files, $event);
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while deleting an image. Please try again after a while.');
            });
    }

    stickPreview(document) {
        this.$rootScope.$broadcast('document:selected', document)
    }
}
    
var DocumentUploadDirectiveFactory = [function DocumentUploadDirectiveFactory(): ng.IDirective {
    return {
        restrict: 'AE',
        scope: {
            config: '=documentUpload',
            applicationStatus: '=applicationStatus'
        },
        bindToController: true,
        controllerAs: 'uplCtrl',
        controller: DocumentUploadController,
        templateUrl: 'controls/documentsUpload/singleDocumentUpload/documentUpload.html'
    }
}]

    
var PictureUploadDirectiveFactory = [function PictureUploadDirectiveFactory(): ng.IDirective {
    return {
        restrict: 'AE',
        scope: {
            config: '=pictureUpload',
            applicationStatus: '=applicationStatus',
            message: '@pictureUploadMsg'
        },
        bindToController: true,
        controllerAs: 'uplCtrl',
        controller: DocumentUploadController,
        templateUrl: 'controls/documentsUpload/singleDocumentUpload/pictureUpload.html'
    }
}]

angular
    .module('app')
    .directive('documentUpload', DocumentUploadDirectiveFactory)
    .directive('pictureUpload', PictureUploadDirectiveFactory)
