
class ManualPaymentController {

    public submited: boolean

    static $inject = ['$scope', '$uibModalInstance', 'dialogs', 'ManualPayment', 'title', 'data', 'Auth', 'paymentInfo']
    constructor(private $scope, private $uibModalInstance, private dialogs, private ManualPayment, private title: string, private data, Auth, paymentInfo) {
        data.userId = Auth.user.userId;
        data.creditorId = paymentInfo.creditorId;
        data.currency = paymentInfo.currencyCode;
        data.paymentMethod = 'BNK';

        $scope.$watch(() => data.accountCode, (val) => data.accountCodeDisplay = val);
    }
        
    submit() {
        this.submited = true;
        this.$scope.$broadcast('submitted', true)

        if (this.data.$validation()) {

            this.ManualPayment.save(this.data).$promise
                .then((results) => {
                    this.$uibModalInstance.close()
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
                });
        }
    }

    close() {
        this.$uibModalInstance.dismiss()
    }
}


angular
    .module('app')
    .controller('ManualPaymentController', ManualPaymentController)