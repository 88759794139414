import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'


class AltosFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'application'
        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'AltosArchive',
                url: 'application/altos',
                actions: {
                    all: {
                        method: 'POST'
                    }
                }
            },
            {
                name: 'AltosStatusPage',
                url: 'application/altos/:applicantId/status',
                actions: {}
            },
            {
                name: 'AltosPersonalPage',
                url: 'application/altos/:applicantId/personal',
                actions: {}
            },
            {
                name: 'AltosHostFamilyPage',
                url: 'application/altos/:applicantId/hostFamily',
                actions: {}
            }
        ];

        this.process(resources)
    }
}

new AltosFactory();
