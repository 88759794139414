import { BusinessRule } from "../common/BusinessRule";
import { DataSource } from "../common/DataSource";
import { QuestionRule } from "../common/QuestionRule";


function converToDictionary(collection, keyAccesor, constructor) {
    var result = {};
    angular.forEach(collection, function (item) {
        result[keyAccesor(item)] = angular.extend(new constructor(), item);
    });
    return result;
}

class MetaFormProvider implements ng.IServiceProvider {

    static defaults = {
        rulesEndpoint: '/forms/:formName?part=rules&version=:version',
        displayFormEndpoint: '/forms/:formName?part=display&version=:version',
        editFormEndpoint: '/forms/:formName?part=edit&version=:version',
        helpTextFormEndpoint: '/forms/:formName?part=helpText&version=:version'
    };

    static $cache = {} ;

    defaults = MetaFormProvider.defaults;


    $get = ['$resource', '$http', '$injector', '$parse', function ($resource, $http, $injector, $parse) {

        function MetaForm(formName, version) {

            version = version || 0;
            var key = formName + '-' + version;

            this.$data; // promise
            this.$formName = formName;

            if (MetaFormProvider.$cache[key]) {
                this.$data = MetaFormProvider.$cache[key];
            } else {
                var metaFormRes = $resource(MetaFormProvider.defaults.rulesEndpoint);
                this.$data = metaFormRes.get({ formName: formName, version: version }, function (response) {


                    // convert to BusinessRule object
                    response.businessRules = converToDictionary(
                        response.businessRules,
                        function (rule) { return rule.id; },
                        BusinessRule);


                    // convert to QuestionRule object
                    response.questionRules = converToDictionary(
                        response.questionRules,
                        function (rule) { return rule.id; },
                        QuestionRule);


                    // convert to QuestionRule object
                    response.dataSources = converToDictionary(
                        response.dataSources,
                        function (ds) { return ds.field; },
                        DataSource);


                    //add reference
                    angular.forEach(response.questionRules, function (questionRule) {
                        questionRule.businessRules = $.map(questionRule.businessRules, function (ruleName) {
                            return response.businessRules[ruleName];
                        });
                    });

                });
                MetaFormProvider.$cache[key] = this.$data;

            }
        }

        MetaForm.prototype.getBusinessRules = function (callback) {
            return this.$data.$promise.then(function (data) {
                callback(data.businessRules);
            });
        };

        MetaForm.prototype.getQuestionRule = function (questionId, callback) {
            return this.$data.$promise.then(function (data) {
                callback(data.questionRules[questionId]);
            });
        };

        MetaForm.prototype.getDropdownsDataSource = function (callback) {
            return this.$data.$promise.then(function (data) {
                callback(data.dataSources);
            });
        };

        MetaForm.prototype.getDataSource = function (name, callback) {
            return this.$data.$promise.then(function (data) {
                callback(data.dataSources[name]);
            });
        };

        MetaForm.prototype.getDropdownOptions = function (dataSource, model, callback) {

            var parameters = {};

            if (angular.isObject(dataSource.dependencies)) {
                var isArray = angular.isArray(dataSource.dependencies);

                angular.forEach(dataSource.dependencies, function (value, key) {
                    parameters[isArray ? value : key] = $parse(value)(model);
                });
            }

            var data = {
                resource: dataSource.resource,
                parameters: parameters
            };

            var service = $injector.get(dataSource.service);

            if (angular.isUndefined(service)) {
                console.error('Metaform Error: Service "' + dataSource.service + '" doesn\'t exist');
                return;
            }

            var method = service[dataSource.method];

            if (!angular.isFunction(method)) {
                console.error('Metaform Error: Method "' + dataSource.method + '" required by the field "' + dataSource.field + '" doesn\'t exist in the "' + dataSource.service + '" Service');
                return;
            }

            method(parameters).$promise.then(function (data) {
                var result = [];
                angular.forEach(data, function (el) {
                    var tooltip = el.tooltip ? el.tooltip: '';
                        
                    result.push({
                        value: el.code,
                        description: el.name,
                        tooltip: el.tooltip || ''
                    });
                });

                callback && callback(result);
            });

        };

        MetaForm.editForm = function (name, version) {
            return MetaForm.defaults.editFormEndpoint
                .replace(':formName', name)
                .replace(':version', version || 0);
        };
        MetaForm.displayForm = function (name, version) {
            return MetaForm.defaults.displayFormEndpoint
                .replace(':formName', name)
                .replace(':version', version || 0);
        };
        MetaForm.helpTextForm = function (name, version) {
            return MetaForm.defaults.helpTextFormEndpoint
                .replace(':formName', name)
                .replace(':version', version || 0);
        };

        MetaForm.defaults = MetaFormProvider.defaults;

        return MetaForm;
    }];
}

angular
    .module("aifs.metaform")
    .provider("MetaForm", MetaFormProvider);
