import { ITransaction } from '../../../../models/finance/ITransaction';
    
//export class AdjustAccount implements IAction{

//    static $inject = ['$uibModal', 'Invoice', 'applicationId', 'invoiceId']
//    constructor(private $uibModal, private Invoice, private applicationId, private invoiceId) {

//    }

//    execute = () => {
//        return this.$uibModal.open({
//            templateUrl: 'areas/action/adjustAccount/adjustAccountModal.html',
//            controller: AdjustAccountActionController,
//            controllerAs: 'ctrl',
//            backdrop: false,
//            resolve: {
//                data: () => <any>{
//                    applicationId: this.applicationId,
//                    invoiceId: this.invoiceId
//                },
//                invoice: () => this.Invoice.get({ applicationId: this.applicationId, invoiceId: this.invoiceId }).$promise,
//                title: () => models.userActions.ActionTypeItems[models.userActions.ActionType.ADJUST_ACCOUNT].name
//            }
//        }).result
//    }
//}

export class ViewTransactionController {

    public selectedTransaction: ITransaction

    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'Invoice', 'title', 'Finance', 'transaction']
    constructor(private $scope, private dialogs, private $uibModalInstance, private Invoice, private title: string, private Finance, private transaction) {
            
    }

    public close() {
        this.$uibModalInstance.close();
    }

    //close() {
    //    this.$uibModalInstance.dismiss()
    //}
}
        