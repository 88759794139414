import _ from 'lodash-es'
import { IAction } from '../IAction'
import { CommentModalController } from '../../../controls/comment/commentModalController';

export class ITep implements IAction {

    static $inject = ['$uibModal', '$rootScope', 'applicationId']
    constructor(private $uibModal, private $rootScope, private applicationId) {
	}

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/iTep/iTepModal.html',
            controller: ITepModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                tests: ['ITep', (ITep) => ITep.query({ applicationId: this.applicationId}).$promise],
                applicationId: () => this.applicationId,
                rootScope: () => this.$rootScope
            }
        }).result;
    }
}

enum ITepStatus {
    ApplicationMismatch = "AppNoMatch",
    Satisfied = "Satisfied",
    GradesTooLow = "GrTooLow",
    Unsatisfied = "Unsatisfd",
    TechnicalDifficulty = "TechDiff",
    Void = "Void",
}
    
    
class ITepModalController {


    ITepStatus = ITepStatus

    uncompletedTests
    completedTests
    noTests;
    isOfficeUser
    applicationInstance

    static $inject = ['dialogs', '$uibModal', '$q', '$sce', '$uibModalInstance', 'applicationId', 'rootScope', 'ITep', 'tests', 'Auth', 'FindApplication', 'ApplicationInstance'];
    constructor(private dialogs, private $uibModal, private $q: ng.IQService, private $sce, private $uibModalInstance, private applicationId, private rootScope, private ITep, private tests, private Auth, private FindApplication, ApplicationInstance) {
        this.init();
        this.isOfficeUser = Auth.user.isOfficer;
        ApplicationInstance(applicationId).subscribe(i => this.applicationInstance = i);
    }

    getStatusName(status: ITepStatus) {
        switch (status) {
            case ITepStatus.ApplicationMismatch:
                return 'Application mismatch';
            case ITepStatus.GradesTooLow:
                return 'Grades too low';
            case ITepStatus.Satisfied:
                return 'Satisfactory';
            case ITepStatus.TechnicalDifficulty:
                return 'Technical difficulty';
            case ITepStatus.Unsatisfied:
                return 'Unsatisfactory ';
            case ITepStatus.Void:
                return 'Void';
        }
    }

    assign() {
        var name = this.applicationInstance.fullName;
        var message = !this.tests || this.tests.length == 0 ? `Are you sure you wish to assign an iTEP test to ${name}?`
            : `${name} has already taken the iTEP. Are you sure you want to assign a new test? Please remember that at least 90 days need to have passed before an applicant can retake the iTEP.`;



        this.dialogs.confirm('Assign an iTEP test', message)
            .result
            .then(() => this.ITep
                .assign({ applicationId: this.applicationId })
                .$promise.then((data) => {
                    this.noTests = data.hasNoTest;
                }))
            .then(() => this.refresh());
    }

    satisfactory(id) {
        return this.ITep
            .satisfactory({ applicationId: this.applicationId, id: id })
            .$promise
            .then(() => {
                return this.refresh();
            });
    }

    unsatisfactory(id) {
        return this.ITep
            .unsatisfactory({ applicationId: this.applicationId, id: id })
            .$promise
            .then(() => {
                return this.refresh();
            });
    }

    void(id) {
        return this.$uibModal
            .open({
                templateUrl: 'controls/comment/commentModal.html',
                controller: CommentModalController,
                controllerAs: 'ctrl',
                backdrop: false
            })
            .result
            .then((data) => {
                return this.ITep
                    .void(data, { applicationId: this.applicationId, id: id })
                    .$promise;
            }).then(() => {
                return this.refresh();
            });
    }

    unassign(id) {
        this.dialogs.confirm('Unassign iTEP test ', `Are you sure you wish to unassign this iTEP test?`)
            .result
            .then(() => this.ITep
                .unassign({ applicationId: this.applicationId, id: id })
                .$promise)
            .then(() => this.refresh());
    }

    reassign(id) {
        this.FindApplication
            .openDialog()
            .then((application) =>
            {
                return this.dialogs
                    .confirm('Reassign iTEP test ', `Are you sure you want to reassign iTEP Certificate to: <br /> ${application.applicationId} - ${application.fullName}?`)
                    .result
                    .then(() => {
                        return this.ITep
                            .reassign({ id: id, applicationId: this.applicationId, newApplicationId: application.applicationId })
                            .$promise
                            .then(() => {
                                this.refresh();
                            });
                    })
            })
    }

    displayError(results) {

        var message = 'The following applicants flight date cannot set, please try again shortly:</br>';
        message += results
            .filter((r) => !r.success)
            .map((r) => `Application ${r.applicationId} - ${r.error}</br>`);

        return this.dialogs
            .error('Issue occur during flight date assign', this.$sce.trustAsHtml(message))
            .result
    }

    refresh() {
        this.ITep
            .query({ applicationId: this.applicationId })
            .$promise
            .then((tests) => {
                this.tests = tests
                this.init();
            });
            
    }

    init() {
        if (!this.tests) {
            this.uncompletedTests = undefined;
            this.completedTests = undefined;
                
        } else {
            this.uncompletedTests = _.find(this.tests, (t: any) => !t.result);
            this.completedTests = _.filter(this.tests, (t: any) => t.result);
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}