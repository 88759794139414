
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        var extension: state<AccessLevelView> = {
            name: 'flight',
            url: '/flight',
            views: {
                content: {
                    applicant: {
                        controller: 'FlightController as ctrl',
                        template: 'areas/flight/applicant.html'
                    }
                }
            },
            resolve: {
                $title: () => 'Flight'
            },
            states: [
                {
                    name: 'flightOption',
                    url: '/',
                    views: {
                        content: {
                            everyone: {
                                controller: 'FlightOptionController as ctrl',
                                template: 'areas/flight/flightOption/flightOption.html'
                            }
                        }
                    }
                },
                {
                    name: 'flightRequestForm',
                    url: '/flightRequestForm',
                    views: {
                        content: {
                            everyone: {
                                controller: 'FlightRequestFormController as ctrl',
                                template: 'areas/flight/flightRequestForm/flightRequestForm.html'
                            }
                        }
                    }
                },
                {
                    name: 'changeVisa',
                    url: '/changeVisa',
                    views: {
                        content: {
                            everyone: {
                                controller: 'ChangeVisaController as ctrl',
                                template: 'areas/flight/changeVisa/changeVisa.html'
                            }
                        }
                    }
                },
                {
                    name: 'travelRequestForm',
                    url: '/travelRequestForm',
                    views: {
                        content: {
                            everyone: {
                                controller: 'TravelRequestFormController as ctrl',
                                template: 'areas/flight/travelRequestForm/travelRequestForm.html'
                            }
                        }
                    }
                }
            ]
        };

        RoutingProvider.addState(extension, 'app');
    }
}

angular
    .module('app')
    .config(RoutingConfig);