
export class SignInController {
        
    user: any = {};
    currentDate: any;
    isLoginFromAzure: boolean = false;
    maintenanceMessage: any;
    isDisplayMaintenanceMessage: boolean = false;

    static $inject = ['$scope', '$state', 'dialogs', 'Auth', 'CheckSigninFromAzure', 'MaintenanceMessages'];
    constructor(private $scope, private $state, private dialogs, private Auth, private CheckSigninFromAzure, MaintenanceMessages) {
            
        var today = new Date();
        var date = today.getDate();
        var month = ["January","February","March","April","May","June","July","August","September","October","November","December"][today.getMonth()];
        this.currentDate = date + this.nth(date) + " " + month;

        MaintenanceMessages.current((maintenanceMessage) => {
            this.isDisplayMaintenanceMessage = !!maintenanceMessage;
            this.maintenanceMessage = maintenanceMessage;
        });

        this.$scope.$watch(() => this.user.login, (newValue, oldValue) => {
            if (newValue && this.validateEmail(newValue)) {

                //newValue is updated email address;
                CheckSigninFromAzure.get({ email: newValue }, (data) => {
                    this.user.isAzureAccount = data.isLoginFromAzure;
                    this.isLoginFromAzure = data.isLoginFromAzure;
                });
                //this.Auth.checkSigninFromAzure({ emailAddress: newValue });
            }
        });
    }

    validateEmail (email){
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }


    nth(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }


    signin() {
        this.$scope.$broadcast('submitted', true);
            
        if (this.user.$validation()) {
            this.Auth.signin(this.user)
                .then(() => {
                    this.redirectToHomePage();
                }, (error) => {
                    if (error.type == 'redirectToPhase3') {
                        this.dialogs.notify(error.title, 'This email address is already registered. Sign in and complete your application at <a href="http://online.aupairamerica.co.uk/" target="_blank">http://online.aupairamerica.co.uk/</a>');
                    }
                    else {
                        this.dialogs[error.type](error.title, error.message);
                    }                        
                });
        }
    }

    oauth(provider) {
        this.Auth.oauthSignin(provider)
            .then((data) => {
                if(this.Auth.isLoggedIn())
                    this.redirectToHomePage();
                else
                    this.$state.go('app.registerOAuth');

            },(error) => {
                this.dialogs[error.type](error.title, error.message);
            });
    }

    redirectToHomePage() {
        let targetState = this.$state.params.state;
        let targetParams = this.$state.params.params;

        if (!targetState) {
            if (this.Auth.user.role === 'OFF') {
                targetState = 'app.workQueue';
                targetParams = { queueName: '' };
            } else {
                targetState = 'app.home';
                targetParams = {};
            }
        }
        
        this.$state.transitionTo(targetState, targetParams, { reload: true, inherit: true, notify: true });
    }
}

angular
    .module('app')
    .controller('SignInController', SignInController);