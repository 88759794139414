
class ExtensionConfirmPlacementController {
    copStatus: number;

    static $inject = ['Auth', 'USAuPairs', 'Spinner'];
    constructor(private Auth, private USAuPairs, private Spinner) {
        this.Spinner.show();

        this.USAuPairs.canAupairExtend({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.copStatus = result.data.copStatus;
            })
            .finally(() => {
                this.Spinner.hide();
            });
    }
}
angular
    .module('app')
    .controller('ExtensionConfirmPlacementController', ExtensionConfirmPlacementController);