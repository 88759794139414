export class MetaFormConfig {
    static $inject = ['MetaFormProvider', 'apiRoutes', '$sceDelegateProvider'];
    constructor(MetaFormProvider, apiRoutes, $sceDelegateProvider) {

        MetaFormProvider.defaults.rulesEndpoint = `${apiRoutes.metaform}AP4/form/:formName?part=rules&version=:version`;
        MetaFormProvider.defaults.displayFormEndpoint = `${apiRoutes.metaform}AP4/form/:formName?part=display&version=:version`;
        MetaFormProvider.defaults.editFormEndpoint = `${apiRoutes.metaform}AP4/form/:formName?part=edit&version=:version`;
        MetaFormProvider.defaults.helpTextFormEndpoint = `${apiRoutes.metaform}AP4/form/:formName?part=helpText&version=:version`;
        MetaFormProvider.defaults.dropdownEndPoint = '';

        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            `${apiRoutes.metaform}AP4/form/**`
        ]);
    }
}

angular
	.module('app')
    .config(MetaFormConfig);