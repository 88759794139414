import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert' 


export class FeeModalController {

    alerts: IAlertConfig[] = []

    static $inject = ['$uibModalInstance', '$scope', 'dialogs', 'editFee', 'financialPeriod', 'invoiceRegion', 'fees', 'fee'];
    constructor(private $uibModalInstance, private $scope, private dialogs, private editFee, private financialPeriod, private invoiceRegion, private fees, private fee) {
        fee.editFee = editFee;
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        if (!this.editFee && _.some(this.fees, (f:any) =>
            (f.feeId == this.fee.feeId)
            &&
            (
                (f.programAP && this.fee.programAP) ||
                (f.programED && this.fee.programED) ||
                (f.programEX && this.fee.programEX)

            )))
        {
            this.dialogs.error('Error', 'This fee cannot be added as it already exists.');
            return;
        }

        if (this.fee.$validation()) {
            this.$uibModalInstance.close(this.fee);
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
