import moment from 'moment'
import { IAction } from '../IAction'
import { ActionType, ActionTypeItems } from '../../../models/userActions/ActionType'
import { ITransaction } from '../../../models/finance/ITransaction'
import { InsuranceFees } from '../../../models/finance/IInsuranceFee'
import { InsuranceType } from '../../../models/finance/InsuranceType'


export class RecordInsurance implements IAction{

    static $inject = ['$uibModal', 'Application', 'Applicant', 'Invoice', 'applicationId', 'invoiceId']
    constructor(private $uibModal, private Application, private Applicant, private Invoice, private applicationId, private invoiceId) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/recordInsurance/recordInsuranceModal.html',
            controller: RecordInsuranceActionController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                data: () => <any>{
                    applicationId: this.applicationId,
                    invoiceId: this.invoiceId,
                    dateEntered: new Date(),
                    receivedDate: moment().utc().startOf('day').toDate()
                },
                invoice: () => this.Invoice.get({ applicationId: this.applicationId, invoiceId: this.invoiceId }).$promise,
                applicant: () => {
                    return this.Application.get({ applicationId: this.applicationId })
                        .$promise
                        .then((data) => {
                            return this.Applicant.get({ applicantId: data.applicantId }).$promise;
                        });
                    },
                title: () => ActionTypeItems[ActionType.RECORD_INSURANCE].name
            }
        }).result
    }
}

class RecordInsuranceActionController {

    public submited: boolean
    public InsuranceFees = InsuranceFees

    public selectedTransaction: ITransaction

    static $inject = ['$rootScope', '$scope', 'dialogs', '$uibModalInstance', 'Invoice', 'data', 'title', 'Finance', 'invoice', 'applicant']
    constructor(private $rootScope, private $scope, private dialogs, private $uibModalInstance, private Invoice, private data, private title: string, private Finance, private invoice, private applicant) {
            
    }

    public doAction() {
        this.submited = true;
        this.$scope.$broadcast('submitted', true)


        if (!this.data.insuranceTypes || this.data.insuranceTypes.length == 0){
            return;
        }

        if (this.data.$validation()) {
                
            this.Invoice.recordInsurance(this.data).$promise
                .then((results) => {
                    this.$rootScope.$broadcast('invoice:changed');
                    this.$uibModalInstance.close()
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
                });
        }
    }

    toggleInsurance(insurance) {
        if (insurance.disabled)
            return;

        insurance.selected = !insurance.selected

        let selectedInsurances = this.invoice.unpaidInsurances.filter(insurance => insurance.selected);
        let selectedInsuranceCombinations = this.invoice.unpaidInsuranceCombinations.filter(i => i.containingFees.every(fee => selectedInsurances.some(ins => ins.feeType == fee)));

        this.data.insuranceTypes = []
        for (let insurance of selectedInsurances) {
            if (selectedInsuranceCombinations.every(i => i.containingFees.every(f => f != insurance.feeType))) {
                this.data.insuranceTypes.push(insurance.insuranceType);
            }
        }
        for (let insurance of selectedInsuranceCombinations) {
            this.data.insuranceTypes.push(insurance.insuranceType);
        }
    }
    disableInsurance(type: InsuranceType, enabled: boolean) {
        for (let insurance of this.invoice.unpaidInsurances) {
            if (insurance.insuranceType == type) {
                insurance.disabled = !enabled;
                return;
            }
        }
    }

    close() {
        this.$uibModalInstance.dismiss()
    }
}