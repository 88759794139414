
export class ReportsController {

    toggle: boolean[]

    static $inject = ['$sce', 'reportGroups'];
    constructor(private $sce, private reportGroups) {
        this.toggle = [];
    }

    expandCollapse(reportGroupId) {
        this.toggle[reportGroupId] = !this.toggle[reportGroupId];
    }

}

angular
    .module('app')
    .controller('ReportsController', ReportsController);