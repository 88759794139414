import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class CommissionStatementController {
    agencyId: number

    alerts: IAlertConfig[] = [];
    selectedPaymentDateId: any;
    commissionPaymentDates: any[];
    commissionStatementReportId: number = 3

    static $inject = ['$rootScope', '$sce', 'Report', 'agencyId', 'commissionStatementPaymentDates'];
    constructor($rootScope, private $sce, private Report, agencyId, commissionStatementPaymentDates) {
        this.agencyId = agencyId;

        this.commissionPaymentDates = commissionStatementPaymentDates;
        if (this.commissionPaymentDates.length > 0) {
            var firstPaymentDate = this.commissionPaymentDates[0];
            this.commissionPaymentDates[0].displayDateText = firstPaymentDate.displayDateText + ' (Latest Commission Payment)';
            this.selectedPaymentDateId = "" + firstPaymentDate.id;
        }
    }

    downloadContractualStatementReport() {
        this.Report.downloadUrl({
            reportId: this.commissionStatementReportId,
            agencyId: this.agencyId,
            selectedPaymentDateId: this.selectedPaymentDateId})
        .$promise
        .then((result) => {
            window.open(this.$sce.trustAsResourceUrl(result.reportURL), '_blank');
        });
    }
}

angular
    .module('app')
    .controller('CommissionStatementController', CommissionStatementController)