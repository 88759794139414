import _ from 'lodash-es'

enum ResultTypes {
    isTooYoung,
    isTooOld,
    isWrongGender,
    isUSNational,
    isWrongCountry,
    isAccepted
}

class BrochureController {

    step: number = 1;
    lastStep: number = 2;
    brochure: any;
    agencies: any[];
    notSupportedCountry: boolean;
    result;
    resultTypes = ResultTypes;
    emailExist: boolean;
    gdpr: boolean;

    static $inject = ['$scope', '$window', '$state', '$stateParams', 'dialogs', 'Auth', 'Brochure', 'RegistrationAgency', 'Registration', '$uibModal', 'RegistrationLookup'];
    constructor(private $scope, private $window, private $state, private $stateParams, private dialogs, private Auth, private Brochure, RegistrationAgency, private Registration, private $uibModal, RegistrationLookup) {
            
        this.brochure = {};
        this.brochure.getCountry = function () {
            return this.residenceCountry;
        }

        var residenceCountries = RegistrationLookup.residence()

        $scope.$watch(() => this.brochure.email, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.emailExist = false;
                if (newValue) {
                    this.Registration.emailValid({ value: newValue }, (result) => {
                        this.emailExist = !result.isValid;
                        if (this.emailExist) {
                            this.displayMessageForExistingUsers();
                        }
                    });
                }
            }
        });
            
        $scope.$watch(() => this.brochure.residenceCountry, (newValue, oldValue) => {
            this.brochure.agencyId = undefined;
            if (newValue !== oldValue) {
                this.lastStep = 2;
                this.notSupportedCountry = false;
                this.agencies = [];

                residenceCountries.$promise.then(c => {
                    var country = _.find(c, (r:any) => r.code == newValue);
                    this.gdpr = country && country.gdpr;
                    this.brochure.versionId = country && country.applicationVersion;
                });

                RegistrationAgency.query({ country: newValue }, (agencies) => {
                    this.agencies = agencies;
                    if (agencies.length === 0) {
                        this.notSupportedCountry = true;
                    }
                    if (agencies.length === 1) {
                        this.brochure.agencyId = agencies[0].agencyId;
                    }
                });
            }
        })
            


    }

    displayMessageForExistingUsers() {

        this.$uibModal.open({
            templateUrl: 'areas/user/brochure/existingUserModal.html',
            controller: ExistingUserModalController,
            controllerAs: 'ctrl',
            backdrop: false
        });
    }

    register() {
        if (this.emailExist) {
            this.displayMessageForExistingUsers();
            return;
        }

        if (this.validation()) {
            this.Brochure.save(this.brochure)
                .$promise
                .then((res) => {
                    this.step = 3;
                    this.result = res.result;
                    //this.$state.transitionTo('app.home', {}, { reload: true, inherit: true, notify: true });
                }, (response) => {
                    this.dialogs.error('Error', response.data);
                });
        }
    }

        

    nextStep() {
        if (this.validation()) {
            this.step++;
        }
    }

    prevStep() {
        this.step--;
    }

    validation() {
        switch (this.step) {
            case 1:
                this.$scope.$broadcast('submitted', true);
                return this.brochure.$validation();
            case 2:
                if (!this.brochure.agencyId) {
                    this.dialogs.error('Brochure', 'Please select agency.')
                    return false;
                } else {
                    return true;
                }
        }
        return true;
    }
}

class ExistingUserModalController {
    static $inject = ['$uibModalInstance'];
    constructor(private $uibModalInstance) {

    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}

angular
    .module('app')
    .controller('BrochureController', BrochureController);
