
class ShareTelephoneReferenceCheckController {

    static $inject = ['telephoneReferenceCheck']
    constructor(public telephoneReferenceCheck) {
    }
        
}

angular
    .module('app')
    .controller('ShareTelephoneReferenceCheckController', ShareTelephoneReferenceCheckController)
