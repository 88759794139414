class SaveFilterController {
        
    filterSettings;

    static $inject = ['$scope', '$uibModalInstance', 'Auth', 'WorkQueueSettings', 'settings', 'rootScope'];
    constructor(private $scope, private $uibModalInstance, Auth, private WorkQueueSettings, settings, private rootScope) {
        this.filterSettings = {
            userId: Auth.user.userId,
            settings: settings
        }
    }
        

    save() {
        this.WorkQueueSettings.save(this.filterSettings, (result) => {
            this.$uibModalInstance.close(result);
        });
    }

    close() {
        this.$uibModalInstance.dismiss();
    }


}

angular
    .module('app')
    .controller('SaveFilterController', SaveFilterController);