function AifsComponentLink(scope, element, attrs, ctrls, transclude) {

    var modelCtrl = ctrls[1];
    var componentCtrl = ctrls[2];

    componentCtrl.$isValid = function () {
        return modelCtrl.$valid;
    };
    componentCtrl.$error = function () {
        return modelCtrl.$error;
    };
}

class AifsComponentController {

    $name

    static $inject = ['$scope', '$element', '$attrs']
    constructor($scope, $element, $attrs) {
        this.$name = $attrs.name;

        $element.controller('aifsQuestion').$addComponent(this);
        $element.on('$destroy', () => {
            $element.controller('aifsQuestion').$removeComponent(this)
        });

        $scope.$on('disableComponent', function (event, data) {
            if (data.name == $attrs.name) {
                $element.attr('disabled', '');
            }
        });
        $scope.$on('enableComponent', function (event, data) {
            if (data.name == $attrs.name) {
                $element.removeAttr('disabled');
            }
        });
    }
}



angular
    .module("aifs.metaform")
    .directive("aifsComponent", function () {
        return {
            restrict: 'A',
            require: ["^form", '?ngModel', 'aifsComponent'],
            link: AifsComponentLink,
            controller: AifsComponentController
        };
    });
