 
    

class VoucherInformationDirective implements angular.IDirective {
    restrict = 'AE'
    controllerAs = 'ctrl'
    controller = VoucherInformationController
    bindToController = true
    templateUrl = 'controls/voucher/voucherInformation.html'
    scope = {
        data: '=',
        canAdd: '='
    }
}

class VoucherInformationController {

    voucherInfo: any;
    voucherCode: string;
    voucherValid: boolean;
    voucherApplied: boolean;
    displayVoucherValidMessage: boolean;
    data: any;
    canAdd: boolean;

    static $inject = ['Auth', 'Finance', 'Registration', '$timeout', 'Spinner'];
    constructor(private Auth, private Finance, private Registration, private $timeout, private Spinner) {
            
    }

    $onInit() {
        this.getVoucherInformation();
    }

    getVoucherInformation() {
        this.Finance.voucherInformation({ applicationId: this.data.applicationId}, (voucherInfo) => {
            if (voucherInfo && voucherInfo.id > 0) {
                this.voucherInfo = voucherInfo;
                this.voucherCode = '';
                this.voucherValid = true;
                this.voucherApplied = true;
            }
        })
    }

    checkAndApplyVoucher() {
        if (this.voucherCode) {

            this.Spinner.show();

            this.data.voucherCode = this.voucherCode;
            this.Registration.voucherValid({}, this.data)
                .$promise
                .then((result) => {
                    if (result.isValid) {
                        this.voucherValid = true;
                        this.displayVoucherValidMessage = false;
                        this.applyVoucher();
                    }
                    else {
                        this.voucherValid = false;
                        this.displayVoucherValidMessage = true;
                    }
                })
                .finally(() => {
                    this.$timeout(() => { this.Spinner.hide(); }, 1000);
                });
        }
    }

    applyVoucher() {

        this.data.voucherCode = this.voucherCode;
        this.Registration.applyVoucher({}, this.data, () => {
            this.getVoucherInformation();
        })

    }

}

angular
    .module('app')
    .directive('voucherInformation', () => new VoucherInformationDirective())

