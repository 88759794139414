
export class AccessDeniedController {

    reason
    businessArea

    static $inject = ['$scope', '$state'];
    constructor(private $scope, private $state) {
        this.reason = $state.params.reason;
        this.businessArea = $state.params.businessArea;
    }
}

angular
    .module('app')
    .controller('AccessDeniedController', AccessDeniedController);
