
class ApplicationShareController {

    tooltip: {
        text: string,
        open: boolean
    } = {
        text: '',
        open: false
    }

    static $inject = ['$state', '$timeout', 'applicationShareGuids']
    constructor(private $state: angular.ui.IStateService, private $timeout: angular.ITimeoutService, private applicationShareGuids) {
    }

    getPublicUrl() {
        if (this.$state.includes('app.applications.applicationShare.application')) {
            return this.$state.href('app.share.application', { applicationGuid: this.applicationShareGuids.applicationGuid }, { absolute: true })
        } else if (this.$state.includes('app.applications.applicationShare.backgroundQuestions')) {
            return this.$state.href('app.share.backgroundQuestions', { backgroundQuestionsGuid: this.applicationShareGuids.backgroundQuestionsGuid }, { absolute: true })
        } else if (this.$state.includes('app.applications.applicationShare.childcareExperience')) {
            return this.$state.href('app.share.childcareExperience', { childcareExperienceGuid: this.applicationShareGuids.childcareExperienceGuid }, { absolute: true })
        } else if (this.$state.includes('app.applications.applicationShare.interviewReport')) {
            return this.$state.href('app.share.interviewReport', { interviewReportGuid: this.applicationShareGuids.interviewReportGuid }, { absolute: true })
        } else if (this.$state.includes('app.applications.applicationShare.telephoneReferenceCheck')) {
            return this.$state.href('app.share.telephoneReferenceCheck', { telephoneReferenceCheckGuid: this.applicationShareGuids.telephoneReferenceCheckGuid }, { absolute: true })
        } else if (this.$state.includes('app.applications.applicationShare.shareApplication')) {
            return this.$state.href('app.share.shareApplication', { shareApplicationGuid: this.applicationShareGuids.shareApplicationGuid }, { absolute: true })
        }
        return undefined;
    }

    onCopyUrlSuccess(e) {
        this.tooltip.text = "Coppied to clipboard!"
        this.tooltip.open = true

        this.$timeout(() => {
            this.tooltip.open = false
        }, 1500)
    }

}

angular
    .module('app')
    .controller('ApplicationShareController', ApplicationShareController)
