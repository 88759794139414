
class ExtensionEducationStatusController {
    educationStatus: string;

    static $inject = ['Auth', 'USAuPairs', 'Spinner'];
    constructor(private Auth, private USAuPairs, private Spinner) {
        this.Spinner.show();

        this.USAuPairs.canAupairExtend({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {                
                this.educationStatus = result.data.educationStatus;
            })
            .finally(() => {
                this.Spinner.hide();
            });        
    }
}
angular
    .module('app')
    .controller('ExtensionEducationStatusController', ExtensionEducationStatusController);