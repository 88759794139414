 

const calculateDelta = (now, date) => Math.round(Math.abs(now - date) / 1000)

const minute = 60
const hour = minute * 60
const day = hour * 24
const week = day * 7
const month = day * 30

    

function RelativeDateFiterFactory($filter): Function {
    var dateFilter = $filter('date');

    return function RelativeDateFiter(value, format, timezone) {
    if (value) {
        var now = new Date()
        var delta = calculateDelta(now, value)

        switch (false) {
            case !(delta < 30):
            return `just now`

            case !(delta < minute):
            return `${delta} seconds ago`

            case !(delta < 2 * minute):
            return `a minute ago`

            case !(delta < hour):
            return `${Math.floor(delta / minute)} minutes ago`

            case Math.floor(delta / hour) !== 1:
            return `an hour ago`

            case !(delta < day):
            return `${Math.floor(delta / hour)} hours ago`

            case !(delta < day * 2):
            return `yesterday`

            case !(delta < week):
            return `${Math.floor(delta / day)} days ago`

            case Math.floor(delta / week) !== 1:
            return `a week ago`

            default:
            return dateFilter(value, format, timezone)
        }
    }
    return;
};
}

angular
    .module('app')
    .filter('relativeDate', ['$filter', RelativeDateFiterFactory]);