import { SectionController } from '../SectionController';
import { DocumentUploadConfig, SingleDocumentUploader, MultiDocumentUploader } from '../../../controls/documentsUpload/DocumentUploadConfig';
import { ReviewPage } from '../../../services/ReviewService';
import { ItemGroupId } from '../../../models/ItemGroup';
import { ReviewItemId } from '../../../models/ReviewItem';

class MyUploadsController extends SectionController {

    videoUploadConfig: DocumentUploadConfig<SingleDocumentUploader>;
    imagesUploadConfig: DocumentUploadConfig<MultiDocumentUploader>;
    certificateUploadConf: DocumentUploadConfig<SingleDocumentUploader>;
    hobbyUploadConfig: any;
    drivingLicenceUploadConf: any;
    passportUploadConf: any;
    passportUploadState: any;

    medicaldocumentUploadConf: DocumentUploadConfig<MultiDocumentUploader>
    criminalRecordCheckUploadConf: DocumentUploadConfig<MultiDocumentUploader>
    covidVacProofUploadConf: DocumentUploadConfig<SingleDocumentUploader>
    medicalUploadState: any;
    criminalUploadState: any;
    eduCertUploadState: any;


    educationCertdocumentUploadConf: DocumentUploadConfig<MultiDocumentUploader>;

    aboutYou: any;
    media: any;
    hostFamilyLetter: any;
    lastAutoSave: Date;
    educationHistory: any[];
    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    page: ReviewPage;
    certificateUploadState: any = 0;
    applicationStatus: string;

    static override $inject = ['$rootScope', '$scope', '$q', '$interval', '$uibModal', 'dialogs', 'ApplicationUploader', 'review', 'applicationId', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'ApplicationDocument', 'applicationObservable','educationAndWorkPage'];
    constructor(
        $rootScope: angular.IRootScopeService,
        $scope: angular.IScope,
        private $q: angular.IQService,
        private $interval: angular.IIntervalService,
        private $uibModal,
        dialogs,
        private ApplicationUploader,
        review,
        applicationId,
        private ApproveChanges,
        Auth,
        ReevaluateSection,
        private ApplicationDocument,
        applicationObservable,
        educationAndWorkPage
    ) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.MyUploads, applicationObservable, dialogs);

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        this.page = review.getPage(ItemGroupId.MyUploads);
        //this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        if (educationAndWorkPage && educationAndWorkPage.history) {
            this.educationHistory = educationAndWorkPage.history.filter(h => h.historyType === 'E').sort(h => h.dateFrom);
        }

        this.videoUploadConfig = {
            hideDescription: true,
            uploader: new ApplicationUploader(applicationId, 3),
            msg: {
                upload: 'Click here to add your video now'
            }
        }

        this.imagesUploadConfig = {
            uploader: new ApplicationUploader(applicationId, 2)
        }

        let uploaderCertUpload = new ApplicationUploader(this.applicationId, 10)

        this.certificateUploadConf = {
            //hideDescription: true,
            uploader: uploaderCertUpload,
            msg: {
                upload: 'Upload Skills Certificates',
                uploaded: 'View Skills Certificates',
                approved: 'Approved Skills Certificates'

            },
            onUpdateHandler: () => {
                this.updateCertificateUploadState(uploaderCertUpload);
            },
            onDeleteHandler: () => {
                this.updateCertificateUploadState(uploaderCertUpload);
            },
            onApproveHandler: () => {
                this.updateCertificateUploadState(uploaderCertUpload);
            },
        }
        this.updateCertificateUploadState(uploaderCertUpload);

        this.drivingLicenceUploadConf = {
            uploader: new ApplicationUploader(applicationId, 7),
            hideDescription: true,
            msg: {
                upload: 'Upload Driving License',
                processing: 'Show Driving License',
                uploaded: 'Show Driving License',
                approved: 'Approved Driving License'

            }
        }


        let passportUploader = new ApplicationUploader(applicationId, 8);

        this.passportUploadConf = {
            uploader: passportUploader,
            hideDescription: true,
            onUpdateHandler: () => {
                this.load(passportUploader);
            },
            onDeleteHandler: () => {
                this.load(passportUploader);
            },
            onApproveHandler: () => {
                this.load(passportUploader);
            },
            msg: {
                upload: 'Upload Passport',
                processing: 'Show Passport',
                uploaded: 'Show Passport',
                approved: 'Approved Passport'
            }
        }

        this.load(passportUploader);

        let uploaderMedical = new ApplicationUploader(applicationId, 4);
        let covidVacProofUUploader = new ApplicationUploader(applicationId, 23);
        let criminalRecordCheckUploader = new ApplicationUploader(applicationId, 12);
        let educationCertUploader = new this.ApplicationUploader(this.applicationId, 18);

        this.medicaldocumentUploadConf = {
            uploader: uploaderMedical,
            hideDescription: true,
            onUpdateHandler: () => {
                this.updateMedicalUploadState(uploaderMedical);
            },
            onDeleteHandler: () => {
                this.updateMedicalUploadState(uploaderMedical);
            },
            onApproveHandler: () => {
                this.updateMedicalUploadState(uploaderMedical);
            },
            msg: {
                upload: 'Upload Medical Documents',
                processing: 'Show Medical Documents',
                uploaded: 'Show Medical Documents',
                approved: 'Approved Medical Documents'

            }
        }
        this.updateMedicalUploadState(uploaderMedical);


        this.criminalRecordCheckUploadConf = {
            uploader: criminalRecordCheckUploader,
            hideDescription: true,
            onUpdateHandler: () => {
                this.updateCriminalUploadState(criminalRecordCheckUploader);
            },
            onDeleteHandler: () => {
                this.updateCriminalUploadState(criminalRecordCheckUploader);
            },
            onApproveHandler: () => {
                this.updateCriminalUploadState(criminalRecordCheckUploader);
            },
            msg: {
                upload: 'Upload Criminal Record Check',
                processing: 'Show Criminal Record Check',
                uploaded: 'Show Criminal Record Check',
                approved: 'Approved Criminal Record Check'

            }
        }
        this.updateCriminalUploadState(criminalRecordCheckUploader);


        this.educationCertdocumentUploadConf = {
            uploader: educationCertUploader,
            hideDescription: true,
            onUpdateHandler: () => {
                this.updateEducationCertUploadState(educationCertUploader);
            },
            onDeleteHandler: () => {
                this.updateEducationCertUploadState(educationCertUploader);
            },
            onApproveHandler: () => {
                this.updateEducationCertUploadState(educationCertUploader);
            },
            msg: {
                upload: 'Upload Certificate',
                processing: 'Processing Certificate',
                uploaded: 'View Certificate',
                approved: 'View Approved Certificate'

            }
        }
        this.updateEducationCertUploadState(educationCertUploader);


        this.covidVacProofUploadConf = {
            uploader: covidVacProofUUploader,
            hideDescription: true,
            msg: {
                upload: 'Upload Proof of Covid-19 Vaccination',
                processing: 'Show Proof of Covid-19 Vaccination',
                uploaded: 'Show Proof of Covid-19 Vaccination',
                approved: 'Approved Proof of Covid-19 Vaccination'
            }
        }

        applicationObservable.subscribe(i => {
            this.applicationStatus = i.status;
        })

        //this.getAllEducationConfig();
    }

    //getAllEducationConfig() {
    //    if (this.educationHistory) {
    //        for (var entry of this.educationHistory) {
    //            this.getEducationConfig(entry);
    //        }
    //    }
    //}

    //getEducationConfig() {
    //    if (!entry.data) {
    //        var educationCertUploader = new this.ApplicationUploader(this.applicationId, 18, 0);
            
    //        this.educationCertdocumentUploadConf = {
    //            uploader: educationCertUploader,
    //            onUpdateHandler: () => {
    //                // This is called when applicant add an image OR management unapprove an image
    //                entry.documentStatusDesc = "View Certificate";
    //                this.updateEducationCertUploadState(entry)
    //            },
    //            onDeleteHandler: () => {
    //                // This is called when applicant deletes an image
    //                entry.documentStatusDesc = "Upload Certificate";
    //                this.updateEducationCertUploadState(entry)
    //            },
    //            onApproveHandler: () => {
    //                // This is called when management approves an image
    //                entry.documentStatusDesc = "View Approved Certificate";
    //                this.updateEducationCertUploadState(entry)
    //            },
    //            msg: {
    //                upload: 'Upload Certificate',
    //                processing: 'Processing Certificate',
    //                uploaded: 'View Certificate',
    //                approved: 'View Approved Certificate'

    //            }
    //        }

    //        entry.educationCertdocumentUploadConf = this.educationCertdocumentUploadConf;

    //    }
    //    if (!this.eduCertUploadState) {
    //        this.updateEducationCertUploadState(entry)
    //    }
    //}

    getEducationUploaderConf(entry) {

        //var educationCertdocumentUploadConf = DocumentUploadConfig<MultiDocumentUploader>;
        if (entry && !entry.data) {
            var educationCertUploader = new this.ApplicationUploader(this.applicationId, 18, entry.id);
            var educationCertdocumentUploadConf = {
                uploader: educationCertUploader,
                onUpdateHandler: () => {
                    // This is called when applicant add an image OR management unapprove an image
                    entry.documentStatusDesc = "View Certificate";
                    this.updateEducationCertUploadState(entry)
                },
                onDeleteHandler: () => {
                    // This is called when applicant deletes an image
                    entry.documentStatusDesc = "Upload Certificate";
                    this.updateEducationCertUploadState(entry)
                },
                onApproveHandler: () => {
                    // This is called when management approves an image
                    entry.documentStatusDesc = "View Approved Certificate";
                    this.updateEducationCertUploadState(entry)
                }
            }
        }
    }

    updateEducationCertUploadState(entry) {
        this.eduCertUploadState = 0;

        this.ApplicationDocument.fetchIndividualDocument({ categoryId: 18, applicationId: this.applicationId, extraReference: 0 })
            .$promise
            .then((result) => {
                this.eduCertUploadState = 0;

                if (result && result.length > 0 && result[0].documents && result[0].documents.length > 0) {
                    this.eduCertUploadState = result[0].documents[0].status;
                }
            });
    }

    updateCriminalUploadState(criminalRecordCheckUploader) {
        this.criminalUploadState = 0;

        this.ApplicationDocument.query({ category: criminalRecordCheckUploader.category, applicationId: this.applicationId })
            .$promise
            .then((result) => {
                this.criminalUploadState = this.getUploadStatusForMultipleFiles(result);
            });
    }

    updateMedicalUploadState(uploader) {
        this.medicalUploadState = 0;
        this.ApplicationDocument.query({ category: uploader.category, applicationId: this.applicationId })
            .$promise
            .then((result) => {
                this.medicalUploadState = this.getUploadStatusForMultipleFiles(result);
            });
    }


    load(passportUploader) {
        this.passportUploadState = 0;
        this.ApplicationDocument.query({ category: passportUploader.category, applicationId: this.applicationId })
            .$promise
            .then((result) => {
                this.passportUploadState = this.getUploadStatusForMultipleFiles(result);
            });
    }

    updateCertificateUploadState(criminalRecordCheckUploader) {
        this.certificateUploadState = 0;

        this.ApplicationDocument.query({ category: criminalRecordCheckUploader.category, applicationId: this.applicationId })
            .$promise
            .then((result) => {
                this.certificateUploadState = this.getUploadStatusForMultipleFiles(result);
            });
    }

    getUploadStatusForMultipleFiles(result) {
        let noDocuments = true;
        let allDocumentsApproved = true;

        for (let i = 0; i < result.documents.length; i++) {
            const category = result.documents[i];
            for (let j = 0; j < category.documents.length; j++) {
                noDocuments = false;

                if (category.documents[j].status !== 1) {
                    allDocumentsApproved = false;
                }
            }
        }

        if (noDocuments) {
            return 0;
        } else {
            return allDocumentsApproved ? 1 : 2;
        }
    }
}

angular
.module('app')
.controller('MyUploadsController', MyUploadsController)
