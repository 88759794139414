import { IAlertConfig } from '../../../models/bootstrap/Alert' 


export class childcareExperience {
    averageHours: string
    responsibilities: string
    confirmChk: string
}

class ExtensionChildcareExperienceController {

    details: any;
    showError: boolean = false;
    displayReadOnly: boolean = false;
    alerts: IAlertConfig[] = [];
    showMessageText: boolean = false;
    displayOfficeMessage: boolean = false;
    message: string;
    data: any;
       
    static $inject = ['$rootScope', '$q', '$timeout', '$scope', '$uibModal', 'Auth', 'USAuPairs', 'Spinner']
    constructor(private $rootScope, private $q, private $timeout, private $scope, private $uibModal, private Auth, private USAuPairs, private Spinner) {           
        this.Spinner.show();
        this.getChildcareExpData();
        this.Spinner.hide();
    }

    getChildcareExpData() {
        this.USAuPairs.getChildcareExp({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.details = result.data;
                this.data = result.data.extensionAppReview;

                this.displayReadOnly = (this.data.extensionApplicationReview
                    && (this.data.extensionApplicationReview.usChildcareExpSectionStatus == '3'
                    || this.data.extensionApplicationReview.usChildcareExpSectionStatus == '5'
                    || this.data.extensionApplicationReview.usChildcareExpSectionStatus == '6')
                        ) || false;

                this.displayOfficeMessage = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.usChildcareExpSectionStatus == '4') || false;

                this.message = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.usChildcareExpSectionStatusReason)
            });
    }

    editChildcareExp(item) {            
        // for first time
        item.usChildcareExperience = (item.usChildcareExperience) || new childcareExperience();

        this.$uibModal.open({
            templateUrl: 'areas/extension/childcareExperience/childcareExperienceModal.html',
            controller: 'ExtensionChildcareExperienceModalController as childExpCtrl',
            backdrop: false,
            resolve: {
                title: () => 'Edit childcare experience',
                childcareExp: () => angular.copy(item)
            }
        }).result.then((data) => {
            var i = this.details.match.indexOf(item);
            if (i > -1) {
                this.details.match[i] = data;
                this.showError = false;
            }
            return data;
        });
    }

    submit() {

        var isValid = true;
        var promises = [];
        this.alerts = [];

        // Get all matches for childcare Experience validation
        this.USAuPairs.getChildcareExp({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {   
                // Iterate through all the childcare experience's and see if its filled
                angular.forEach(result.data.match, function (value, key) {
                    if (!value.usChildcareExperience) {
                        isValid = false;
                    }
                });

                // if everything is good, save Submitted in DB otherwise display an error
                if (isValid) {  
                    this.Spinner.show();
                    this.details.aupairId = this.Auth.user.usAuPairId;
                    this.details.isSubmitted = true;

                    promises.push(this.USAuPairs.submitChildcareExp({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

                    this.$q.all(promises)
                        .then(() => {                                
                            this.$rootScope.$broadcast('update:extension');  
                            this.getChildcareExpData();
                            this.alerts.push({
                                type: 'success',
                                msg: 'Save complete',
                                dismissOnTimeout: 3000
                            })
                            this.showMessageText = true;
                            this.$timeout(() => { this.showMessageText = false; }, 3000);
                        }, () => {
                            this.alerts.push({
                                type: 'danger',
                                msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk"> info@aupairamerica.co.uk</a>',
                                dismissOnTimeout: 3000
                            })
                            this.showMessageText = true;
                            this.$timeout(() => { this.showMessageText = false; }, 3000);
                        })
                        .finally(() => {
                            this.Spinner.hide();
                        });
                }
                else {
                    this.showError = true;
                }
            });
    }
}

angular
    .module('app')
    .controller('ExtensionChildcareExperienceController', ExtensionChildcareExperienceController)

