 

var BlockPasteDirectiveFactory = ['dialogs', function BlockPasteDirectiveFactory (dialogs): ng.IDirective {
       
    function link(scope: ng.IScope, element: ng.IAugmentedJQuery) {
        element.bind('paste', function (event) {
            event.preventDefault();
            dialogs.notify('Info', 'Paste options are disabled on this field!');
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}]

angular
    .module('app')
    .directive('blockPaste', BlockPasteDirectiveFactory);