import _ from 'lodash-es'
import moment from 'moment'
import { IAction } from '../IAction'

export class ScheduleInterview implements IAction {

    static $inject = ['$uibModal', 'interview']
    constructor(private $uibModal, private interview, private applicantUserId = undefined) { }

    execute = () => {

        return this.$uibModal.open({
            templateUrl: 'areas/action/scheduleInterview/scheduleInterviewModal.html',
            controller: ScheduleInterviewModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                interview: () => this.interview,
                applicantUserId: () => this.applicantUserId
            }
        }).result
    }
}

interface IScheduleInterviewModalScope extends angular.IScope {
    interviewForm: any
}

class ScheduleInterviewModalController {

    public applicants: Array<any>

    public events
    public slots

    static $inject = ['dialogs', '$q', '$scope', '$uibModalInstance', 'Event', 'InterviewerApplicant', 'interview', 'applicantUserId']
    constructor(
        private dialogs,
        private $q: angular.IQService,
        private $scope: IScheduleInterviewModalScope,
        private $uibModalInstance,
        private Event,
        private InterviewerApplicant,
        private interview,
        applicantUserId
    ) {
        interview.applicantUserId = applicantUserId

        var todayDate = moment();
        interview.todaydate = function () {
            return todayDate;
        }

        if (!interview.duration)
            interview.duration = 120

        this.$scope.$watch(() => this.interview.dateStart, (newValue, oldValue) => {
            if (newValue && moment.isDate(newValue) && newValue != oldValue) {
                if (moment(newValue).isSame(oldValue, 'day')) {
                    this.refreshResult()
                } else {
                    this.updateEvents()
                }
            }
        })

        this.$scope.$watch(() => this.interview.duration, (newValue, oldValue) => {
            this.refreshResult()
        })


        this.updateEvents()
    }

    collision() {
        return !this.checkNoCollison({
            dateStart: this.interview.dateStart,
            dateEnd: moment(this.interview.dateStart).add(this.interview.duration, 'minutes').toDate()
        })
    }

    private updateEvents() {
        var promise
        if (this.interview && this.interview.userId && this.interview.dateStart) {

            this.events = this.Event.user({
                id: this.interview.userId,
                from: moment(this.interview.dateStart).format('DD/MM/YYYY'),
                to: moment(this.interview.dateStart).add(1, 'day').format('DD/MM/YYYY')
            })
            promise = this.events.$promise
        } else {
            this.events = []
            promise = this.$q.when(this.events)
        }
        return promise.then((data) => {
            this.refreshResult()
            return data
        })
    }

    private refreshResult() {

        for (let event of this.events) {

            var before = {
                dateStart: moment(event.dateStart).subtract(this.interview.duration, 'minutes').toDate(),
                dateEnd: event.dateStart
            }
            event.before = this.checkNoCollison(before) ? before : undefined


            var after = {
                dateStart: event.dateEnd,
                dateEnd: moment(event.dateEnd).add(this.interview.duration, 'minutes').toDate()
            }
            event.after = this.checkNoCollison(after) ? after : undefined
        }
    }


    private checkNoCollison(newEvents) {
        return _.every(this.events,
            (evn: any) => newEvents.dateEnd <= evn.dateStart || evn.dateEnd <= newEvents.dateStart)
    }


    selectAvalibleSlot(slot) {
        this.interview.dateStart = slot.dateStart
        //this.interview.time = slot.dateStart.getHours() + ':' + ('0' + slot.dateStart.getMinutes()).slice(-2)
        //this.interview.duration = moment.duration(moment(slot.dateEnd).diff(moment(slot.dateStart))).asMinutes().toString()              
    }

    save() {
        this.$scope.$broadcast('submitted', true)

        var form = this.$scope.interviewForm
        if (form.$dirty) {
            var int = this.interview
            this.Event.create({
                userId: int.userId,
                agencyId: int.agencyId,
                entryType: int.entryType,
                dateStart: int.dateStart,
                dateEnd: moment(int.dateStart).add(int.duration, 'minutes').toDate(),
                location: this.mapTypeToLocation(int.entryType),
                notes: int.notes,
                invitees: [int.applicantUserId]
            }).$promise.then(() => {
                this.$uibModalInstance.close()
            })
        }

    }

    mapTypeToLocation(type) {
        switch (type) {
            case 1:
                return 'In person';
            case 5:
                return 'Online';
            default:
                return '';
        }
    }

    close() {
        this.$uibModalInstance.dismiss()
    }

}