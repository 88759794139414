 

class ApplicationCommentDirective implements ng.IDirective {
        restrict = 'AE'
        controller = ApplicationCommentController
        controllerAs = 'acCtrl'
        bindToController = true
        templateUrl = 'controls/applicationComment/applicationComment.html'    
        scope = {
            applicationId: '='
        }               
}

class ApplicationCommentController {
    applicationId: number
    comments: any[];
    newComment: string

    static $inject = ['$scope', 'ApplicationComments', 'Auth', 'dialogs'];
    constructor(private $scope, private ApplicationComments, private Auth, private dialogs) {

        $scope.$watch(() => this.applicationId, (newValue, oldValue) => {
            if (newValue) {
                this.refreshComments();
            }                
        });

        $scope.$on('applicationCommentsChange', (event, data) => {
            this.refreshComments();
        })
    }

    addComment() {  
        this.ApplicationComments.save({
            applicationId: this.applicationId,
            comment: this.newComment,
            author: this.Auth.user.firstName + ' ' + this.Auth.user.lastName
        })
            .$promise
            .then(() => {
                this.newComment = "";
                return this.refreshComments().$promise;
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while saving your comment. Please try again after a while.');
            });
    }

    refreshComments() {
        return this.ApplicationComments.query({
            applicationId: this.applicationId
        }, (result) => {
            this.comments = result;
        });
    }
}


angular
    .module('app')
    .directive('applicationComment', () => new ApplicationCommentDirective());