export class EnvironmentConfig {
    static $inject = ['$compileProvider'];
    constructor($compileProvider) {

        //check if devconsole is opened
        var devtools: any = function () { };
        devtools.toString = function () {
            this.opened = true;
        }
        console.log('%c', devtools);

        // disable debug info
        if (!devtools.opened) {
            $compileProvider.debugInfoEnabled(false);
        }
    }
}

angular
    .module('app')
    .config(EnvironmentConfig);


var environment = {
    isDevelopment: false,
    isProduction: true,
    appInsightsConnectionString: 'InstrumentationKey=b4a99977-ed14-4274-90d8-35eca3259559;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=4c8137a3-ea6c-4fd3-a239-8301892be720',
    hubSpotTrackingScript: '//js-eu1.hs-scripts.com/143809537.js?businessUnitId=16399423'
}

angular
    .module('app')
    .constant('environment', environment);