import { AgencyContributionsModalController } from './modals/AgencyContributionsModalController'


export class AgencyMarketingController {

    years: number[]
    currentYear: number
    selectedYear: number
    contributions: any[]

    static $inject = ['$rootScope', '$scope', '$uibModal', 'dialogs', 'agencyId', 'Auth', 'AgencyContributions'];
    constructor(private $rootScope, private $scope, private $uibModal, private dialogs, private agencyId, private Auth, private AgencyContributions) {

        this.years = [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
        this.selectedYear = this.currentYear = new Date().getFullYear();

        this.refresh();
    }

    refresh() {
        this.AgencyContributions
            .getPerYear({ year: this.selectedYear, agencyId: this.agencyId })
            .$promise
            .then(data => { this.contributions = data });
    }

    addContribution() {
        var data = {
            agencyId: this.agencyId,
            budgetYear: this.selectedYear
        };
        this.openModal(data);
    }

    editContribution(data) {
        this.openModal(angular.copy(data));
    }

    deleteContribution(data) {
        this.dialogs.confirm('Alert', `Are you sure that you want to delete this contribution?`)
            .result
            .then(() => {
                return this.AgencyContributions.remove(data).$promise;
            })
            .then(() => {
                this.refresh();
            });
    }


    openModal(contribution: any) {
        this.$uibModal.open({
            templateUrl: 'areas/agency/AgencyDetails/modals/agencyContributionModal.html',
            controller: AgencyContributionsModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                agencyContribution: () => contribution
            }
        }).result.then(() => {
            this.refresh();
        });
    }
}

angular
    .module('app')
    .controller('AgencyMarketingController', AgencyMarketingController)