class GridExport {

    constructor(private WorkQueue) {
    }

    saveAsCSV(gridSelector, filename, onlySelected = false) {

        var tmpColDelim = String.fromCharCode(11),
            tmpRowDelim = String.fromCharCode(0),
            colDelim = '","',
            rowDelim = '"\r\n"';

        var rowsSelector = onlySelected ? 'thead tr:first-child():has(th), tbody tr.table-row-selected:has(td)'
            : 'thead tr:first-child():has(th), tbody tr:has(td)';

        var $table = $(gridSelector);
        var $rows = $table.find(rowsSelector);
        var csv = '"' + $rows.map((i, row) => {
            var $row = $(row);
            var $cols = $row.find('th, td');

            return $cols.map((j, col) => $(col).text().trim().replace(/"/g, '""'))
                .get().join(tmpColDelim);

        })
            .get().join(tmpRowDelim)
            .split(tmpRowDelim).join(rowDelim)
            .split(tmpColDelim).join(colDelim) + '"';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        this.saveFile(blob, filename);
    }

    getAsCSV(params, filename) {

        this.WorkQueue.export(params, (response) => {
            this.saveFile(response.data, filename)
        });
    }

    saveFile(blob, filename) {
        var nav = navigator as any;
        if (nav.msSaveBlob) { // IE 10+
            nav.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}

class GridExportProvider implements ng.IServiceProvider {
    $get = ['WorkQueue', function (WorkQueue) {
        return new GridExport(WorkQueue);
    }]
}

angular
    .module('app')
    .provider('GridExport', GridExportProvider);

