import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService';
import _ from 'lodash-es';

interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
    agencyId: number;
    filters: {
        agencyId: number;
    };
}

const gridSettingsStorageKey = 'queue-grid-settings';

class AgencyContactsController {

    params: WorkQueueParams;

    agencyId: number;

    agencyTotalCount: number;
    selectedFilterWorkQueueId: number;
    selectedDefaultWorkQueue: boolean;
    isSelectedFilterWorkQueue: boolean;
    agencyPage: any;

    loading: boolean = false;

    gridSettings: any;
    agencyContacts: any;
    selectedContacts: any;
    showSuccessText: boolean = false
    isAgencyManagmentAllowed: boolean = false;
    isOfficeUser: boolean = false;

    primaryContact: any = null;
    emergencyContact: any = null;
    agencyMessagesContact: any = null;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;

    alerts: IAlertConfig[] = []
    selectedContactsForm: any;

    static $inject = ['$scope', 'Auth', 'dialogs', 'localStorageService', 'agencyId', 'AgencyContact', '$state', '$timeout', '$uibModal','GridExport'];
    constructor(private $scope, Auth, private dialogs, private localStorageService, agencyId, private AgencyContact, private $state, private $timeout, private $uibModal, private GridExport) {
        this.agencyId = agencyId;

        this.selectedGridItems = [];
        this.selections = [];

        this.isAgencyManagmentAllowed = Auth.isInBusinessArea(BusinessArea.AGENCY_MANAGEMENT);
        this.isOfficeUser = Auth.user.isOfficer;
            
        this.params = {
            page: 0,
            pageSize: 100,
            orderBy: 'contactId',
            descending: true,
            agencyId: agencyId,
            filters: { agencyId: agencyId }
        };
        this.gridSettings = this.localStorageService.get(gridSettingsStorageKey);

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                var url = this.$state.href('app.contactInformation.contactBasicDetails', { contactId: values[0].contactId });
                window.open(url, '_blank');
                this.selectedGridItems = [];
            }
        }, true);
    }

    export() {
        this.GridExport.saveAsCSV('.agencyContactsTable', 'export.csv', this.selections.length != 0)
    }

    refreshAllActiveContactList() {
        this.AgencyContact.getAllActiveContacts({ agencyId: this.agencyId }, (result) => {
            //to do add 3 bkack and white
            this.agencyContacts = result;

            this.AgencyContact.getAgencyMainContacts({ agencyId: this.agencyId }, (result) => {
                //to do add 3 bkack and white
                this.selectedContacts = result;

                if (this.selectedContacts.primaryContactId) {
                    this.primaryContact = this.agencyContacts.filter(contact => contact.contactId === this.selectedContacts.primaryContactId)[0];
                }

                if (this.selectedContacts.emergencyContactId) {
                    this.emergencyContact = this.agencyContacts.filter(contact => contact.contactId === this.selectedContacts.emergencyContactId)[0];
                }

                if (this.selectedContacts.agencyMessagesContactId) {
                    this.agencyMessagesContact = this.agencyContacts.filter(contact => contact.contactId === this.selectedContacts.agencyMessagesContactId)[0];
                }
            });
        });
    }

    getAgencyContacts(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'contactId';
            this.params.descending = true;
        }

        this.refresh();
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.agencyPage.result) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.agencyPage.result) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                contactId: item.contactId
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.agencyPage.result) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.contactId === item.contactId);
        }
    }

    refresh() {
        this.loading = true;
        new this.AgencyContact.getAll(this.params, (result) => {
            this.agencyTotalCount = result.totalCount;
            this.agencyPage = result;

            this.lastPage = Math.floor(this.agencyPage.totalCount / this.params.pageSize);

            this.selections = [];

            this.loading = false;

            this.refreshAllActiveContactList();

        }, () => {
            this.loading = false;
        });
    }  

    save() {
        if (this.selectedContactsForm.$dirty) {
            this.alerts = [];

            var form = this.selectedContactsForm;

            this.AgencyContact.saveSelectedContacts({ agencyId: this.agencyId }, this.selectedContacts)
                .$promise
                .then(() => {
                    form.$setPristine();
                    this.showSuccessText = true;
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured while saving profile details. Please try again after a while.');
                });
        }
    }

    addNewContact() {
        var data = <any>{
            agencyId: this.agencyId,
            firstName: '',
            lastName: '',
            jobTitle: 'I',
            roleType: 'INT',
            sex: '',
            email: '',
            minId: 2,
            maxId: 4
        };
        this.openModal(data);
    }

    openModal(data: any) {
        this.$uibModal.open({
            templateUrl: 'areas/agency/AgencyDetails/modals/addContactModal.html',
            controller: 'AgencyContactModalController as ctrl',
            backdrop: false,
            resolve: {
                agencyContact: () => data
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    changePageSize() {
        this.lastPage = Math.floor(this.agencyPage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }
}

angular
    .module('app')
    .controller('AgencyContactsController', AgencyContactsController)