
class ShareInterviewReportController {

    static $inject = ['interviewReport']
    constructor(public interviewReport) {
    }

}

angular
    .module('app')
    .controller('ShareInterviewReportController', ShareInterviewReportController)