 

var ResourceDirectiveFactory = ['$parse', 'ResourcesLoader', function ResourceDirectiveFactory($parse, ResourcesLoader): ng.IDirective {

    function link(scope, elem, attr, ctrl) {
        ResourcesLoader.load().then(function (data) {
            var value = $parse(attr.resource)(data);
            elem.text(value);
        });
    }

    return {
        restrict: 'AE',
        link: link,
    };
}]

angular
    .module('app')
    .directive('resource', ResourceDirectiveFactory);