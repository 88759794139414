

class RegisterOAuthController {
        
    registrant: any;
    oauthInfo: any;


    static $inject = ['$scope', '$state', '$stateParams', 'dialogs', 'Auth', 'RegistrationAgency'];
    constructor(private $scope, private $state, private $stateParams, private dialogs, private Auth, private RegistrationAgency) {
        var registrant = this.registrant = <any>{};

        if ($stateParams.agencyId) {
            var agency = RegistrationAgency.get({ agencyId: $stateParams.agencyId }, () => {
                registrant.agencyId = agency.id;
                registrant.residenceCountry = agency.countryCode;
                $scope.$broadcast('disableComponent', { name: 'residenceCountry' });
            });

        }


        this.oauthInfo = Auth.getOAuthInfo();
        var userInfo = this.oauthInfo.userInfo.userInformation;

        var nameParts = userInfo.name.split(' ');
        if (nameParts.length >= 1)
            registrant.firstName = nameParts[0];
        if (nameParts.length >= 2)
            registrant.lastName = nameParts[1];

        if (angular.isDefined(userInfo.gender)) {
            if (userInfo.gender == 1)
                registrant.gender = 'M';
            else if (userInfo.gender == 2)
                registrant.gender = '';
        }

        registrant.email = userInfo.email;
        registrant.emailConfirm = userInfo.email;
        registrant.oauthProvider = this.oauthInfo.userInfo.providerName;
        registrant.oauthUserId = userInfo.id;

        var countryParts = userInfo.locale.split(/_|-/);
        if (countryParts.length >= 2) {
            var country = countryParts[1].toUpperCase();
            registrant.nationalityCountry = country;
            if (!$stateParams.agencyId) {
                registrant.residenceCountry = country;
            }
        }
    }


       

    register() {
        this.$scope.$broadcast('submitted', true);

        if (this.registrant.$validation()) {
            this.Auth.oauthSignup(this.registrant, this.oauthInfo.accessToken)
                .then(() => {
                }, (error) => {
                    this.dialogs[error.type](error.title, error.message);
                });
        }
    }
}

angular
    .module('app')
    .controller('RegisterOAuthController', RegisterOAuthController);

