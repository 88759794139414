import { IAction } from '../IAction'

export class SubmitApplicationToOffice implements IAction {

    static $inject = ['$uibModal', 'applicationId']
    constructor(private $uibModal, private applicationId) { }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/submitApplicationToOffice/submitApplicationToOfficeModal.html',
            controller: SubmitApplicationToOfficeModalController,
            controllerAs: 'samCtrl',
            resolve: {
                applicationId: () => this.applicationId
            },
            backdrop: false
        }).result
    }
}

class SubmitApplicationToOfficeModalController {

    public alerts: Array<Object> = []
    public pendingRequest: boolean

    static $inject = ['dialogs', '$uibModalInstance', 'InterviewerReview', 'applicationId']
    constructor(private dialogs, private $uibModalInstance, private InterviewerReview, private applicationId) { }

    submit() {
        this.pendingRequest = true
        this.InterviewerReview.submitToOffice({
            applicationId: this.applicationId
        }, {
                //do nothing
            }, (data) => {
                this.$uibModalInstance.close();
            }, (data) => {
                this.pendingRequest = false
                this.alerts.push({ type: 'danger', status: data.status, messages: data.data })
            })
    }

    closeAlert = function (index) {
        this.alerts.splice(index, 1)
    }

    close() {
        this.$uibModalInstance.dismiss()
    }

}