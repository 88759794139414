
class SharedFlightContactDetailsController {
    sharedFlightContactDetails1: any[];
    sharedFlightContactDetails2: any[];
    sharedFlightContactDetails3: any[];
    isDataExists: boolean = null;
    showSuccessText: boolean = false;

    static $inject = ['$scope', 'applicationId', 'ApplicationPlacement', 'dialogs']
    constructor(private $scope, private applicationId, private ApplicationPlacement, private dialogs) {
        this.ApplicationPlacement.sharedFlightContactDetails({
            applicationId: this.applicationId
        }).$promise
            .then((data) => {
                this.isDataExists = data.length > 0;

            this.sharedFlightContactDetails1 = data.filter((x) => x.legNo == 1);
            this.sharedFlightContactDetails2 = data.filter((x) => x.legNo == 2);
            this.sharedFlightContactDetails3 = data.filter((x) => x.legNo == 3);
        });
    }
}

angular
    .module('app')
    .controller('SharedFlightContactDetailsController', SharedFlightContactDetailsController);