 

class ApplicantProfileDirective implements ng.IDirective {
        restrict = 'AE'
        controller = ApplicantProfileController
        controllerAs = 'acCtrl'
        bindToController = true
        templateUrl = 'controls/ApplicantProfile/applicationProfile.html'    
        scope = {
            applicationId: '='
        }               
}

class ApplicantProfileController {
    applicationId: number
    applicationProfile: any;

    static $inject = ['$scope', 'ApplicantProfile', 'Auth', 'dialogs'];
    constructor(private $scope, private ApplicantProfile, private Auth, private dialogs) {

        $scope.$on('changeApplicationStatus', (event, onHoldStatus) => {
            this.refresh();
        });
        $scope.$on('push:applicaionStatusChange', (event, onHoldStatus) => {
            this.refresh();
        });
    }

    $onInit() {
        this.refresh();
    }

    private refresh() {
        this.ApplicantProfile.query({
            applicationId: this.applicationId
        }, (result) => {
            this.applicationProfile = result;
        });
    }
}


angular
    .module('app')
    .directive('applicantProfile', () => new ApplicantProfileDirective());
