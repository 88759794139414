 

var IframeAutoResizeDirectiveFactory = [function IframeAutoResizeDirectiveFactory(): ng.IDirective {

    function link(scope, iframe, attrs) {
            
        //if ($.browser.safari || $.browser.opera) {

        //    iframe.load(function () {
        //        setTimeout(function () {
        //            iframe.style.height = iframe.contentWindow.document.body.offsetHeight + 'px';
        //        }, 0);
        //    });
                
        //    var iSource = iframe.src;
        //    iframe.src = '';
        //    iframe.src = iSource;

        //} else {
            iframe.load(function () {
                this.style.height = this.contentWindow.document.body.offsetHeight + 'px';
            });
        //}

    }
    return {
        restrict: 'A',
        link: link
    };
}]


angular
    .module('app')
    .directive('iframeAutoResize', IframeAutoResizeDirectiveFactory);