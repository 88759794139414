import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class ViewContractualAgreementController {

    agencyForGettingParent: any
    selectedContract: any;
    agencyId: number
    contactId: number
    userId: number
    contractualAgreementReportId: number = 16
        
    alerts: IAlertConfig[] = []
    scheduledOfferedAndNewContractsDates: any[];
    selectedContractId: any;
    agencyTerritoryCurrencyList: any[];

    static $inject = ['$scope', 'Auth', '$uibModal', '$q', 'dialogs', 'agencyId', 'contactId', '$timeout', 'AgencyContract', 'Report', '$sce', '$state', 'contractId', '$window'];
    constructor(private $scope, private Auth, private $uibModal, private $q, private dialogs, agencyId, contactId, private $timeout, private AgencyContract, private Report, private $sce, private $state, private contractId, private $window) {
        this.agencyId = agencyId;
        this.contactId = contactId;
        this.userId = Auth.user.userId;

        this.selectedContractId = "" + contractId;
        this.loadSelectedContract();

        this.AgencyContract.getScheduledOfferedAndNewContracts({ agencyId: this.agencyId }, (result) => {
            this.scheduledOfferedAndNewContractsDates = result;

            this.selectedContractId = "" + contractId;
            this.loadSelectedContract();
        });
    }

    downloadContractualAgreementReport() {
        this.Report.downloadUrl({ reportId: this.contractualAgreementReportId, agencyId: this.agencyId }, (data) => {
            window.open(this.$sce.trustAsResourceUrl(data.reportURL), '_blank');
        })
    }

    changeContract() {

        ////reload page
        //if (this.selectedContractId) {
        //    var url = this.$state.href('app.agencyInformation.contractualAgreement', { contractId: this.selectedContractId });
        //    this.$window.open(url, '_blank');
        //}
        this.$state.transitionTo('app.agencyInformation.contractualAgreement', { contractId: this.selectedContractId }, { reload: true, inherit: true, notify: true });
    }

    loadSelectedContract() {
        if (this.selectedContractId) {
            var data = {
                agencyId: this.agencyId,
                contactId: this.contactId,
                contractId: this.selectedContractId,
                userId: this.userId
            };

            this.AgencyContract.getContractById({ agencyId: this.agencyId }, data)
                .$promise
                .then((results) => {
                    this.selectedContract = results;
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured while Getting contract. Please try again after a while.')
                });
        } else {
            this.selectedContract = {};
        }
    }

    print() {
        this.$window.print();
    }
}

angular
    .module('app')
    .controller('ViewContractualAgreementController', ViewContractualAgreementController)