
export class ApplicantController {

    isProduction;

    static $inject = ['environment', 'ApplicationStatus', 'Auth']
    constructor(environment, ApplicationStatus, Auth) {

        this.isProduction = environment.isProduction;
    }
}