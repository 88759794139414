import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'


class FlightsFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'application'
        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'Flights',
                url: 'application/flights/:id',
                params: {
                    id: '@id'
                },
                actions: {
                    query: {
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('departDate', 'arrivalDate')
                        )
                    },
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('checkInTime', 'departDate', 'arrivalDate', 'flightLegs.departDateTime', 'flightLegs.arrivalDateTime')
                        )
                    }
                }
            },
            {
                name: 'FlightMessages',
                url: 'application/flightMessages/:id',
                params: { id: '@id' },
                actions: {
                    query: { method: 'GET', isArray: true }
                }
            }
        ];

        this.process(resources)
    }
}

new FlightsFactory();
