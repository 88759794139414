import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService';
import { GatewayModalController } from './GatewayModalController';
import _ from 'lodash-es';

interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}

export class GatewaysController {
    gateways;

    editFrom;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public gatewaysPage: { gateways: any[], totalCount:number};
    params: WorkQueueParams;
    loading: boolean = false;

    selections: any[];
    selectedGridItems: any[];

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;

    allSelected: boolean;

    static $inject = ['$scope', 'Auth', 'Spinner', '$q', '$state', 'dialogs', '$uibModal', 'GridExport', 'Gateway'];
    constructor(private $scope, private Auth, private Spinner, private $q, private $state, private dialogs, private $uibModal, private GridExport, private Gateway) {

        this.selections = [];
        this.selectedGridItems = [];

        this.params = {
            page: 0,
            pageSize: 50,
            orderBy: 'gatewayName',
            descending: false
        };

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.editGateway(values[0]);
                this.selectedGridItems = [];
            }
        }, true); 

    }

    getGateways(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'gatewayName';
            this.params.descending = false;
        }

        this.refresh();
    }


    refresh() {
        this.loading = true;

        new this.Gateway.query(this.params, (result) => {
            this.gatewaysPage = result;

            this.lastPage = Math.floor(this.gatewaysPage.totalCount / this.params.pageSize);

            this.selections = [];

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
        }, () => {
            this.loading = false;
        });
    }

    addGateway() {
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainFlights/gatewayModal.html',
            controller: GatewayModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                gatewayInfo: {}
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    editGateway(gatewayInfo) {

        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainFlights/gatewayModal.html',
            controller: GatewayModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                gatewayInfo: this.Gateway.get({ gatewayCode: gatewayInfo.gatewayCode }).$promise
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    //public export() {
    //    this.GridExport.saveAsCSV('.gatewayTable', 'export.csv', this.selections.length != 0)
    //}

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.gatewaysPage.gateways) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.gatewaysPage.gateways) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                gatewayCode: item.gatewayCode
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.gatewaysPage.gateways) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.gatewayCode === item.gatewayCode);
        }
    }

    changePageSize() {
        this.lastPage = Math.floor(this.gatewaysPage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }
}


angular
    .module('app')
    .controller('GatewaysController', GatewaysController);