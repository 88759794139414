import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class AltosHostFamilyController {
    altosHostFamily: any

    static $inject = ['$scope', 'applicantId', 'altosHostFamilyPage'];
    constructor(private $scope, private applicantId, altosHostFamilyPage) {
        this.altosHostFamily = altosHostFamilyPage;
    }
}

angular
    .module('app')
    .controller('AltosHostFamilyController', AltosHostFamilyController)