import { BusinessArea } from '../../../services/AuthService';

class ContactPaymentsController {


    financeArea: boolean;
    contactPayments:any[];
    years: any[];
    selectedYear: string;

    static $inject = ['$rootScope', '$scope', 'contactId', '$uibModal', 'Payments', 'Auth', 'dialogs', 'interviewer', 'paymentInfo'];
    constructor(private $rootScope, private $scope, private contactId, private $uibModal, private Payments, Auth, private dialogs, private interviewer, private paymentInfo) {

        this.financeArea = Auth.isInBusinessArea(BusinessArea.FINANCE);

        if (this.financeArea) {
            this.loadPayments(true);
        }
    }

    loadPayments(isSetYear) {
        this.Payments.contactPayments({ contactId: this.contactId }, { interviewerId: this.contactId, selectedYear: this.selectedYear })
            .$promise
            .then((res) => {
                this.contactPayments = res.payments;

                if (!this.years && isSetYear) {
                    this.selectedYear = res.selectedYear;
                    this.years = res.years;
                }
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while load payment for interviewer. Please try again after a while.')
            });
    }

    manualPayment() {
        if (!this.paymentInfo.creditorId) {
            return;
        }

        this.$uibModal.open({
            templateUrl: 'areas/payments/manualPaymentModal/manualPaymentModal.html',
            controller: 'ManualPaymentController',
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                paymentInfo: () => this.paymentInfo,
                data: () => <any>{
                    contactId: this.contactId,
                    contact: `${this.contactId} - ${this.interviewer.firstName} ${this.interviewer.lastName}`
                },
                title: () => ''
            }
        }).result
    }

    refresh() {
        this.loadPayments(true);
    }
}

angular
    .module('app')
    .controller('ContactPaymentsController', ContactPaymentsController)