import { IAlertConfig } from '../../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../../services/AuthService';
import { SystemAnnouncementsModalController } from './SystemAnnouncementsModalController';
import _ from 'lodash-es';



export class SystemAnnouncementsController {
    announcements;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;


    static $inject = ['$scope', 'Auth', '$q', '$state', 'SystemAnnouncements', 'dialogs', '$uibModal', 'Agency', 'GridExport'];
    constructor(private $scope, private Auth, private $q, private $state, private SystemAnnouncements, private dialogs, private $uibModal, private Agency, private GridExport) {

        this.refresh();

        this.selections = [];
        this.selectedGridItems = [];

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.selectAnnouncement(values[0]);
                this.selectedGridItems = [];
            }
        }, true); 
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.announcements) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.announcements) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                messageId: item.messageId
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.announcements) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.messageId === item.messageId);
        }
    }


    refresh() {
        new this.SystemAnnouncements.query().$promise.then((data) => {
            this.announcements = data;

            this.selections = [];
        });
    }
        
    selectAnnouncement(announcement) {

        this.$uibModal.open({
            templateUrl: 'areas/tools/communications/systemAnnouncements/systemAnnouncementsModal.html',
            controller: SystemAnnouncementsModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                announcement: angular.copy(announcement)
            }
        }).result.then(() => {
            this.refresh();
        });    
    }

    addMessage() {
        this.$uibModal.open({
            templateUrl: 'areas/tools/communications/systemAnnouncements/systemAnnouncementsModal.html',
            controller: SystemAnnouncementsModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                announcement: {  }
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    removeMessage() {
        if (this.selections && this.selections.length === 1 && this.selections[0].canDelete) {
            this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this announcement?')
                .result
                .then(() => {
                    this.SystemAnnouncements
                        .remove({ id: this.selections[0].id })
                        .$promise
                        .then(() => {
                            this.refresh();
                        }, () => {
                            this.dialogs.error('Error', 'We are sorry but message cannot be deleted. Please try again in 5 minutes. If the problem persists, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
                        });
                });
        }
    }

}


angular
    .module('app')
    .controller('SystemAnnouncementsController', SystemAnnouncementsController);