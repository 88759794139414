
export class AdminController {

    isProduction;

    static $inject = ['environment']
    constructor(environment) {
        this.isProduction = environment.isProduction;
            
    }
}