import { BusinessArea } from '../../../services/AuthService';
import _ from 'lodash-es';

class AgencyTarget {
    agencyTargetId: number;
    agencyId: number;
    targetType: number;
    countryCode: string;
    country: string;
    targetYear: number;
    targetTotal: number;
    auPair: number;
    educare: number;
    apex: number;
}

class AgencyTargetItem {
    agencyTargetId: number;
    agencyId: number;
    targetType: number;
    countryCode: string;
    country: string;
    targetYear: number;
    receivedTargetTotal: number;
    receivedAuPair: number;
    receivedEducare: number;
    receivedApex: number;
    departureTargetTotal: number;
    departureAuPair: number;
    departureEducare: number;
    departureApex: number;

    checked: boolean;
}

class AgencyTargetMapper {
    static map(target: AgencyTarget): AgencyTargetItem {
        var item = new AgencyTargetItem();
        item.agencyId = target.agencyId;
        item.agencyTargetId = target.agencyTargetId;
        item.targetType = target.targetType;
        item.targetYear = target.targetYear;
        item.countryCode = target.countryCode;
        item.country = target.country;

        switch (target.targetType) {
            case 1: {
                item.receivedTargetTotal = target.targetTotal;
                item.receivedAuPair = target.auPair;
                item.receivedEducare = target.educare;
                item.receivedApex = target.apex;
                break;
            }
            case 2: {
                item.departureTargetTotal = target.targetTotal;
                item.departureAuPair = target.auPair;
                item.departureEducare = target.educare;
                item.departureApex = target.apex;
                break;
            }
            default: {
                break;
            }
        }

        return item;
    }
}

class AgencyTargetsController {

    loading: boolean = false;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    countryCode: string;
    agencyId: number;
    isAgencyManagmentAllowed: boolean = false;

    currentYear: number;
    maxYear: number;

    allAgencyTargets: AgencyTarget[] = new Array<AgencyTarget>();
    currentPage: AgencyTargetItem[] = new Array<AgencyTargetItem>();
    allAgencyTargetItems: AgencyTargetItem[] = new Array<AgencyTargetItem>();
    fields: string[] = [
        'check',
        'country',
        'targetYear',
        'receivedTargetTotal',
        'receivedAuPair',
        'receivedEducare',
        'receivedApex',
        'departureTargetTotal',
        'departureAuPair',
        'departureEducare',
        'departureApex'
    ];

    static $inject = ['$scope', '$uibModal', 'AgencyTarget', 'agencyAddressPage', 'Auth'];
    constructor($scope, private $uibModal, private AgencyTarget, agencyAddressPage, Auth) {
        this.agencyId = agencyAddressPage.agencyId;
        this.countryCode = agencyAddressPage.countryCode;
        this.getAgencyTargets();
        this.isAgencyManagmentAllowed = Auth.isInBusinessArea(BusinessArea.AGENCY_MANAGEMENT);

        this.currentYear = new Date().getFullYear();
        this.maxYear = this.currentYear + 2;

        this.selections = [];
        this.selectedGridItems = [];

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.editItem(values[0]);
                this.selectedGridItems = [];
            }
        }, true);
    }

    getAgencyTargets() {
        this.loading = true;
        new this.AgencyTarget.getAll({ agencyId: this.agencyId }, (result: AgencyTarget[]) => {
            this.allAgencyTargets = result;
            var items = this.allAgencyTargets.map(AgencyTargetMapper.map);
            this.allAgencyTargetItems = items;

            this.selections = [];

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    addItem() {
        var data = <any>{
            agencyId: this.agencyId,
            targetType: 1,
            countryCode: this.countryCode,
            targetYear: new Date().getFullYear(),
            targetTotal: 0,
            auPair: 0,
            educare: 0,
            apex: 0
        };
        this.openModal(data, true);
    }

    editItem(gridItem: AgencyTargetItem) {
        var data = this.allAgencyTargets.filter(x => x.agencyTargetId == gridItem.agencyTargetId)[0];
        // TODO: condition need to be verified, temporary comment a part
        var editMode = this.isAgencyManagmentAllowed; //&& this.currentYear <= gridItem.targetYear && gridItem.targetYear <= this.maxYear;
        this.openModal(data, editMode);
    }

    openModal(data: any, editMode: boolean) {
        this.$uibModal.open({
            templateUrl: 'areas/agency/AgencyDetails/modals/addTargetModal.html',
            controller: 'AgencyTargetModalController as ctrl',
            backdrop: false,
            resolve: {
                agencyTarget: () => data,
                editMode: () => editMode,
            }
        }).result.then(() => {
            this.getAgencyTargets();
        });
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.allAgencyTargetItems) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.allAgencyTargetItems) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                agencyTargetId: item.agencyTargetId
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.allAgencyTargetItems) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.agencyTargetId === item.agencyTargetId);
        }
    }
}

angular
    .module('app')
    .controller('AgencyTargetsController', AgencyTargetsController)