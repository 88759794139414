
class AgencyCollectionsController {

    static $inject = ['$rootScope', '$scope', 'Auth'];
    constructor(private $rootScope, private $scope, private Auth) {
            
    }
}

angular
    .module('app')
    .controller('AgencyCollectionsController', AgencyCollectionsController)