
class ChildcareExperienceController {
        
    static $inject = ['$uibModalInstance', 'ChildcareExperience', 'title', 'childcareExperience', '$scope'];
    constructor(private $uibModalInstance, private ChildcareExperience, public title: string, public childcareExperience, private $scope) {

    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var isValid = this.childcareExperience.$validation();

        if (isValid) {
            this.childcareExperience.frequency = this.frequencyDescription(this.childcareExperience.careFrequency);

            this.ChildcareExperience
                .save(this.childcareExperience)
                .$promise
                .then((data) => {
                    this.$uibModalInstance.close(data);
                });
        }
    }

    frequencyDescription(careFrequency) {
        switch (careFrequency) {
            case 'D':
                return 'Daily';
            case 'M':
                return 'Monthly';
            case 'W':
                return 'Weekly';
            case 'K':
                return 'Weekends';
            case 'O':
                return 'Overnight';
            case 'S':
                return 'School holidays';
            case 'C':
                return 'Occasionally'
            default:
                return '';
        }
    }
}

angular
    .module('app')
    .controller('ChildcareExperienceController', ChildcareExperienceController);