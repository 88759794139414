
export class PlacementDetailsController {

    matchAgreementResponse: string        
    matchAgreementAgreed: boolean
    matchAgreementReject: boolean
    placement: any;
    isOnlineOrAwaitingOrToBeMatch: boolean;
    isREM: boolean;
    isPlacedOrActive: boolean;
    isPlcOrTbm: boolean;
    hostFamilyId: any;
    matches: any;
    pndMatch: boolean;

    appStatus: string;

    static $inject = ['$scope', '$location', '$window', 'applicationId', 'applicationObservable', 'PlacementDetails', 'PlacementMatchesDetails', 'PlacementLetter'];
    constructor($scope: angular.IScope, private $location, private $window, private applicationId, applicationObservable, PlacementDetails, PlacementMatchesDetails, private PlacementLetter) {

        applicationObservable.subscribe(applicationInstance => {
            this.isREM = (applicationInstance.status === 'REM');
            this.isOnlineOrAwaitingOrToBeMatch = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA' || applicationInstance.status === 'TBM');
            this.isPlacedOrActive = (applicationInstance.status === 'PLC' || applicationInstance.status === 'ACT')
            this.isPlcOrTbm = ((applicationInstance.status != 'PLC') || (applicationInstance.status != 'TBM'));

            this.appStatus = applicationInstance.status;

            if (this.isOnlineOrAwaitingOrToBeMatch) {

                PlacementMatchesDetails.getMatches({ applicationId: applicationId, status: 'PND' }).$promise.then((matches) => {
                    this.pndMatch = matches && matches.length > 0;
                });

            } else if (this.isPlacedOrActive || this.isREM) {
                PlacementDetails.get({ applicationId: applicationId }).$promise.then((results) => {
                    this.placement = results;

                    this.hostFamilyId = results.hostFamilyId;

                    PlacementMatchesDetails.getMatches({ applicationId: applicationId, status: 'CUR'}).$promise.then((matches) => {
                        this.matches = matches;
                    });

                    // Get response from US about applicant MatchAgreement
                    //this.matchAgreementResponse = $location.url().split('=')[1]; // This works too
                    this.matchAgreementResponse = $location.search().AgreementSigned;
                    if (!angular.isUndefined(this.matchAgreementResponse)) {

                        this.matchAgreementResponse = this.matchAgreementResponse.toLowerCase();
                        // Its not undefined, lets check what did applicant answer           
                        if (this.matchAgreementResponse == "yes") {
                            this.matchAgreementAgreed = true;
                        }

                        if (this.matchAgreementResponse == "no") {
                            this.matchAgreementReject = true;
                        }
                    }
                });


            }
        })
            
            
    }

    link() {
        let plc = this.placement;
        let link;
        if (plc.address1.indexOf('box ') < 0) {
            link = (plc.address1 && `${plc.address1},` || '') +
                (plc.address2 && `${plc.address2},` || '') +
                (plc.city && `${plc.city},` || '') + 
                (plc.postCode || '');

        } else {
            link = plc.postCode || '';
        }
        return link.replace(' ', '+');
    }

    placementLetter() {
        this.PlacementLetter.signedURL({
            applicationIds: [this.applicationId]
        }, (result) => {
            this.$window.open(result.url, '_blank');
        })
    } 
}

angular
    .module('app')
    .controller('PlacementDetailsController', PlacementDetailsController);


