import { IAction } from '../IAction'
import { ActionType, ActionTypeItems } from '../../../models/userActions/ActionType'

export class CreateInvoice implements IAction {

    static $inject = ['$rootScope', 'dialogs', 'Invoice', 'applicationId']
    constructor(private $rootScope, private dialogs, private Invoice, private applicationId) {

    }

    public execute = () => {
        var title = ActionTypeItems[ActionType.CREATE_INVOICE].name;
        return this.dialogs.confirm(title, `Please confirm your action.`)
            .result
            .then(() => this.Invoice.create({ applicationId: this.applicationId }).$promise)
            .then(() => {
                this.$rootScope.$broadcast('invoice:changed');
            });


        //return this.$uibModal.open({
        //    templateUrl: 'controls/actions/confirmModal.html',
        //    controller: controls.actions.ActionController,
        //    controllerAs: 'ctrl',
        //    backdrop: false,
        //    resolve: {
        //        Service: () => this.Invoice,
        //        serviceMethod: () => 'cancel',
        //        data: () => data,
        //        title: () => models.userActions.ActionTypeItems[models.userActions.ActionType.CANCEL_INVOICE].name
        //    }
        //}).result
    }
}