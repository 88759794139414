 
import { DocumentUploadConfig, MultiDocumentUploader } from "../documentsUpload/DocumentUploadConfig";



class DocumentDisplayController {

    document;
    isApplicant: boolean;
    isOfficeUser: boolean;
    otherDocumentUploadsConf: DocumentUploadConfig<MultiDocumentUploader>;
    showOtherDocumentUpload: boolean = false;
    applicationStatus: string;

    static $inject = ['$scope', '$rootScope', 'ApplicationDocument', 'dialogs', 'Auth', 'ApplicationUploader'];
    constructor(scope, private $rootScope, private ApplicationDocument, private dialogs, private Auth, ApplicationUploader) {
        this.isApplicant = Auth.user.isApplicant;
        this.isOfficeUser = Auth.user.isOfficer;

        if (scope.$parent && scope.$parent.ctrl && scope.$parent.ctrl.applicationId) {
            this.showOtherDocumentUpload = true;

            let uploader = new ApplicationUploader(scope.$parent.ctrl.applicationId, 20);    //20 is Other + Other certificate (Type of document 16, 31)

            this.otherDocumentUploadsConf = {
                uploader: uploader
            }
        }
    }

    approve() {
        this.document.status = 1 // approved
        this.ApplicationDocument.approve({ documentId: this.document.id }, { status: this.document.status })
            .$promise
            .then(() => {
                this.uploadDataStateChanged();
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while approving an image. Please try again after a while.');
            });
    }

    unapprove() {
        this.document.status = 2 // awaiting approval
        this.ApplicationDocument.unapprove({ documentId: this.document.id }, { status: this.document.status })
            .$promise
            .then(() => {
                this.uploadDataStateChanged();
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while unapproving an image. Please try again after a while.');
            });
    }

    uploadDataStateChanged() {
        this.$rootScope.$broadcast('uploadDataStateChanged', true);
    }

}


var DocumentDisplayDirectiveFactory = [function DocumentDisplayDirectiveFactory(): ng.IDirective {
    return {
        restrict: 'AE',
        scope: {
            document: '=documentDisplay',
            applicationStatus: "="
        },
        controller: DocumentDisplayController,
        controllerAs: 'mcCtrl',
        bindToController: true,
        templateUrl: 'controls/documentDisplay/documentDisplay.html'
    };
}]
    


angular
    .module('app')
    .directive('documentDisplay', DocumentDisplayDirectiveFactory);
