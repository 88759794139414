 

class ApplicationInstance {
        
    public instance;
    public subscribers = [];

    eventListeners: any[];

    constructor(private applicationId: number, private ApplicationData, private $rootScope) {
        this.get();

        this.eventListeners = [
            $rootScope.$on('changeInterviewerAndAgency', (event, data) => {
                this.refresh();
            }),

            $rootScope.$on('reviewService:pageNotesChange', (event, page) => {
                this.refresh();
            }),

            $rootScope.$on('push:applicaionStatusChange', (event, data) => {
                this.refresh();
            }),

            $rootScope.$on('changedOnHoldStatus', (event, onHoldStatus) => {
                this.refresh();
            }),

            $rootScope.$on('changeApplicationStatus', (event, onHoldStatus) => {
                this.refresh();
            }),

            $rootScope.$on('auth:user-change', (event, user) => {
                if (user && user.roleDetails && user.roleDetails.applicationId == this.applicationId) {
                    this.refresh();
                }
            })
        ];

    }

    private get() {
        this.instance = this.ApplicationData.get({ applicationId: this.applicationId });
    }

    public refresh() {
        this.get();
        return this.instance.$promise.then(i => {
            for (let subscriber of this.subscribers) {
                // add try cache 
                subscriber(i);
            }
            //this.$rootScope.$broadcast(`application-update-${this.applicationId}`, this.instance);

            return i;
        });
    }

    public toPromise() {
        return this.instance.$promise;
    }

    public subscribe(subscriber) {
        this.subscribers.push(subscriber);
        this.instance.$promise.then(i => {
            subscriber(i);
        }) 
    }

    public unsubscribe() {
        this.subscribers = [];
        for (let unregisterFn of this.eventListeners) {
            unregisterFn();
        }
    }
}


class ApplicationInstanceProvider implements ng.IServiceProvider {

    private instance: any;

    static $inject = [];
    constructor() {
    }
        
    $get = ['ApplicationData', '$rootScope', function (ApplicationData, $rootScope) {
            
        return (applicationId) => {
            if (this.instance) {
                if (this.instance.applicationId === applicationId) {
                    return this.instance;
                }

                this.instance.unsubscribe();
            }

            this.instance = new ApplicationInstance(applicationId, ApplicationData, $rootScope);
            return this.instance;
        };

    }];
}

angular
    .module('app')
    .provider('ApplicationInstance', ApplicationInstanceProvider);
