import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

function agencyTitle(subsection) {
    return ['agency', function (agency) {
        return `${subsection} - ${agency.agencyId} - ${agency.agencyName}`;
    }];
}


class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        var managementMenu: state<AccessLevelView> = {
            name: 'management',
            url: '/management',
            abstract: true,
            resolve: {
                agencyId: ['Auth', function (Auth) {
                    return Auth.user.roleDetails.agencyId;
                }],
                agency: ['Agency', 'agencyId', function (Agency, agencyId) {
                    return Agency.get({ agencyId: agencyId }).$promise;
                }],
                $title: () => 'Management'
            },
            views: {
                content: {
                    agency: {
                        controller: 'ManagementController as ctrl',
                        template: 'areas/agency/managementInformations.html'
                    }
                }
            },
            states: [
                {
                    name: 'basicDetails',
                    url: '',
                    views: {
                        '': {
                            agency: {
                                controller: 'BasicDetailsController as ctrl',
                                template: 'areas/agency/AgencyDetails/basicDetails.html'
                            }
                        }
                    },
                    resolve: {
                        agencyBasicDetailsPage: ['AgencyBasicDetailsPage', 'agencyId', function (AgencyBasicDetailsPage, agencyId) {
                            return AgencyBasicDetailsPage.get({ agencyId: agencyId }).$promise;
                        }],
                        $title: agencyTitle('Basic details')
                    }
                },
                {
                    name: 'agencyAddress',
                    url: '/address',
                    views: {
                        '': {
                            agency: {
                                controller: 'AgencyAddressController as ctrl',
                                template: 'areas/agency/AgencyDetails/agencyAddress.html'
                            }
                        }
                    },
                    resolve: {
                        agencyAddressPage: ['AgencyAddressPage', 'agencyId', function (AgencyAddressPage, agencyId) {
                            return AgencyAddressPage.get({ agencyId: agencyId }).$promise;
                        }],
                        agencyBasicDetailsPage: ['AgencyBasicDetailsPage', 'agencyId', function (AgencyBasicDetailsPage, agencyId) {
                            return AgencyBasicDetailsPage.get({ agencyId: agencyId }).$promise;
                        }],
                        $title: agencyTitle('Address')
                    }
                },
                {
                    name: 'agencyBranches',
                    url: '/branches',
                    views: {
                        '': {
                            agency: {
                                controller: 'AgencyBranchesController as $ctrl',
                                template: 'areas/agency/AgencyDetails/agencyBranches.html'
                            }
                        }
                    },
                    resolve: {
                        $title: agencyTitle('Branches'),
                        branches: ['Branches', 'agencyId', function (Branches, agencyId) {
                            return Branches.queryForAgency({ agencyId: agencyId }).$promise;
                        }]
                    }
                },
                {
                    name: 'agencyContacts',
                    url: '/contacts',
                    views: {
                        '': {
                            agency: {
                                controller: 'AgencyContactsController as $ctrl',
                                template: 'areas/agency/AgencyDetails/agencyContacts.html'
                            }
                        }
                    },
                    resolve: {
                        agencyBasicDetailsPage: ['AgencyBasicDetailsPage', 'agencyId', function (AgencyBasicDetailsPage, agencyId) {
                            return AgencyBasicDetailsPage.get({ agencyId: agencyId }).$promise;
                        }],
                        $title: agencyTitle('Contracts')
                    }
                },
                {
                    name: 'agencyContracts',
                    url: '/contracts',
                    views: {
                        '': {
                            agency: {
                                template: 'templates/empty.html'
                            }
                        }
                    },
                    abstract: true,
                    resolve: {
                        $title: () => 'Contracts'
                    },
                    states: [
                        {
                            name: 'contractualAgreement',
                            url: '/contractualAgreement',
                            views: {
                                '': {
                                    agency: {
                                        controller: 'ContractualAgreementController as ctrl',
                                        template: 'areas/agency/agencyContract/contractualAgreement.html'
                                    }
                                }
                            },
                            resolve: {
                                agencyId: ['Auth', function (Auth) {
                                    return Auth.user.roleDetails.agencyId;
                                }],
                                contactId: ['Auth', function (Auth) {
                                    return Auth.user.roleDetails.contactId;
                                }],
                                $title: () => 'Contract'
                            }
                        },
                        {
                            name: 'commissionStatement',
                            url: '/commissionStatement',
                            views: {
                                '': {
                                    agency: {
                                        controller: 'CommissionStatementController as ctrl',
                                        template: 'areas/agency/agencyContract/commissionStatement.html'
                                    }
                                }
                            },
                            resolve: {
                                agencyId: ['Auth', function (Auth) {
                                    return Auth.user.roleDetails.agencyId;
                                }],
                                commissionStatementPaymentDates: ['CommissionStatementPaymentDates', 'agencyId', function (CommissionStatementPaymentDates, agencyId) {
                                    return CommissionStatementPaymentDates.query({ agencyId: agencyId }).$promise;
                                }],
                                $title: () => 'Commission Statement'
                            }
                        },
                        {
                            name: 'localFeeAgreement',
                            url: '/localFeeAgreement',
                            views: {
                                '': {
                                    agency: {
                                        controller: 'LocalFeeAgreementController as ctrl',
                                        template: 'areas/agency/agencyContract/localFeeAgreement.html'
                                    }
                                }
                            },
                            resolve: {
                                agencyId: ['Auth', function (Auth) {
                                    return Auth.user.roleDetails.agencyId;
                                }],
                                $title: () => 'Local Fees & Agreement'
                            }
                        },
                        {
                            name: 'termsOfBusiness',
                            url: '/contract/:contractId/termsOfBusiness',
                            resolve: {
                                agencyId: ['Auth', function (Auth) {
                                    return Auth.user.roleDetails.agencyId;
                                }],
                                contractId: ['$stateParams', function ($stateParams) {
                                    return $stateParams.contractId;
                                }],
                                contactId: ['Auth', function (Auth) {
                                    return Auth.user.roleDetails.contactId;
                                }],
                                $title: () => 'Terms Of Business'
                            },
                            params: {
                                contractId: '@contractId'
                            },
                            views: {
                                '': {
                                    agency: {
                                        controller: 'TermsOfBusinessController as ctrl',
                                        template: 'areas/agency/agencyContract/termsOfBusiness.html'
                                    }
                                }
                            }
                        }
                    ]
                },
                {
                    name: 'agencyCompetitors',
                    url: '/competitors/:competitorId',
                    views: {
                        '': {
                            agency: {
                                controller: 'AgencyCompetitorsController as $ctrl',
                                template: 'areas/agency/AgencyDetails/agencyCompetitors.html'
                            }
                        }
                    },
                    resolve: {
                        $title: agencyTitle('Competitors'),
                        agencyId: ['Auth', function (Auth) {
                            return Auth.user.roleDetails.agencyId;
                        }],
                        competitorId: ['$stateParams', function ($stateParams) {
                            return $stateParams.competitorId;
                        }],
                        competitors: ['Competitors', 'agencyId', function (Competitors, agencyId) {
                            return Competitors.queryForAgency({ agencyId: agencyId }).$promise;
                        }]
                    }
                }
            ]
        };

        RoutingProvider.addState(managementMenu, 'app');
    }
}

angular
    .module('app')
    .config(RoutingConfig);