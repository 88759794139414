angular
    .module("aifs.metaform")
    .directive("aifsWidget", ['$parse', 'MetaForm', function ($parse, MetaForm) {
        function AifsWidgetLink (scope, element, attrs, ctrls, transclude) {
            var newScope = scope.$new(true);

            newScope.prevent = function($event){
                $event.preventDefault();
                $event.stopPropagation();
            };

            scope.$watch(attrs.aifsWidget, function (newValue, oldValue) {
                newScope.model = newValue;
            });
            newScope.model = $parse(attrs.aifsWidget)(scope);

            scope.$watch(attrs.aifsWidgetTranslation, function (newValue, oldValue) {
                newScope.translation = newValue;
            });
            newScope.translation = $parse(attrs.aifsWidgetTranslation)(scope);

            scope.$watch(attrs.aifsWidgetSnapshot, function (newValue, oldValue) {
                newScope.snapshot = newValue;
            });
            newScope.snapshot = $parse(attrs.aifsWidgetSnapshot)(scope);

            newScope.MetaForm = MetaForm;

            transclude(newScope, function (clone, scope) {
                element.append(clone);
            });
        }

        return {
            transclude: true,
            link: AifsWidgetLink,
            restrict: 'A'
        };
    }]);
