 

export interface ISpinner {
    show();
    hide();
}

class Spinner {
    private spinners: ISpinner[] = [];

    register(spinner: ISpinner) {
        this.spinners.push(spinner);
    }

    show() {
        for (let spinner of this.spinners) {
            spinner.show();
        }
    }

    hide() {
        for (let spinner of this.spinners) {
            spinner.hide();
        }
    }
}


class SpinnerProvider implements ng.IServiceProvider {


    private spinner: Spinner;

    static $inject = ['$injector'];
    constructor(private $injector) {
    }

        
    $get = [function () {

        if (!this.spinner)
            this.spinner = new Spinner();

        return this.spinner;

    }];
}

angular
    .module('app')
    .provider('Spinner', SpinnerProvider);
