
class ForgotPasswordController {
        
    user: any = {};
    sended: boolean;

    static $inject = ['$scope', '$state', 'dialogs', 'Auth'];
    constructor(private $scope, private $state, private dialogs, private Auth) {
    }


    reset() {
        this.$scope.$broadcast('submitted', true);

        if (this.$scope.forgotForm.$valid) {
            this.Auth.forgotPassword(this.user)
                .then((data) => {
                    this.sended = true;
                }, (error) => {
                    this.dialogs.error('Error', error);
                });
        }
    }
}

angular
    .module('app')
    .controller('ForgotPasswordController', ForgotPasswordController);