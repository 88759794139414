function countWords(text){
    if (angular.isString(text)) {
        var result = text
            .replace(/[,.?!"`()\[\]{}:;]/gi, ' ')   // remove punctuation
            .trim()
            .split(/\s+/);

        return (result.length === 1 && result[0] === '') ? 0 : result.length;
    }

    return 0;
}

function WordCountValidatorLink(scope, elem, attrs, ctrls) {
    var value = parseInt(attrs['wordCount']);
    if (value > 0) {
        ctrls[0].$validators.wordCount = function (modelValue, viewValue) {
            return countWords(viewValue) >= value;
        };
    }
}

function WordCountMaxValidatorLink(scope, elem, attrs, ctrls) {
    var value = parseInt(attrs['wordCountMax']);
    if (value > 0) {
        ctrls[0].$validators.wordCountMax = function (modelValue, viewValue) {
            return countWords(viewValue) <= value;
        };
    }
}

angular
    .module('aifs.metaform')
    .directive('wordCount', function () {
        return {
            require: ['ngModel'],
            restrict: 'A',
            link: WordCountValidatorLink
        };
    });

angular
    .module('aifs.metaform')
    .directive('wordCountMax', function () {
        return {
            require: ['ngModel'],
            restrict: 'A',
            link: WordCountMaxValidatorLink
        };
    });
