import _ from 'lodash-es'
export class StaffHomeController {

    isJunoUser: boolean;
    isAgent: boolean;
    systemAnnouncements: any[];

    static $inject = ['Auth', 'SystemAnnouncements'];
    constructor(Auth,  private SystemAnnouncements) {

        if (!Auth.user.isOfficer) {
            this.refreshAnnouncements();
        }

        this.isAgent = Auth.user.isAgent;
        this.isJunoUser = (Auth.user.isOfficer) ? true : false;
    }

    markAsRead(announcement) {
        this.SystemAnnouncements.markAsRead({}, { id: announcement.id }, () => {
            this.refreshAnnouncements();
        })
    }

    refreshAnnouncements() {
        this.SystemAnnouncements.current((announcements) => {
            this.systemAnnouncements = announcements.filter(a => !a.readByUser);
        })
    }
}

angular
    .module('app')
    .controller('StaffHomeController', StaffHomeController);