import _ from 'lodash-es'
import { IAction } from '../IAction'
import { ActionType, ActionTypeItems } from '../../../models/userActions/ActionType'
import { ITransaction } from '../../../models/finance/ITransaction'

export class RefundInvoice implements IAction{

    static $inject = ['$uibModal', 'applicationId', 'invoiceId', 'invoice']
    constructor(private $uibModal, private applicationId, private invoiceId, private invoice) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/refundInvoice/refundInvoiceModal.html',
            controller: RefundInvoiceActionController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                data: () => <any>{ applicationId: this.applicationId, invoiceId: this.invoiceId },
                title: () => ActionTypeItems[ActionType.REFUND_INVOICE].name,
                transactions: () => this.invoice.transactions.filter(t => t.type == 'PAY')
            }
        }).result
    }
}

class RefundInvoiceActionController {

    public selectedTransaction: ITransaction
    public submitted: boolean;

    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'Invoice', 'data', 'title', 'transactions', 'Finance']
    constructor(private $scope, private dialogs, private $uibModalInstance, private Invoice, private data, private title: string, public transactions, private Finance) {
        angular.extend(this.data, { changeCurrency: false })

        let recalculateRefundAmount = () => {
            if (this.data.changeCurrency) {
                this.Finance.calculateRefundAmount({ amount: this.data.originalAmount, originalCurrencyCode: this.data.transactionCurrencyCode, refundCurrencyCode: this.data.refundCurrencyCode }).$promise.then((response) => {
                    this.data.refundAmount = response.refundAmount
                    this.data.comment = `${response.originalAmount} ${response.originalCurrencyCode} = ${response.refundAmount} ${response.refundCurrencyCode}
Rate: 1.00 ${response.originalCurrencyCode} = ${response.conversionRate} ${response.refundCurrencyCode}`
                })
            }
        }

        $scope.$watch(() => this.data.originalAmount, recalculateRefundAmount)

        $scope.$watch(() => this.selectedTransaction, () => {
            if (this.selectedTransaction) {
                this.data.paymentTransactionId = this.selectedTransaction.id

                if (this.data.changeCurrency) {
                    this.data.originalAmount = this.selectedTransaction.transactionAmount
                    this.data.transactionCurrencyCode = this.selectedTransaction.transactionCurrencyCode
                }
                else {
                    this.data.refundAmount = this.selectedTransaction.transactionAmount
                    this.data.refundCurrencyCode = this.selectedTransaction.transactionCurrencyCode
                }
            }
        })

        $scope.$watch(() => this.data.changeCurrency, () => {
            if (this.selectedTransaction) {
                if (this.data.changeCurrency) {
                    this.data.originalAmount = this.selectedTransaction.transactionAmount
                    this.data.transactionCurrencyCode = this.selectedTransaction.transactionCurrencyCode
                    this.data.refundCurrencyCode = this.data.accountCurrencyCode
                    recalculateRefundAmount()
                }
                else {
                    this.data.refundAmount = this.selectedTransaction.transactionAmount
                    this.data.refundCurrencyCode = this.selectedTransaction.transactionCurrencyCode
                    this.data.comment = null
                }
            }
        })

        $scope.$watch(() => this.data.accountCurrencyCode, () => {
            if (this.data.changeCurrency) {
                this.data.refundCurrencyCode = this.data.accountCurrencyCode
                recalculateRefundAmount()
            }
        })
    }

    public doAction() {
        this.$scope.$broadcast('submitted', true);
        this.submitted = true;
            
        var isValid = this.data.$validation() && this.selectedTransaction;
        if (isValid) {
            this.Invoice.refund(this.data).$promise
                .then((results) => {
                    if (_.some(results, (r: any) => r.success == false)) {
                        this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
                    }
                })
                .finally(() => {
                    this.$uibModalInstance.close()
                })
        }
    }

    close() {
        this.$uibModalInstance.dismiss()
    }
}
        
