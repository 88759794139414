
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
    
export class MenuConfig {
    static $inject = ['MenuProvider'];
    constructor(MenuProvider) {

        MenuProvider.AddMenu('applicant', 'settings', {
            items: [
                {
                    name: 'Communication preferences',
                    state: 'app.settings.marketing'
                },
                {
                    name: 'Change Password',
                    state: 'app.settings.password'
                }
            ]
        });

        MenuProvider.AddMenu('preInterview', 'settings', {
            items: [
                {
                    name: 'Communication preferences',
                    state: 'app.settings.marketing'
                },
                {
                    name: 'Change Password',
                    state: 'app.settings.password'
                }
            ]
        });

        MenuProvider.AddMenu('preloggedin', 'settings', {
            items: [
                {
                    name: 'Communication preferences',
                    state: 'app.settings.marketing'
                },
                {
                    name: 'Change Password',
                    state: 'app.settings.password'
                }
            ]
        });

        MenuProvider.AddMenu('staff', 'settings', {
            items: [
                {
                    name: 'Application settings',
                    state: 'app.settings.application'
                },
                {
                    name: 'Country settings',
                    state: 'app.settings.country'
                },
                {
                    name: 'Change Password',
                    state: 'app.settings.password'
                }
            ]
        });


        MenuProvider.AddMenu('staff', 'applicationSettings', {
            items: [
                {
                    name: 'Communication preferences',
                    state: 'app.applications.settings.marketing'
                }
            ]
        });
    }
}

angular
    .module('app')
    .config(MenuConfig);