import _ from 'lodash-es'
import { IAction } from '../IAction'

export class AssignToInterviwer implements IAction {

    static $inject = ['$uibModal', '$rootScope', 'Interviewer', 'applicationIds', 'agencyId', 'interviewerId']
    constructor(private $uibModal, private $rootScope, private Interviewer, private applicationIds, private agencyId, private interviewerId) {

    }

    execute = () => {

        return this.$uibModal.open({
            templateUrl: 'areas/action/assignToInterviewer/assignToInterviewerModal.html',
            controller: AssignToInterviwerModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationIds: () => this.applicationIds,
                interviewerId: () => this.interviewerId,
                rootScope: () => this.$rootScope,
                interviewers: () => this.Interviewer.agencyActive({
                    agencyId: this.agencyId
                }).$promise
            }
        }).result
    }

}

class AssignToInterviwerModalController {

    public selectedInterviewer: any
    public currentInterviewer: any

    static $inject = ['dialogs', '$sce', '$uibModalInstance', 'Interviewer', 'applicationIds', 'interviewers', 'interviewerId', 'rootScope']
    constructor(private dialogs, private $sce, private $uibModalInstance, private Interviewer, private applicationIds, public interviewers, private interviewerId, private rootScope) {
        if (interviewerId && interviewerId > 0) {
            var filterInterview = _.filter(interviewers, { id: interviewerId })
            if (filterInterview && filterInterview.length > 0) {
                this.currentInterviewer = filterInterview[0]
                this.selectedInterviewer = this.currentInterviewer
            }
        }
    }

    fullNameWithId = (inter) => `${inter.id} - ${inter.firstName} ${inter.lastName}`

    fullName = (inter) => `${inter.firstName} ${inter.lastName}`

    assign() {
        if (!this.applicationIds) {
            return this.displayError('No applicationIds provided');
        }

        this.Interviewer
            .assign({ id: this.selectedInterviewer.id }, { application: this.applicationIds })
            .$promise
            .then((results) => {
                if (_.some(results, (r: any) => r.success == false)) {
                    return this.displayError(results)
                }
                this.rootScope.$broadcast('changeInterviewerAndAgency', {
                    interviewerId: this.selectedInterviewer.id,
                    interviewerFirstName: this.selectedInterviewer.firstName,
                    interviewerLastName: this.selectedInterviewer.lastName
                })
                return results;
            }).finally(() => {
                this.$uibModalInstance.close()
            })
    }

    displayError(results) {

        var message = 'The following applicants could not be assigned, please try again shortly:</br>'
        if (typeof results === 'object' && results != null) {
            message += results
                .filter((r) => !r.success)
                .map((r) => `Application ${r.applicationId} - ${r.error}</br>`);
        } else if (_.isString(results)) {
            message += results;
        }

        return this.dialogs
            .error('Issue occur during assign', this.$sce.trustAsHtml(message))
            .result

    }

    close() {
        this.$uibModalInstance.dismiss()
    }

}

