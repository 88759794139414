import _ from 'lodash-es';

export class ContactsController {

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    static $inject = ['$scope', '$state', 'AgencyContact', 'branch', 'agencyContacts'];
    constructor($scope, private $state, private AgencyContact, private branch, private agencyContacts) {

        this.selections = [];
        this.selectedGridItems = [];

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {

                var url = this.$state.href('app.contactInformation.contactBasicDetails', { contactId: values[0].id });
                if (url) {
                    window.open(url, '_blank');
                }
                this.selectedGridItems = [];
            }
        }, true);
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.agencyContacts) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.agencyContacts) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                id: item.id
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.agencyContacts) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }

    refresh() {
        this.AgencyContact.queryForBranch({ branchId: this.branch.id })
            .$promise
            .then((data) => {
                this.agencyContacts = data;

                this.selections = [];
            })
    }
}    

