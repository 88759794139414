
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a

export class MenuConfig {
static $inject = ['MenuProvider'];
constructor(MenuProvider) {

    MenuProvider.AddMenu('everyone', 'extension', {
        items: [
            {
                name: 'Extension Option',
                state: 'app.extension.extendOption',
                display: 'always',
                shorthand: 'option'
            }, 
            {
                name: 'Extension Term',
                state: 'app.extension.extensionTerm',
                display: 'started',
                shorthand: 'term'                      
            },
            {
                name: 'US Contact Information',
                state: 'app.extension.contactInfo',
                display: 'started',
                shorthand: 'contact'
            },
            {
                name: 'Driving Update',
                state: 'app.extension.extensionDriving',
                display: 'started',
                shorthand: 'driving'
            },
            {
                name: 'Child Age Willingness',
                state: 'app.extension.childAgeWilling',
                display: 'started',
                shorthand: 'willingness'
            },
            {
                name: 'US Childcare Experience',
                state: 'app.extension.childcareExperience',
                display: 'started',
                shorthand: 'experience'
            },
            {
                name: 'Profile',
                state: 'app.extension.extensionProfile',
                display: 'started',
                shorthand: 'profile'
            },
            {
                name: 'Confirmation of Placement',
                state: 'app.extension.confirmPlacement',
                display: 'started',
                shorthand: 'confirmation'
            },
            {
                name: 'Education Status',
                state: 'app.extension.educationStatus',
                display: 'education',
                shorthand: 'education'
            }
        ]
    });

}
}

angular
    .module('app')
    .config(MenuConfig);