import _ from 'lodash-es'
import { BusinessArea } from '../../../services/AuthService';
import { LegalOnHoldModalController } from './LegalOnHoldModalController'

export class ApplicantDataController {

    lastLogin: Date
    legalArea: boolean

    static $inject = ['$uibModal', 'GdprStatus', 'User', 'Auth', 'userLastLogin', 'userAgreements', 'gdprStatus', 'userStatus','applicantDataReplication']
    constructor(private $uibModal, private GdprStatus, private User, Auth, userLastLogin, private userAgreements, private gdprStatus, private userStatus, private applicantDataReplication) {
        this.lastLogin = userLastLogin.lastLogin;

        this.legalArea = Auth.isInBusinessArea(BusinessArea.LEGAL);
    }

    unlock() {
        this.User
            .unlock({ userId: this.userStatus.applicantUserId })
            .$promise
            .then((results) => {
                this.userStatus.isLockedOut = false;
            });
    }

    changeLegalOnHold() {
        return this.$uibModal
            .open({
                templateUrl: 'areas/application/applicantData/legalOnHoldModal.html',
                controller: LegalOnHoldModalController,
                controllerAs: '$ctrl',
                backdrop: false,
                resolve: {
                    gdprStatus: () => angular.copy(this.gdprStatus)
                }
            })
            .result
            .then((results) => {
                this.gdprStatus = results;
            });

    }
}


angular
    .module('app')
    .controller('ApplicantData', ApplicantDataController);

