import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class MarketingController {

    alerts: IAlertConfig[] = []

    static $inject = ['marketingPreferences', 'action', 'dialogs'];
    constructor(public marketingPreferences, action, public dialogs) {
        if (action == 'unsubscribe') {
            this.unsubscribe();
        }
    }

    unsubscribe() {
        this.dialogs
            .confirm('Please Confirm', 'Are you sure that you wish to unsubscribe?')
            .result
            .then(() => {
                this.marketingPreferences.email = false;
                this.marketingPreferences.phone = false;
                this.marketingPreferences.message = false;
                this.marketingPreferences.hasSubscribe = false;
                this.save();
            })
    }

    save() {
        this.marketingPreferences.$save(() => {
                this.alerts.push({
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while saving user countries. Please try again after a while.')
            });
    }

    closeAlert = function (alert) {
        var index = this.alerts.indexOf(alert);
        if (index > -1) {
            this.alerts.splice(index, 1);
        };
    }
}

angular
    .module('app')
    .controller('MarketingController', MarketingController);