import { BusinessArea } from '../../../services/AuthService';
import { FlightLegDetailsModalController } from './FlightLegDetailsModalController';


export class FlightDetailsModalController {

    selectedFlightMessage;

    static $inject = ['$uibModalInstance', '$uibModal', 'dialogs', 'Flights', 'Airport', 'Airline', 'flight', 'flightMessages'];
    constructor(public $uibModalInstance, private $uibModal, private dialogs, private Flights, private Airport, private Airline, private flight, private flightMessages) {
        this.ticketMessageChange();
    }

    save() {
        this.Flights
            .save({
                id: this.flight.id,
                confirmedPPO: this.flight.confirmedPPO,
                ticketMessageId: this.flight.ticketMessageId
            })
            .$promise
            .then((results) => {
                this.$uibModalInstance.close()
            }, () => {
                this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
            });
    }

    ticketMessageChange() {
        this.selectedFlightMessage = this.flightMessages.find(m => m.id == this.flight.ticketMessageId);
    }

    public selectFlightLeg(flightLeg) {
        return this.$uibModal.open({
            templateUrl: 'areas/tools/maintainFlights/flightLegDetailsModal.html',
            controller: FlightLegDetailsModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            size: 'md',
            resolve: {
                departAirport: () => this.Airport.get({ airportCode: flightLeg.departAirportCode}).$promise,
                arrivalAirport: () => this.Airport.get({ airportCode: flightLeg.arrivalAirportCode }).$promise,
                airline: () => this.Airline.get({ code: flightLeg.airlineCode }).$promise,
                flightLeg: () => angular.copy(flightLeg)
            }
        }).result;
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}