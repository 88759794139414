
class ChangePasswordController {

    user: any = {};
    changed: boolean;

    static $inject = ['$scope', '$state', 'dialogs', 'Auth'];
    constructor(private $scope, private $state, private dialogs, private Auth) {
    }


    submit() {
        this.$scope.$broadcast('submitted', true);

        var form = this.$scope.passwordForm;
        if (form.$valid) {
            this.Auth.changePassword(this.user)
                .then((data) => {
                    form.$setPristine();
                    this.changed = true;
                }, (error) => {
                    this.changed = false;
                    this.dialogs.error('Error', error);
                });
        }
    }
}

angular
    .module('app')
    .controller('ChangePasswordController', ChangePasswordController);
