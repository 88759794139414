import _ from 'lodash-es'
import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class PreInterviewFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {
            get: { method: 'GET', params: { applicationId: '@applicationId' } },
            update: { method: 'PUT', params: { applicationId: '@applicationId' } }
        }
        this.server = 'application'
        this.initialize()
    }


    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'PreInterviewContact',
                url: 'preinterview/applicant/:applicantId/contact',
                params: { applicantId: '@applicantId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'preferredMethodContact'
                            )
                        ),
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('preferredMethodContact')
                        )
                    }
                }
            },
            {
                name: 'PreInterviewPassport',
                url: 'preinterview/application/:applicationId/passport',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'birthDate',
                                'minBirthDate',
                                'maxBirthDate',
                                'passportExpiryDate'
                            )
                        )
                    }
                }
            },
            {
                name: 'PreInterviewDriving',
                url: 'preinterview/application/:applicationId/driving',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'roadTypes'
                            ),
                            this.trans.convertToDate(
                                'licenseValidTo',
                                'fullLicenseExpectedOn',
                                'permanenteLicenseExpectedOn',
                                'startedDrivingDate',
                                'practicalTestPassed'
                            )
                        ),
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('roadTypes')
                        )
                    }
                }
            },
            {
                name: 'PreInterviewAvailability',
                url: 'preinterview/application/:applicationId/availability',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'availabilityDateEarliest',
                                'availabilityDateLatest',
                                'availabilityDateLatestMax',
                                'onHolidayFrom',
                                'onHolidayTo',
                            )
                        ),
                    }
                }
            },
            {
                name: 'PreInterviewProgram',
                url: 'preinterview/application/:applicationId/program',
                params: { applicationId: '@applicationId' }
            },
            {
                name: 'PreInterviewPrematch',
                url: 'preinterview/application/:applicationId/prematch',
                params: { applicationId: '@applicationId' }
            },
            {
                name: 'PreInterviewVisa',
                url: 'preinterview/application/:applicationId/visa',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'enteredDate',
                                'departedDate'
                            )
                        )
                    }
                }
            },
            {
                name: 'PreInterviewChildcare',
                url: 'preinterview/application/:applicationId/childcare',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox('specialNeed')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('specialNeed')
                        )
                    }
                }
            },
            {
                name: 'PreInterviewMedical',
                url: 'preinterview/application/:applicationId/medical',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'medicalCondition',
                                'medicalAid',
                                'specialDietDetails',
                                'allergies',
                                'allergyAnimals'
                            )
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString(
                                'medicalCondition',
                                'medicalAid',
                                'specialDietDetails',
                                'allergies',
                                'allergyAnimals'
                            )
                        )
                    }
                }
            },
            {
                name: 'PreInterviewHealth',
                url: 'preinterview/application/:applicationId/health',
                params: { applicationId: '@applicationId' }
            }
        ]

        this.process(resources)
    }
}

new PreInterviewFactory()
