import { IAlertConfig } from '../../../models/bootstrap/Alert' 

enum InsuranceAmazonResources { PlanOfInsurance = 1, LiabilityAndPropertyInsurance = 2, InsuranceCoverageExplained = 3 }

class ExtensionInsuranceController {  
    insuranceData: any;    
    applicationInstance;
    insuranceCoverageExplained;
    year;

    static $inject = ['$scope', 'Auth', 'USAuPairs', 'ExtensionInsuranceToken', 'applicationObservable', 'AmazonResource', 'Spinner'];
    constructor(private $scope, private Auth, private USAuPairs, public ExtensionInsuranceToken, private applicationObservable, AmazonResource, private Spinner) {           
        this.Spinner.show();
            
        this.USAuPairs.insuranceData({
            aupairId: this.Auth.user.usAuPairId
        })
        .$promise
        .then((result) => {                    
            this.insuranceData = result.data;
            this.setParameters(result);

            if (this.insuranceData.activeYear1)
                this.year = 1;
            else if (this.insuranceData.activeYear2)
                this.year = 2;
        });

        applicationObservable.subscribe(applicationInstance => {
            this.applicationInstance = applicationInstance;

            AmazonResource.query({ applicationId: applicationInstance.applicationId, groupId: 1 })
                .$promise
                .then(result => {
                    this.insuranceCoverageExplained = this.findInsuranceUrl(result.insuranceAmazonUrls, InsuranceAmazonResources.InsuranceCoverageExplained);
                });
        })

        this.Spinner.hide();
    }

    findInsuranceUrl(insuranceAmazonUrls, insuranceAmazonResource) {
        return insuranceAmazonUrls[insuranceAmazonResource - 1].url;
    }

    setParameters(result) {
        angular.forEach(result.data.insurances, function (value, key) {
            if (value.isMandatory && !value.isPaid) {
                this.insuranceData.amountOutstanding = this.insuranceData.amountOutstanding + value.amount;
                this.insuranceData.selectedInsuranceType = this.setInsuranceType(this.insuranceData.selectedInsuranceType, true, false, value.insuranceType);
            }
        }, this);
    }

    setInsuranceType(currentSelectedType, addingType, deleteType, value) {

        var selectedInsType = "";

        // if currentSelectedType is NULL/Undefined (nothing is selected)
        if (!currentSelectedType) {
            // when user is buying the first insurance. 
            if (addingType) {
                selectedInsType = value.toString()
            }
        }
        else {
            // when user is trying to add insurance to buy
            if (addingType) {
                selectedInsType = currentSelectedType.toString() + ',' + value.toString()
            }
        }

        // this is called when user is trying to uncheck previously selected insurance
        if (deleteType) {   
            var deleteChar = '';
            // if first option deleted
            if (currentSelectedType.charAt(0) == value.toString()) {
                deleteChar = value.toString() + ',';
            }
            else {
                deleteChar = ',' + value.toString();
            }

            selectedInsType = currentSelectedType.replace(deleteChar.toString(), '');
            selectedInsType = selectedInsType.replace(value.toString(), '');
        }

        return selectedInsType;            
    }

    toggleInsurance(item) {
        if (!(item.isPaid) && !(item.isMandatory)) {
            var currentStatus = item.selected;

            if (angular.isUndefined(this.insuranceData.selectedInsuranceType))
            {
                this.insuranceData.selectedInsuranceType = '';
            }

            // If currentStatus is True (checked), means user has unchecked it
            // So delete the item total from Outstanding total
            if (currentStatus) {
                this.insuranceData.amountOutstanding = this.insuranceData.amountOutstanding - item.amount;
                this.insuranceData.selectedInsuranceType = this.setInsuranceType(this.insuranceData.selectedInsuranceType, false, true, item.insuranceType);
            }
            else {
                this.insuranceData.amountOutstanding = this.insuranceData.amountOutstanding + item.amount;
                this.insuranceData.selectedInsuranceType = this.setInsuranceType(this.insuranceData.selectedInsuranceType, true, false, item.insuranceType);
            }

            // Change user display of tick/unticked
            item.selected = !item.selected;
        }
    }

    submit() {
        // This gets Token 
        this.ExtensionInsuranceToken.get({
            applicationId: this.Auth.user.usAuPairId,
            amount: this.insuranceData.amountOutstanding
        })
            .$promise
            .then((result) => {
                //alert(result.url);
                window.location.href = result.returnurl;
            });

    }
}

angular
    .module('app')
    .controller('ExtensionInsuranceController', ExtensionInsuranceController)
