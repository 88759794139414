 
import _ from 'lodash-es'
import { SingleDocumentUploader, MultiDocumentUploader } from '../controls/documentsUpload/DocumentUploadConfig';

enum UploadCateogry { Hobby = 10, PreviousUSAHostFamilyChildcareCertificate = 17, EducationCertificate = 18 }


class ApplicationUploaderProvider implements ng.IServiceProvider {

    $get = ['ApplicationDocument',
        function (ApplicationDocument) {

            class ApplicationUploader implements SingleDocumentUploader, MultiDocumentUploader {
                constructor(
                    private applicationId: number,
                    private category: number,
                    private extraReference?: number,
                    private referenceId?: number) {
                }

                getDocumentType() {
                    return this.getDocumentTypes()
                        .then((documentTypes) => {
                            if (angular.isArray(documentTypes.documents) && documentTypes.documents.length > 0) {
                                return documentTypes.documents[0];
                                //this.documentType = documentTypes[0].id
                                //var documents = documentTypes[0].documents

                                //if (angular.isArray(documents) && documents.length > 0) {
                                //    // If its Hobbies and Interest document
                                //    if (documentTypes[0].id == 37) {
                                //        var matchingDocument = _.filter(documents, { extraReference: this.extraReference })
                                //        if (matchingDocument.length) {
                                //            return matchingDocument[0]
                                //        }
                                //    }
                                //    else {
                                //        return documents[0];
                                //    }
                                //}
                            }
                            if (angular.isArray(documentTypes) && documentTypes.length > 0) {
                                return documentTypes[0];
                            }
                        });
                }

                getDocumentTypes() {
                    if (this.referenceId && this.referenceId > 0) {
                        return ApplicationDocument.fetchUploadedDocumentsForReference({
                            categoryId: this.category,
                            applicationId: this.applicationId,
                            referenceId: this.referenceId
                        }).$promise
                    }
                    else if (this.extraReference && this.extraReference > 0) {
                        return ApplicationDocument.fetchIndividualDocument({
                            categoryId: this.category,
                            applicationId: this.applicationId,
                            extraReference: this.extraReference
                        }).$promise
                    }
                    else {
                        return ApplicationDocument.query({
                            category: this.category,
                            applicationId: this.applicationId
                        }).$promise
                    }
                }

                prepareUpload(documentType: number) {
                    return ApplicationDocument.prepareUpload({
                        documentType: documentType,
                        applicationId: this.applicationId
                    }).$promise;
                }
                uploadSuccess(document, uploadDescription) {
                    return ApplicationDocument.uploadSuccess({
                        applicationId: this.applicationId,
                        documentId: uploadDescription.documentId,
                        referenceId: (this.referenceId && this.referenceId > 0) ? this.referenceId : null,
                        extraReference: (this.extraReference && this.extraReference > 0) ? this.extraReference : null,
                        files: document.files,
                        description: document.description
                    }).$promise;
                }

                uploadDescription(documentId: number) {
                    return ApplicationDocument.uploadDescription({
                        documentId: documentId
                    }).$promise;
                }

                addFile(file) {
                    return ApplicationDocument
                        .addPage({
                            documentId: file.documentId
                        }, {
                            pageId: file.pageId,
                            path: file.path
                        })
                        .$promise;
                }

                uploadFailed(uploadDescription, error) {
                    return ApplicationDocument.uploadFailed({
                        documentId: uploadDescription.documentId,
                        errorCode: error.code,
                        errorMessage: error.message,
                        files: error.files
                    }).$promise;
                }
            }

            return ApplicationUploader;
        }];
}

angular
    .module('app')
    .provider('ApplicationUploader', ApplicationUploaderProvider);

