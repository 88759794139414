import _ from 'lodash-es'

class FindAuPairModalController {
        
    interviewerOptions: any[];
    agencieOptions: any[];
    applicationStatusOptions: any[];

    type = [];
        
    typeOptions = [
        { value: "applicants", label: "Applicants" },
        { value: "participants", label: "Participants" }
    ];

    yesNoOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
           
    ]
        

    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'settings', 'ApplicationLookup', 'agencies', 'interviewers', 'applicationStatuses', 'programOptions', 'countryOptions', 'drivingLicenceOptions', 'visaOptions'];
    constructor(private $scope, private dialogs, private $uibModalInstance, private settings, ApplicationLookup, private agencies, private interviewers, private applicationStatuses, private programOptions, private countryOptions, private drivingLicenceOptions, private visaOptions) {

        switch (settings.type) {

            case 'applicants':
                this.type = ['applicants']
                break;

            case 'participants':
                this.type = ['participants']
                break;

            case 'all':
                this.type = ['applicants', 'participants']
                break;
        }
        this.applicationStatusListFilter();
        this.updateAgencieOptions();
        this.updateInterviewerOptions();
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        if (this.type.length == 0) {
            // show walidation message
            return;
        } else if (this.type.length == 1) {
            this.settings.type = this.type[0];
        } else {
            this.settings.type = 'all';
        }


        this.$uibModalInstance.close(this.settings);
            
    }

    applicationStatusListFilter() {
        var applicantsSelected = _.includes(this.type, 'applicants');
        var participantsSelected = _.includes(this.type, 'participants');
        this.applicationStatusOptions = this.applicationStatuses && this.applicationStatuses.filter((v) => (applicantsSelected && v.applicantSearch) || (participantsSelected && v.participantSearch));
    }

    updateAgencieOptions() {
        if (this.settings.filters.country) {
            this.agencieOptions = _.filter(this.agencies, { country: this.settings.filters.country });
        } else {
            this.agencieOptions = this.agencies.slice();
        }

        if (this.agencieOptions.length > 0) {
            this.agencieOptions.unshift({ id: 0, name: 'None' });
            this.agencieOptions.unshift({ name: ' ' });
        } else {
            this.agencieOptions.unshift({ name: ' ' });
        }
    }

    updateInterviewerOptions() {
        if (this.settings.filters.agency) {
            this.interviewerOptions = _.filter(this.interviewers, { agencyId: this.settings.filters.agency });
        } else if (this.settings.filters.country) {
            this.interviewerOptions = _.filter(this.interviewers, { country: this.settings.filters.country });
        } else {
            this.interviewerOptions = this.interviewers.slice();
        }


        if (this.interviewerOptions.length > 0) {
            this.interviewerOptions.unshift({ id: 0, name: 'None' });
            //this.interviewerOptions.unshift({ id: undefined, name: '----- All ------' });
        } else {
            //this.interviewers.unshift({ id: undefined, name: '----- None Available ------' });
        }
    }

    countryChanged() {
        this.updateAgencieOptions();
        this.updateInterviewerOptions();
    }

    agencyChanged() {
        this.updateInterviewerOptions();
    }
}

angular
    .module('app')
    .controller('FindAuPairModalController', FindAuPairModalController);
