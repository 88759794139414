import { DataAccessFactory } from "./DataAccessFactory";
import { Resource } from './dataAccess';

class NotificationsFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'notifications'
        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'NotificationsService',
                url: '',
                actions: {
                    query: {
                        url: 'notifications',
                        method: 'GET',
                        isArray: true
                    },
                }
            }];


        this.process(resources)
    }
}

new NotificationsFactory();