export enum ActionType {
    // these keys match the names from the user action table in the db
    SUBMIT_TO_AGENCY,
    SUBMIT_TO_OFFICE,
    CREATE_INVOICE,
    CANCEL_INVOICE,
    REFUND_INVOICE,
    RECORD_PAYMENT,
    RECORD_INSURANCE,
    ADJUST_ACCOUNT,
    ADD_VOUCHER,
    DELETE_VOUCHER,
    SendMessage,
    ADD_COMPETITOR_REPORT
}

export interface ActionTypeItem {
    name: string
} 

export var ActionTypeItems: { [index: string]: ActionTypeItem } = {}
ActionTypeItems[ActionType.SUBMIT_TO_AGENCY] = { name: 'Submit to agency' }
ActionTypeItems[ActionType.SUBMIT_TO_OFFICE] = { name: 'Submit to office' }
ActionTypeItems[ActionType.CREATE_INVOICE] = { name: 'Create invoice' }
ActionTypeItems[ActionType.CANCEL_INVOICE] = { name: 'Cancel invoice' }
ActionTypeItems[ActionType.REFUND_INVOICE] = { name: 'Pay refund' }
ActionTypeItems[ActionType.RECORD_PAYMENT] = { name: 'Record payment' }
ActionTypeItems[ActionType.RECORD_INSURANCE] = { name: 'Record insurance' }
ActionTypeItems[ActionType.ADJUST_ACCOUNT] = { name: 'Adjust account' }
ActionTypeItems[ActionType.ADD_VOUCHER] = { name: 'Add voucher' }
ActionTypeItems[ActionType.DELETE_VOUCHER] = { name: 'Delete voucher' }
ActionTypeItems[ActionType.ADD_COMPETITOR_REPORT] = { name: 'Add competitor report' }
    
    