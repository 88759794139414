export enum InsuranceFeeType {
    ComprehensiveInsurance = 1,
    TravelPackage = 2,
    MedicalUpgrade = 3,
    AifsDeCancellationInsurance = 4,
    TravelInsurance = 5,
    SportsInsurance = 6,
    PreExistingMedicalInsurance = 7
}

export interface IInsuranceFee {
    displayName: string;
}

export var InsuranceFees: { [index: number]: IInsuranceFee } = {}
InsuranceFees[InsuranceFeeType.ComprehensiveInsurance] = { displayName: 'Comprehensive Insurance' }
InsuranceFees[InsuranceFeeType.TravelPackage] = { displayName: 'Travel and Sports Package Insurance' }
InsuranceFees[InsuranceFeeType.MedicalUpgrade] = { displayName: 'Medical Upgrade Insurance' }
InsuranceFees[InsuranceFeeType.AifsDeCancellationInsurance] = { displayName: 'Travel Cancellation Insurance' }
InsuranceFees[InsuranceFeeType.TravelInsurance] = { displayName: 'Travel Insurance' }
InsuranceFees[InsuranceFeeType.SportsInsurance] = { displayName: 'Sports Insurance' }
InsuranceFees[InsuranceFeeType.PreExistingMedicalInsurance] = { displayName: 'Pre Existing Medical Insurance' }
