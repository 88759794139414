 


class MediaGridController {
        
    config;
    photosNumber;
    documents;
    currentDocument;
    applicationStatus;

    static $inject = ['$scope', '$uibModal', 'DocumentUpload'];
    constructor(private $scope, private $uibModal, private DocumentUpload) {
        $scope.$on('sideMenu:uploadDataStateChanged', (event, data) => {
            this.loadMedia();
        });
    }

    $onInit() {
        this.loadMedia();
    }

    private addDocument(document) {
        this.documents[this.currentDocument++] = document;
    }

    private loadMedia() {
        this.config.uploader.getDocumentTypes().then((documentTypes) => {
            this.currentDocument = 0;
            this.documents = [];
            for (let documentType of documentTypes.documents) { 
                for (let document of documentType.documents) {
                    this.addDocument(document);
                }
            }
            var missing = this.photosNumber - this.currentDocument;
            if (missing > 0) {
                while (missing--) {
                    this.documents.push({ isAddPhoto: true });
                }
            }
        });
    }

    private fileSelected(files, documentType) {
        if (!angular.isArray(files) || files.length === 0)
            return;

        this.$uibModal.open({
            templateUrl: 'controls/documentsUpload/documentModal/singlePage/documentUploadModal.html',
            controller: 'DocumentUploadModalController as dumCtrl',
            backdrop: false,
            resolve: {
                documentType: () => documentType,
                documentDefinition: () => this.DocumentUpload.documentDefinition({ documentType: documentType }),
                uploader: () => this.config.uploader,
                files: () => files,
                hideDescription: () => false,
                onUpdateHandler: () => null,
                onDeleteHandler: () => null,
                onApproveHandler: () => null
            }
        }).result.then((document) => {
            this.addDocument(document);
        });
    }

    photoSelected(files) {
        this.fileSelected(files, 6);
    }

    openDialog(document) {
        this.$uibModal.open({
            templateUrl: 'controls/documentsUpload/documentModal/singlePage/documentDisplayModal.html',
            controller: 'DocumentDisplayModalController as ddmCtrl',
            backdrop: false,
            resolve: {
                documentType: () => document.documentType,
                documentDefinition: () => this.DocumentUpload.documentDefinition({ documentType: document.documentType }),
                uploader: () => this.config.uploader,

                document: () => angular.copy(document),
                onUpdateHandler: () => null,
                onDeleteHandler: () => null,
                onApproveHandler: () => null,
                applicationStatus: () => this.applicationStatus
            }
        }).result.then(() => {
            this.loadMedia();
        });
    }
}

    
var MediaGridDirectiveFactory = [function MediaGridDirectiveFactory(): ng.IDirective {
    return {
        restrict: 'AE',
        scope: {
            config: '=mediaGrid',
            photosNumber: '=',
            applicationStatus: '=applicationStatus'
        },
        controller: MediaGridController,
        controllerAs: 'mcCtrl',
        bindToController: true,
        templateUrl: 'controls/documentsUpload/mediaGrid/mediaGrid.html'
    };
}]


angular
    .module('app')
    .directive('mediaGrid', MediaGridDirectiveFactory);

