import { IAction } from '../IAction'

export class Reinstate implements IAction{

    static $inject = ['$uibModal', '$rootScope', 'applicationId']
    constructor(private $uibModal, private $rootScope, private applicationId) {
	}

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/reinstate/reinstateModal.html',
            controller: ReinstateModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId,
                rootScope: () => this.$rootScope
            }
        }).result;
    }
}
    
class ReinstateModalController {
    public commentText: string;

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'applicationId', 'Reinstate', 'Auth', 'rootScope'];
    constructor(private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private applicationId, private Reinstate, private Auth, private rootScope) {
            
    }

    reinstate() {
        var user = this.Auth.user;

        var data = {
            applicationId: this.applicationId,
            userId: user.userId,
            author: user.firstName + ' ' + user.lastName,
            comment: this.commentText
        };

        this.Reinstate.update({ applicationId: this.applicationId }, data)
            .$promise
            .then((results) => {
                this.rootScope.$broadcast('changeApplicationStatus', true);
            }, () => {
                return this.dialogs
                    .error('Error', 'We are sorry but the application could not be reinstate. Please try again in 5 minutes.')
                    .result
            })
            .finally(() => {
                this.$uibModalInstance.close();
            });
    }
        

    close() {
        this.$uibModalInstance.dismiss();
    }

}