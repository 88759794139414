 
import _ from 'lodash-es'


class StaticLookup {

    applicantsRequiredInterview;

    constructor(private $q: ng.IQService, private Interviewer, private InterviewerApplicant) {

        this.applicantsRequiredInterview = this.mapResource(InterviewerApplicant.requiredInterview,
            (a) => {
                return {
                    name: `${a.applicationId} - ${a.firstName} ${a.lastName}`,
                    code: a.userId
                };
            });
    }

    howManyBrothersSisters = () => {
        return this.createFakeResourceResponse([
            { code: '0', name: '0' },
            { code: '1', name: '1' },
            { code: '2', name: '2' },
            { code: '3', name: '3' },
            { code: '4', name: '4' },
            { code: '5+', name: '5+' }
        ]);
    }

    howLongKnowYears = () => {
        return this.createFakeResourceResponse([
            { code: '0', name: '0' },
            { code: '1', name: '1' },
            { code: '2', name: '2' },
            { code: '3', name: '3' },
            { code: '4', name: '4' },
            { code: '5+', name: '5+' }
        ]);
    }

    duration = () => {
        return this.createFakeResourceResponse([
            { code: 60, name: '1 hour' },
            { code: 90, name: '1 hour 30 minutes' },
            { code: 120, name: '2 hours' },
            { code: 150, name: '2 hours 30 minutes' },
            { code: 180, name: '3 hours or more' }
        ]);
    }

    howLongWasFormalInterview = () => this.duration();

    howLongKnowMonths = () => {
        var values = _.map(_.range(0, 12), (val) => <any>{ code: val, name: val });
        return this.createFakeResourceResponse(values);
    }

    englishSpeakingInterviewers = (param) => {
        var promise = this.Interviewer.englishSpeaking(param)
            .$promise
            .then((interviewers) => _.filter(interviewers, (i: any) => i.id != param.id))
            .then((interviewers) => _.map(interviewers, (i: any) => <any>{
                name: `${i.firstName} ${i.lastName} - ${i.id}`,
                code: i.id
            }));
        return {
            $promise: promise
        };

    };


    private mapResource(resource, getter) {
        return function (...params) {
            var promise = resource.apply(this, params)
                .$promise
                .then((interviewers) => {
                    var data = _.map(interviewers, getter);
                    return data;
                });
            return {
                $promise: promise
            };
        }
    }

    private createFakeResourceResponse(obj) {
        obj.$promise = this.$q.when(obj);
        return obj;
    }
}



class StaticLookupProvider implements ng.IServiceProvider {

    staticLookup: StaticLookup;

    constructor() {
    }

    $get = ['$q', 'Interviewer', 'InterviewerApplicant', ($q: ng.IQService, Interviewer, InterviewerApplicant) => {
        if (!this.staticLookup)
            this.staticLookup = new StaticLookup($q, Interviewer, InterviewerApplicant);

        return this.staticLookup;
    }];
}

angular
    .module('app')
    .provider('StaticLookup', StaticLookupProvider);
