
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { AccessLevelView } from '../accessLevel';
import { RoutingProvider, state } from '../../services/RoutingService';

import { BranchController } from '../../areas/agency/branch/BranchController';
import { BasicDetailsController } from '../../areas/agency/branch/BasicDetailsController';
import { ContactsController } from '../../areas/agency/branch/ContactsController';

var branchTitle = (subsection) => ['branch', (branch) => `${subsection} - ${branch.id} - ${branch.name}`];

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        var branch: state<AccessLevelView> = {
            name: 'branch',
            url: '/branch/{branchId:int}',
            abstract: true,
            views: {
                content: {
                    controllerAs: '$ctrl',
                    staff: {
                        controller: BranchController,
                        template: 'areas/agency/branch/Branch.html'
                    }
                }
            },
            resolve: {
                branchId: ['$stateParams', ($stateParams) =>  $stateParams.branchId],
                branch: ['Branches', 'branchId', (Branches, branchId) => Branches.get({ branchId: branchId }).$promise ],
                $title: ['branch', (branch) => `Branch - ${branch.name}` ]
            },
            states: [
                {
                    name: 'basicDetails',
                    url: '',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            office: {
                                controller: BasicDetailsController,
                                template: 'areas/agency/branch/BasicDetails.html'
                            }
                        }
                    },
                    resolve: {
                        $title: branchTitle('Basic details')
                    }
                },
                {
                    name: 'contacts',
                    url: '/contacts',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            office: {
                                controller: ContactsController,
                                template: 'areas/agency/branch/Contacts.html'
                            }
                        }
                    },
                    resolve: {
                        $title: branchTitle('Contacts'),
                        agencyContacts: ['AgencyContact', 'branchId', (AgencyContact, branchId) => AgencyContact.queryForBranch({ branchId: branchId }).$promise],
                    }
                }
            ]
        };

        RoutingProvider.addState(branch, 'app');
       
    }
}

angular
    .module('app')
    .config(RoutingConfig);