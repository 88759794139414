import { BusinessArea } from './AuthService'

export interface Component {
    template?: string
    controller?: string | Function,
    controllerAs?: string
    businessArea?: BusinessArea
}

interface Views<TView> {
    [name: string]: TView | View<TView>
}

export interface state<TView> {
    name: string,
    abstract?: boolean,
    url: string,
    views?: Views<TView>,
    resolve?: any,
    params?: any,
    states?: state<TView>[],
    onEnter?: any
}

export interface BaseView {
    default?: Component
}

export class View<TView extends BaseView> {
    default: Component

    constructor(private name: string, def: TView) {

        var defRouting: Component = {
            controller: angular.noop,
            template: ''
        };

        if (def.default instanceof Object) {
            angular.extend(defRouting, def.default);
        }

        for (let key of Object.keys(def)) {
            if (key === 'controllerAs') {
                this[key] = def[key];
            } else {
                this[key] = angular.extend(angular.copy(defRouting), def[key]);
            }
        }
    }

    controllerProvider = ['AccessLevel', this.controllerProviderImpl.bind(this)]
    templateProvider = ['AccessLevel', 'Auth', '$http', '$templateCache', '$q', this.templateProviderImpl.bind(this)];

    private getRouting(userLevels) {
        var result = userLevels.firstMatch(this);

        if (!result && angular.isDefined(this.default))
            result = this.default;

        return result;
    }

    private controllerProviderImpl(AccessLevel) {
        var routing = this.getRouting(AccessLevel.userLevels);
        return routing.controller;
    }

    private templateProviderImpl(AccessLevel, Auth, $http, $templateCache, $q) {
        return $q((resolve, reject) => {

            AccessLevel.promise().then((userLevels) => {
                var routing = this.getRouting(userLevels);

                if (!routing) {
                    reject({ reason: 'state_access_denied', message: `Don't have access to ${this.name} state` });
                } else if (routing.businessArea && !Auth.isInBusinessArea(routing.businessArea)) {
                    reject({ reason: 'business_area_required', businessArea: routing.businessArea });
                } else {
                    var url = routing.template;
                    if (!url) {
                        resolve(url);
                    } else {
                        $http.get(url, { cache: $templateCache })
                            .then((response) => {
                                resolve(response.data);
                            });
                    }
                }
            });
        });
    }
}






export class RoutingProvider<T> implements angular.IServiceProvider {

    static $inject = ['$stateProvider'];
    constructor(private $stateProvider) { }

    addState(state: state<T> | state<T>[], namePrefix?: string) {

        if (state instanceof Array) {
            for (let substate of state) {
                this.addState(substate, namePrefix);
            }
        } else {
            var stateName = state.name;
            if (namePrefix)
                stateName = namePrefix + '.' + stateName;

            state.resolve = state.resolve || {};

            if (state.views) {
                var views = state.views;
                for (let key of Object.keys(views)) {
                    var view = views[key];
                    if (!(view instanceof View)) {
                        views[key] = new View<T>(stateName, view);
                    }
                }
            }

            this.$stateProvider.state(stateName, state);

            if (state.states instanceof Array) {
                for (let substate of state.states) {
                    this.addState(substate, stateName);
                }
            }
        }
    }

    $get = [function () {
        return {};
    }];
}

angular
    .module('app')
    .provider('Routing', RoutingProvider);
