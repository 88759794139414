//import { enableProdMode } from '@angular/core';
//import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import { UpgradeModule, downgradeComponent } from '@angular/upgrade/static'

//import { AppModule } from './app/app.module';
import '../app'
//import { environment } from './environments/environment';
//import { TestComponent } from './app/test/test.component';

//if (environment.production) {
//  enableProdMode();
//}

//platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {

//    angular.module('app')
//        .directive('test', downgradeComponent({
//            component: TestComponent
//        }))

//    const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
//    upgrade.bootstrap(document.documentElement, ["app"]);

//}).catch(err => console.error(err));

angular.element(function () {
    angular.bootstrap(document.documentElement, ['app']);
});