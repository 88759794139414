import { IAction } from '../IAction'
import { ActionType, ActionTypeItems } from '../../../models/userActions/ActionType'
import { ITransaction } from '../../../models/finance/ITransaction'


export class AdjustAccount implements IAction{

    static $inject = ['$uibModal', 'Invoice', 'applicationId', 'invoiceId']
    constructor(private $uibModal, private Invoice, private applicationId, private invoiceId) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/adjustAccount/adjustAccountModal.html',
            controller: AdjustAccountActionController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                data: () => <any>{
                    applicationId: this.applicationId,
                    invoiceId: this.invoiceId
                },
                invoice: () => this.Invoice.get({ applicationId: this.applicationId, invoiceId: this.invoiceId }).$promise,
                title: () => ActionTypeItems[ActionType.ADJUST_ACCOUNT].name
            }
        }).result
    }
}

class AdjustAccountActionController {

    public selectedTransaction: ITransaction

    static $inject = ['$rootScope', '$scope', 'dialogs', '$uibModalInstance', 'Invoice', 'data', 'title', 'Finance', 'invoice']
    constructor(private $rootScope, private $scope, private dialogs, private $uibModalInstance, private Invoice, private data, private title: string, private Finance, private invoice) {

        data.balance = invoice.amount;
        data.currencyCode = invoice.currencyCode;
    }

    public doAction() {
        this.$scope.$broadcast('submitted', true)
            
        if (this.data.$validation()) {
            this.Invoice.recordAccountAdjustment(this.data).$promise
                .then((results) => {
                    this.$rootScope.$broadcast('invoice:changed');
                    this.$uibModalInstance.close();
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
                })
        }
    }

    close() {
        this.$uibModalInstance.dismiss()
    }
}
        