
class InterviewReportController {
        
    saved: boolean;

    static $inject = ['$scope', '$q', 'dialogs', 'HelpTexts', 'about', 'english', 'childcare', 'motivation', 'academicStatus', 'personality', 'evaluation', 'returner', 'medical', 'referral'];
    constructor(private $scope, private $q, private dialogs, private HelpTexts, private about, private english, private childcare, private motivation, private academicStatus, private personality, private evaluation, private returner, private medical, private referral) {

    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form['InterviewReportAbout'].$dirty || form['InterviewReportMetTheApplicant'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportAbout' }, this.about).$promise);
        }
        if (form['InterviewReportEnglishSkills'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportEnglishSkills' }, this.english).$promise);
        }
        if (form['InterviewReportChildcareExperience'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportChildcareExperience' }, this.childcare).$promise);
        }
        if (form['InterviewReportMotivation'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportMotivation' }, this.motivation).$promise);
        }
        if (form['AcademicStatus'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'AcademicStatus' }, this.academicStatus).$promise);
        }
        if (form['InterviewReportPersonality'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportPersonality' }, this.personality).$promise);
        }
        if (form['InterviewReportEvaluation'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportEvaluation' }, this.evaluation).$promise);
        }
        if (form['InterviewReportPreviousExperience'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportPreviousExperience' }, this.returner).$promise);
        }
        if (form['InterviewReportMedicalCriminal'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportMedicalCriminal' }, this.medical).$promise);
        }
        if (form['InterviewReportAmbassadorReferral'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'InterviewReportAmbassadorReferral' }, this.referral).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                $('html, body').animate({ scrollTop: 860 }, 200);
                this.saved = true;
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
            });
    }
}

angular
    .module('app')
    .controller('HelpText.InterviewReportController', InterviewReportController);