import { AgencyTerritoryModalController } from '../AgencyDetails/modals/AgencyTerritoryModalController'
import _ from 'lodash-es';
import { BusinessArea } from '../../../services/AuthService';

export class AgencyTerritoriesController {
    orderBy: string = 'isActive asc';

    countriesOptions: any[];
    allCountriesOptions: any[];
    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;
    isAgencyManagmentAllowed: boolean = false;

    static $inject = ['$rootScope', '$scope', 'Auth', '$state', '$uibModal', 'dialogs', 'AgencyTerritory', 'agencyTerritories', 'agencyId','countryOptions'];
    constructor(private $rootScope, private $scope, private Auth, private $state, private $uibModal, private dialogs, private AgencyTerritory, private agencyTerritories, private agencyId, private countryOptions) {

        this.selections = [];
        this.selectedGridItems = [];
        this.isAgencyManagmentAllowed = Auth.isInBusinessArea(BusinessArea.AGENCY_MANAGEMENT);
    }

    add() {
        var data = <any>{
            agencyId: this.agencyId
        };

        return this.$uibModal
            .open({
                templateUrl: 'areas/agency/AgencyDetails/modals/addTerritoryModal.html',
                controller: AgencyTerritoryModalController,
                controllerAs: 'ctrl',
                backdrop: false,
                resolve: {
                    agencyTerritory: {
                        agencyId: this.agencyId,
                        countryCodes: this.agencyTerritories.filter(terr => terr.isActive).map(a => a.countryCode),
                        countryOptions: this.countryOptions
                    }
                }
            })
            .result
            .then(() => {
                return this.refresh();
            });
    }


    //delete() {
    //    //var agencyTerritory = this.selections[0];
    //    var agencyTerritoryIds = this.selections.map(a => a.agencyTerritoryId);

    //    var data = { agencyId: this.agencyId, agencyTerritoryIds: agencyTerritoryIds };
    //    //var data = { agencyId: this.agencyId, agencyTerritoryId: this.selections[0].agencyTerritoryId };
    //    this.dialogs.confirm('Delete Agency Territory', `Are you sure that you want to delete territory?`)
    //        .result
    //        .then(() => {
    //            this.AgencyTerritory.remove(data)
    //                .$promise.then(
    //                    (result) => {
    //                        this.refresh();
    //                    },
    //                    () => {
    //                        this.dialogs.error('Internal error', 'Error occured. Please try again after a while.');
    //                    }
    //                )
    //        }
    //        );
    //}

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.agencyTerritories) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.agencyTerritories) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                id: item.id
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.agencyTerritories) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }

    refresh() {
        this.AgencyTerritory.query({ agencyId: this.agencyId })
            .$promise
            .then((data) => {
                this.agencyTerritories = data;

                this.selections = [];
            })
    }
}

angular
    .module('app')
    .controller('AgencyTerritoriesController', AgencyTerritoriesController)