
declare global {

    interface Window {
        attachEvent(event: string, listener: EventListener): boolean;
        detachEvent(event: string, listener: EventListener): void;
    }

    interface Document {
        attachEvent(event: string, listener: EventListener): boolean;
        detachEvent(event: string, listener: EventListener): void;
    }
}

declare var chrome: any;


export class OAuth {
    static popup(url, provider, callback) {
        var opts, wnd, wndTimeout;

        function getMessage(e) {
            if ((url.indexOf(e.origin) != 0 && location.origin != e.origin) || !(e.data && e.data.authType))
                return;
            try { wnd.close(); } catch (e) { }
            //opts.data = e.data;
            return opts.callback(null, { provider: opts.provider, data: e.data });
        }
            
        opts = {
            provider: provider,
            callback: function (e, r) {
                if (window.removeEventListener)
                    window.removeEventListener("message", getMessage, false);
                else if (window.detachEvent)
                    window.detachEvent("onmessage", getMessage);
                else if (document.detachEvent)
                    document.detachEvent("onmessage", getMessage);
                opts.callback = function () { };
                if (wndTimeout) {
                    clearTimeout(wndTimeout);
                    wndTimeout = undefined;
                }
                return callback(e, r);
            }
        };


        if (window.attachEvent)
            window.attachEvent("onmessage", getMessage);
        else if (document.attachEvent)
            document.attachEvent("onmessage", getMessage);
        else if (window.addEventListener)
            window.addEventListener("message", getMessage, false);
        if (typeof chrome != 'undefined' && chrome.runtime && chrome.runtime.onMessageExternal)
            chrome.runtime.onMessageExternal.addListener(function (request, sender, sendResponse) {
                request.origin = sender.url.match(/^.{2,5}:\/\/[^/]+/)[0];
                return getMessage(request);
            });


        wndTimeout = setTimeout(function () {
            opts.callback(new Error('Authorization timed out'));
            try { wnd.close(); } catch (e) { }
        }, 1200 * 1000);


        // create popup
        var width = Math.max(350, Math.floor(window.outerWidth * 0.8));
        var height = Math.max(800, Math.floor(window.outerHeight * 0.5));
        var left = window.screenX + (window.outerWidth - width) / 2;
        var top = window.screenY + (window.outerHeight - height) / 8;

        var wnd_options = `width=${width},height=${height},left=${left},top=${top}` +
            `,toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0`;

        wnd = window.open(url, "Authorization", wnd_options);
        if (wnd) {
            wnd.focus();
        } else {
            opts.callback(new Error("Could not open a popup"));
        }
    }
        
}