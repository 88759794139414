 
import _ from 'lodash-es'


const mapStatusToProgress = {
    'upload': 'info',
    'success': 'success',
    'faild': 'danger'
}

class Item {

    file;
    status;

    constructor(private $window, attr) {
        angular.extend(this, attr);
    }

    isImage() {
        if (!angular.isObject(this.file) || !(this.file instanceof this.$window.File))
            return false;
        var type = '|' + this.file.type.slice(this.file.type.lastIndexOf('/') + 1) + '|';
        return '|apng|png|jpg|jpeg|jfif|pjpeg|pjp|bmp|gif|svg|tif|tiff|webp|avif|'.indexOf(type) !== -1;
    }

    isVideo() {
        if (!angular.isObject(this.file) || !(this.file instanceof this.$window.File))
            return false;
        var type = '|' + this.file.type.slice(this.file.type.lastIndexOf('/') + 1) + '|';
        return '|m4v|3gp|nsv|ts|ty|strm|rm|rmvb|m3u|ifo|mov|qt|divx|xvid|bivx|vob|nrg|img|iso|pva|wmv|asf|asx|ogm|m2v|avi|bin|dat|dvr-ms|mpg|mpeg|mp4|mkv|avc|vp3|svq3|nuv|viv|dv|fli|flv|wpl|'.indexOf(type) !== -1;
    }

    progressType = () => mapStatusToProgress[this.status];
}



class UploadStatus {
    static inProgress = "inProgress"
    static upload = "upload"
    static failed = "failed"
}

class DocumentUploadModalController {

    status: UploadStatus;
    items = [];
    document;
    allowedContentType;
    showApproveButton: boolean;

    uploads = [];

    static $inject = ['$rootScope', '$window', '$q', 'dialogs', 'Upload', '$uibModalInstance', 'Auth', 'files', 'documentType', 'documentDefinition', 'uploader', 'hideDescription', 'onUpdateHandler'];
    constructor(private $rootScope, private $window, private $q, private dialogs, private Upload, private $uibModalInstance, Auth, files, private documentType, private documentDefinition, public uploader, public hideDescription, public onUpdateHandler) {

        var user = Auth.user;
        this.document = {
            uploadedBy: user.firstName + ' ' + user.lastName,
            referee: ''
        };

        this.allowedContentType = documentDefinition.allowedContentType + '*';

        this.addItems(files);

        if (this.documentType && this.documentType === 49) {
            this.showApproveButton = false;
        }
    }

    // do something base on uploadDescription

    fileSelected($files, $event) {
        if (!angular.isArray($files) || $files.length === 0)
            return;

        if (this.items.length + $files.length > this.documentDefinition.maximumNumberOfPages ) {
            this.dialogs.error('Too many files selected', 'Too many files selected');
            return;
        }

        var incorectExtension;
        this.addItems($files);

        if (incorectExtension) {
            this.dialogs.error('Wrong type', 'One of the file have wrong type.');
        }
    }

    addItems(files) {
        let fileTooBig = false;
        for (let file of files) {


            if (file.size > this.documentDefinition.maximumPageSize) {
                fileTooBig = true;
                continue;
            }

            

            var extension = file.name.slice(file.name.lastIndexOf('.'));
            if ('|.apng|.png|.jpg|.jpeg|.jfif|.pjpeg|.pjp|.bmp|.gif|.svg|.tif|.tiff|.webp|.avif|.heic|'.indexOf('|' + extension + '|') >= 0) {
                extension = '.webp';
            }
            this.items.push(new Item(this.$window, {
                file: file,
                extension: extension
            }));
        }

        if (fileTooBig) {
            this.dialogs.error('Selected file is too big.', 'Selected file is too big.')
                .result
                .then(() => {
                    if (this.items.length == 0) {
                        this.$uibModalInstance.dismiss();
                    }
                });
        }
    }

    removeItem(item) {
        _.remove(this.items, item);
    }

    abort() {
        for (let item of this.uploads) {
            item.abort();
        }
    }
    uploadItems(uploadDescription) {

        var promises = this.items.filter(item => item.status !== 'success').map((item, i) => {

            let key = uploadDescription.filePattern.replace('[PAGE]', '-' + i) + item.extension;

            item.status = 'upload';
            item.progress = 0;
            var upload = this.Upload.upload({
                url: 'https://' + uploadDescription.bucket + '.s3.amazonaws.com/',
                method: 'POST',
                fields: {
                    key: key,
                    AWSAccessKeyId: uploadDescription.awsAccessKeyId,
                    acl: uploadDescription.acl,
                    policy: uploadDescription.policy,
                    signature: uploadDescription.signature,
                    'Content-Type': item.file.type
                },
                file: item.file
            });
            this.uploads.push(upload);

            return upload
                .then((data, status, headers, config) => {
                    item.status = 'success';
                    return {
                        status: 'success',
                        pageId: i,
                        path: key
                    };
                }, (res) => {

                    this.handleError(res.data);

                    item.status = 'failed';
                    item.progress = 100;
                    this.abort(); // about others upload
                    return {
                        status: 'failed',
                        pageId: i,
                        path: key
                    };
                }, (evt) => {
                    item.progress = Math.round(100.0 * evt.loaded / evt.total);
                });
        });
        return this.$q
            .all(promises)
            .then((uploads: any[]) => {
                if (uploads.filter((f) => f.status === 'failed').length == 0) {
                    this.document.files = uploads;
                    return this.uploader
                        .uploadSuccess(this.document, uploadDescription)
                        .then(uploadResult => {
                            this.uploadDataStateChanged();
                            if (this.onUpdateHandler) {
                                this.onUpdateHandler(uploadResult.uploadState);
                            }
                            return uploadResult;
                        });
                } else {
                    this.status = UploadStatus.failed;
                    var sendFiles = uploads.filter((f) => f.status === 'success').map(f => f.key);
                    return this.uploader
                        .uploadFailed(uploadDescription, { code: 1, message: '', files: sendFiles });
                }
            });
    }

    upload() {
        this.status = UploadStatus.inProgress;

        return this.uploader
            .prepareUpload(this.documentType)
            .then((uploadDescription) => this.uploadItems(uploadDescription))
            .then((document) => {
                this.$uibModalInstance.close(document);
            });
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }

    avaliblePages() {
        return Math.max(0, this.documentDefinition.maximumNumberOfPages - this.items.length);
    }

    isInProgress() {
        return this.status === UploadStatus.inProgress;
    }

    hasFaild() {
        return this.status === UploadStatus.failed;
    }

    uploadDataStateChanged() {
        this.$rootScope.$broadcast('uploadDataStateChanged', true);
    }

    handleError(xml) { 
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(xml, "text/xml");
        let code = xmlDoc.getElementsByTagName("Code")[0].textContent;

        let msg;
        switch (code) {
            case 'EntityTooLarge':
                msg = "File is too big";
                break;
            case 'EntityTooSmall':
                msg = "File is too small";
                break;
            case 'ExpiredToken':
                msg = "Timeout, please try again";
                break;
        }

        this.dialogs.error('Upload Error', msg);
    }
}

angular
    .module('app')
    .controller('DocumentUploadModalController', DocumentUploadModalController);

