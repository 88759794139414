 
import _ from 'lodash-es'
import { Message } from '../models/push/Message'
import ng from 'angular'


class Participant {
    userId: Number
    name: string
    isAuthor: boolean
}

class Conversation {

    id: number
    date: Date
    messages: Message[]
    participants: Participant[]
    lastMessageDate: any
    otherMessages: any
    unreadCount: number
    canSendMessage: boolean

    constructor(private Conversations, private Messages, private $rootScope, data, private isOtherThanOfficerUser: boolean, userId: number) {
        angular.extend(this, data)
        this.canSendMessage = this.participants.some(p => p.userId == userId)
        var others = _.filter(this.messages, (message) => message.other)
        this.otherMessages = {
            count: others.length,
            people: _.uniqBy(others, (message:any) => message.author)
        }
    }

    getLastMessage() {
        if (this.messages && this.messages.length > 0) {
            return _.maxBy(this.messages, (message) => message.id)
        }
        return undefined;
    }

    getLastMessageDate() {
        var lastMessage = this.getLastMessage()
        return lastMessage ? lastMessage.date : new Date(this.lastMessageDate)
    }

    update() {
        //return this.$http.get(`${this.server}conversations/${this.id}`)
        return this.Conversations.get({ id: this.id })
            .$promise
            .then((conversation) => {
                angular.extend(this, conversation)
                this.date = new Date(conversation.date)

                this.$rootScope.$broadcast('commun:update')
            })
    }

    loadMessages(riseNewMessageEvent = false) {
        //var url = `${this.server}conversations/${this.id}/messages`
        //return this.$http.get(url) 
        return this.Messages.queryForConversation({ conversationId: this.id })
            .$promise
            .then((messages) => {
                this.messages = []; //load always updated messages. If message send in between. It should be syncronized.
                for (let m of messages) {
                    this.messages.push(new Message(m))
                    if (riseNewMessageEvent)
                        this.$rootScope.$broadcast('commun:new-message', m)
                }

                //Juno user will not update read only message
                if (this.isOtherThanOfficerUser) {
                    this.$rootScope.$broadcast('commun:update')
                }
            })
    }

    addMessage(msg) {
        if (!this.messages) {
            return undefined;
        }

        var message = new Message(msg)
        this.messages.push(message)

        if (message.other) {
            var other = this.otherMessages
            other.count++
            if (!_.includes(other.people, message.author)) {
                other.people.push(message.author)
            }
        }
        return message
    }

    sendMessage(text) {
        //return this.$http.post(`${this.server}messages`, { text: text, conversationId: this.id })
        return this.Messages.save({ text: text, conversationId: this.id })
            .$promise
            .then((newAddedMessage) => {
                this.addMessage(newAddedMessage)
            })
    }

    clearUnreadMessages = function () {
        //return this.$http.post(`${this.server}conversations/${this.id}/clearUnreadCount`)
        return this.Conversations.clearUnreadCount({id: this.id}).$promise
    }
}

class Communication {
    private promise
    conversations: Conversation[]
    applicantUserId: number

    constructor(private Conversations, private Messages, private $q, private $rootScope, private userId: number, private isOtherThanOfficerUser: boolean) {
        this.conversations = []
    }

    addConversation(conversation) {
        //this.promise = this.$http.post(`${this.server}conversations`, conversation)
        this.promise = this.Conversations.save(conversation)
            .$promise
            .then((conversation) => {
                var newConversation = new Conversation(this.Conversations, this.Messages, this.$rootScope, conversation, this.isOtherThanOfficerUser, this.userId)
                this.conversations.push(newConversation)
                this.$rootScope.$broadcast('addedNewConversation', newConversation)
                return newConversation
            })

        return this.promise
    }

    conversation(conversationId) {
        return _.find(this.conversations, { id: conversationId })
    }

    updateConversation(conversationId) {
        this.conversation(conversationId).update()
    }

    loadConversation(conversationId) {
        this.conversation(conversationId).loadMessages()
    }

    loadConversations() {
        this.conversations = []
            
        //this.promise = this.$http.get(`${this.server}conversations`)
        this.promise = this.Conversations.query()
            .$promise
            .then((conversations) => {
                if (conversations) {
                    angular.forEach(conversations, (conversation) => {
                        if (!this.conversation(conversation.id)) {
                            this.conversations.push(new Conversation(this.Conversations, this.Messages, this.$rootScope, conversation, this.isOtherThanOfficerUser, this.userId))
                        }
                    })
                }
                return this
            })

        return this.promise
    }

    loadApplicationConversations(applicantUserId: number, monthsBack?: number) {

        
        //let route = `${this.server}conversations?userId=${applicantUserId}`;
        //if (monthsBack && _.isNumber(monthsBack)) {
        //    route += `/${monthsBack}`;
        //}

        //return this.$http.get(route)
        var query: any = { userId: applicantUserId }
        if (monthsBack && _.isNumber(monthsBack)) {
            query.monthsBack = monthsBack;
        }

        return this.Conversations.query(query)
            .$promise
            .then((conversations) => {
                if (conversations) {
                    return conversations.map(conversation => new Conversation(this.Conversations, this.Messages, this.$rootScope, conversation, this.isOtherThanOfficerUser, this.userId));
                }
                return [];
            })
    }

    totalUnreadMessages() {
        return _.reduce(this.conversations, (sum, conversation) => sum + conversation.unreadCount, 0)
    }
}



class communicationProvider implements ng.IServiceProvider {

    private static communication

    $get = ['Conversations', 'Messages', '$q', '$rootScope', 'Auth', function (Conversations, Messages, $q, $rootScope, Auth) {

        if (!this.communication) {


            this.communication = new Communication(Conversations, Messages, $q, $rootScope, Auth.user.userId, this.isOtherThanOfficerUser)

            $rootScope.$on('auth:user-change', (event, user) => {
                if (!user.isOfficer && user.isLoggedIn) {
                    this.communication.userId = user.userId;
                    this.communication.loadConversations()
                }
            });

            if (!Auth.user.isOfficer && Auth.user.isLoggedIn) {
                this.communication.loadConversations();
            }
            //else
            //{
            //    //Message for juno user/ Officer
            //    $rootScope.$on('auth:application-message', (event, userId) => {
            //    //getting applicant user Id from message controller
            //        this.communication.userId = userId;
            //        this.communication.loadApplicationConversations(userId)
            //    });
            //}
        }

        return this.communication;
    }]
}

angular
    .module('app')
    .provider('Communication', communicationProvider)

