 
import moment from 'moment';
import { OAuth } from './OAuth';
import { SiteUser, ApplicantRole } from './siteUser';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export enum BusinessArea {
    PRE_PLACEMENT				= 1,
    POST_PLACEMENT			    = 2,
    FINANCE					    = 3,
    REPORTING					= 4,
    AIFS_GERMANY				= 5,
    AGENCY_ONLINE				= 6,
    MARKETING					= 11,
    AU_PAIR_MANAGEMENT		    = 12,
    ONLINE_APPLICATIONS		    = 15,
    AU_PAIR_STAFF_LONDON		= 16,
    FINANCE_APPROVALS			= 17,
    STAMFORD 					= 19,
    CONTENT_AUTHORS			    = 20,
    ITEP_ADMINS				    = 21,
    FINANCE_ADMINISTRATOR		= 22,
    AGENCY_CONTRACT_MANAGEMENT  = 23,
    LONDON_SYSTEMS              = 24,
    AGENCY_MANAGEMENT           = 25,
    AGENCY_CONTRACT             = 26,
    COUNTRY_MANAGEMENT          = 27,
    FLIGHT_MANAGEMENT           = 28,
    BULK_EXPORT                 = 29,
    OFFICE_USERS_MANAGEMENT     = 30,
    COMMUNICATIONS              = 31,
    LEGAL = 32,
    AGREEMENTS_MANAGEMENT = 33,
    AGENCY_COMPETITOR = 34
}

export class Auth {
    static RoleNames = {
        // these keys match the names from the user roleType table in the db
        ROLE_PRE: 'PRE',
        ROLE_PREINTERVIEW: 'PRE_INT',
        ROLE_APPLICANT: 'APP',
        ROLE_INTERVIEWER: 'INT',
        ROLE_BRANCHMANAGER: 'BRA',
        ROLE_AGENCY: 'AGY',
        ROLE_OFFICE: 'OFF',
        ROLE_PUBLIC: 'PUB'
    };

    static defaults = {
        endpoint: '',
        signin: '/auth/signin',
        signinAsUser: '/auth/signinAsUser',
        signup: '/auth/signup',
        signupI: '/auth/signupI',
        signout: '/auth/signout',
        validToken: '/auth/validToken',
        forgot: '/auth/pass/forgot',
        reset: '/auth/pass/reset',
        password: '/auth/pass/password',
        change: '/auth/pass/change',
        oauthSignip: '/externalAuthentication/redirect/',
        oauthSignup: '/oauth/signup',
        termsAndConditions: '',
        termsAndConditionsAgreed: '',
        termsAndConditionsTmpl: '',
        termsAndConditionsAgreement: '',
        selectAgency: '',
        token: '/auth/token',
        termsAndConditionsRegistration: ''
    }

    private oauthInfo = {};

    private anonymousUser: SiteUser = {
        userName: '',
        role: Auth.RoleNames.ROLE_PUBLIC,
        targetRole: Auth.RoleNames.ROLE_PUBLIC
    };

    user: SiteUser = {};
    authChannel: BroadcastChannel
    isLocked: boolean
    refreshTokenTimeout

    static $inject = ['$http', '$window', '$document', '$location', '$resource', '$rootScope', '$interval', '$q', '$sce', 'localStorageService', 'dialogs', 'ShownMessage', 'AuPairRequestInterceptor', 'ApplicationInsights'];
    constructor(private $http, private $window, private $document, private $location, private $resource, private $rootScope, private $interval, private $q, private $sce, private localStorageService, private dialogs, private ShownMessage, private AuPairRequestInterceptor, private ApplicationInsights) {

        this.changeUser(localStorageService.get('user') || this.anonymousUser);

        this.isLocked = false;
        if ($window.BroadcastChannel) {
            this.authChannel = new BroadcastChannel('authChannel');
            this.authChannel.onmessage = (event) => {
                this.isLocked = true;
                this.$rootScope.$broadcast("appLocked");
            };
        }

        this.setRenewTokenTimeout();
    }

    private changeUser(user, broadcast = true) {

        angular.forEach(this.user, (value, name) => {
            delete this.user[name];
        });

        angular.extend(this.user, user);
        if (!user.role) {
            user.role = Auth.RoleNames.ROLE_PUBLIC;
            user.targetRole = Auth.RoleNames.ROLE_PUBLIC
        }

        this.setupRole();
        
        if (broadcast) {
            this.$rootScope.$broadcast('auth:user-change', this.user);
        }
    }

    private validateExpiration(expiresIn) {

        var now = new Date();
        this.ApplicationInsights.trackTrace({
            message: 'Validate Expiration',
            severityLevel: SeverityLevel.Warning,
            properties: {
                expiresIn: expiresIn,
                moment: moment().unix(),
                valueOf: now.valueOf(),
                getTime: now.getTime(),
                toISOString: now.toISOString()
            }
        });

        return moment().unix() < expiresIn
    }

    private setRenewTokenTimeout() {
        // on active we refresh token 30 seconds before it expires
        this.$interval(() => {
            if (!this.$document[0].hidden && this.user.expiresIn && !this.validateExpiration(this.user.expiresIn - 30)) {
                this.refreshToken()
            }
        }, 10000); // check every 10 second
    }

    private setupRole() {
        let role = this.user.targetRole;

        var roleDetails = <ApplicantRole>this.user.roleDetails;
        if (roleDetails && this.user.isUserQualified === true) {
            if (roleDetails.hasLatestTermsAndConditions === false || roleDetails.agencySelected === false) {
                role = 'PRE';
            }
            else if (roleDetails.preInterviewComplete == false) {
                role = 'PRE_INT'
            }
        }

        this.user.role = role;
        this.setUserRoles(this.user);
    }

    private displayPopup() {
        var roleDetails = this.user.roleDetails
        if (roleDetails && roleDetails.messages) {
            for (let message of roleDetails.messages) {
                this.dialogs.notify('Message', this.$sce.trustAsHtml(message.content), { backdrop: false, keyboard: false })
                    .result
                    .then(() => {
                        // ShownMessage
                        this.ShownMessage.save({ userId: this.user.userId, messageId: message.messageId});
                    });
            }
        }
    } 

    private setUserRoles(user: SiteUser) {
        if (user.role) {
            switch (user.role) {
                // TODO:: move these to getters
                case Auth.RoleNames.ROLE_PRE:
                case Auth.RoleNames.ROLE_PREINTERVIEW:
                case Auth.RoleNames.ROLE_APPLICANT:
                    user.isApplicant = true;
                    user.isLoggedIn = true;
                    break;
                case Auth.RoleNames.ROLE_INTERVIEWER:
                    user.isInterviewer = true;
                    user.isLoggedIn = true;
                    break;
                case Auth.RoleNames.ROLE_BRANCHMANAGER:
                    user.isBranchManager = true;
                    user.isLoggedIn = true;
                    break;
                case Auth.RoleNames.ROLE_AGENCY:
                    user.isAgent = true;
                    user.isLoggedIn = true;
                    break;
                case Auth.RoleNames.ROLE_OFFICE:
                    user.isOfficer = true;
                    user.isLoggedIn = true;
                    break;
                case Auth.RoleNames.ROLE_PUBLIC:
                    user.isLoggedIn = false;
                    break;
            }
        }
    }

    private loginUser(data, brodcast = true) {

        var user: SiteUser = data.userInfo;

        user.targetRole = user.role;

        this.setupToken(user, data);

        user.usAccessToken = data.usAccessToken;
        user.usAuPairId = data.usAuPairId;

        this.changeUser(user, brodcast);
        this.localStorageService.set('user', this.user);

        this.displayPopup();
            
        return this.$q.when(this.user);

    }

    setupToken(user, data) {
        user.accessToken = data.accessToken;
        user.expiresIn = data.expiresIn;

        user.refreshToken = data.refreshToken;
        user.refreshTokenExpiresIn = data.refreshTokenExpiresIn;
    }



    private logoutUser() {
        this.localStorageService.remove('user');
        this.changeUser(this.anonymousUser);
    }


    termsAndConditions(acceptedTC: boolean) {
        if (acceptedTC) {
            var r = this.$resource(Auth.defaults.termsAndConditionsAgreed, { userId: '@userId' });
            return r.get({ userId: this.user.userId }).$promise;
        } else {
            var r = this.$resource(Auth.defaults.termsAndConditions, { userId: '@userId' });
            return r.get({ userId: this.user.userId }).$promise;
        }
    }

    termsAndConditionsRegistration() {
        var r = this.$resource(Auth.defaults.termsAndConditionsRegistration);
        return r.get().$promise.then((response) => {
            return response;
        });
    }

    acceptTermsAndConditions(termsId) {
        var r = this.$resource(Auth.defaults.termsAndConditionsAgreement, { termsId: '@termsId', userId: '@userId' });
        return r.save({ termsId: termsId, userId: this.user.userId }, () => {
            var roleDetails = <ApplicantRole>this.user.roleDetails;
            roleDetails.hasLatestTermsAndConditions = true;
            roleDetails.agreedTermsId = termsId;

            this.setupRole();
            this.localStorageService.set('user', this.user);
            this.$rootScope.$broadcast('auth:user-change', this.user);
        }).$promise;
    }

    forDNQ() {
        var roleDetails = <ApplicantRole>this.user.roleDetails;
        roleDetails.hasLatestTermsAndConditions = false;

        this.setupRole();
        this.localStorageService.set('user', this.user);
        this.$rootScope.$broadcast('auth:user-change', this.user);
    }

    selectAgency(agencyId?, interviewerId?, voucherCode?) {
        var r = this.$resource(Auth.defaults.selectAgency, { userId: '@userId', agencyId: '@agencyId', interviewerId: '@interviewerId', voucherCode: '@voucherCode' }, { save: { method:'POST', interceptor: this.AuPairRequestInterceptor } });
        return r.save({ userId: this.user.userId, agencyId: agencyId, interviewerId: interviewerId, voucherCode: voucherCode }, () => {
            var roleDetails = <ApplicantRole>this.user.roleDetails;
            roleDetails.agencySelected = true;

            this.setupRole();
            this.localStorageService.set('user', this.user);
            this.$rootScope.$broadcast('auth:user-change', this.user);
        }).$promise;
    }

    completePreinterview() {
        var roleDetails = <ApplicantRole>this.user.roleDetails;
        roleDetails.preInterviewComplete = true;
        this.setupRole();
        this.localStorageService.set('user', this.user);
        this.$rootScope.$broadcast('auth:user-change', this.user);
    }

    isAuthorize(accessLevel, role) {
        if (angular.isUndefined(role)) {
            role = this.user.role;
        }
        return accessLevel.indexOf(role) >= 0;
    }

    isLoggedIn(user) {
        if (angular.isUndefined(user)) {
            user = this.user;
        }
        return user.role != this.anonymousUser.role;
    }

    private fromJson(data, def) {
        var result = data || def;

        if (data) {
            try {
                result = JSON.parse(data);
            }
            catch (e) { }
        }

        return result;
    }

    signin(data) {

        //default settings
        var signinData = {
            authType: 'password'
        };
        angular.extend(signinData, data);

        return this.$http.post(Auth.defaults.endpoint + Auth.defaults.signin, signinData)
            .then((response) => {
                // success
                if (response.data.needRedirect) {
                    this.$window.location.href = response.data.url;
                } else {
                    return this.loginUser(response.data);
                }
            }, (response) => {
                //error
                if (response.status == 409) {
                    if (response.data.isUserExistsinPhase3) {
                        //same applicant existin phase 3. So redirect to phase 3
                        return this.$q.reject({
                            type: 'redirectToPhase3',
                            title: 'Email Already Registered',
                            message: this.fromJson(response.data, 'Phase 3 applicant')
                        });
                    }
                }
                else if (response.status == 400) {
                    return this.$q.reject({
                        type: 'error',
                        title: 'Log in',
                        message: this.fromJson(response.data, 'Account details not found or incorrect.')
                    });
                } else {
                    return this.$q.reject({
                        type: 'error',
                        title: 'Log in',
                        message: this.fromJson(response.data, 'There was a problem contacting the server. Please try again in a moment.')
                    });
                }
            });
    }

    loginAsUser(roleType, relatedId) {
        var data = {
            roleType: roleType,
            relatedId: relatedId 
        }

        return this.getAccessToken().then(token => {
            var config = {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            };
            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.signinAsUser, data, config)
                .then((response) => {
                    // juno user logout
                    this.logoutUser();

                    //login new applicant
                    if (this.authChannel) {
                        this.authChannel.postMessage({
                            tyee: 'userLoginAsDiffrentUser'
                        });
                    }

                    return this.loginUser(response.data);
                }, (response) => {
                    if (response.status == 400) {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Log in',
                            message: this.fromJson(response.data, 'Account details not found or incorrect.')
                        });
                    } else {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Log in',
                            message: this.fromJson(response.data, 'There was a problem contacting the server. Please try again in a moment.')
                        });
                    }
                });
        })
    }

    signup(user) {
        var hubSpotTrackingId = this.getCookie('hubspotutk');
        user.hubSpotTrackingId = hubSpotTrackingId;
        user.site = this.$location.absUrl();


        return this.$http.post(Auth.defaults.endpoint + Auth.defaults.signup, user)
            .then(() => {
                //success
                var signinData = {
                    login: user.email,
                    password: user.password
                };
                return this.signin(signinData);

            }, (response) => {
                // error
                if (response.status == 409) {

                    if (response.data.redirectUrl) {
                        return this.$q.reject({
                            type: 'redirect',
                            title: 'Sign Up',
                            redirectUrl: response.data.redirectUrl,
                            message: this.fromJson(response.data.message, 'Phase 3 applicant')
                        });
                    }
                    else
                    {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Sign Up',
                            message: this.fromJson(response.data, 'There are errors in your sign up details. Please correct any errors on the form and try again')
                        });
                    }
                } else {
                    return this.$q.reject({
                        type: 'error',
                        title: 'Sign Up',
                        message: this.fromJson(response.data, 'There was a problem contacting the server. Please try again in a moment.')
                    });
                }
            });
    }

    getCookie(name: string): string {
        let cookieValue = '';
        if (document.cookie) {
            const cookies = document.cookie.split(';');
            const cookie = cookies.find(c => c.trim().startsWith(name + '='));
            if (cookie) {
                cookieValue = cookie.split('=')[1];
            }
        }
        return cookieValue;
    }


    getAccessToken() {
        // User not log in
        if (!this.user || !this.user.isLoggedIn) {
            return this.$q.when(undefined);
        }

        //check if token need to be referesh
        var token = this.user.accessToken
        if (!token || (this.user.expiresIn && !this.validateExpiration(this.user.expiresIn - 10))) { // refresh token 10 seconds before it expires
            return this.refreshToken();
        }

        return this.$q.when(token);
    }

    refreshToken() {
        //check localstorage first
        let user = this.localStorageService.get('user');
        if (user && user.expiresIn && this.validateExpiration(user.expiresIn - 30)) {
            this.changeUser(user, false);
            return this.$q.when(user.accessToken);
        }

        // get new token from backend if refresh token didn't expire
        if (this.user.refreshToken && this.user.refreshTokenExpiresIn && this.validateExpiration(this.user.refreshTokenExpiresIn - 10)) {
            return this.$http.get(`${Auth.defaults.endpoint}${Auth.defaults.token}?grant_type=refresh_token&refresh_token=${encodeURIComponent(this.user.refreshToken)}`)
                .then((response) => {
                    var token = response.data.accessToken;
                    if (token) {
                        this.loginUser(response.data, false)
                        return token;
                    } else {
                        this.signout();
                        return this.$q.reject();
                    }
                }, (response) => {
                    this.signout();
                    return this.$q.reject();
                });
        }

        this.signout();
        return this.$q.reject();

    }

    getUSAccessToken() {
        // User not log in
        if (!this.user || !this.user.isLoggedIn) {
            return this.$q.when(undefined);
        }

        return this.$q.when(this.user.usAccessToken);
    }

    signout() {
        this.logoutUser();
    }

    validToken(model) {
        return this.$http.post(Auth.defaults.endpoint + Auth.defaults.validToken, model)
            .then(
            (response) => response.data,
            (response) => response.data);
    }

    forgotPassword(model) {
        return this.$http.post(Auth.defaults.endpoint + Auth.defaults.forgot, model)
            .then(
            (response) => response.data,
            (response) => this.$q.reject(response.data));
    }
    resetPassword(model) {
        return this.$http.post(Auth.defaults.endpoint + Auth.defaults.reset, model)
            .then(
            (response) => response.data,
            (response) => this.$q.reject(response.data));
    }
    changePassword(model) {
        return this.$http.post(Auth.defaults.endpoint + Auth.defaults.password, model)
            .then(
            (response) => response.data,
            (response) => this.$q.reject(response.data));
    }

    private tokenSignin(token) {
        var signinData = {
            authType: 'token',
            provider: 'internal',
            token: token
        };
        return this.signin(signinData);
    }

    oauthSignin(provider, success, error) {
        return this.$q((resolve, reject) => {

            OAuth.popup(Auth.defaults.endpoint + Auth.defaults.oauthSignip + provider, provider, (er, response) => {
                if (response.data.authType == 'internal') {
                    resolve(this.loginUser(response.data));
                } else {
                    this.oauthInfo = response.data;
                    resolve(response);
                }
            });

        });
    }

    oauthSignup(user, token) {
        return this.$http.post(Auth.defaults.endpoint + Auth.defaults.oauthSignup, user)
            .then(() => {
                // success
                var signinData = {
                    authType: 'token',
                    provider: user.oauthProvider,
                    token: token
                };

                return this.signin(signinData);
            }, (response) => {
                //error
                if (response.status == 409) {
                    return this.$q.reject({
                        type: 'error',
                        title: 'Validation error',
                        message: response.headers.message ? response.headers.message : 'Please review the form and try again'
                    });
                } else {
                    return this.$q.reject({
                        type: 'error',
                        title: 'Connection problem',
                        message: 'Please review the form and try again after a while.'
                    });
                }
            });
    }
    getOAuthInfo() {
        return this.oauthInfo;
    }

    updateUser(data) {
        angular.extend(this.user, data);
        this.localStorageService.set('user', this.user);
        this.$rootScope.$broadcast('auth:user-update', this.user);
    }

    isInBusinessArea(businessArea) {
        return this.user.businessAreas.some(ba => ba.id == businessArea);
    }

    isInAnyBusinessAreas(...businessAreas) {
        return this.user.businessAreas.some(ba => businessAreas.some(businessArea => ba.id === businessArea));
    }
}

class AuthProvider {
    auth;
    defaults = Auth.defaults;

    $get = ['$http', '$window', '$document', '$resource', '$location', '$rootScope', '$interval', '$q', '$sce', 'localStorageService', 'dialogs', 'ShownMessage', 'AuPairRequestInterceptor', 'ApplicationInsights',
        function ($http, $window, $document, $resource, $location, $rootScope, $interval, $q, $sce, localStorageService, dialogs, ShownMessage, AuPairRequestInterceptor, ApplicationInsights) {
            if (!this.auth)
                this.auth = new Auth($http, $window, $document, $location, $resource, $rootScope, $interval, $q, $sce, localStorageService, dialogs, ShownMessage, AuPairRequestInterceptor, ApplicationInsights);

            return this.auth;
        }]
}

angular
    .module('app')
    .provider('Auth', AuthProvider);

