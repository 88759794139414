export class CommentModalController {

    data:any = {}

    static $inject = ['$uibModalInstance', '$scope'];
    constructor(private $uibModalInstance, private $scope) {
    }

    save() {
        this.$scope.$broadcast('submitted', true)

        if (this.data.$validation()) {
            this.$uibModalInstance.close(this.data);
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}