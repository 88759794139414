    
class AuthConfig {
    static $inject = ['AuthProvider', 'apiRoutes'];
    constructor(AuthProvider, apiRoutes) {
        var usersServer = apiRoutes.users;
        var authenticationServer = apiRoutes.authentication;
        var registrationServer = apiRoutes.registration;

        AuthProvider.defaults.signin = `${usersServer}users/signin`;
        AuthProvider.defaults.signinAsUser = `${usersServer}users/signinAsUser`;
        AuthProvider.defaults.validToken = `${usersServer}users/validToken`;
        AuthProvider.defaults.forgot = `${usersServer}users/forgot`;
        AuthProvider.defaults.reset = `${usersServer}users/reset`;
        AuthProvider.defaults.password = `${usersServer}users/password`;
        AuthProvider.defaults.signup = `${registrationServer}registration/register`;
        AuthProvider.defaults.signupI = `${registrationServer}registration/register/interviewer`;
        AuthProvider.defaults.oauthSignip = `${authenticationServer}externalAuthentication/redirect/`;
        AuthProvider.defaults.oauthSignup = `${registrationServer}registration/register`;
        AuthProvider.defaults.termsAndConditions = `${usersServer}users/:userId/agreements/last`;
        AuthProvider.defaults.termsAndConditionsAgreed = `${usersServer}users/:userId/agreement/settings`;
        AuthProvider.defaults.termsAndConditionsAgreement = `${usersServer}users/:userId/agreement/:termsId/accept`;
        AuthProvider.defaults.termsAndConditionsRegistration = `${registrationServer}registration/currentTerms/`;

        AuthProvider.defaults.selectAgency = `${usersServer}registration/selectAgency`;

        AuthProvider.defaults.termsAndConditionsTmpl = 'areas/user/termsAndConditions/termsAndConditionsModal.html';


        AuthProvider.defaults.token = `${usersServer}users/oauth/token`;
    }
}

angular
	.module('app')
	.config(AuthConfig);