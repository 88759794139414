import { IAction } from '../IAction'
import { ActionType, ActionTypeItems } from '../../../models/userActions/ActionType'

export class AddVoucher implements IAction {
        
    static $inject = ['$uibModal', 'Application', 'Applicant', 'Voucher', 'applicationId']
    constructor(private $uibModal, private Application, private Applicant, private Voucher, private applicationId) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/addVoucher/addVoucherModal.html',
            controller: AddVoucherActionController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                data: () => <any>{
                    applicationId: this.applicationId
                },
                vouchers: () => this.Voucher.available({ applicationId: this.applicationId}).$promise,
                title: () => ActionTypeItems[ActionType.ADD_VOUCHER].name
            }
        }).result
    }
}

class AddVoucherActionController {

    public submited: boolean

    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'Voucher', 'data', 'title', 'vouchers']
    constructor(private $scope, private dialogs, private $uibModalInstance, private Voucher, private data, private title: string, private vouchers) {
            
    }

    public doAction() {
        this.submited = true;
        this.$scope.$broadcast('submitted', true)


        if (!this.data.promotionId){
            return;
        }

        //if (this.data.$validation()) {
            
            this.Voucher.save(this.data).$promise
                .then((results) => {
                    this.$uibModalInstance.close()
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
                });
        //}
    }

    close() {
        this.$uibModalInstance.dismiss()
    }
}
        