 

function MapFiter(value, mapObject) {
    if (angular.isObject(mapObject)) {
        return mapObject[value] || mapObject[''];
    }

    return;
}

function MapFiterFactory(): Function {
    return MapFiter;
}

angular
    .module('app')
    .filter('map', [MapFiterFactory]);

