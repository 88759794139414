
class ShareFeeSheetController {
        
    static $inject = ['feeSheetInfo']
    constructor(public feeSheetInfo) {
            
    }        
}

angular
    .module('app')
    .controller('ShareFeeSheetController', ShareFeeSheetController)