
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a

export class MenuConfig {
    static $inject = ['MenuProvider'];
    constructor(MenuProvider) {

        MenuProvider.AddMenu('everyone', 'flight', {
            items: [
                {
                    name: 'Options',
                    state: 'app.flight.flightOption',
                    display: 'always',
                    shorthand: 'option'
                },
                {
                    name: 'Flight Request Form',
                    state: 'app.flight.flightRequestForm',
                    display: 'started',
                    shorthand: 'flightHome'
                },
                {
                    name: 'Submit Change Proof',
                    state: 'app.flight.changeVisa',
                    display: 'started',
                    shorthand: 'visa'
                },
                {
                    name: 'Travel Request Form',
                    state: 'app.flight.travelRequestForm',
                    display: 'started',
                    shorthand: 'travelEN'
                }
            ]
        });

    }
}

angular
    .module('app')
    .config(MenuConfig);