
class SettingsController {

    items: any[];
    selected: any;
    displayCountrySettings: any;

    static $inject = ['Auth', 'applicationObservable', '$state', 'OfficeUserAgency'];
    constructor(Auth, applicationObservable, public $state, private OfficeUserAgency) {

        var user = Auth.user;

        OfficeUserAgency.get({ userId: user.userId }).$promise
            .then((data) => {
                this.displayCountrySettings = data.result;

                if (user.isApplicant) {
                    applicationObservable.subscribe(i => {
                        this.items = [
                            {
                                name: 'Change Password',
                                state: 'app.settings.password'
                            }
                        ];
                        if (i.gdpr) {
                            this.items.push({
                                name: 'Communication preferences',
                                state: 'app.settings.marketing'
                            });
                        }
                        this.defineSelected();
                    });
                } else if (user.isOfficer && this.displayCountrySettings) {
                    this.items = [
                        {
                            name: 'Application settings',
                            state: 'app.settings.application'
                        },
                        {
                            name: 'Country settings',
                            state: 'app.settings.country'
                        },
                        {
                            name: 'Change Password',
                            state: 'app.settings.password'
                        }
                    ];
                    this.defineSelected();
                } else if (user.isOfficer && !this.displayCountrySettings) {
                    this.items = [
                        {
                            name: 'Application settings',
                            state: 'app.settings.application'
                        },
                        {
                            name: 'Change Password',
                            state: 'app.settings.password'
                        }
                    ];
                    this.defineSelected();
                } else {
                    this.items = [

                        {
                            name: 'Communication preferences',
                            state: 'app.settings.marketing'
                        },
                        {
                            name: 'Change Password',
                            state: 'app.settings.password'
                        }
                    ];
                    this.defineSelected();
                }
            });
            

    };

    defineSelected() {

        for (var item of this.items) {
            var link = item.linkStage || item.state;
            if (this.$state.includes(link)) {
                this.selected = item;
            }
        }

    }
}

angular
    .module('app')
    .controller('SettingsController', SettingsController);
