import { IAlertConfig } from '../../../models/bootstrap/Alert'

export class AltosPersonalController {

    altosPersonal: any;

    static $inject = ['$scope', 'applicantId', 'altosPersonalPage'];
    constructor(private $scope, private applicantId, altosPersonalPage) {
        this.altosPersonal = altosPersonalPage;
    }
}

angular
    .module('app')
    .controller('AltosPersonalController', AltosPersonalController)