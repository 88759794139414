import _ from 'lodash-es';


export class ContactCommissionsModalController {
    public reason: string;
    public isInterviewer: boolean;

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'commission', 'ContactCommission', '$state', 'Auth'];
    constructor(private dialogs, private $q, private $sce, private $uibModalInstance, private commission, private ContactCommission, private $state, Auth) {
        this.isInterviewer = Auth.user.isInterviewer;  
    }

    save() {
        this.ContactCommission
            .save(this.commission)
            .$promise
            .then(() => {
                this.$uibModalInstance.close();
            }, (response) => {
                    this.displayError(response.data);
            });
    }

    displayError(data) {

        const message = 'Error occured. Please try again after a while.';
        return this.dialogs
            .error('Error', this.$sce.trustAsHtml((data && data.reason) || message))
            .result
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}