import { ResourceGroupEnum } from '../../../models/ResourceGroup';

enum ApplicantResourceType {
    PDF,
    WORD,
    URL
}

class ResourcesGroupController {
    static $inject = ['$stateParams', '$uibModal', 'Resource', 'Auth', 'resourceGroupId'];

    categories: any;
    isAgent: boolean;
    isOfficer: boolean;
    isBranchManager: boolean;
    isInterviewer: boolean;
    isApplicant: boolean;
    isHideAddingResource: boolean;
    disableDeleteButton: boolean;

    agencyId: number;
    thisYear: number;
    nextYear: number;

    ResourceGroupEnum = ResourceGroupEnum;

    constructor($stateParams, private $uibModal, private Resource, private Auth, private resourceGroupId) {
        Resource.query({ groupId: resourceGroupId })
            .$promise
            .then(resp =>  this.categories = resp.categories );

        this.isAgent = Auth.user.isAgent;
        this.isOfficer = Auth.user.isOfficer;
        this.isBranchManager = Auth.user.isBranchManager;
        this.isInterviewer = Auth.user.isInterviewer;
        this.isApplicant = Auth.user.isApplicant;

        this.disableDeleteButton = !this.isOfficer;
        this.isHideAddingResource = ($stateParams.applicationId && this.isOfficer) || !this.isOfficer;

        if (this.isAgent || this.isBranchManager || this.isInterviewer) {
            this.agencyId = Auth.user.roleDetails.agencyId;
            const today = new Date();
            this.thisYear = today.getFullYear();
            this.nextYear = this.thisYear + 1;

        }
    }

    addItem(category) {
        this.$uibModal.open({
            templateUrl: 'areas/resource/resourcesGroup/modals/addResourcesModal.html',
            controller: 'ResourcesGroupModalController as ctrl',
            backdrop: false,
            resolve: {
                resource: () => <any>{
                    categoryId: category.id,
                    readOnlyMode: false,
                    isPrivate: false
                }
            }
        }).result.then((data) => {
            this.Resource.query({ groupId: this.resourceGroupId })
                .$promise
                .then(resp => { this.categories = resp.categories; });
        });
    }

    displayItem(resource) {
        if (!this.isOfficer && resource != null) {
            window.open(resource.url, '_blank');

            return;
        }

        resource.readOnlyMode = true;

        this.$uibModal.open({
            templateUrl: 'areas/resource/resourcesGroup/modals/displayResourcesModal.html',
            controller: 'ResourcesGroupModalController as ctrl',
            backdrop: false,
            resolve: {
                resource: () => resource,
                disableDeleteButton: () => this.disableDeleteButton,
                readOnlyMode: true
            }
        }).result.then((data) => {
            this.Resource.query({ groupId: this.resourceGroupId })
                .$promise
                .then(resp => { this.categories = resp.categories; });
        });
    }

}

angular
    .module('app')
    .controller('ResourcesGroupController', ResourcesGroupController);
