 
import moment, { unitOfTime } from 'moment'

export interface Event {
    id: number
    title: string
    typeId: any
    type: any
    startsAt: any
    endsAt: any
    event: any
}

export class EventManager {

    private dateFormat: string = 'DD/MM/YYYY'

    view: unitOfTime.DurationConstructor = 'month'
    viewTitle: string

    private startDate: moment.Moment
    private endDate: moment.Moment
    viewDate: moment.Moment
    events: Event[]

    loadedEvents: angular.IPromise<Event[]>

    constructor(private Auth, private Event) {
        this.viewDate = moment().startOf('day')
        this.startDate = moment(this.viewDate).subtract(1, 'months').startOf('month')
        this.endDate = moment(this.viewDate).add(1, 'months').startOf('month')

        this.reloadEvents()
    }

    private eventSource(start: moment.Moment, end: moment.Moment): angular.IPromise<any[]> {
        return this.Event.agency({
            id: this.Auth.user.roleDetails.agencyId,
            from: start.format(this.dateFormat),
            to: end.format(this.dateFormat)
        }).$promise
    }

    getUpcomingEvents(): angular.IPromise<Event[]> {
        // write as observables in Angular 2
        return this.loadedEvents.then((events) => {
            return events.filter((e) => {
                return e.startsAt > this.viewDate
            })
        })
    }

    reloadEvents(start: moment.Moment = this.startDate, end: moment.Moment = this.endDate): angular.IPromise<Event[]> {
        this.loadedEvents = this.eventSource(start, end)
            .then((events) => {
                this.events = events.map((e) => {
                    return {
                        id: e.id,
                        title: e.location,
                        typeId: e.entryType,
                        type: e.entryTypeName,
                        startsAt: e.dateStart,
                        endsAt: e.dateEnd,
                        event: e
                    }
                })
                return this.events
            })
        return this.loadedEvents
    }

    //TODO - needs refactor but I'm too tired to do it now
    decrement(): void {
        var newDate = moment(this.viewDate).subtract(1, this.view)
           
        var newStartDate = moment(this.viewDate).subtract(1, 'months').startOf('month')
        var newEndDate = moment(this.viewDate).add(1, 'months').startOf('month')
        this.reloadEvents(newStartDate, newEndDate)
            .then(() => {                    
                this.viewDate = newDate
            })            
    }

    //TODO - needs refactor but I'm too tired to do it now
    increment(): void {
        var newDate = moment(this.viewDate).add(1, this.view)

        var newStartDate = moment(this.viewDate).add(1, 'months').startOf('month')
        var newEndDate = moment(this.viewDate).add(2, 'months').startOf('month')
        this.reloadEvents(newStartDate, newEndDate)
                .then(() => {                        
                    this.viewDate = newDate
                })
    }

}

