 
import { ReviewTranslationDataSourceController } from './reviewTranslationDs'

class ReviewTranslationDirectiveFactory implements ng.IDirective {
    restrict = 'A'
    require = ['^apReviewTranslationDs']
    link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrls) => {
        this.$templateRequest('controls/reviewTranslation/reviewTranslation.html').then((template) => {
            var ctrl: ReviewTranslationDataSourceController = ctrls[0]

            scope.show = () => ctrl.show
            scope.getModel = () => ctrl.model

            var content = this.$compile(template)(scope)
            element.parent().parent().append(content)
        })
    }
    scope = {
        name: '@apReviewTranslation'

    }

    constructor(private $compile: ng.ICompileService, private $templateRequest: ng.ITemplateRequestService) {
    }
}

angular
    .module('app')
    .directive('apReviewTranslation', ['$compile', '$templateRequest', ($compile: ng.ICompileService, $templateRequest: ng.ITemplateRequestService) => new ReviewTranslationDirectiveFactory($compile, $templateRequest)])