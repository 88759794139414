import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class LookupFactory extends DataAccessFactory {

    constructor() {
        super()
        this.server = 'lookup'

        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'Lookup',
                url: 'lookups',
                actions: {
                    allergies: { method: 'GET', url: 'lookups/allergies', isArray: true, cache: true },
                    animalAllergies: { method: 'GET', url: 'lookups/animal_allergies', isArray: true, cache: true },
                    country: { method: 'GET', url: 'lookups/country', isArray: true, cache: true },
                    countryApp: { method: 'GET', url: 'lookups/country/app', isArray: true, cache: false },
                    childcareExperienceType: { method: 'GET', url: 'lookups/childcare_experience_type', isArray: true, cache: true, transformResponse: this.trans.responseHandler((data) => data.map((e) => <any>{ code: Number(e.code), name: e.name} )) },
                    dialingCodes: { method: 'GET', url: 'lookups/country/dialingCodes/:countryCode', isArray: true, cache: true, transformResponse: this.trans.responseHandler(this.trans.convertToListboxItems('description', 'iddCode')) },
                    dietaryRequirements: { method: 'GET', url: 'lookups/dietary_requirements', isArray: true, cache: true },
                    drivingFrequency: { method: 'GET', url: 'lookups/driving_frequency', isArray: true, cache: true },
                    roadType: { method: 'GET', url: 'lookups/road_type', isArray: true, cache: true },
                    allGenders: { method: 'GET', url: 'lookups/all_genders', isArray: true, cache: true },
                    apexSubCodes: { method: 'GET', url: 'lookups/apex_sub_codes', isArray: true, cache: true },
                    hobbiesAndInterests: { method: 'GET', url: 'lookups/hobbies_interests', isArray: true, cache: true },
                    drivingLicenseType: { method: 'GET', url: 'lookups/driving_license_type/:applicationId', isArray: true, cache: true },
                    infantExperience: { method: 'GET', url: 'lookups/infant_experience', isArray: true, cache: true },
                    languages: { method: 'GET', url: 'lookups/languages', isArray: true, cache: true },
                    maritalStatus: { method: 'GET', url: 'lookups/marital_status', isArray: true, cache: true, transformResponse: this.trans.responseHandler(this.trans.convertToListboxItems('name', 'code')) },
                    medicalConditions: { method: 'GET', url: 'lookups/medical_conditions', isArray: true, cache: true },
                    religions: { method: 'GET', url: 'lookups/religions', isArray: true, cache: true },
                    smokingFrequency: { method: 'GET', url: 'lookups/smoking_frequency', isArray: true, cache: true },
                    specialSkills: { method: 'GET', url: 'lookups/special_skills', isArray: true, cache: true },
                    swimmingLevel: { method: 'GET', url: 'lookups/swimming_level', isArray: true, cache: true },
                    auPairOrganisation: { method: 'GET', url: 'lookups/au_pair_organisation', isArray: true, cache: true },
                    immunisations: { method: 'GET', url: 'lookups/immunisations', isArray: true, cache: true },
                    specialNeed: { method: 'GET', url: 'lookups/special_need', isArray: true, cache: true },
                    householdDuties: { method: 'GET', url: 'lookups/house_chores', isArray: true, cache: true },

                    apexQualifications: { method: 'GET', url: 'lookups/application/:applicationId/apex_qualifications', isArray: true, cache: true },
                    departureGatewaysAvailable: { method: 'GET', url: 'lookups/application/:applicationId/departure_gateways_available', isArray: true, cache: true }
                }
            }
        ]

        this.process(resources)
    }
}

new LookupFactory()

