RemoteValidatorDirective.$inject = ['$http', '$q']
function RemoteValidatorDirective($http, $q){
	function RemoteValidatorLink(scope, elem, attrs, ctrls) {

	    ctrls[0].$asyncValidators.remote = function(modelValue, viewValue){

	        return $q(function(resolve, reject) {

		        $http({
		            url: attrs.remote, 
		            method: 'POST', 
		            data: {value: viewValue}
		        }).then(function(response){
		            if(response.data && response.data.isValid == false){
		            	reject();
		            	return;
		            }
		            resolve();
		        },function(response){
		            if(response.status == 409 || response.data && response.data.isValid == false){
		            	reject();
		            	return;
		            }
		            resolve();
		        });
	        });
	    };
	}

    return {
        require: ['ngModel'],
        restrict: 'A',
        link: RemoteValidatorLink
    };
}

angular
    .module('aifs.metaform')
	.directive('remote', RemoteValidatorDirective);