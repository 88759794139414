
class EducationAndWorkModalController {
        
    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'EducationAndWorkHistory', 'title', 'historyEntry'];
    constructor(private $scope, private dialogs, private $uibModalInstance, private EducationAndWorkHistory, public title: string, public historyEntry) {

    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var isValid = this.historyEntry.$validation();

        if (isValid) {
            this.EducationAndWorkHistory
                .save(this.historyEntry)
                .$promise
                .then(data => {
                    this.$uibModalInstance.close(data);
                }, () => {
                    this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
                });
        }
        //else
        //    alert('Please fix all the error on this page before saving');
    }
}

angular
    .module('app')
    .controller('EducationAndWorkModalController', EducationAndWorkModalController);