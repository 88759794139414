 
import { DataTransformation } from './DataTransformation'
import { Resource, Action, Auth } from './dataAccess'

export interface IReviewableItem {
	note
}

export class DataAccessFactory {

    protected trans: DataTransformation
	server : string
	queryParams
    defaultActions: { [id: string]: Action }

    constructor() {
        this.trans = new DataTransformation()
		this.queryParams = { '$inlinecount': 'allpages' }
	}

    process(resources: Resource[]) {

		var app = angular.module('app')
		angular.forEach(resources, (item) => {

			var actions: { [id: string]: Action } = {
				get: { method: 'GET' },
				save: { method: 'POST' },
				query: { method: 'GET' },								// {method: 'GET', isArray: true},
				remove: { method: 'DELETE', params: { id: '@id' } },	// {method: 'DELETE'},
				delete: { method: 'DELETE' },
				update: { method: 'PUT' }
			}

			angular.extend(actions, this.defaultActions || {} , item.actions || {})

			app.factory(item.name, ['$resource', 'apiRoutes', 'AuPairRequestInterceptor', 'USRequestInterceptor', ($resource, apiRoutes, AuPairRequestInterceptor, USRequestInterceptor) => {
				var serverUrl = apiRoutes[this.server]
				if (!serverUrl)
					throw `apiRoutes service does not define '${this.server}'`

				angular.forEach(actions, (action) => {
					if (action.url) action.url = serverUrl + action.url

					//action.interceptor = {
					//	request: function (config) {
					//		console.log("aa");
					//		return config;
					//	}
					//}

					//action.interceptor = AuPairRequestInterceptor;

					switch (action.auth || item.auth) {
						case Auth.AuPair:
							action.interceptor = AuPairRequestInterceptor;
							break;

						case Auth.US:
							action.interceptor = USRequestInterceptor;
							break;

						case Auth.None:
							break;

						default:
							action.interceptor = AuPairRequestInterceptor;
							break;
                    }
				})

				return $resource(serverUrl + item.url, item.params || {}, actions)
			}])
		})
	}

}
