 
class HubSpotScriptLoaderDirectiveFactory implements ng.IDirective {
    restrict = 'A'
    link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
        const script = this.$window.document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.environment.hubSpotTrackingScript;
        script.async = true;
        script.defer = true;
        script.id = 'hs-script-loader';
        element.context.appendChild(script);

    }
    constructor(private $window: ng.IWindowService, private environment) {
    }
}

angular
    .module('app')
    .directive('hubSpotScriptLoader', ['$window', 'environment', ($window, environment) => new HubSpotScriptLoaderDirectiveFactory($window, environment)])