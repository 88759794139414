import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { Auth, BusinessArea } from '../../../services/AuthService';

export class FinancialPeriodModal {

    alerts: IAlertConfig[] = []
    editMode: boolean;
    financeAdminArea: boolean;

    static $inject = ['$uibModalInstance', 'Auth', 'dialogs', '$scope', 'FinancialPeriods', 'financialPeriod', 'openViewMode'];
    constructor(private $uibModalInstance, Auth, private dialogs, private $scope, private FinancialPeriods, private financialPeriod, private openViewMode) {

        this.financeAdminArea = Auth.isInBusinessArea(BusinessArea.FINANCE_ADMINISTRATOR);
        if (this.financeAdminArea && this.financialPeriod.canEdit && openViewMode) {
            this.editMode = true;
        } else {
            this.editMode = false;
        }
    }

    saveFinancialPeriod() {
        this.$scope.$broadcast('submitted', true);
        if (this.financialPeriod.$validation()) {
            this.FinancialPeriods.save(this.financialPeriod)
                .$promise.then(
                    () => {
                        this.$uibModalInstance.close();
                    },
                    () => {
                        this.dialogs.error('Internal error', 'Error occured while saving financial period. Please try again after a while.');
                    }
                );
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}
