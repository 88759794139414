    
class VoucherController {
    voucherData: any = {};
    isOnlineOrAwaitingOrRegister: boolean;

    static $inject = [ 'applicationObservable'];
    constructor( applicationObservable) {
        applicationObservable.subscribe(applicationInstance => {
            this.voucherData = {
                applicationId: applicationInstance.applicationId,
                agencyId: applicationInstance.agencyId,
                userId: applicationInstance.userId
            }

            this.isOnlineOrAwaitingOrRegister = (applicationInstance.status === 'REG' || applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA');
        });
    }
}

angular
    .module('app')
    .controller('VoucherController', VoucherController)