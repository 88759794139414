import moment from 'moment';
import { ScheduleInterview } from '../action/scheduleInterview/scheduleInterview';
import { EventManager } from '../../controls/calendar/EventManager';

class InterviewerCalendarController {

    static $inject = ['$scope', '$uibModal', 'dialogs', 'Auth', 'Event'];

    eventManager: EventManager;
    scheduleInterviewAction: ScheduleInterview;

    public viewDateEvents: Event[];

    constructor(
        private $scope: angular.IScope,
        private $uibModal,
        private dialogs,
        private Auth,
        private Event) {

        this.eventManager = new EventManager(Auth, Event);

        this.$scope.$watchGroup([() => this.eventManager.events, () => this.eventManager.viewDate],
            (newValues, oldValues) => {
                let events: any[] = newValues[0];
                const startOfDay: moment.Moment = newValues[1];
                const endOfDay: moment.Moment = moment(startOfDay).endOf('day');

                events = events || [];

                this.viewDateEvents = events.filter((d) => !startOfDay.isAfter(d.endsAt) && !endOfDay.isBefore(d.startsAt));
            })
    }

    addInterview() {
        const interviewDate = moment(this.eventManager.viewDate).add(12, 'hours').toDate();
        const scheduleInterviewAction = new ScheduleInterview(this.$uibModal, {
            agencyId: this.Auth.user.roleDetails.agencyId,
            userId: this.Auth.user.userId,
            interviewerId: this.Auth.user.roleDetails.contactId,
            dateStart: interviewDate
        });
        return scheduleInterviewAction
            .execute()
            .then(() => this.eventManager.reloadEvents());
    }

    canInterviewBeScheduled() {
        const today = new Date();
        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate()).valueOf();
        const selectedDate = moment(this.eventManager.viewDate).toDate().valueOf();
        return selectedDate >= date;
    }

    remove(event) {
        return this.dialogs
            .confirm('Please confirm.', 'Please confirm that you want to remove event.')
            .result
            .then(() => this.Event.remove({ id: event.id }).$promise)
            .then(() => this.eventManager.reloadEvents());
    }

    filterAttendees = (attendee) => attendee.id !== this.Auth.user.userId;
    isOwner = (attendee) => attendee.id === this.Auth.user.userId;

}

angular
    .module('app')
    .controller('InterviewerCalendarController', InterviewerCalendarController);
