 

class AgencyProfileDirective implements ng.IDirective {
        restrict = 'AE'
        controller = AgencyProfileeController
        controllerAs = 'acCtrl'
        bindToController = true
        templateUrl = 'controls/AgencyProfile/agencyProfile.html'    
        scope = {
            agencyId: '=',
            contactId: '='
        }               
}

class AgencyProfileeController {
    agencyId: number
    contactId: number
    agencyProfile: any;

    static $inject = ['$scope', 'AgencyBasicDetailsPage', 'Auth', 'dialogs'];
    constructor(private $scope, private AgencyBasicDetailsPage, private Auth, private dialogs) {
    }

    $onInit() {
        this.refresh();
    }

    private refresh() {
        this.AgencyBasicDetailsPage.get({
            agencyId: this.agencyId
            //contactId: this.contactId
        }, (result) => {
            this.agencyProfile = result;
        });
    }
}


angular
    .module('app')
    .directive('agencyProfile', () => new AgencyProfileDirective());
