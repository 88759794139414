
class SidebarDirective implements ng.IDirective {
	restrict = 'AE';
	bindToController = true;
	templateUrl = 'controls/sidebar/sidebar.html';
	transclude = true;
	link = function (scope, element, attrs, ctrl, transclude) {
		element.addClass('sidebar');
		element.find('custom-transclude').replaceWith(transclude());
	}
}

angular
	.module('app')
	.directive('sidebar', () => new SidebarDirective());