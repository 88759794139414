import _ from 'lodash-es'
import { IAction } from '../IAction'
import { ReadmitChangeType } from './ReadmitChangeType'
import { ReadmitChange } from './ReadmitChange'

interface Country {
    code: string;
    name: string;
}

export class Readmit implements IAction {

    static $inject = ['$uibModal', '$rootScope', 'applicationId']
    constructor(private $uibModal, private $rootScope, private applicationId) {
	}

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/readmit/readmitModal.html',
            controller: ReadmitModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId,
                rootScope: () => this.$rootScope
            }
        }).result;
    }
}
    
class ReadmitModalController {
    public changeTypes = ReadmitChangeType;
    public change: ReadmitChangeType = null;
    public countryList: Country[] = [];

    public model: ReadmitChange;

    static $inject = [
        'dialogs',
        '$q',
        '$sce',
        '$uibModalInstance',
        '$filter',
        'applicationId',
        'Readmit',
        'Auth',
        'rootScope',
        'ApplicationLookup'
    ];

    constructor(
        private dialogs,
        private $q: ng.IQService,
        private $sce,
        private $uibModalInstance,
        private $filter,
        private applicationId,
        private Readmit,
        private Auth,
        private rootScope,
        private ApplicationLookup
    ) {
        ApplicationLookup.countryApp(resp => {
            this.countryList = resp;
        });

        this.change = this.changeTypes.BirthDate;
        this.model = {
            birthDate: null,
            gender: 'F',
            countryCode: null
        };
    }

    readmit() {
        if (this.change == null) {
            return this.validationError();
        }

        let pickedCountry = _.find(this.countryList, c => c.code === this.model.countryCode);
        let changeTypesDescriptions = [
            { id: ReadmitChangeType.BirthDate, displayText: 'age', value: this.$filter('age')(this.model.birthDate) },
            { id: ReadmitChangeType.Gender, displayText: 'gender', value: 'female' },
            { id: ReadmitChangeType.ApplicationCountry, displayText: 'application country', value: pickedCountry ? pickedCountry.name : null }
        ];

        let pickedChange = _.find(changeTypesDescriptions, ctd => ctd.id === this.change);
        if (pickedChange == null || pickedChange.value == null) {
            return this.validationError();
        }

        let user = this.Auth.user;
        let data = {
            applicationId: this.applicationId,
            userId: user.userId,
            author: user.firstName + ' ' + user.lastName,
            changeType: this.change,
            changeModel: this.model
        };

        this.dialogs.confirm('Confirmation', `Do you want to change the ${pickedChange.displayText} to ${pickedChange.value}?`)
            .result
            .then(() => this.request(data));
    }

    request(data: any) {
        if (typeof data !== 'object' || data == null) {
            return;
        }

        this.Readmit.update({ applicationId: this.applicationId }, data)
            .$promise
            .then((results) => {
                this.rootScope.$broadcast('changeApplicationStatus', true);
            }, (error) => {
                let reason = 'We are sorry but the application could not be readmit. ';
                reason += error && _.isString(error.data) && error.data.length > 0
                    ? `${error.data}.`
                    : 'Please try again in 5 minutes.';

                return this.dialogs
                    .error('Error', reason)
                    .result
            })
            .finally(() => {
                this.$uibModalInstance.close();
            });
    }

    validationError() {
        return this.dialogs
            .error('Validation error', 'Please choose what is to be changed in order to readmit the application.')
            .result;
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}