 

class ODataParamsProvider implements ng.IServiceProvider {

    $get = function () {

        function ODataParams(currentPage, pageItems, filterBy, filterByFields, orderBy, orderByReverse) {

            var params = <any>{};
            if (orderBy) {
                    params.$orderby = orderBy + (orderByReverse ? ' desc' : '');
            }

            var filters = [];
            for (var key in filterByFields) {
                filters.push('contains(' + key + ',\'' + filterByFields[key] + '\')');
            }
            if (filters.length > 0){
                params.$filter = filters.join(' and ');
            }


            if (pageItems) {
                params.$top = pageItems;
                params.$skip = pageItems * currentPage;
                    
            }
            return params;
        }

        return ODataParams;
    };
}

angular
    .module('app')
    .provider('ODataParams', ODataParamsProvider);
