import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert' 


export class UserModalController {

    alerts: IAlertConfig[] = []
    countriesOptions: any[];
    allCountriesOptions: any[];
    applicableAgenciesOptions: any[];
    allApplicableAgenciesOptions: any[];
    canAddPromotionFee: boolean = false;
    //promotion: any = {};
    editMode: boolean;

    title: string;

    static $inject = ['$uibModalInstance', 'dialogs', '$scope', 'user', 'OfficeUsers'];
    constructor(private $uibModalInstance, private dialogs, private $scope, private user, private OfficeUsers) {
        
        this.title = this.getTitle();

    }

    getTitle() {
        if (!this.user.userId) {
            return "Add User";
        } else {
            return "Edit User";
        }
    }


    deleteUser() {
        this.dialogs.confirm('Delete user', `Are you sure that you want to delete user?`)
            .result
            .then(() => {
                this.OfficeUsers.delete({ userId: this.user.userId })
                    .$promise.then(
                        (result) => {
                            this.$uibModalInstance.close();
                        },
                        () => {
                            this.dialogs.error('Internal error', 'Error occured. Please try again after a while.');
                        }
                    );
            });
    }
        
    saveUser() {
        this.$scope.$broadcast('submitted', true);
        if (this.user.$validation()) {
           
            this.OfficeUsers.save(this.user)
                    .$promise.then(
                        (result) => {
                            this.$uibModalInstance.close();
                        },
                        () => {
                            this.dialogs.error('Internal error', 'Error occured. Please try again after a while.');
                        }
                    );
        }
    }


    close() {
        this.$uibModalInstance.dismiss();
    }
}
