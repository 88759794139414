 
import { ActionItem } from '../../models/userActions/ActionItem'
import { ActionType } from '../../models/userActions/ActionType'
import { CreateInvoice } from '../../areas/action/createInvoice/CreateInvoice'
import { CancelInvoice } from '../../areas/action/cancelInvoice/CancelInvoice'
import { RefundInvoice } from '../../areas/action/refundInvoice/RefundInvoice'
import { RecordPayment } from '../../areas/action/recordPayment/RecordPayment'
import { RecordInsurance } from '../../areas/action/recordInsurance/RecordInsurance'
import { AdjustAccount } from '../../areas/action/adjustAccount/AdjustAccount'
import { AddVoucher } from '../../areas/action/addVoucher/AddVoucher'
import { DeleteVoucher } from '../../areas/action/deleteVoucher/DeleteVoucher'
import { InvalidActionCall } from '../../areas/action/invalidActionCall/InvalidActionCall'
import { UserAction } from '../../services/dataAccess/UserFactory'

class FinancialMenuDirective implements angular.IDirective {
    restrict = 'AE'
    controllerAs = 'ctrl'
    controller = FinancialMenuController
    bindToController = true
    templateUrl = 'controls/financialMenu/financialMenu.html'
    scope = {
        applicationId: '=',
        invoice: '='
    }
}
    

class FinancialMenuController {

    applicationId: number
    invoice: any

    actions: Array<ActionItem>

    static $inject = ['$scope', 'Action', '$injector', '$q', '$uibModal']
    constructor(private $scope: angular.IScope, private Action, private $injector: ng.auto.IInjectorService, private $q: angular.IQService, private $uibModal) {

        $scope.$watch(() => this.applicationId, (entryId) => {
            this.updateActions()
        });

        $scope.$watch(() => this.invoice, (entryId) => {
            this.updateActions()
        });

    }

    $onInit() {
        this.updateActions()
    }


    private updateActions() {
           
        var promisses = [];

        if (this.applicationId) {
            promisses.push(this.Action.available({ entryId: this.applicationId, entryType: 'APPLICATION' }).$promise)
        }
        if (this.invoice) {
            promisses.push(this.Action.available({ entryId: this.invoice.id, entryType: 'INVOICE' }).$promise)
        }
        if (promisses.length == 0) {
            this.actions = [];
            return;
        }

        this.$q
            .all(promisses)
            .then(data => {

                var availableActions = Array.prototype.concat.apply([], data);

                this.actions = availableActions
                    .filter((action: UserAction) => this.getAction(ActionType[action.name]))
                    .map((action: UserAction) => new ActionItem(action))
            })
    }

    actionSelected(action: ActionItem) {

        if (!action.performable) {
            var invalidActionCall = new InvalidActionCall(this.$uibModal, action.actionTypeItem.name, action.errorMessages);
            invalidActionCall.execute();
            return this.$q.reject();
        }

            
        return this.performAction(action).then(() => {
            this.updateActions()
        })
    }

    performAction(action: ActionItem) {
        var def = this.getAction(action.actionType);
        if (!def) {
            return this.$q.reject();
        }

        var inst:any = this.$injector.instantiate(def,
            {
                invoice: this.invoice,
                applicationId: this.applicationId,
                invoiceId: this.invoice ? this.invoice.id : 0
            });
        return inst.execute();
    }

    getAction(type: ActionType): any {
        switch (type) {
            case ActionType.CREATE_INVOICE:
                return CreateInvoice;
            case ActionType.CANCEL_INVOICE:
                return CancelInvoice;
            case ActionType.REFUND_INVOICE:
                return RefundInvoice;
            case ActionType.RECORD_PAYMENT:
                return RecordPayment;
            case ActionType.RECORD_INSURANCE:
                return RecordInsurance;
            case ActionType.ADJUST_ACCOUNT:
                return AdjustAccount;
            case ActionType.ADD_VOUCHER:
                return AddVoucher;
            case ActionType.DELETE_VOUCHER:
                return DeleteVoucher;
        }
    }
}

angular
    .module('app')
    .directive('financialMenu', () => new FinancialMenuDirective())
