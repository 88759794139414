import moment from 'moment'
    
class LocaleConfig {
    static $inject = []
    constructor() {
        moment.locale('en', {
            week: {
                dow: 1 // Monday is the first day of the week
            }
        });
    }
}
    
angular
	.module('app')
    .config(LocaleConfig);