import _ from 'lodash-es'

export class MwlEventsGroupDirective implements ng.IDirective {
    restrict = 'A';
    controllerAs = 'vm';
    controller = MwlEventsGroupController;
    bindToController = true;
    templateUrl = 'controls/calendar/calendarMonthDayEvents.html';
    scope = {
        events: '=mwlEventsGroup'
    };
}

class MwlEventsGroupController {

    events: any;
    groups: any;

    static $inject = ['$scope'];
    constructor($scope) {
        $scope.$watch(() => this.events, (newEvents) => {
            this.groups = _.groupBy(newEvents, (e: any) => e.type);
        })
    }
}
