import { IAlertConfig } from '../../../models/bootstrap/Alert'
class AgencyProfileController {

    agencyData: any;
    $dirty: boolean = false
    alerts: IAlertConfig[] = [];

    static $inject = ['$scope', '$q', 'AgencyData', 'Auth', 'dialogs'];
    constructor(private $scope, private $q: ng.IQService, private AgencyData, private Auth, private dialogs) {
        this.agencyData = this.AgencyData.query({
            agencyId: Auth.user.roleDetails.agencyId
        });

        $scope.$watch(() => this.$scope.agencyProfileForm.$dirty, () => {
            this.$dirty = this.$scope.agencyProfileForm.$dirty;
        })
    }

    save() {
        this.$scope.$broadcast('submitted', true);
            
        var form = this.$scope.agencyProfileForm;

        if (form['AgencyDetails'].$dirty) {
            this.agencyData.metaform = form['AgencyDetails'].$metaformVersion;
            this.AgencyData.update({
                agencyId: this.Auth.user.roleDetails.agencyId
            }, this.agencyData)
            .$promise
            .then(() => {
                form.$setPristine();
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
            });
        }
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}

angular
    .module('app')
    .controller('AgencyProfileController', AgencyProfileController)