import { IAlertConfig } from '../../../models/bootstrap/Alert'
import { BusinessArea } from '../../../services/AuthService';
import _ from 'lodash-es';
import { FlightMessageModalController } from './FlightMessageModalController';

interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}

export class FlightMessagesController {

    editFrom;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public flightMessages: any[];
    params: WorkQueueParams;
    loading: boolean = false;

    selections: any[];
    selectedGridItems: any[];

    allSelected: boolean;

    static $inject = ['$scope', 'Auth', '$q', '$state', '$uibModal', 'dialogs', 'FlightMessages'];
    constructor(private $scope, Auth, private $q, private $state, private $uibModal, private dialogs, private FlightMessages) {

        this.selections = [];
        this.selectedGridItems = [];

        this.params = {
            page: 0,
            pageSize: 50,
            orderBy: 'id',
            descending: true
        };

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.edit(values[0]);
                this.selectedGridItems = [];
            }
        }, true);

        this.refresh();
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.flightMessages) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.flightMessages) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                id: item.id
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.flightMessages) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }

    refresh() {
        this.loading = true;

        new this.FlightMessages.query(this.params, (result) => {
            this.flightMessages = result;

            this.selections = [];

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
        }, () => {
            this.loading = false;
        });
    }

    add() {
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainFlights/flightMessageModal.html',
            controller: FlightMessageModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                flightMessageInfo: { type : 'Ticket' },
                editMode: true
            }
        }).result.then(() => {
            this.refresh();
        });
    }


    edit(flightMessageInfo) {

        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainFlights/flightMessageModal.html',
            controller: FlightMessageModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                flightMessageInfo: this.FlightMessages.get({ id: flightMessageInfo.id}).$promise,
                editMode: true
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    remove() {
        if (this.selections.length == 1) {
            var flightMessageId = this.selections[0].id;
            this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this flight message?')
                .result
                .then(() => {
                    this.FlightMessages
                        .remove({ id: flightMessageId })
                        .$promise
                        .then(() => {
                            this.refresh();
                        }, () => {
                            this.dialogs.error('Error', 'We are sorry but flight message cannot be deleted. Please try again in 5 minutes. If the problem persists, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
                        });
                });
        }
    }

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}


angular
    .module('app')
    .controller('FlightMessagesController', FlightMessagesController);