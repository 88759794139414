import _ from 'lodash-es'

export class LegalOnHoldModalController {

    model: any

    static $inject = ['$scope', '$uibModalInstance', 'dialogs', 'GdprStatus', 'gdprStatus']
    constructor(private $scope, private $uibModalInstance, private dialogs, private GdprStatus, private gdprStatus) {
        gdprStatus.legalOnHold = !gdprStatus.legalOnHold;
        gdprStatus.legalOnHoldComment = '';
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        const isValid = this.gdprStatus.$validation();

        if (isValid) {
            this.GdprStatus
                .save(this.gdprStatus)
                .$promise
                .then((results) => {
                    this.$uibModalInstance.close(this.gdprStatus);
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
                })
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}


angular
    .module('app')
    .controller('LegalOnHoldModalController', LegalOnHoldModalController);

