import { IAlertConfig } from '../../../models/bootstrap/Alert' 


class FeeSheetController {
    feeSheetInformations
    //agencyId: number;
    applicationId: number;
    //contractId: number;
    //isFeeSheetAccepted: boolean;

    alerts: IAlertConfig[] = [];
    agencyContractFees: any[];
    otherFees: any[];
    agencyApexPromotionDiscounts: any[];
    isAccepted: boolean;
    isOfficeUser: boolean;
    isApplicantLoggedIn: boolean;
    selectedFeeSheet: any;

    arrivalDateYear: any;

    static $inject = ['$scope', '$timeout', '$filter', 'Auth', 'dialogs', 'applicationId', 'contractId', 'financialPeriodID', 'applicationObservable', 'FeeSheetInfo', 'ApplicationPlacement'];
    constructor(private $scope, private $timeout, private $filter, Auth, private dialogs, applicationId, private contractId, private financialPeriodID, private applicationObservable, private FeeSheetInfo, private ApplicationPlacement) {
        this.applicationId = applicationId;
        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicantLoggedIn = Auth.user.isApplicant;

        applicationObservable.subscribe(applicationInstance => {
            if (applicationInstance.status === 'PLC') {
                ApplicationPlacement.placementInfo({ applicationId: this.applicationId }, (placementInfo) => {
                    this.arrivalDateYear = placementInfo.arrivalDate.getFullYear();
                    this.refresh();
                });
            } else {
                this.refresh();
            }
        });  
    }

    refreshFees() {

    }

    refresh() {
        return this.FeeSheetInfo.query({ applicationId: this.applicationId })
            .$promise
            .then((results) => {
                if (results) {
                    this.feeSheetInformations = results

                    const preselectedFeeSheet = this.feeSheetInformations.filter(f => f.contractId === this.contractId && f.financialPeriodID === this.financialPeriodID)
                    this.selectedFeeSheet = (preselectedFeeSheet && preselectedFeeSheet.length > 0)
                        ? preselectedFeeSheet[0]
                        : this.feeSheetInformations[0];
                }
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while getting Fee Sheet Information. Please try again after a while.')
            });
    }

    acceptFeeSheet() {
        if (this.selectedFeeSheet.isFeeSheetAccept) {
            this.FeeSheetInfo.accept({ applicationId: this.applicationId }, this.selectedFeeSheet)
                .$promise
                .then(() => {
                    return this.refresh();
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured while accepting fee sheet. Please try again after a while.')
                });
        }
    }
}

angular
    .module('app')
    .controller('FeeSheetController', FeeSheetController)