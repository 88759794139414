import _ from 'lodash-es'
import { IAction } from '../IAction'

export class setFlightDates implements IAction {

    static $inject = ['$uibModal', '$rootScope', 'applicationId', 'earliestFlightDate', 'latestFlightDate']
    constructor(private $uibModal, private $rootScope, private applicationId, private earliestFlightDate, private latestFlightDate) {
	}

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/flightDates/setFlightDatesModal.html',
            controller: SetFlightDatesModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId,
                rootScope: () => this.$rootScope,
                earliestFlightDate: () => this.earliestFlightDate,
                latestFlightDate: () => this.latestFlightDate
            }
        }).result;
    }
}
    
class SetFlightDatesModalController {
    public selectedEarliestFlightDate: any;
    public selectedLatestFlightDate: any;
    public earliestFlightDates: any[];
    public latestFlightDates: any[];

    public earliestFlightMandatory: boolean;
    public latestFlightMandatory: boolean;

    static $inject = [ 'dialogs', '$q', '$sce', '$uibModalInstance', 'applicationId', 'rootScope', 'ApplicationFlightDetails', 'earliestFlightDate', 'latestFlightDate'];
    constructor( private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private applicationId, private rootScope, private ApplicationFlightDetails, private earliestFlightDate, private latestFlightDate) {
        this.loadArrivalDates();
    }

    loadArrivalDates() {
        this.ApplicationFlightDetails.query({ applicationId: this.applicationId }, (response) => {
            this.earliestFlightDates = angular.copy(response);
            this.latestFlightDates = angular.copy(response)

            if (this.earliestFlightDate) {
                var filterFlightDate: any = _.filter(response, { arrivalDateDisplay: this.earliestFlightDate });
                if (filterFlightDate && filterFlightDate.length > 0) {
                    this.selectedEarliestFlightDate = filterFlightDate[0].arrivalDate;
                }
            }

            if (this.latestFlightDate) {
                var filterFlightDate: any = _.filter(response, { arrivalDateDisplay: this.latestFlightDate });
                if (filterFlightDate && filterFlightDate.length > 0) {
                    this.selectedLatestFlightDate = filterFlightDate[0].arrivalDate;
                }
            }
        });
    }

    setFlights() {
        var data = {
            applicationId: this.applicationId,
            flightDateEarliest: this.selectedEarliestFlightDate,
            flightDateLatest: this.selectedLatestFlightDate
        };

        this.ApplicationFlightDetails.save({ applicationId: this.applicationId }, data)
        .$promise
        .then((results) => {
            if (_.some(results, (r: any) => r.success == false)) {
                return this.displayError(results);
            }
            this.rootScope.$broadcast('changeFlightDates', results.flightDateEarliestDisplay, results.flightDateLatestDisplay);

            return results;
        })
        .finally(() => {
            this.$uibModalInstance.close();
        });
    }

    displayError(results) {

        var message = 'The following applicants flight date cannot set, please try again shortly:</br>';
        message += results
            .filter((r) => !r.success)
            .map((r) => `Application ${r.applicationId} - ${r.error}</br>`);

        return this.dialogs
            .error('Issue occur during flight date assign', this.$sce.trustAsHtml(message))
            .result
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
