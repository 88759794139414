 

export class AffixSaveMessageController {

    public elem
    public dirty

    static $inject = ['$scope']
    constructor(private $scope) {
        //$scope.$watch(() => this.dirty, (newVal: boolean, oldVal: boolean) => {
        //    if (!oldVal && newVal) {
        //        setTimeout(() => {
        //            this.elem.affix('checkPosition')
        //        }, 0)
        //    }
        //})
    }
}

function updateWidth(elem) {
    var width = elem.parent().width() || elem.parent().parent().width();
    if (width) {
        elem.width(width);
    }
}

class AffixSaveMessageDirective implements ng.IDirective {
    restrict = 'AE'
    controllerAs = 'ctrl'
    require = 'affixSaveMessage'
    controller = AffixSaveMessageController
    bindToController = true
    link = function (scope, element, attrs, ctrl) {

        let elem: any = $(element);

        elem.addClass('affixSaveMessage')
        updateWidth(elem)
        $(window).resize(() => updateWidth(elem))

        //elem.affix({
        //    offset: {
        //        bottom: () => <any>$('.bs-docs-footer').outerHeight(false)
        //    }
        //});

        ctrl.elem = elem
    }
    scope = {
        dirty: '=affixSaveMessage'
    }
}

angular
    .module('app')
    .directive('affixSaveMessage', () => new AffixSaveMessageDirective())

