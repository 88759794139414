 

class PersonalMenuDirective implements ng.IDirective {
    restrict = 'AEC';
    controllerAs = 'pmCtrl';
    controller = PersonalMenuController;
    bindToController = true;
    templateUrl = 'controls/personalMenu/personalMenu.html';
}

class PersonalMenuController {
        
    isApplicant;
    unreadMessages;
    displayName;
    applicationId;
    isSocial;
    isOfficeUser;
    isLoggedIn;
    acceptedTC;

    static $inject = ['$scope', 'Communication', 'Auth', 'AccessLevel'];
    constructor(private $scope, private Communication, private Auth, private AccessLevel) {

        AccessLevel
            .promise()
            .then((userLevels) => {
            if (userLevels.has('applicant')) {
                this.isApplicant = true;
            }
        });

        $scope.$on('commun:update',() => {
            this.updateTotalUnreadMessages();
        });

        $scope.$on('auth:user-update', () => {
            this.updateUserData(Auth.user);
        });

        this.updateTotalUnreadMessages();
        this.updateUserData(Auth.user);

        this.isLoggedIn = Auth.isLoggedIn();
    }

    private updateUserData(user) {
        this.displayName = `${user.firstName} ${user.lastName}`;
        if (user.roleDetails && user.roleDetails.applicationId)
            this.displayName += ` (${user.roleDetails.applicationId})`;

        this.isSocial = user.isSocial;
        this.isOfficeUser = user.isOfficer;

        if (user.roleDetails) {
            this.acceptedTC = user.roleDetails.hasLatestTermsAndConditions;
        }
    }

    private updateTotalUnreadMessages() {
        this.Communication
            .promise
            .then((communication) => {
                this.unreadMessages = communication.totalUnreadMessages();
            });
    }
        
}


angular
    .module('app')
    .directive('personalMenu',() => new PersonalMenuDirective());