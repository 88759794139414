import { BusinessArea } from '../../services/AuthService';


class ToolsController {

    financeArea: boolean;
    countryManagementArea: boolean;
    flightManagementArea: boolean;
    iTepAdminsArea: boolean;
    userManagementArea: boolean;
    communicationsArea: boolean;
    toggleFinance: boolean = true;
    toggleFlightMaintain: boolean = true;
    toggleCommunications: boolean = true;
    toggleiTepMaintain: boolean = true;
    agreementManagementArea: boolean;
    

    static $inject = ['Auth', '$state'];
    constructor(Auth, $state) {
        this.financeArea = Auth.isInBusinessArea(BusinessArea.FINANCE);
        this.countryManagementArea = Auth.isInBusinessArea(BusinessArea.COUNTRY_MANAGEMENT);

        this.flightManagementArea = Auth.isInBusinessArea(BusinessArea.FLIGHT_MANAGEMENT);
        this.iTepAdminsArea = Auth.isInBusinessArea(BusinessArea.ITEP_ADMINS);

        this.userManagementArea = Auth.isInBusinessArea(BusinessArea.OFFICE_USERS_MANAGEMENT);
        this.communicationsArea = Auth.isInBusinessArea(BusinessArea.COMMUNICATIONS);
        this.agreementManagementArea = Auth.isInBusinessArea(BusinessArea.AGREEMENTS_MANAGEMENT);

        if ($state.current.name === 'app.tools') {
            let state;
            if (this.countryManagementArea)
                state = 'app.tools.country'
            else if (this.userManagementArea)
                state = 'app.tools.users'
            else if (this.communicationsArea)
                state = 'app.tools.maintenanceMessages'
            else if (this.financeArea)
                state = 'app.tools.fees'
            else if (this.flightManagementArea)
                state = 'app.tools.maintainFlights'
            else if (this.iTepAdminsArea)
                state = 'app.tools.iTepImports'

            $state.go(state);
        }
    }

    expandCollapseFinance() {
        this.toggleFinance = !this.toggleFinance;
    }

    expandCollapseFlightMaintain() {
        this.toggleFlightMaintain = !this.toggleFlightMaintain;
    }

    expandCollapseCommunications() {
        this.toggleCommunications = !this.toggleCommunications;
    }

    expandCollapseiTepMaintain() {
        this.toggleiTepMaintain = !this.toggleiTepMaintain;
    }
}

angular
    .module("app")
    .controller("ToolsController", ToolsController);