
export class QuestionRule {

    rulesMatchType
    businessRules

    isValid () {
        var isAlternative = this.rulesMatchType == "or";
        var result = !isAlternative;

        angular.forEach(this.businessRules, function (rule) {
            var isValid = rule.lastValue;

            if (isAlternative) {
                if (isValid) {
                    result = true;
                    return true;
                }
            } else {
                if (!isValid) {
                    result = false;
                    return true;
                }
            }
            return undefined;
        });
        return result;
    };
}
