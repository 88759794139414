import _ from 'lodash-es'

// http://stackoverflow.com/a/6713782
var equals;
equals = function equals( x, y ) {
    // if both x and y are null or undefined and exactly the same
    if ( x === y ) return true;

    if(isEmpty(x) && isEmpty(y)) return true;

    // if they are not strictly equal, they both need to be Objects
    if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;

    // they must have the exact same prototype chain, the closest we can do is test there constructor.
    if ( x.constructor !== y.constructor ) return false;

    for ( var p in x ) {
        // other properties were tested using x.constructor === y.constructor
        if ( ! x.hasOwnProperty( p ) ) continue;

        // allows to compare x[ p ] and y[ p ] when set to undefined
        if ( ! y.hasOwnProperty( p ) ) return false;

        // if they have the same strict value or identity then they are equal
        if ( x[ p ] === y[ p ] ) continue;

        // Numbers, Strings, Functions, Booleans must be strictly equal
        if ( typeof( x[ p ] ) !== "object" ) return false;

        // Objects and Arrays must be tested recursively
        if ( !equals( x[ p ],  y[ p ] ) ) return false;
    }

    for ( p in y ) {
        // allows x[ p ] to be set to undefined
        if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) return false;
    }
    return true;
};

function isEmpty(val) {
    return !val || val == null || /^\s*$/.test(val);
}

AifsDisplayController.$inject = ['$scope', '$element', '$attrs', 'MetaForm']
function AifsDisplayController($scope, $element, $attrs, MetaForm) {
    var displayOldValue = false;

    function updateOptions(currentValue, oldValue, dataAccess) {

        var value;
        if(!displayOldValue) {
            value = currentValue;
        } else {
            value = oldValue;
        }

        if(angular.isDefined(oldValue)){
            if(!equals(currentValue, oldValue)){
                $element.addClass('modyfied');
            } else{
                $element.removeClass('modyfied');
            }

            if(displayOldValue) {
                $element.addClass('old');
            } else {
                $element.removeClass('old');
            }
        }


        if(angular.isUndefined(value)){
            //$element.text(''); JK
            return;
        }

        if(dataAccess) {
            dataAccess(function (data) {

                var result;
                if(angular.isObject(value)) {
                    result = _.reduce(value, function(result, val, key){
                        if(val) {
                            var desc = _.find(data, function(o){ return o.value == key }).description;
                            result.push(desc);
                        }
                        return result;
                    }, []).join(', ');

                } else {
                    var match = _.find(data, {value: value});
                    result = match ? match.description : value;
                }

                // If applicant didnt answer this question previously,
                // instead of clearing it, write 'not answered before'
                if (displayOldValue && (!value || value.length === 0 || angular.equals(value, {})))
                    result = '-- not answered before --';

                $element.text(result);

            });
        } else {
            if (displayOldValue && (!value || value.length === 0 || angular.equals(value, {})))
                value = '-- not answered before --';
            $element.text(value);
        }
    }

    function setupWatch(dataAccess){
        $scope.$watchGroup([$attrs.aifsDisplay, $attrs.aifsDisplay.replace('model.', 'snapshot.')], function(newValues){
            var currentValue = newValues[0];
            var oldValue = newValues[1];

            updateOptions(currentValue, oldValue, dataAccess);

            $element.on("click", function(e) {
                displayOldValue = !displayOldValue;
                updateOptions(currentValue, oldValue, dataAccess);
            });
        });
    }


    if ($attrs.aifsDisplayName) {
        var formCtrl = $element.controller('aifsDisplayForm');
        var metaForm = new MetaForm(formCtrl.Id);
        metaForm.getDataSource($attrs.aifsDisplayName, function (dataSource) {
            if (dataSource) {
                var dataAccess = function(fun) {
                    metaForm.getDropdownOptions(dataSource, $scope.model, fun);
                };
                setupWatch(dataAccess);
            }
        });

    } else if ($attrs.aifsDisplayValues) {
        var data = angular.fromJson($attrs.aifsDisplayValues);
        var dataAccess = function(fun){
            fun(data);
        };
        setupWatch(dataAccess);
    } else {
        setupWatch(undefined);
    }
}

angular
    .module("aifs.metaform")
    .directive("aifsDisplay", function () {
        return {
            restrict: 'A',
            controller: AifsDisplayController
        };
    });
