 

class ApplicationReviewDirective implements ng.IDirective {
    restrict = 'AE'
    controller = ApplicationReviewController
    controllerAs = 'acCtrl'
    bindToController = true
    templateUrl = 'controls/ApplicationReview/applicationReview.html'
    scope = {
        applicationId: '=',
        reviewingByOther: '=?',
        reviewingByMe: '=?',
        reviewingUser: '=?'
    }
}

class ApplicationReviewController {
    applicationId: number
    reviewingByOther: boolean;
    reviewingByMe: boolean;
    reviewingUser: string;
    reviewerTitle: string;

    static $inject = ['$scope', 'Auth', '$q'];
    constructor(private $scope, private Auth, private $q) {
            
        //I created seperated event listner to lister from ApplicationsHub and from throughout application
        //Listen from ApplicationsHub- [applicationReviewStartNotification], [applicationReviewStopNotification],
        $scope.$on('applicationReviewStartNotification', (event, message) => {
            if (this.applicationId === message.applicationId) {
                this.reviewerTitle = '';
                var deferred = this.$q.defer();

                if (message.currentReviewingUserName) {
                    this.reviewingUser = message.currentReviewingUserName;
                    this.reviewerTitle = message.currentReviewingUserName + ' is reviewing';
                }

                if (message.currentReviewingUserId !== Auth.user.userId) {
                    this.reviewingByOther = true;                        
                }
                else {
                    this.reviewingByMe = true;
                }

                //$scope.$apply();
                deferred.resolve();
            }
        });

        $scope.$on('applicationReviewStopNotification', (event, message) => {
            if (this.applicationId === message.applicationId) {
                var deferred = this.$q.defer();

                this.reviewingByOther = false;
                this.reviewingByMe = false;
                this.reviewingUser = '';

                $scope.$apply();
                deferred.resolve();
            }
        });

            //I used this to get notification from only UsersHub
        //Listen from UsersHub- [application - list - review - started], [application - list - review - stopped].
        $scope.$on('application-list-review-started', (event, message) => {
            if (this.applicationId === message.applicationId) {
                this.reviewerTitle = '';
                var ctrl = $scope.acCtrl;

                if (message.currentReviewingUserName) {
                    this.reviewingUser = message.currentReviewingUserName;
                    this.reviewerTitle = message.currentReviewingUserName + ' is reviewing';
                }

                if (ctrl.reviewingByOther != message.reviewingByOther && ctrl.reviewingByMe != message.reviewingByMe) {
                    if (message.currentReviewingUserId !== Auth.user.userId) {
                        ctrl.reviewingByOther = true;
                    }
                    else {
                        ctrl.reviewingByMe = true;
                    }

                    ctrl.$scope.$apply();
                }
            }
        });

        $scope.$on('application-list-review-stopped', (event, message) => {
            if (this.applicationId === message.applicationId) {
                var ctrl = $scope.acCtrl;
                if (ctrl.reviewingByOther != message.reviewingByOther && ctrl.reviewingByMe != message.reviewingByMe) {
                    ctrl.reviewingByOther = false;
                    ctrl.reviewingByMe = false;
                    this.reviewingUser = '';
                this.reviewerTitle = '';

                    ctrl.$scope.$apply();
                }
            }
        });
    }

    $onInit() {
        if (this.reviewingUser) {
            this.reviewerTitle = this.reviewingUser + ' is reviewing';
        }
    }
}


angular
    .module('app')
    .directive('applicationReview', () => new ApplicationReviewDirective());