import { BusinessArea } from '../../../services/AuthService';
import _ from 'lodash-es';

export class PreviewAgreementController {

    static $inject = ['$scope', 'Auth', '$q', 'dialogs', 'agreement'];
    constructor(private $scope, private Auth, private $q, private dialogs, private agreement) {
    }
}


angular
    .module('app')
    .controller('PreviewAgreementController', PreviewAgreementController);