import { DataAccessFactory } from './DataAccessFactory'
import { Auth, Resource } from './dataAccess'

class USAuPairFactory extends DataAccessFactory {        

    constructor() {
        super();            
        this.server = 'usAuPair'

        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'USAuPairs',
                auth: Auth.US,
                url: 'APIAService/v1/AuPairs',
                actions: {                                                
                    canAupairExtend: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/canextend',
                        params: { aupairId: '@aupairId' }
                    },
                    saveContact: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/us-contact',
                        params: { aupairId: '@aupairId' }
                    },
                    getContact: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/us-contact',
                        params: { aupairId: '@aupairId' }
                    },
                    saveProfile: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-profile',
                        params: { aupairId: '@aupairId' }
                    },
                    getChildcareExp: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/matches/us-childcare-experiences',
                        params: { aupairId: '@aupairId' }
                    },
                    saveChildcareExp: {
                        method: 'POST',
                        url: 'APIAService/v1/Matches/:matchId/us-childcare-experience',
                        params: { matchId: '@matchId' }
                    },
                    getExtensionTerm: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-terms',
                        params: { aupairId: '@aupairId' }
                    },
                    saveExtensionTerm: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-terms',
                        params: { aupairId: '@aupairId' }
                    },
                    getExtensionProfile: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-profile',
                        params: { aupairId: '@aupairId' }
                    },
                    saveExtensionProfile: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-profile',
                        params: { aupairId: '@aupairId' }
                    },
                    getChildWilling: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-childcare-willing',
                        params: { aupairId: '@aupairId' }
                    },
                    saveChildWilling: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-childcare-willing',
                        params: { aupairId: '@aupairId' }
                    },
                    getDriving: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/drivinglicense',
                        params: { aupairId: '@aupairId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('data.auPairDrivingLicense.stateExpirationDate', 'data.auPairDrivingLicense.countryExpirationDate')
                            )
                    },
                    saveDriving: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/drivinglicense',
                        params: { aupairId: '@aupairId' }
                    },
                    getExtensionStatus: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-application-status',
                        params: { aupairId: '@aupairId' }
                    },
                    submitChildcareExp: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/us-childcare-experiences-status',
                        params: { aupairId: '@aupairId' }
                    },
                    saveExtensionSameFamily: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-with-samehostfamily',
                        params: { aupairId: '@aupairId' }
                    },
                    getFlightHomeData: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/flight-home-request',
                        params: { aupairId: '@aupairId' }
                    },
                    saveFlightHomeData: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/flight-home-request',
                        params: { aupairId: '@aupairId' }
                    },
                    GetPCA: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/program-compliance-administrator',
                        params: { aupairId: '@aupairId' }
                    },
                    getENFlightData: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/extension-match-travel',
                        params: { aupairId: '@aupairId' }
                    },
                    saveENFlight: {
                        method: 'POST',
                        url: 'APIAService/v1/Matches/:matchId/extension-travel',
                        params: { matchId: '@matchId' }
                    },
                    insuranceData: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/insurance',
                        params: { aupairId: '@aupairId' }
                    },
                    getVisaStatus: {
                        method: 'GET',
                        url: 'APIAService/v1/AuPairs/:aupairId/visa-status',
                        params: { aupairId: '@aupairId' }
                    },
                    saveVisaStatus: {
                        method: 'POST',
                        url: 'APIAService/v1/AuPairs/:aupairId/visa-status',
                        params: { aupairId: '@aupairId' }
                    },
                }
            },
            {
                name: 'ExtensionLookup',
                auth: Auth.US,
                url: '',
                actions: {
                    state: {
                        method: 'GET',
                        url: 'APIAService/v1/lookup/states',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItemsUS('stateDesc', 'stateTypeId')
                        )
                    },
                    nationality: {
                        method: 'GET',
                        url: 'APIAService/v1/lookup/nationality',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItemsUS('nationalityName', 'nationalityCode')
                            )
                    },
                    bestTime: {
                        method: 'GET',
                        url: 'APIAService/v1/lookup/contact-best-times',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItemsUS('description', 'id')
                        )
                    }
                }
            },
            {
                name: 'FlightLookup',
                auth: Auth.US,
                url: '',
                actions: {
                    destinationCity: {
                        method: 'GET',
                        url: 'APIAService/v1/lookup/international-destination-cities',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItemsUS('description', 'obIntlGatewayCode1')
                        )
                    },
                    departureCity: {
                        method: 'GET',
                        url: 'APIAService/v1/lookup/domestic-departure-cities',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItemsUS('description', 'obDomGatewayCode1')
                        )
                    },
                    getFeeTypeAmount: {
                        method: 'GET',
                        url: 'APIAService/v1/lookup/reference-fee-types/:id/latest-fee-amount',
                        params: { id: '@id' }
                    },
                    enTransferCity: {
                        method: 'GET',
                        url: 'APIAService/v1/lookup/departure-cities',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItemsUS('tthfDepPlaceDesc', 'tthfDepPlace1')
                        )
                    },
                }
            }
        ]

        this.process(resources)
    }
}

new USAuPairFactory()
