import { Auth } from '../../../services/AuthService'
import { ContactRole } from '../../../services/siteUser'

interface Activity {
    applicationId: number
    isInterviewerAssigned: boolean
    applicantName: string
    type: number
    activeBy: number
    activeByUserName: string
    activeDate: Date
    objectType: string
    objectId: number
}

enum RefernceType {
    'Character' = 1,
    'Educare',
    'Childcare',
    'Previous Host Family'
}
enum HealthStatusType {
    'Raised' = 1,
    'Ok to proceed by agent' = 2,
    'Flagged for Office review' = 3,
    'OK to proceed by Office' = 4,
    'OK to proceed, with conditions' = 5,
    'More info required' = 6,
    'Cannot Proceed' = 7,
    'Changed answer' = 8
}

class ActivityFeedDirective implements angular.IDirective {
    restrict = 'AE'
    controllerAs = 'ctrl'
    controller = ActivityFeedController
    bindToController = true
    templateUrl = 'areas/home/components/activityFeed.html'
    scope = {
        whenScrolled : '='
    }
}

export class ActivityFeedController {

    $promise

    isInterviewer: boolean;
    isAgent: boolean;
    isBranchManager: boolean;
    isOfficer: boolean;
    j: number = 0;
    activities: Activity[];
    startPage: number = 0;
    endPage: number = 50;

    static $inject = ['$scope', '$q', 'Activity', 'Auth'];
    constructor($scope, private $q, private Activity, private auth: Auth) {

        this.isInterviewer = this.auth.user.role === 'INT';
        this.isAgent = this.auth.user.role === 'AGY';
        this.isBranchManager = this.auth.user.role === 'BRA';
        this.isOfficer = this.auth.user.isOfficer;
        this.activities = [];

        this.loadMore();
    }

    getRefernceTypeName(referenceType: number) {
        return RefernceType[referenceType]
    }

    getHealthStatusTypeName(healthStatus) {
        if (healthStatus) {
            return HealthStatusType[healthStatus.HealthStatusId]
        }
        return '';
    }
     
    loadMore() {
        var role = <ContactRole>this.auth.user.roleDetails
        var deferred = this.$q.defer()
        this.$promise = deferred.promise

        if (this.isInterviewer) {
            this.Activity.interviewer(
                {
                    interviewerId: role.contactId,
                    startPage: this.startPage,
                    endPage: this.endPage
                }).$promise.then(data => this.handleActivityResponse(data));
        }
        else if (this.isAgent) {
            this.Activity.agency(
                {
                    interviewerId: role.contactId,
                    agencyId: role.agencyId,
                    startPage: this.startPage,
                    endPage: this.endPage
                }).$promise.then(data => this.handleActivityResponse(data));
        }
        else if (this.isBranchManager && role.contactId != null) {
            this.Activity.branchManager(
                {
                    interviewerId: role.contactId,
                    agencyId: role.agencyId,
                    startPage: this.startPage,
                    endPage: this.endPage
                }).$promise.then(data => this.handleActivityResponse(data));
        }
        else if (this.isOfficer) {
            this.Activity.officer(
                {
                    interviewerId: role.contactId,
                    agencyId: role.agencyId,
                    startPage: this.startPage,
                    endPage: this.endPage
                }).$promise.then(data => this.handleActivityResponse(data));
        }

        deferred.resolve(this);
    }

    handleActivityResponse(data: any): void {
        $.merge(this.activities, data);

        this.startPage = this.endPage + 1;
        this.endPage += 50;
    }
}   
    

angular
    .module('app')
    .directive('activityFeed', () => new ActivityFeedDirective())

    angular
        .module('app')
        .controller('ActivityFeedController', ActivityFeedController)
        .directive('whenScrolled', [function whenScrolled () {
            return function (scope, elm, attr) {
                var raw:any = angular.element($('#parent'))[0];

            angular.element($('#parent')).bind('scroll', function () {
                if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
                    scope.$apply(attr['whenScrolled']);
                }
            });
        };
    }])

