
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { AccessLevelView } from '../accessLevel';
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessDeniedController } from '../../areas/user/accessDenied/AccessDeniedController';

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        const data: state<AccessLevelView>[] = [
            {
                name: 'register',
                url: '/register',
                views: {
                    content: {
                        anon: {
                            controller: 'RegisterController as regCtrl',
                            template: 'areas/user/account/register.html'
                        }
                    }
                }
            },
            {
                name: 'registerWithVoucher',
                url: '/register/voucher/{voucher}',
                views: {
                    content: {
                        anon: {
                            controller: 'RegisterController as regCtrl',
                            template: 'areas/user/account/register.html'
                        }
                    }
                }
            },
            {
                name: 'registerAgency',
                url: '/register/{agencyId:[0-9]*}',
                views: {
                    content: {
                        anon: {
                            controller: 'RegisterController as regCtrl',
                            template: 'areas/user/account/register.html'
                        }
                    }
                }
            },
            {
                name: 'registerReferral',
                url: '/register/ref/{referrerIdentifier}',
                views: {
                    content: {
                        anon: {
                            controller: 'RegisterController as regCtrl',
                            template: 'areas/user/account/register.html'
                        }
                    }
                }
            },
            {
                name: 'registerInterviewer',
                url: '/register/interviewer/{interviewerId:[0-9]*}',
                views: {
                    content: {
                        anon: {
                            controller: 'RegisterController as regCtrl',
                            template: 'areas/user/account/register.html'
                        }
                    }
                }
            },
            {
                name: 'registerOAuth',
                url: '/registerOAuth',
                views: {
                    content: {
                        anon: {
                            controller: 'RegisterOAuthController as regCtrl',
                            template: 'areas/user/account/register.html'
                        }
                    }
                }
            },
            {
                name: 'registerOAuthAgency',
                url: '/registerOAuth/{agencyId:[0-9]*}',
                views: {
                    content: {
                        anon: {
                            controller: 'RegisterOAuthController as regCtrl',
                            template: 'areas/user/account/register.html'
                        }
                    }
                }
            },
            {
                name: 'brochure',
                url: '/brochure',
                views: {
                    content: {
                        anon: {
                            controller: 'BrochureController as ctrl',
                            template: 'areas/user/brochure/brochure.html'
                        }
                    }
                }
            },

            {
                name: 'login',
                url: '/login',
                views: {
                    content: {
                        anon: {
                            controller: 'SignInController as $ctrl',
                            template: 'areas/user/account/signIn.html'
                        }
                    }
                },
                params: {
                    state: null,
                    params: null
                }
            },
            {
                name: 'magicLink',
                url: '/magicLink/{token}',
                views: {
                    content: {
                        anon: {
                            controller: 'MagicLinkController as $ctrl',
                            template: 'areas/user/account/magicLink.html'
                        }
                    }
                },
                resolve: {
                    token: ['$stateParams', function ($stateParams) {
                        return $stateParams.token;
                    }],
                }
            },
            {
                name: 'forgot',
                url: '/forgot',
                views: {
                    content: {
                        anon: {
                            controller: 'ForgotPasswordController as forgotCtrl',
                            template: 'areas/user/password/forgotPassword.html'
                        }
                    }
                },
                resolve: {

                }
            },
            {
                name: 'reset',
                url: '/reset/{token}',
                views: {
                    content: {
                        everyone: {
                            controller: 'ResetPasswordController as resetCtrl',
                            template: 'areas/user/password/resetPassword.html'
                        }
                    }
                },
                resolve: {
                    token: ['$stateParams', function ($stateParams) {
                        return $stateParams.token;
                    }],
                    validToken: ['Auth', 'token', function (Auth, token) {
                        return Auth.validToken({ token: token });
                    }]
                }
            },
            {
                name: 'settings',
                url: '/settings',
                views: {
                    content: {
                        applicant: {
                            controller: 'SettingsController as ctrl',
                            template: 'areas/user/settings/settings.html'
                        },
                        preloggedin: {
                            controller: 'SettingsController as ctrl',
                            template: 'areas/user/settings/settings.html'
                        },
                        preInterview: {
                            controller: 'SettingsController as ctrl',
                            template: 'areas/user/settings/settings.html'
                        },
                        staff: {
                            controller: 'SettingsController as ctrl',
                            template: 'areas/user/settings/settings.html'
                        }
                    }
                },
                resolve: {
                    settings: ['UserSettings', 'Auth', function (UserSettings, Auth) {
                        return UserSettings.get({ userId: Auth.user.userId }).$promise
                    }]
                },
                states: [
                    {
                        name: 'marketing',
                        url: '/marketing/{action:(?:unsubscribe)}',
                        views: {
                            '': {
                                everyone: {
                                    controller: 'MarketingController as ctrl',
                                    template: 'areas/user/marketing/marketing.html'
                                }
                            }
                        },
                        resolve: {
                            action: ['$stateParams', function ($stateParams) {
                                return $stateParams.action
                            }],
                            marketingPreferences: ['MarketingPreferences', 'Auth', function (MarketingPreferences, Auth) {
                                return MarketingPreferences.get({ applicationId: Auth.user.roleDetails.applicationId })
                                    .$promise;
                            }]
                        },
                        params: {
                            action: { value: null, squash: true }
                        }
                    },
                    {
                        name: 'password',
                        url: '/password',
                        views: {
                            '': {
                                loggedinNonSocial: {
                                    controller: 'ChangePasswordController as changePassCtrl',
                                    template: 'areas/user/password/changePassword.html'
                                },
                                preloggedin: {
                                    controller: 'ChangePasswordController as changePassCtrl',
                                    template: 'areas/user/password/changePassword.html'
                                }
                            }
                        }
                    },
                    {
                        name: 'application',
                        url: '/application',
                        views: {
                            '': {
                                loggedinNonSocial: {
                                    controller: 'ApplicationSettingsController as ctrl',
                                    template: 'areas/user/applicationSettings/applicationSettings.html'
                                }
                            }
                        }
                    },
                    {
                        name: 'country',
                        url: '/country',
                        views: {
                            '': {
                                loggedinNonSocial: {
                                    controller: 'CountrySettingsController as ctrl',
                                    template: 'areas/user/countrySettings/countrySettings.html'
                                }
                            }
                        }
                    }
                ]
            },
            {
                name: 'marketing',
                url: '/marketing/{token}/{action:(?:unsubscribe)}',
                views: {
                    content: {
                        everyone: {
                            controller: 'MarketingController as ctrl',
                            template: 'areas/user/marketing/marketing.html'
                        }
                    }
                },
                resolve: {
                    token: ['$stateParams', function ($stateParams) {
                        return $stateParams.token;
                    }],
                    action: ['$stateParams', function ($stateParams) {
                        return $stateParams.action
                    }],
                    marketingPreferences: ['MarketingPreferencesToken', 'token', function (MarketingPreferencesToken, token) {
                        return MarketingPreferencesToken.get({ token: token })
                            .$promise
                            .then((e) => {
                                e.token = token;
                                return e;
                            });
                    }]
                },
                params: {
                    action: { value: null, squash: true }
                }
            },
            {
                name: 'password',
                url: '/password',
                views: {
                    content: {
                        loggedinNonSocial: {
                            controller: 'ChangePasswordController as changePassCtrl',
                            template: 'areas/user/password/changePassword.html'
                        },
                        preloggedin: {
                            controller: 'ChangePasswordController as changePassCtrl',
                            template: 'areas/user/password/changePassword.html'
                        }
                    }
                }
            },
            {
                name: 'accessDenied',
                url: '/accessDenied',
                views: {
                    content: {
                        controllerAs: '$ctrl',
                        loggedinNonSocial: {
                            controller: AccessDeniedController,
                            template: 'areas/user/accessDenied/accessDenied.html'
                        }
                    }
                },
                params: {
                    reason: null,
                    businessArea: null
                }
            },
            {
                name: 'logout',
                url: '/logout',
                views: {
                    content: {
                        loggedin: {
                            controller: 'SignOutController'
                        }
                    }
                },
                params: {
                    state: null,
                    params: null
                }
            }

        ];

        RoutingProvider.addState(data, 'app');

    }
}

angular
    .module('app')
    .config(RoutingConfig);