
class OrientationController {
    isOnlineOrAwaitingOrToBeMatched: boolean;
    isPlacedOrActive: boolean;
    isPlacedActiveOrRematch: boolean;
    showOrientationInformation: boolean;

    static $inject = ['$scope', 'applicationObservable']
    constructor(private $scope, applicationObservable) {

        applicationObservable.subscribe(applicationInstance => {
            this.isOnlineOrAwaitingOrToBeMatched = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA' || applicationInstance.status === 'TBM');
            this.isPlacedActiveOrRematch = (applicationInstance.status === 'PLC' || applicationInstance.status === 'ACT' || applicationInstance.status === 'REM');
            this.showOrientationInformation = (applicationInstance.status === 'PLC' || applicationInstance.status === 'ACT' || applicationInstance.status === 'INA' || applicationInstance.status === 'AWL' || applicationInstance.status === 'APD'); 
        })
            
    }
}

angular
    .module('app')
    .controller('OrientationController', OrientationController);