// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
 
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

import { ApplicantController } from '../../areas/layout/ApplicatController'
import { AdminController } from '../../areas/layout/AdminController'

import { ApplicantHomeController } from '../../areas/home/ApplicantHomeController'
import { InvalidApplicatHomeController } from '../../areas/home/InvalidApplicatController'

import { ReportsController } from '../../areas/reports/Reports'
import { ReportController } from '../../areas/reports/Report'

import { AgreementController } from '../../areas/user/agreement/AgreementController'
import { ContactCommissionsController } from '../../areas/contact/ContactDetails/ContactCommissionsController';
import { PreInterviewController } from '../../areas/application/preInterview/PreInterviewController';
import { StaffHomeController } from '../../areas/home/StaffHomeController';
import { PreloggedinController } from '../../areas/user/preloggedin/PreloggedinController';
import { SignInController } from '../../areas/user/account/SignInController';



class RoutingConfig {
    static $inject = ['$urlRouterProvider', '$locationProvider', 'RoutingProvider'];
    constructor($urlRouterProvider, $locationProvider, RoutingProvider: RoutingProvider<AccessLevelView>) {

        $locationProvider.html5Mode(true);
        $urlRouterProvider.otherwise('/');

        const data: state<AccessLevelView> = {
            name: 'app',
            abstract: true,
            url: '',
            views: {
                '': {
                    controllerAs: '$ctrl',
                    customer: {
                        controller: ApplicantController,
                        template: 'areas/layout/applicant.html'
                    },
                    staff: {
                        controller: AdminController,
                        template: 'areas/layout/admin.html'
                    },

                }
            },
            resolve: {
                applicationId: ['Auth', function (Auth) {
                    return Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                }],
                applicationObservable: ['ApplicationInstance', 'applicationId', function (ApplicationInstance, applicationId) {
                    if (applicationId) {
                        return ApplicationInstance(applicationId);
                    }
                }],
            },
            states: [
                {
                    name: 'home',
                    url: '/',
                    resolve: {
                        applicationStatus: ['ApplicationStatus', 'applicationId', function (ApplicationStatus, applicationId: number) {
                            if (applicationId) {
                                return ApplicationStatus.get({ applicationId: applicationId }).$promise
                            }
                        }],
                        applicantId: ['applicationObservable', function (applicationObservable) {
                            if (applicationObservable) {
                                return applicationObservable.toPromise().then(a => a.applicantId)
                            }
                        }],
                        review: ['ReviewService', 'Auth', 'applicationObservable', function (ReviewService, Auth, applicationObservable){
                            if (applicationObservable) {
                                return applicationObservable.toPromise().then(a => ReviewService(a.applicationId, Auth.user.userId, Auth.user.role).$promise)
                            }
                        }]
                    },
                    views: {
                        'content': {
                            controllerAs: '$ctrl',
                            anon: {
                                controller: SignInController,
                                template: 'areas/user/account/signIn.html'
                            },
                            invalidApplicant: {
                                controller: InvalidApplicatHomeController,
                                template: 'areas/home/invalidApplicant.html'
                            },
                            applicant: {
                                controller: ApplicantHomeController,
                                template: 'areas/home/applicant.html'
                            },
                            office: {
                                controller: StaffHomeController,
                                template: 'areas/home/office.html'
                            },
                            staff: {
                                controller: StaffHomeController,
                                template: 'areas/home/admin.html'
                            },
                            preloggedin: {
                                controller: PreloggedinController,
                                template: 'areas/user/preloggedin/preloggedin.html'
                            },
                            preInterview: {
                                controller: PreInterviewController,
                                template: 'areas/application/preInterview/preInterview.edit.html'
                            }
                        }
                    },
                    params: {
                        logInRedirect: false
                    }
                },
                {
                    name: 'calendar',
                    url: '/calendar',
                    views: {
                        content: {
                            interviewer: {
                                controller: 'InterviewerCalendarController as ctrl',
                                template: 'areas/calendar/interviewer.html'
                            },
                            branch: {
                                controller: 'InterviewerCalendarController as ctrl',
                                template: 'areas/calendar/interviewer.html'
                            },
                            agency: {
                                controller: 'AdminCalendarController as ctrl',
                                template: 'areas/calendar/admin.html'
                            }
                        }
                    },
                    resolve: {
                        $title: () => 'Calendar'
                    }
                },
                {
                    name: 'profile',
                    url: '/profile',
                    abstract: true,
                    views: {
                        content: {
                            staff: {
                                controller: 'ProfileController as ctrl',
                                template: 'areas/profile/admin.html'
                            }
                        },
                    },
                    resolve: {
                        $title: () => 'My Profile',
                        contactId: ['Auth', function (Auth) {
                            return Auth.user.roleDetails.contactId;
                        }],
                        contactPaymentDetails: ['ContactPaymentDetails', 'contactId', (ContactPaymentDetails, contactId) => {
                            return ContactPaymentDetails.get({ contactId: contactId }).$promise
                        }]
                    },
                    states: [
                        {
                            name: 'myProfile',
                            url: '',
                            views: {
                                '': {
                                    staff: {
                                        controller: 'MyProfileController as ctrl',
                                        template: 'areas/profile/myProfile/admin.html'
                                    }
                                }
                            },
                            resolve: {
                                $title: () => 'My Profile'
                            }
                        },
                        {
                            name: 'agencyProfile',
                            url: '/agencyProfile',
                            views: {
                                '': {
                                    agency: {
                                        controller: 'AgencyProfileController as ctrl',
                                        template: 'areas/profile/agencyProfile/admin.html'
                                    }
                                }
                            },
                            resolve: {
                                $title: () =>  'Agency Profile'
                            }
                        },
                        {
                            name: 'interviewerTraining',
                            url: '/training',
                            views: {
                                '': {
                                    staff: {
                                        controller: 'InterviewerTrainingController as ctrl',
                                        template: 'areas/profile/interviewerTraining/interviewerTraining.html'
                                    }
                                }
                            },
                            resolve: {
                                $title: () => 'Interviewer Training'
                            }
                        },
                        {
                            name: 'contactCommissions',
                            url: '/contactCommissions',
                            views: {
                                '': {
                                    controllerAs: '$ctrl',
                                    staff: {
                                        controller: ContactCommissionsController,
                                        template: 'areas/contact/ContactDetails/contactCommissions.html'
                                    }
                                }
                            },
                            resolve: {
                                $title: () => "Commissions"
                            }
                        }
                    ]
                },
                {
                    name: 'payments',
                    url: '/payments',
                    resolve: {
                    },
                    views: {
                        content: {
                            applicant: {
                                controller: 'PaymentsController as ctrl',
                                template: 'areas/payments/applicant.html'
                            }
                        }
                    },
                    states: [
                        {
                            name: 'invoiceDetails',
                            url: '/Invoice',
                            views: {
                                content: {
                                    applicant: {
                                        controller: 'ApplicantPaymentsController as ctrl',
                                        template: 'areas/payments/invoice/applicant.html'
                                    }
                                }
                            },
                            resolve: {
                                placementInfo: ['ApplicationPlacement', 'applicationId', function (ApplicationPlacement, applicationId) {
                                    return ApplicationPlacement.placementInfo({ applicationId: applicationId }).$promise
                                }],
                                paymentOptions: ['PaymentOptions', 'applicationObservable', 'applicationId', function (PaymentOptions, applicationObservable, applicationId) {
                                    return applicationObservable.toPromise().then(a => {
                                        return PaymentOptions.byAgency({ countryCode: a.countryCode, agencyId: a.agencyId }).$promise;
                                    });
                                }],
                                applicationStatus: ['ApplicationStatus', 'applicationId', function (ApplicationStatus, applicationId: number) {
                                    return ApplicationStatus.get({ applicationId: applicationId }).$promise
                                }],
                                $title: () => 'Payments'
                            }
                        },
                        {
                            name: 'insuranceDetails',
                            url: '/Insurance',
                            views: {
                                content: {
                                    applicant: {
                                        controller: 'ApplicationInsuranceController as ctrl',
                                        template: 'areas/payments/insurance/ApplicationInsurance.html'
                                    }
                                }
                            },
                            resolve: {
                                placementInfo: ['ApplicationPlacement', 'applicationId', function (ApplicationPlacement, applicationId: number) {
                                    return ApplicationPlacement.placementInfo({ applicationId: applicationId }).$promise;
                                }]
                            }
                        },
                        {
                            name: 'successfulPayment',
                            url: '/successfulPayment',
                            views: {
                                content: {
                                    applicant: {
                                        controller: 'PaymentResultController as ctrl',
                                        template: 'areas/payments/paymentResult/paymentResult.html'
                                    }
                                }
                            },
                            resolve: {
                                result: [function () {
                                    return { successful: true } 
                                }]
                            }
                        },
                        {
                            name: 'failedPayment',
                            url: '/failedPayment',
                            views: {
                                content: {
                                    applicant: {
                                        controller: 'PaymentResultController as ctrl',
                                        template: 'areas/payments/paymentResult/paymentResult.html'
                                    }
                                }
                            },
                            resolve: {
                                result: [function () {
                                    return { successful: false }
                                }]
                            }
                        },
                        {
                            name: 'feeSheet',
                            url: '/feeSheet',
                            views: {
                                content: {
                                    applicant: {
                                        template: 'areas/payments/feeSheet/feeSheet.html',
                                        controller: 'FeeSheetController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                $title: () => 'Fee Sheet',
                                financialPeriodID: ['$stateParams', function ($stateParams) {
                                    return $stateParams.financialPeriodID;
                                }],
                                contractId: ['$stateParams', function ($stateParams) {
                                    return $stateParams.contractId;
                                }]
                            },
                            params: {
                                financialPeriodID: null,
                                contractId: null
                            }
                        },
                        {
                            name: 'extensionInsurance',
                            url: '/extensionInsurance',
                            views: {
                                content: {
                                    applicant: {
                                        template: 'areas/payments/extensionInsurance/extensionInsurance.html',
                                        controller: 'ExtensionInsuranceController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                $title: () => 'Extension Insurance'
                            },
                        }
                    ]
                },
                {
                    name: 'reports',
                    url: '/reports',
                    resolve: {
                        reportGroups: ['Report', function (Report) {
                            return Report.query().$promise;
                        }],
                        $title: () => 'Reports'
                    },
                    views: {
                        content: {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: ReportsController,
                                template: 'areas/reports/reports.html'
                            }
                        }
                    },
                    states: [
                        {
                            name: 'display',
                            url: '/:reportId',
                            views: {
                                '': {
                                    controllerAs: '$ctrl',
                                    everyone: {
                                        controller: ReportController,
                                        template: 'areas/reports/report.html'
                                    }
                                }
                            },
                            resolve: {
                                reportId: ['$stateParams', function ($stateParams) {
                                    return $stateParams.reportId
                                }],
                                reportUrl: ['Report', 'reportId', function (Report, reportId) {
                                    return Report.getUrl({ reportId: reportId }).$promise.then(data => data.reportURL);
                                }]
                            },
                            params: {
                                action: { value: null, squash: true }
                            }
                        }
                    ]
                },
                {
                    name: 'termsAndConditions',
                    url: '/termsAndConditions',
                    params: {
                        acceptedTC: null
                    },
                    resolve: {
                        acceptedTC: ['$stateParams', function ($stateParams) {
                            return $stateParams.acceptedTC;
                        }]
                    },
                    views: {
                        content: {
                            everyone: {
                                controller: 'TermsAndConditionsController as tcCtrl',
                                template: 'areas/user/termsAndConditions/termsAndConditions.html'
                            }
                        }
                    }
                },
                {
                    name: 'termsAndConditionsRegistration',
                    url: '/termsAndConditionsRegistration',
                    resolve: {
                        termsAndConditionsRegistration: ['Auth', function (Auth) {
                            return Auth.termsAndConditionsRegistration();
                        }]
                    },
                    views: {
                        content: {
                            anon: {
                                controller: 'TermsAndConditionsRegistrationController as tCtrl',
                                template: 'areas/user/termsAndConditions/termsAndConditionsRegistration.html'
                            }
                        }
                    }
                },
                {
                    name: 'agreement',
                    url: '/agreement/:id',
                    resolve: {
                        agreement: ['Agreement', '$stateParams', function (Agreement, $stateParams) {
                            return Agreement.get({ id: $stateParams.id}).$promise;
                        }]
                    },
                    views: {
                        content: {
                            controllerAs: '$ctrl',
                            office: {
                                controller: AgreementController,
                                template: 'areas/user/agreement/agreement.html'
                            }
                        }
                    }
                },
                {
                    name: 'voucher',
                    url: '/voucher',
                    views: {
                        content: {
                            applicant: {
                                controller: 'VoucherController as ctrl',
                                template: 'areas/user/voucherSettings/voucher.html'
                            }
                        }
                    }
                }                
            ]
        };
        RoutingProvider.addState(data);
    }
}

angular
    .module('app')
    .config(RoutingConfig);