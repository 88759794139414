 

class PromotionFeeDirective implements ng.IDirective {
    restrict = 'AE'
    controller = PromotionFeeController
    controllerAs = 'fee'
    bindToController = true
    templateUrl = 'controls/promotions/promotionFee.html'
    scope = {
        data: '=',
        isEditable: '=',
        currencyCode: '='
        //removePromotionFee: '=',
        //indexPromotion: '='
    }
}

class PromotionFeeController {
    data: any;
    isEditable: boolean;
    currencyCode: string;

    feesList: any[];
    //removePromotionFee: any;
    //indexPromotion: number;

    static $inject = ['Promotion', '$rootScope','$scope'];
    constructor(private Promotion, private $rootScope, $scope) {
            

        //if (this.removePromotionFee && this.indexPromotion) {
        //    this.removePromotionFee();
        //}
        $scope.$watch(()=>this.data.feeId, (newVal, oldVal) => {
            this.$rootScope.$broadcast('amount-changed');
        });
        $scope.$watch(() => this.data.amount, (newValues) => {
            this.$rootScope.$broadcast('amount-changed');
        });
    }

    $onInit() {
        if (this.isEditable) {
            this.data.feeId = "" + this.data.feeId;
        }
        this.Promotion.feesByRegion({ currencyCode: this.currencyCode }, (data) => {
            this.feesList = data;
        });
    }

}

angular
    .module('app')
    .directive('promotionFee', () => new PromotionFeeDirective());