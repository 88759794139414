 

var BindHtmlCompileDirectiveFactory = ['$compile', function BindHtmlCompileDirectiveFactory($compile): ng.IDirective {
       
    function link(scope, element, attrs) {
        scope.$watch(function () {
            return scope.$eval(attrs.bindHtmlCompile);
        }, function (value) {
            element.html(value);
            $compile(element.contents())(scope);
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}]

angular
    .module('app')
    .directive('bindHtmlCompile', BindHtmlCompileDirectiveFactory);