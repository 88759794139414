import moment from 'moment';
import { IAction } from '../IAction'

export class CreateNewContract implements IAction{

    static $inject = ['$uibModal', 'agencyContractData']
    constructor(private $uibModal, private agencyContractData) {
    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/createNewContract/CreateNewContractModal.html',
            controller: CreateNewContractModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                agencyContractData: () => this.agencyContractData,
            }
        }).result;
    }
}

interface ICreateNewContractModalScope extends angular.IScope {
    createNewContractForm: any
}

class CreateNewContractModalController {
    public alerts: Array<Object> = [];
    showMessageText: boolean = false;
    createNewContractData: any = {};

    static $inject = ['$rootScope', '$scope', '$timeout', 'dialogs', '$uibModalInstance', 'agencyContractData', 'AgencyContract'];
    constructor(private $rootScope, private $scope: ICreateNewContractModalScope, private $timeout, private dialogs, private $uibModalInstance, private agencyContractData, private AgencyContract) {

        var message = `NOTE: The new start date will be ${moment(this.agencyContractData.nextContractStartDate).format('DD MMM YYYY')}. This cannot be changed afterwards.`;

        this.createNewContractData = {
            agencyId: this.agencyContractData.agencyId,
            nextContractStartDate: moment(this.agencyContractData.nextContractStartDate).format('DD MMM YYYY'),
            noteForNewContract: message,
            copyPreviousContractFees: true,
            contractId: this.agencyContractData.id
        };
    }

    createNewContract() {
        this.$scope.$broadcast('submitted', true);

        this.AgencyContract.create({ agencyId: this.createNewContractData.agencyId }, this.createNewContractData)
            .$promise
            .then(() => {
                this.$rootScope.$broadcast('new-contract-created', this.createNewContractData);
            }, () => {
                this.$rootScope.$broadcast('contract-create-error', this.createNewContractData);
            })
            .finally(() => {
                this.$scope.createNewContractForm.$setPristine();
                this.$uibModalInstance.close();
            });
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}

