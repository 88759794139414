import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class CalendarFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'calendar'
        this.initialize()
    }


    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'Event',
                url: 'calendar/:id',
                actions: {
                    create: {
                        method: 'POST',
                        url: 'calendar/add'
                    },
                    user: {
                        url: 'calendar/user/:id',
                        params: { id: '@id' },
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('dateStart', 'endDate')
                        )
                    },
                    agency: {
                        url: 'calendar/agency/:id',
                        params: { id: '@id' },
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('dateStart', 'endDate')
                        )
                    },
                    remove: {
                        method: 'DELETE',
                        params: {
                            id: '@id'
                        }
                    }

                }
            }
        ]

        this.process(resources)
    }

}

new CalendarFactory()
