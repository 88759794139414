 

class ITepTestController {

    applicationId
    comment

    static $inject = ['$scope', 'dialogs', 'ITep', 'Auth', '$window']
    constructor(private $scope, private dialogs, private ITep, private Auth, $window) {
    }

    $onInit() {
        this.comment = this.ITep.lastComment({ applicationId: this.applicationId });

    }
}

function ITepTestDirectiveFactory(): ng.IDirective {
    return {
        restrict: 'AE',
        scope: {
            applicationId: '=applicationId'
        },
        controller: ITepTestController,
        controllerAs: 'ctrl',
        bindToController: true,
        templateUrl: 'controls/iTepTest/iTepTest.html'
    }
}

angular
    .module('app')
    .directive('iTepTest', ITepTestDirectiveFactory)