
AifsTextboxController.$inject = ['$scope', '$element', '$attrs']
function AifsTextboxController($scope, $element, $attrs) {
    this.$name = $attrs.name;

    $element.controller('aifsQuestion').$addComponent(this);
}

function AifsTextboxLink(scope, element, attrs, ctrls, transclude) {

    var formController = ctrls[0];
    var modelController = ctrls[1];
    var inputController = ctrls[2];

    inputController.$isValid = function () {
        return modelController.$valid;
    };


    function validation() {
        var property = formController[attrs.name];
        scope.isInvalid = property.$dirty && property.$invalid;
    }
    var propertyExpression = [formController.$name, attrs.name, ""].join(".");
    scope.$parent.$watch(propertyExpression + '$dirty', validation);
    scope.$parent.$watch(propertyExpression + '$invalid', validation);

}

angular
    .module("aifs.metaform")
    .directive("aifsTextbox", function () {
        return {
            restrict: 'A',
            require: ["^form", '?ngModel', 'aifsTextbox'],
            link: AifsTextboxLink,
            controller: AifsTextboxController,
        };
    });