
export class MagicLinkController {
        
    user: any = {};
    currentDate: any;
    isLoginFromAzure: boolean = false;
    maintenanceMessage: any;
    isDisplayMaintenanceMessage: boolean = false;

    static $inject = ['$scope', '$state', 'dialogs', 'Auth', 'token'];
    constructor(private $scope, private $state, private dialogs, private Auth, token) {

        var data = {
            authType: "token",
            provider: "mobile",
            token: token
        }

        this.Auth.signin(data)
            .then(() => {
                this.redirectToHomePage();
            }, (error) => {
                if (error.type == 'redirectToPhase3') {
                    this.dialogs.notify(error.title, 'This email address is already registered. Sign in and complete your application at <a href="http://online.aupairamerica.co.uk/" target="_blank">http://online.aupairamerica.co.uk/</a>');
                }
                else {
                    // Display error code 

                    //this.dialogs[error.type](error.title, error.message);
                }
            });
    }
    

    redirectToHomePage() {
        let targetState = this.$state.params.state;
        let targetParams = this.$state.params.params;

        if (!targetState) {
            if (this.Auth.user.role === 'OFF') {
                targetState = 'app.workQueue';
                targetParams = { queueName: '' };
            } else {
                targetState = 'app.home';
                targetParams = {};
            }
        }
        
        this.$state.transitionTo(targetState, targetParams, { reload: true, inherit: true, notify: true });
    }
}

angular
    .module('app')
    .controller('MagicLinkController', MagicLinkController);