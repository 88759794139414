
class TermsAndConditionsRegistrationController {
    termsText: string;

    static $inject = ['termsAndConditionsRegistration'];
    constructor(public termsAndConditionsRegistration) {
    }
        
}

angular
    .module('app')
    .controller('TermsAndConditionsRegistrationController', TermsAndConditionsRegistrationController);