
class PaymentResultController {
        
    static $inject = ['result']
    constructor(public result) {

    }
}

angular
    .module('app')
    .controller('PaymentResultController', PaymentResultController)