import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class ActivityFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'activity'
        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'Activity',
                url: 'activity/',
                actions: {                        
                    interviewer: {
                        url: 'activity/interviewer/:interviewerId/:startPage/:endPage',
                        params: { interviewerId: '@interviewerId', startPage: '@startPage', endPage: '@endPage' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('activeDate')
                        ),
                        isArray: true
                    },
                    agency: {
                        url: 'activity/agency/:agencyId/:interviewerId/:startPage/:endPage',
                        params: { agencyId: '@agencyId', interviewerId: '@interviewerId', startPage: '@startPage', endPage: '@endPage' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('activeDate')
                        ),
                        isArray: true
                    },
                    branchManager: {
                        url: 'activity/branchManager/:agencyId/:interviewerId/:startPage/:endPage',
                        params: { agencyId: '@agencyId', interviewerId: '@interviewerId', startPage: '@startPage', endPage: '@endPage' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('activeDate')
                        ),
                        isArray: true
                    },
                    officer: {
                        url: 'activity/officer/:startPage/:endPage',
                        params: { startPage: '@startPage', endPage: '@endPage' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('activeDate')
                        ),
                        isArray: true
                    }
                }
            }
        ];
        this.process(resources)
    }
}

new ActivityFactory()
