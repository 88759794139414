import { IAction } from '../IAction'
import { ActionType, ActionTypeItems } from '../../../models/userActions/ActionType'

export class DeleteVoucher implements IAction {

    static $inject = ['dialogs', 'Application', 'Applicant', 'Voucher', 'applicationId']
    constructor(private dialogs, private Application, private Applicant, private Voucher, private applicationId) {

    }

    execute = () => {

        return this.dialogs.confirm(ActionTypeItems[ActionType.DELETE_VOUCHER].name, `Are you sure you want to remove the promotional voucher from this application?`)
            .result
            .then(() => this.Voucher.delete({ applicationId: this.applicationId }));
    }
}
  