import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { UserModalController } from './UserModalController'

interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}

export class UsersController {

    users = []

    alerts: IAlertConfig[] = []

    public promotionPage: { promotions: any[], totalCount: number };
    params: WorkQueueParams;
    loading: boolean = false;
    agencyId: number;

    countrySettings = {}
    financialDetails = {}

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;

    columns;

    static $inject = ['$scope', '$q', 'Spinner', 'OfficeUsers', 'dialogs', '$uibModal'];
    constructor(private $scope, private $q, private Spinner, private OfficeUsers, private dialogs, private $uibModal) {

        this.refresh();

        this.selections = [];
        this.selectedGridItems = [];

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.editUser(values[0]);
                this.selectedGridItems = [];
            }
        }, true);

        this.columns = [
            'check',
            'userId',
            'login',
            'address',
            'name',
            'country'
        ];  
    }

    $onInit() {

    }

    addUser() {
        var data: any = { }
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainUsers/userModal.html',
            controller: UserModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                user: () => data
            }
        }).result.then(() => {
            this.refresh();
        }, () => { });
    }

    editUser(user) {
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainUsers/userModal.html',
            controller: UserModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                user: this.OfficeUsers.get({ userId: user.userId }).$promise
            }
        }).result.then(() => {
            this.refresh();
        }, () => { });
    }


    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.promotionPage.promotions) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.promotionPage.promotions) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                promotionId: item.promotionId
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.promotionPage.promotions) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.promotionId === item.promotionId);
        }
    }

    refresh() {
        this.OfficeUsers.query().$promise.then((data) => {
            this.users = data;
            this.selections = [];
        });
    }

}


angular
    .module('app')
    .controller('UsersController', UsersController);
