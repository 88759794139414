import * as _ from 'lodash'
import { IAction } from '../IAction'

export class HealthStatus implements IAction {

    static $inject = ['$uibModal']
    constructor(private $uibModal, private healthInfo, private applicantUserId = undefined) { }

    execute = () => {

        return this.$uibModal.open({
            templateUrl: 'areas/action/healthStatus/healthStatusModal.html',
            controller: HealthStatusModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                healthInfo: () => this.healthInfo,
                applicantUserId: () => this.applicantUserId
            }
        }).result
    }
}

interface HealthStatuss {
    id: string;
    text: string;
    IsForOfficer: boolean
}

interface IHealthStatusModalScope extends angular.IScope {
    healthStatusForm: any
}

export class HealthStatusModal {
    healthStatusId: number
    comments: string
    officeUserStatus: string
    statusProvidedBy: string
}

class HealthStatusModalController {

    public healthStatusList: HealthStatuss[] = [];
    details = new HealthStatusModal();

    public isEditable: boolean = false;

    static $inject = ['dialogs', '$q', '$scope', '$uibModalInstance', 'ApplicationLookup', 'healthInfo', 'ApplicationReviewHealth']
    constructor(
        private dialogs,
        private $q: angular.IQService,
        private $scope: IHealthStatusModalScope,
        private $uibModalInstance,
        ApplicationLookup,
        private healthInfo,
        private ApplicationReviewHealth) {

        ApplicationLookup.healthStatus(result => {
            this.healthStatusList = result;
        });

        this.setEditable();
    }

    setEditable() {
        this.ApplicationReviewHealth.getLatest({
            applicationId: this.healthInfo.applicationId
        }
        ).$promise
            .then((result) => {
                if (result) {
                    var isAgentStatus = (result.healthStatusId === 2 || result.healthStatusId === 3);
                    if (this.healthInfo.userId === result.userId && result.healthStatusId !== 1) {
                        this.details.healthStatusId = result.healthStatusId;
                        this.details.comments = result.healthComments;
                        this.isEditable = false;
                    }
                    else {
                        this.isEditable = true;
                    }
                    if (this.healthInfo.userId !== result.userId && result.healthStatusId !== 1) {
                        this.details.officeUserStatus = result.healthStatusText;
                        this.details.statusProvidedBy = (isAgentStatus) ? 'Agent' : 'Office';


                        if ((isAgentStatus && this.healthInfo.isAgent) || (!isAgentStatus && this.healthInfo.isOfficer) ){
                            this.details.healthStatusId = result.healthStatusId;
                            this.details.comments = result.healthComments;
                            this.isEditable = false;
                        }
                    }
                                        
                }
            }, (error) => {
                this.dialogs.error('Internal error', 'Error occured while getting Health status. Please try again after a while.');
            })
    }
    //healthStatusListFilter(item) {
    //    return item.isForOfficer === true && item.id !== 1;
    //};

    saveHealthStatus() {
        this.$scope.$broadcast('submitted', true)

        if (this.details.healthStatusId) {
            var form = this.$scope.healthStatusForm
            if (form.$dirty) {

                this.ApplicationReviewHealth.update({
                    userId: this.healthInfo.userId,
                    healthStatusId: this.details.healthStatusId,
                    healthComments: this.details.comments,
                    applicationId: this.healthInfo.applicationId
                }).$promise
                    .then(() => {
                        this.$uibModalInstance.close();
                    }, (error) => {
                        this.dialogs.error('Internal error', 'Error occured while saving Health status. Please try again after a while.')
                    })
            }
        }
        else {
            this.dialogs.error('Validation Error', 'Please provide us more details about the reason you want to set Health status for this Application.');
        }        

    }

    close() {
        this.$uibModalInstance.dismiss()
    }

}