import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert'
import { BusinessArea } from '../../../services/AuthService';
import { FinancialPeriodModal } from './FinancialPeriodModal';


interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}
//const gridSettingsStorageKey = 'financialPeriods-grid-settings';

export class FinancialPeriodsController {

    editFrom;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public financialPeriodsPage: { financialPeriods: any[], totalCount: number };
    params: WorkQueueParams;
    loading: boolean = false;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;

    columns;
    financeAdminArea: boolean;


    static $inject = ['$scope', '$q', '$state', 'Auth', 'FinancialPeriods', 'GridExport', 'dialogs', '$uibModal', 'Agency'];
    constructor(private $scope, private $q, private $state, Auth, private FinancialPeriods, private GridExport, private dialogs, private $uibModal, private Agency) {

        this.financeAdminArea = Auth.isInBusinessArea(BusinessArea.FINANCE_ADMINISTRATOR);

        this.selections = [];
        this.selectedGridItems = [];

        this.params = {
            page: 0,
            pageSize: 50,
            orderBy: 'id',
            descending: true
        };
            
    
        this.columns = [
                'check',
                'startDate',
                'endDate',
                'displayEndDate'
            ];

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.showFinancialPeriods(values[0]);
                this.selectedGridItems = [];
            }
        }, true);
    }

    getFinancialPeriods(currentPage, pageItems, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'id';
            this.params.descending = true;
        }

        this.refresh(false);
    }

    refresh(isShowLastSelectedFinancialPeriods) {
        this.loading = true;

        new this.FinancialPeriods.all(this.params, (result) => {
            this.financialPeriodsPage = result;

            this.lastPage = Math.floor(this.financialPeriodsPage.totalCount / this.params.pageSize);

            if (!isShowLastSelectedFinancialPeriods && this.financialPeriodsPage.financialPeriods) {
                this.selections = [];
            }
            else {
                this.selections = result.financialPeriods.filter(p => this.selections.some(s => s.id === p.id));
            }

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
        }, () => {
            this.loading = false;
        });
    }

    addFinancialPeriods() {
        var data: any = {
            id: 0,
            editMode: true
        }

        this.$uibModal.open({
            templateUrl: 'areas/tools/financialPeriods/financialPeriodModal.html',
            controller: FinancialPeriodModal,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                financialPeriod: () => data,
                openViewMode: false
            }
        }).result.then(() => {
            this.refresh(false);
        }, () => { });
    }

    editFinancialPeriods() {
        var id = this.selections[0].id;
        this.$uibModal.open({
            templateUrl: 'areas/tools/financialPeriods/financialPeriodModal.html',
            controller: FinancialPeriodModal,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                financialPeriod: this.FinancialPeriods.query({ id: id }).$promise,
                openViewMode: false
            }
        }).result.then(() => {
            this.refresh(true);
        }, () => { });
    }

    viewFinancialPeriods() {
        var id = this.selections[0].id;
        this.$uibModal.open({
            templateUrl: 'areas/tools/financialPeriods/financialPeriodModal.html',
            controller: FinancialPeriodModal,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                financialPeriod: this.FinancialPeriods.query({ id: id }).$promise,
                openViewMode: true
            }
        }).result.then(() => {
            this.refresh(true);
        }, () => { });
    }

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.financialPeriodsPage.financialPeriods) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.financialPeriodsPage.financialPeriods) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                id: item.id
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.financialPeriodsPage.financialPeriods) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }

    changePageSize() {
        this.lastPage = Math.floor(this.financialPeriodsPage.totalCount / this.params.pageSize);

        this.refresh(false);
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }

    showFinancialPeriods(financialPeriods) {
        this.$uibModal.open({
            templateUrl: 'areas/tools/financialPeriods/financialPeriodModal.html',
            controller: FinancialPeriodModal,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                financialPeriods: this.FinancialPeriods.query({ id: financialPeriods.id }).$promise
            }
        }).result.then(() => {
            this.refresh(true);
        });
    }

    public export() {
        this.GridExport.saveAsCSV('.applicationsTable', 'financialPeriods.csv', this.selections.length != 0)
    }
}


angular
    .module('app')
    .controller('FinancialPeriodsController', FinancialPeriodsController);