
interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
    filters: {
        country: string[];
        applicationStatus: string[];
    }
}

export class AltosQueueController {

    selectedAltos: any[];
    params: WorkQueueParams;

    loading: boolean = false;
    altosTotalCount: number;
    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;
    altosPage: any;
    selections: any[];
    filterAccordion: any;
    isChecked: number;

    statusList: any[];
    countryList: any[];

    static $inject = ['$rootScope', '$scope', '$q', '$state', 'Auth', 'AltosArchive', 'localStorageService', 'ApplicationLookup', 'GridExport'];
    constructor(private $rootScope, private $scope, private $q, private $state, private Auth, private AltosArchive, private localStorageService, ApplicationLookup, private GridExport) {

        this.selectedAltos = [];

        this.params = {
            page: 0,
            pageSize: 100,
            orderBy: 'applicantId',
            descending: true,
            filters: <any>{
                country: [],
                applicationStatus:[]
            }
        };      

        $scope.$watch(() => this.selectedAltos[0], (values) => {
            if (values) {
                var url = this.$state.href('^.altosInformation.status', { applicantId: values.applicantId });
                window.open(url, '_blank');
            }
        }, true);

        ApplicationLookup.country((data) => {
            this.countryList = data;
        });

        this.statusList = [
            { 'code': 'ACT', 'desc': 'Active' }, { 'code': 'APD', 'desc': 'Au Pair Departed' }, { 'code': 'AWL', 'desc': 'Au Pair AWOL' },
            { 'code': 'CAN', 'desc': 'Cancelled' }, { 'code': 'FAI', 'desc': 'Failed' }, { 'code': 'INA', 'desc': 'Inactive' },
            { 'code': 'REJ', 'desc': 'Rejected' }, { 'code': 'TXP', 'desc': 'Time Expired' }
        ];

        $scope.$watch(() => this.params.filters.applicationStatus, (newval, oldval) => {
            if (newval != oldval)
                this.refresh();
        }, true);

        $scope.$watch(() => this.params.filters.country, (newval, oldval) => {
            if (newval != oldval) {
                this.refresh();
            }
        }, true);
    }

    getAltosArchive(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;

        this.refresh();
    }

    refresh() {
        this.loading = true;

        new this.AltosArchive.all(this.params, (result) => {
            this.altosPage = result;
            this.lastPage = Math.floor(this.altosPage.totalCount / this.params.pageSize);
            this.altosTotalCount = result.totalCount;

            this.selections = [];

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    getGridTemplate() {
        var rootPath = 'areas/altos/grid/';
        return `${rootPath}Default.html`;
    }

    applyFilters() {
        this.refresh();
    }

    changePageSize() {
        this.lastPage = Math.floor(this.altosPage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }

    public export() {
        this.GridExport.saveAsCSV('.altosArchiveTable', 'export.csv', this.selections.length != 0)
    }
}
angular
    .module('app')
    .controller('AltosQueueController', AltosQueueController);
