 

class LocalFeeDirective implements ng.IDirective {
    restrict = 'AE'
    controller = LocalFeeController
    controllerAs = 'fee'
    bindToController = true
    templateUrl = 'controls/agencyContracts/localFee.html'
    scope = {
        agencyId: '=',
        localFeeData: '=',
        isEditable: '='
    }
}

class LocalFeeController {
    agencyId: number
    localFeeData: any;
    whenPayableList: any[];
    isEditable: boolean;
    currencyList: any[];
    canAddEditNewFee: boolean;

    static $inject = ['$scope', 'Auth', '$q', 'AgencyLookup', 'FinanceLookup'];
    constructor(private $scope, private Auth, private $q, private AgencyLookup, private FinanceLookup) {

        AgencyLookup.whenPayable(resp => {
            this.whenPayableList = resp;

            var payableWhenId = this.localFeeData.payableWhenId;
            if (!payableWhenId) {
                payableWhenId = "0";
            }
            this.localFeeData.payableWhenId = "" + payableWhenId;
        });

        FinanceLookup.allCurrency(resp => {
            this.currencyList = resp;
        });
    }

    $onInit() {
        this.canAddEditNewFee = (this.Auth.user.isOfficer && this.isEditable && this.localFeeData.isNewFee);

       
    }
}

angular
    .module('app')
    .directive('localFee', () => new LocalFeeDirective());