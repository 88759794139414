import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService';
import { ReviewPaymentsKind } from './ReviewPaymentsKind';
import _ from 'lodash-es';

interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}

export class ReviewPaymentsController {
    //ReviewPaymentsKind = ReviewPaymentsKind
    reviewPayments;

    editFrom;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public reviewPaymentsPage: { reviewPayments: any[], totalCount:number};
    params: WorkQueueParams;
    //loading: boolean = false;
    selections: any[];
    selectedGridItems: any[];

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;

    allSelected: boolean;

    dataAccess;

    showSuppressed: boolean;

    static $inject = ['$scope', 'Auth', 'Spinner', '$timeout', '$q', '$state', 'dialogs', '$uibModal', 'GridExport', 'kind', 'CommissionPayments', 'AgencyContributions'];
    constructor(private $scope, private Auth, private Spinner, private $timeout, private $q, private $state, private dialogs, private $uibModal, private GridExport, private kind: ReviewPaymentsKind, private CommissionPayments, private AgencyContributions) {

        this.selections = [];
        this.selectedGridItems = [];

        this.params = {
            page: 0,
            pageSize: 50,
            orderBy: 'id',
            descending: true
        };

        switch (this.kind) {
            case ReviewPaymentsKind.Commission:
                this.dataAccess = this.CommissionPayments;
                break;
            case ReviewPaymentsKind.Maketing:
                this.dataAccess = this.AgencyContributions;
                break;
        }

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {

                this.selectedGridItems = [];
            }
        }, true); 
    }

    getGeneratedReviewPayments(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'id';
            this.params.descending = true;
        }

        this.refresh();
    }

    refresh() {
        var params = angular.extend({}, this.params, { showSuppressed: this.showSuppressed });

        this.dataAccess.all(params, (result) => {
            this.setCommonPropertyForRefresh(result);
        });
    }

    setCommonPropertyForRefresh(result) {
        this.reviewPaymentsPage = result;

        this.lastPage = Math.floor(this.reviewPaymentsPage.totalCount / this.params.pageSize);

        this.selections = [];

        //this.loading = false;
        this.$scope.$broadcast("grid_refresh");
    }

    approve() {
        if (this.selections && this.selections.length > 0) {
            this.dialogs.confirm('Alert', 'Are you sure you want to Approve these payments?')
                .result
                .then(() => {
                    this.Spinner.show();
                    return this.dataAccess
                        .approve({ paymentIds: this.selections.map(p => p.id) })
                        .$promise
                        .then((r) => {
                            this.checkIfAnyApprovalFailed(r);
                            this.refresh();
                        }, () => this.onRequestFaild())
                        .finally(() => {
                            this.$timeout(() => {
                                this.Spinner.hide();
                            }, 1000);
                        });
                });
        }
    }

    suppress() {
        if (this.selections && this.selections.length > 0) {
            this.dialogs.confirm('Alert', 'Are you sure you want to Suppress these payments?')
                .result
                .then(() => {
                    this.Spinner.show();
                    this.dataAccess
                        .suppress({ paymentIds: this.selections.map(p => p.id) })
                        .$promise
                        .then((r) => this.refresh(), () => this.onRequestFaild())
                        .finally(() => {
                            this.$timeout(() => {
                                this.Spinner.hide();
                            }, 1000);
                        })

                });
        }
    }

    generate() {
        this.Spinner.show();
        this.dataAccess
            .generate()
            .$promise
            .then((r) => this.refresh(), () => this.onRequestFaild())
            .finally(() => {
                this.$timeout(() => {
                    this.Spinner.hide();
                }, 1000);
            });
    }

    checkIfAnyApprovalFailed(results: any[]) {
        var failedApprovals = results.filter(r => r.status != "success");
        if (failedApprovals.length > 0) {
            this.dialogs.error('Error', 'Some of approvals failed.')
        }
    }

    onRequestFaild() {
        this.dialogs.error('Internal error', 'Error occured. Please try again after a while.');
    }


    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            if (!_.includes(this.selections, { id: item.id })) {
                this.selections.push(item);

                for (let reviewPayment of this.reviewPaymentsPage.reviewPayments) {
                    if (!reviewPayment.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let reviewPayment of this.reviewPaymentsPage.reviewPayments) {
                reviewPayment.checked = true;
                this.selections.push(reviewPayment);
            }
        } else {
            for (let reviewPayment of this.reviewPaymentsPage.reviewPayments) {
                reviewPayment.checked = false;
            }
        }
    }


    public export() {
        this.GridExport.saveAsCSV('.reviewPaymentTable', 'export.csv', this.selections.length != 0)
    }

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }

    changePageSize() {
        this.lastPage = Math.floor(this.reviewPaymentsPage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }

    toggleShowSuppressed() {
        this.showSuppressed = !this.showSuppressed;
        this.refresh();
    }
}


angular
    .module('app')
    .controller('ReviewPaymentsController', ReviewPaymentsController);