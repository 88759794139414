import _ from 'lodash-es'
import { IAction } from '../IAction'

export class setTXPDates implements IAction {

    static $inject = ['$uibModal', '$rootScope', 'applicationId', 'TXPDate']
    constructor(private $uibModal, private $rootScope, private applicationId, private TXPDate) {
	}

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/TXPDates/setTXPDatesModal.html',
            controller: SetTXPDatesModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId,
                rootScope: () => this.$rootScope,
                txpDate: () => angular.copy(this.TXPDate)
            }
        }).result;
    }
}
    
class SetTXPDatesModalController {


    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'applicationId', 'rootScope', 'ApplicationTXPDetails', 'txpDate'];
    constructor(private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private applicationId, private rootScope, private ApplicationTXPDetails, private txpDate) {

    }

    save() {
        var data = {
            applicationId: this.applicationId,
            txpDate: this.txpDate
        };

        this.ApplicationTXPDetails.save({ applicationId: this.applicationId }, data)
        .$promise
        .then((results) => {
            if (_.some(results, (r: any) => r.success == false)) {
                return this.displayError(results);
            }
            this.rootScope.$broadcast('changeTXPDates', results.txpDate);

            return results;
        })
        .finally(() => {
            this.$uibModalInstance.close();
        });
    }

    displayError(results) {

        var message = 'The following applicants flight date cannot set, please try again shortly:</br>';
        message += results
            .filter((r) => !r.success)
            .map((r) => `Application ${r.applicationId} - ${r.error}</br>`);

        return this.dialogs
            .error('Issue occur during flight date assign', this.$sce.trustAsHtml(message))
            .result
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
