import _ from 'lodash-es'
import moment from 'moment';

class Registrant {
    residenceCountry;

    getCountry() {
        return this.residenceCountry;
    }
}

const voucherStorageKey = 'register-voucher';

class RegisterController {

    step: number = 1;
    lastStep: number = 1;
    registrant: any;
    agencies: any[];
    notSupportedCountry: boolean;
    gdpr: boolean;
    submitted: boolean;

    static $inject = ['$scope', '$window', '$state', '$stateParams', 'dialogs', 'Auth', 'RegistrationAgency', 'RegistrationLookup', 'localStorageService', 'RegistrationInterviewer'];
    constructor(private $scope, private $window, private $state, private $stateParams, private dialogs, private Auth, RegistrationAgency, RegistrationLookup, localStorageService, RegistrationInterviewer) {

        this.submitted = false;

        localStorageService.set(voucherStorageKey, '');

        this.registrant = {};
        this.registrant.getCountry = function () {
            return this.residenceCountry;
        }

        var residenceCountries = RegistrationLookup.residence()

        if ($stateParams.agencyId) {
            $scope.$broadcast('disableComponent', { name: 'residenceCountry' });

            RegistrationAgency.get({ agencyId: $stateParams.agencyId }, (agency) => {
                this.agencies = [agency];
                this.notSupportedCountry = false;
                this.registrant.agencyId = agency.id;
                this.registrant.residenceCountry = agency.countryCode;
            });

        }
        else if ($stateParams.interviewerId) {
            $scope.$broadcast('disableComponent', { name: 'residenceCountry' });

            RegistrationInterviewer.get({ interviewerId: $stateParams.interviewerId }, (regByInterviewerDetail) => {
                if (regByInterviewerDetail) {
                    var agency = regByInterviewerDetail.agency;
                    var interviewer = regByInterviewerDetail.interviewer;

                    this.agencies = [agency];
                    this.notSupportedCountry = false;
                    this.registrant.agencyId = agency.id;
                    this.registrant.residenceCountry = agency.countryCode;
                    this.registrant.interviewerId = interviewer.id;
                    if (this.registrant.interviewerId > 0) {
                        this.registrant.interviewer = { id: interviewer.id, name: interviewer.name };

                        this.registrant.registerByInterviewerUrl = true;
                    }
                }
            });
        }
        else if ($stateParams.voucher) {
            localStorageService.set(voucherStorageKey, $stateParams.voucher);
        }
        else if ($stateParams.referrerIdentifier) {
            this.registrant.referrerIdentifier = $stateParams.referrerIdentifier
            this.registrant.sourceCode = 'RF001';
        }
        else {
            $scope.$watch(() => this.registrant.residenceCountry, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    this.registrant.agencyId = undefined;
                    this.registrant.interviewerId = undefined;
                    this.registrant.interviewer = {};
                    this.registrant.registerByInterviewerUrl = false;

                    residenceCountries.$promise.then(c => {
                        var country = _.find(c, (r: any) => r.code == newValue);
                        this.gdpr = country && country.gdpr;
                        this.registrant.versionId = country && country.applicationVersion;
                        if (!country || country.applicationVersion == 4) {
                            this.lastStep = 1;
                            this.notSupportedCountry = false;
                        } else {
                            this.lastStep = 2;
                            this.notSupportedCountry = false;
                        }

                        this.agencies = [];
                        RegistrationAgency.query({ country: newValue }, (agencies) => {
                            this.agencies = agencies;
                            if (agencies.length === 0) {
                                this.notSupportedCountry = true;
                            }
                            if (agencies.length === 1) {
                                this.registrant.agencyId = agencies[0].id;
                            }
                        });

                    });
                }
            })
        }
    }

        

    oauth(provider) {
        this.Auth.oauthSignin(provider)
            .then((data) => {
                if (this.Auth.isLoggedIn()) {
                    this.redirectToHomePage();
                } else { 
                    if (this.$stateParams.agencyId) {
                        this.$state.go('app.registerOAuthAgency', this.$stateParams);
                    } else {
                        this.$state.go('app.registerOAuth');
                    }
                }
            }, (error) => {
                this.dialogs[error.type](error.title, error.message);
            });
    }

    register() {
        if (this.validation() && this.registrant.agreeTermsAndConditions) {
            const years = moment().diff(this.registrant.birthDate, 'years', false); 12
            if (years < 17) {
                this.dialogs
                    .confirm('Confirm', `Have you checked your Date of Birth? You are currently ${years} years old.`)
                    .result.then(() => this.signUp())
            } else {
                return this.signUp();
            }
        }
    }

    signUp() {
        return this.Auth.signup(this.registrant)
            .then(() => {
                this.redirectToHomePage();
            }, (error) => {
                if (error.type == 'redirect') {
                    this.dialogs.notify(error.title, error.message)
                        .result
                        .then(() => {
                            this.$window.location.href = error.redirectUrl;
                        });
                }
                else {
                    this.dialogs[error.type](error.title, error.message);
                }
            });
    }

    redirectToHomePage() {
        var targetState = 'app.home';
        var targetParams = {};
        if (this.Auth.user.role == 'OFF') {
            targetState = 'app.workQueue';
            targetParams = { queueName: '' };
        }
        this.$state.transitionTo(targetState, targetParams, { reload: true, inherit: true, notify: true });
    }
        

    nextStep() {
        if (this.validation()) {
            this.step++;
        }
    }

    prevStep() {
        this.step--;
    }

    validation() {
        switch (this.step) {
            case 1:
                this.$scope.$broadcast('submitted', true);
                this.submitted = true;
                return this.registrant.$validation();
            case 2:
                if (!this.registrant.agencyId) {
                    this.dialogs.error('Registration', 'Please select agency.')
                    return false;
                } else {
                    return true;
                }
        }
        return true;
    }
}

angular
    .module('app')
    .controller('RegisterController', RegisterController);
