   

function StrLimitFilterFactory($filter): Function {
    return function StrLimitFilter(input, limit) {
        if (!input) return;
        if (input.length <= limit) {
            return input;
        }

        return $filter('limitTo')(input, limit) + '...';
    }
}

angular
    .module('app')
    .filter('strLimit', ['$filter', StrLimitFilterFactory])