import { BusinessArea } from '../../../services/AuthService';
import { FlightDetailsModalController } from './FlightDetailsModalController';
import _ from 'lodash-es';

export class FlightsController {

    flights: any[];

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    static $inject = ['$scope', 'Auth', 'Flights', 'FlightMessages', '$uibModal'];
    constructor($scope, Auth, private Flights, private FlightMessages, private $uibModal) {

        this.refresh();

        this.selections = [];
        this.selectedGridItems = [];

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.selectFlight(values[0]);
                this.selectedGridItems = [];
            }
        }, true);
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.flights) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.flights) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                id: item.id
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.flights) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }

    refresh() {
        this.Flights.query().$promise.then((data) => {
            this.flights = data;

            this.selections = [];
        });
    }

    public selectFlight(flight) {
        return this
            .$uibModal
            .open({
                templateUrl: 'areas/tools/maintainFlights/flightDetailsModal.html',
                controller: FlightDetailsModalController,
                controllerAs: '$ctrl',
                backdrop: false,
                size: 'lg',
                resolve: {
                    flight: () => this.Flights.get({ id: flight.id }).$promise,
                    flightMessages: () => this.FlightMessages.query().$promise
                }
            })
            .result.then(() => {
                this.refresh();
            });
    }
}