 


class MenuProvider implements ng.IServiceProvider {

    private static menus = {};

    static $inject = [];
    constructor() {
    }

    AddMenu(accessLevel, name, menu) {
        if (angular.isObject(name)) {
            menu = name;
            name = '';
        }

        var menuGroup = MenuProvider.menus[name] || (MenuProvider.menus[name] = {});
        menuGroup[accessLevel] = menu;
    }

    $get = ['AccessLevel', '$state', '$rootScope', function (AccessLevel, $state, $rootScope) {

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams, options) {
            for (var menuGroupKey in MenuProvider.menus) {
                var menuGroup = MenuProvider.menus[menuGroupKey];
                for (var menuKey in menuGroup) {
                    var menu = menuGroup[menuKey];
                    menu.selected = undefined;
                    for (var item of menu.items) {
                        var link = item.linkStage || item.state;
                        if ($state.includes(link)) {
                            menu.selected = item;
                        }
                    }
                }
            }
        });


        return function getMenu(name) {
            name = name || '';

            var menuGroup = MenuProvider.menus[name];
            if (menuGroup) {
                return AccessLevel.userLevels.firstMatch(menuGroup);
            }

            return [];
        }



    }];
}

angular
    .module('app')
    .provider('Menu', MenuProvider);

