
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';
import { WorkqueueType } from '../../areas/queue/QueueController';
import { AgencyCommissionsController } from '../../areas/agency/AgencyDetails/AgencyCommissionsController';
import { AgencyMarketingController } from '../../areas/agency/AgencyDetails/AgencyMarketingController';
import { AgencyPaymentController } from '../../areas/agency/AgencyDetails/AgencyPaymentController';
import { BusinessArea } from '../../services/AuthService'

function agencyTitle(subsection) {
    return ['agency', function (agency) {
        return `${subsection} - ${agency.agencyId} - ${agency.agencyName}`;
    }];
}

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

            const agencyList: state<AccessLevelView> = {
                name: 'agency',
                url: '/agency/{queueName}',
                resolve: {
                    displayTypes: [function () {
                        return [WorkqueueType.Agency, WorkqueueType.Transaction];
                    }],
                    queueName: ['$stateParams', function ($stateParams) {
                        return $stateParams.queueName;
                    }],
                    quickSerch: ['$stateParams', function ($stateParams) {
                        return $stateParams.quickSerch;
                    }],
                    columnSelections: ['WorkQueueColumnSelection', 'Auth', function (WorkQueueColumnSelection, Auth) {
                        return WorkQueueColumnSelection.get({ userId: Auth.user.userId }).$promise
                    }],
                    drivingLicenceOptions: ['ApplicationLookup', function (ApplicationLookup) {
                        return ApplicationLookup.drivingLicenseType().$promise;
                    }],
                    programOptions: ['ApplicationLookup', function (ApplicationLookup) {
                        return ApplicationLookup.programType().$promise;
                    }],
                    countryOptions: ['ApplicationLookup', function (ApplicationLookup) {
                        return ApplicationLookup.country().$promise;
                    }],
                    visaOptions: ['ApplicationLookup', function (ApplicationLookup) {
                        return ApplicationLookup.visaStatus().$promise;
                    }],
                    applicationStatuses: ['ApplicationStatus', function (ApplicationStatus) {
                        return ApplicationStatus.getAll().$promise;
                    }],
                    interviewersList: ['Interviewer', 'Auth', function (Interviewer, Auth) {
                        if (Auth.user.isOfficer) {
                            return Interviewer.query().$promise;
                        } else if (Auth.user.isAgent) {
                            return Interviewer.agencyActive({ agencyId: Auth.user.roleDetails.agencyId }).$promise;
                        } else if (Auth.user.isBranchManager) {
                            return Interviewer.branchActive({ branchId: Auth.user.roleDetails.branchId }).$promise;
                        } else if (Auth.user.isInterviewer) {
                            return Interviewer.get({ id: Auth.user.roleDetails.contactId }).$promise.then((i) => [i]);
                        }
                    }],
                    activeApplicationsPromotionList: ['Promotions', 'Auth', function (Promotions, Auth) {
                        if (Auth.user.isOfficer) {
                            return Promotions.activeApplicationsPromotions().$promise;
                        }
                    }],
                    userSettings: ['UserSettings', 'Auth', function (UserSettings, Auth) {
                        return UserSettings.get({ userId: Auth.user.userId }).$promise
                    }],
                    queues: ['Workqueues', 'displayTypes', function (Workqueues, displayTypes) {
                        return Workqueues.query().$promise.then(qs => {
                            return qs.filter(q => displayTypes.indexOf(q.workqueueType) !== -1);
                        });
                    }],
                    $title: () => 'Agency'
                },
                views: {
                    content: {
                        staff: {
                            controller: 'QueueController as ctrl',
                            template: 'areas/queue/Queue.html'
                        }
                    }
                }
            };

        RoutingProvider.addState(agencyList, 'app');

            RoutingProvider.addState({
                name: 'agencyInformation',
                url: '/agency/information/{agencyId:int}',
                abstract: true,
                resolve: {
                    agencyId: ['$stateParams', function ($stateParams) {
                        return $stateParams.agencyId;
                    }],
                    agency: ['Agency', 'agencyId', function (Agency, agencyId) {
                        return Agency.get({ agencyId: agencyId }).$promise;
                    }],
                    $title: ['agency', function (agency) {
                        if (agency.agencyId == 0) {
                            return `Create`;
                        }
                        else {
                            return `${agency.agencyId} - ${agency.agencyName}`;
                        }
                    }]
                },
                views: {
                    content: {
                        office: {
                            controller: 'AgencyController as ctrl',
                            template: 'areas/agency/agencyInformations.html'
                        },
                        staff: {
                            template: 'templates/empty.html'
                        }
                    }
                },
                states: [
                    {
                        name: 'basicDetails',
                        url: '',
                        views: {
                            '': {
                                office: {
                                    controller: 'BasicDetailsController as ctrl',
                                    template: 'areas/agency/AgencyDetails/basicDetails.html'
                                }
                            }
                        },
                        resolve: {
                            agencyBasicDetailsPage: ['AgencyBasicDetailsPage', 'agencyId', function (AgencyBasicDetailsPage, agencyId) {
                                return AgencyBasicDetailsPage.get({ agencyId: agencyId }).$promise;
                            }],
                            $title: agencyTitle('Basic details')
                        }
                    },
                    {
                        name: 'agencyAddress',
                        url: '/address',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyAddressController as ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyAddress.html'
                                }
                            }
                        },
                        resolve: {
                            agencyAddressPage: ['AgencyAddressPage', 'agencyId', function (AgencyAddressPage, agencyId) {
                                return AgencyAddressPage.get({ agencyId: agencyId }).$promise;
                            }],
                            agencyBasicDetailsPage: ['AgencyBasicDetailsPage', 'agencyId', function (AgencyBasicDetailsPage, agencyId) {
                                return AgencyBasicDetailsPage.get({ agencyId: agencyId }).$promise;
                            }],
                            $title: agencyTitle('Address')
                        }
                    },
                    {
                        name: 'agencyContacts',
                        url: '/contacts',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyContactsController as $ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyContacts.html'
                                }
                            }
                        },
                        resolve: {
                            agencyBasicDetailsPage: ['AgencyBasicDetailsPage', 'agencyId', function (AgencyBasicDetailsPage, agencyId) {
                                return AgencyBasicDetailsPage.get({ agencyId: agencyId }).$promise;
                            }],
                            $title: agencyTitle('Contacts')
                        }
                    },
                    {
                        name: 'agencyPayments',
                        url: '/payments',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyPaymentsListController as ctrl',
                                    template: 'areas/agency/agencyPaymentsList.html'
                                }
                            }
                        },
                        resolve: {
                            paymentInfo: ['Payments', 'agencyId', function (Payments, agencyId) {
                                return Payments.agencyInfo({ agencyId: agencyId }).$promise;
                            }],
                            $title: agencyTitle('Agency Payments')
                        }
                    },
                    {
                        name: 'agencyPaymentDetails',
                        url: '/payment',
                        views: {
                            '': {
                                controllerAs: '$ctrl',
                                office: {
                                    controller: AgencyPaymentController,
                                    template: 'areas/agency/AgencyDetails/agencyPayment.html'
                                }
                            }
                        },
                        resolve: {
                            paymentDetail: ['AgencyPayments', 'agencyId', function (AgencyPayments, agencyId) {
                                return AgencyPayments.query({ agencyId: agencyId }).$promise;
                            }],
                            $title: agencyTitle('Agency Payment Information')
                        }
                    },
                    {
                        name: 'agencyBranches',
                        url: '/branches',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyBranchesController as $ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyBranches.html'
                                }
                            }
                        },
                        resolve: {
                            $title: agencyTitle('Branches'),
                            branches: ['Branches', 'agencyId', function (Branches, agencyId) {
                                return Branches.queryForAgency({ agencyId: agencyId }).$promise;
                            }]
                        }
                    },
                    {
                        name: 'agencyTargets',
                        url: '/targets',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyTargetsController as $ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyTargets.html'
                                }
                            }
                        },
                        resolve: {
                            agencyAddressPage: ['AgencyAddressPage', 'agencyId', function (AgencyAddressPage, agencyId) {
                                return AgencyAddressPage.get({ agencyId: agencyId }).$promise;
                            }],
                            $title: agencyTitle('Targets')
                        }
                    },
                    {
                        name: 'agencyContractualAgreement',
                        url: '/contractualAgreement',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyContractualAgreementController as ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyContractualAgreement.html'
                                }
                            }
                        },
                        resolve: {
                            agencyId: ['$stateParams', function ($stateParams) {
                                return $stateParams.agencyId;
                            }],
                            $title: agencyTitle('Agreement')
                        }
                    },
                    {
                        name: 'contractualAgreement',
                        url: '/contractualAgreement/{contractId:int}',
                        views: {
                            '': {
                                office: {
                                    controller: 'ViewContractualAgreementController as ctrl',
                                    template: 'areas/agency/agencyContract/viewContractualAgreement.html'
                                }
                            }
                        },
                        resolve: {
                            agencyId: ['$stateParams', function ($stateParams) {
                                return $stateParams.agencyId;
                            }],
                            contractId: ['$stateParams', function ($stateParams) {
                                return $stateParams.contractId;
                            }],
                            contactId: ['Auth', function (Auth) {
                                return Auth.user.roleDetails.contactId;
                            }],
                            $title: agencyTitle('View Agreement')
                        }
                    },
                    {
                        name: 'agencyCommissions',
                        url: '/commissions',
                        views: {
                            '': {
                                controllerAs: '$ctrl',
                                office: {
                                    controller: AgencyCommissionsController,
                                    template: 'areas/agency/AgencyDetails/agencyCommissions.html',
                                }
                            }
                        },
                        resolve: {
                            $title: agencyTitle('Commissions')
                        }
                    },
                    {
                        name: 'agencyMarketing',
                        url: '/marketing',
                        views: {
                            '': {
                                controllerAs: '$ctrl',
                                office: {
                                    controller: AgencyMarketingController,
                                    template: 'areas/agency/AgencyDetails/agencyMarketing.html'
                                }
                            }
                        },
                        resolve: {
                            $title: agencyTitle('Marketing')
                        }
                    },
                    {
                        name: 'agencyCollections',
                        url: '/collections',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyCollectionsController as ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyCollections.html'
                                }
                            }
                        },
                        resolve: {
                            $title: agencyTitle('Collections')
                        }
                    },
                    {
                        name: 'agencyComments',
                        url: '/comments',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyCommentsController as ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyComments.html'
                                }
                            }
                        },
                        resolve: {
                            $title: agencyTitle('Comments')
                        }
                    },
                    {
                        name: 'termsOfBusiness',
                        url: '/contract/:contractId/termsOfBusiness',
                        views: {
                            '': {
                                office: {
                                    controller: 'TermsOfBusinessController as ctrl',
                                    template: 'areas/agency/agencyContract/termsOfBusiness.html'
                                }
                            }
                        },
                        resolve: {
                            agencyId: ['$stateParams', 'Auth', function ($stateParams, Auth) {
                                return $stateParams.agencyId;
                            }],
                            contractId: ['$stateParams', function ($stateParams) {
                                return $stateParams.contractId;
                            }],
                            $title: () => 'Terms Of Business'
                        },
                        params: {
                            agencyId: '@agencyId',
                            contractId: '@contractId'
                        }
                    },
                    {
                        name: 'feeSheet',
                        url: '/feeSheet/{year:int}',
                        views: {
                            'content@app': {
                                staff: {
                                    controller: 'AgencyFeeSheetController as ctrl',
                                    //controller: auPair.areas.agency.FeeSheetController,//'FeeSheetController as ctrl',
                                    //controllerAs: 'ctrl',
                                    template: 'areas/agency/feeSheet/feeSheet.html'
                                }
                            }
                        },
                        resolve: {
                            year: ['$stateParams', function ($stateParams) {
                                return $stateParams.year;
                            }]
                        }
                    },
                    {
                        name: 'agencyTerritories',
                        url: '/territories',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyTerritoriesController as ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyTerritories.html'
                                }
                            }
                        },
                        resolve: {
                            $title: agencyTitle('Territories'),
                            agencyId: ['$stateParams', function ($stateParams) {
                                return $stateParams.agencyId;
                            }],
                            agencyTerritories: ['AgencyTerritory', 'agencyId', function (AgencyTerritory, agencyId) {
                                return AgencyTerritory.query({ agencyId: agencyId }).$promise;
                            }],
                            countryOptions: ['ApplicationLookup', function (ApplicationLookup) {
                                return ApplicationLookup.country().$promise;
                            }]
                        }
                    },
                    {
                        name: 'agencyCompetitors',
                        url: '/competitors/:competitorId',
                        views: {
                            '': {
                                office: {
                                    controller: 'AgencyCompetitorsController as $ctrl',
                                    template: 'areas/agency/AgencyDetails/agencyCompetitors.html'
                                }
                            }
                        },
                        resolve: {
                            $title: agencyTitle('Competitors'),
                            agencyId: ['$stateParams', function ($stateParams) {
                                return $stateParams.agencyId;
                            }],
                            competitorId: ['$stateParams', function ($stateParams) {
                                return $stateParams.competitorId;
                            }],
                            competitors: ['Competitors', 'agencyId', function (Competitors, agencyId) {
                                return Competitors.queryForAgency({ agencyId: agencyId }).$promise;
                            }]
                        }
                    }
                ]
            }, 'app');
        }
    }

angular
    .module('app')
    .config(RoutingConfig);