
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
    
export class MenuConfig {
    static $inject = ['MenuProvider'];
    constructor(MenuProvider) {

        MenuProvider.AddMenu('everyone', 'placement', {
            items: [
                {
                    name: 'Placement details',
                    state: 'app.placement.placementDetails'
                },
                {
                    name: 'Interested Host Family',
                    state: 'app.placement.interestedHF'
                },
                {
                    name: 'Visa details',
                    state: 'app.placement.visaDetails'
                },
                {
                    name: 'Orientation',
                    state: 'app.placement.preDeparture'
                },
                {
                    name: 'Travel details',
                    state: 'app.placement.flightDetails'
                },
                //{
                //    name: 'National Interest Exception',
                //    state: 'app.placement.nationalInterestException',
                //    visibility: ['NationalInterestException', 'Auth', '$stateParams', function (NationalInterestException, Auth, $stateParams) {
                //        return NationalInterestException
                //            .get({ applicationId: $stateParams.applicationId || Auth.user.roleDetails.applicationId })
                //            .$promise
                //            .then(d => d.applicable && d.dS2019SentDate);
                //    }]
                //},
                {
                    name: 'Before you depart',
                    state: 'app.placement.beforeYouDepart'
                },
                {
                    name: 'In The US',
                    state: 'app.placement.orientation'
                }
            ]
        });

        MenuProvider.AddMenu('everyone', 'placementOffice', {
            items: [
                {
                    name: 'Placement details',
                    state: 'app.applications.placement.placementDetails'
                },
                {
                    name: 'Interested Host Family',
                    state: 'app.applications.placement.interestedHF'
                },
                {
                    name: 'Visa details',
                    state: 'app.applications.placement.visaDetails'
                },
                {
                    name: 'Orientation',
                    state: 'app.applications.placement.preDeparture'
                },
                {
                    name: 'Travel details',
                    state: 'app.applications.placement.flightDetails'
                },
                {
                    name: 'Before you depart',
                    state: 'app.applications.placement.beforeYouDepart'
                },
                {
                    name: 'In The US',
                    state: 'app.applications.placement.orientation'
                }
            ]
        });

    }
}

angular
    .module('app')
    .config(MenuConfig);