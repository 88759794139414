
function MatchValidatorLink(scope, elem, attrs, ctrls) {
    var field = ctrls[1];
    var compareField = ctrls[0][attrs['mfMatch']];



    //field.$validators.match = valid
    field.$validators.match = function (modelValue, viewValue) {
        return field.$isEmpty(modelValue) || compareField.$viewValue === viewValue;
    };
    // compareField.$validators.match = function (value){
    //     field.$validate();
    //     return true;
    // };
    compareField.$parsers.unshift(function (value) {
        field.$validate();
        return value;
    });


    // scope.$watch(function() {
    //     var modelValue = field.$modelValue || field.$$invalidModelValue;
    //     return (field.$pristine && angular.isUndefined(modelValue)) || compareField.$viewValue === field.$viewValue;
    //     //return (ctrl.$pristine && angular.isUndefined(modelValue)) || scope.match === modelValue;
    // }, function(currentValue) {
    //     field.$setValidity('match', currentValue);
    // });
}

angular
    .module('aifs.metaform')
    .directive('mfMatch', function () {
        return {
            require: ['^form', 'ngModel'],
            restrict: 'A',
            // scope: {
            //     match: '='
            // },
            link: MatchValidatorLink
        };
    });
