import _ from 'lodash-es'
import { IAction } from '../IAction'

export class AssignToAgency implements IAction{

    static $inject = ['$uibModal', 'dialogs', 'Auth', 'applicationIds', 'agencyId', 'interviewerId']
    constructor(private $uibModal, private dialogs, private Auth, private applicationIds, private agencyId, private interviewerId) {

    }

    isDisabled = function () {
        return !this.isAnySelected() || !this.isCorrectStatus() || !this.isSameCountry();
    }

    isAnySelected = function () {
        return this.applicationIds.length !== 0
    }

    //isCorrectStatus = function () {
    //    return this.selections.every(x => x.status === 'REG' || x.status === 'OLA')
    //}

    //isSameCountry = function () {
    //    return this.selections.length !== 0 && this.selections.every(x => x.countryCode === this.selections[0].countryCode)
    //}

    execute = () => {
        if (!this.isAnySelected()) {
            return this.dialogs
                .notify('Assign Application', 'You need to select an applicant first')
                .result;
        }
        //else if (!this.isCorrectStatus()) {
        //    return this.dialogs
        //        .notify('Assign Application', 'Applicants can only be assigned at REG and OLA status')
        //        .result;
        //}
        //else if (!this.isSameCountry()) {
        //    return this.dialogs
        //        .notify('Assign Application', 'You have to select applicants from the same country')
        //        .result;
        //}


        return this.$uibModal.open({
            templateUrl: 'areas/action/assignToAgency/assignToAgencyModal.html',
            controller: AssignToAgencyModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationIds: () => this.applicationIds,
                selectedAgencyId: () => this.agencyId,
                agencyId: () => this.agencyId,
                interviewerId: () => this.interviewerId
            }
        }).result;
    }
}
    
class AssignToAgencyModalController {

    public showAgencySelection: boolean;
    public agencies: any[];
    public interviewers: any[];
    public selectedAgencyId: any;
    public selectedInterviewer: any;
    public currentInterviewer: any;
    public currentAgency: any;

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'Agency', 'Applicant', 'Application', 'Interviewer', 'applicationIds', 'selectedAgencyId', 'agencyId', 'interviewerId', '$rootScope'];
    constructor(private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private Agency, private Applicant, private Application, private Interviewer, private applicationIds: any[], selectedAgencyId, private agencyId, private interviewerId, private $rootScope) {
        if (selectedAgencyId) {
            this.selectedAgencyId = selectedAgencyId;
            this.showAgencySelection = false;
        } else {
            this.showAgencySelection = true;
        }

        this.loadAgencies();            
    }

    fullName = (inter) => `${inter.firstName} ${inter.lastName}`;
    fullNameWithId = (inter) => `${inter.id} - ${inter.firstName} ${inter.lastName}`;

    assign() {
        var data = {
            application: this.applicationIds,
            interviewerId: this.selectedInterviewer && this.selectedInterviewer.id
        };

        this.Agency.assign({ agencyId: this.selectedAgencyId }, data)
            .$promise
            .then((results) => {
                if (_.some(results, (r: any) => r.success == false)) {
                    return this.displayError(results);
                }
                if (this.selectedInterviewer) {
                    this.$rootScope.$broadcast('changeInterviewerAndAgency', {
                        agencyId: this.selectedAgencyId,
                        interviewerId: this.selectedInterviewer.id,
                        interviewerFirstName: this.selectedInterviewer.firstName,
                        interviewerLastName: this.selectedInterviewer.lastName
                    });
                }
                else {
                    this.$rootScope.$broadcast('changeInterviewerAndAgency', {
                        agencyId: this.selectedAgencyId,
                        interviewerSelected: false
                    });
                }

                return results;
            })
            .finally(() => {
                this.$uibModalInstance.close();
            });
    }

    loadAgencies() {
        var promises = this.applicationIds
            .map((applicationId) =>
                this.Application.get({ applicationId: applicationId })
                    .$promise
                    .then((application) => this.Applicant.get({ applicantId: application.applicantId }).$promise)
                    .then((applicant) => applicant.countryCode)
            );

        this.$q.all(promises).then((countryCodes) => {
            var uniq = _.uniq(countryCodes);
            if (uniq.length == 1) {

                this.Agency.byCountry({ country: uniq[0] }, (response) => {
                    this.agencies = response;
                    this.selectedAgencyId = this.agencyId;

                    if (this.agencyId && this.agencyId > 0) {
                        var filterAgency = _.filter(this.agencies, { agencyId: this.agencyId });
                        if (filterAgency && filterAgency.length > 0) {
                            this.currentAgency = filterAgency[0];
                            if (this.interviewerId && this.interviewerId > 0) {
                                this.Interviewer.agencyActive({ agencyId: this.agencyId }, (interviewerResponse) => {
                                    if (interviewerResponse) {
                                        this.interviewers = interviewerResponse;

                                        var filterInterview = _.filter(interviewerResponse, { id: this.interviewerId });
                                        if (filterInterview && filterInterview.length > 0) {
                                            this.currentInterviewer = filterInterview[0];
                                            this.selectedInterviewer = this.currentInterviewer;
                                        }
                                    }
                                });
                            }
                        }
                        this.loadInterviewers();
                    }
                });

                    
            }
        });
    }

    loadInterviewers() {
        if (this.selectedAgencyId) {
            this.interviewers = this.Interviewer.agencyActive({
                agencyId: this.selectedAgencyId
            });
        } else {
            this.interviewers = [];
        }
    }

    displayError(results) {

        var message = 'The following applicants could not be assigned, please try again shortly:</br>';
        message += results
            .filter((r) => !r.success)
            .map((r) => `Application ${r.applicationId} - ${r.error}</br>`);

        return this.dialogs
            .error('Issue occur during assign', this.$sce.trustAsHtml(message))
            .result

    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
