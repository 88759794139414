

var title = 'Leave this page?';
var message = 'Your information has not been saved. Do you want to leave the page without saving?';

var ConfirmOnExitDirectiveFactory = ['$state', '$window', '$parse', 'dialogs', function ConfirmOnExitDirectiveFactory($state, $window, $parse, dialogs): ng.IDirective {

    function link($scope, elem, attrs) {


        function check() {
            // check attribute value 
            if (attrs.confirmOnExit) {
                return $parse(attrs.confirmOnExit)($scope);
            }
            //otherwise check form
            else if (attrs.name) {
                var form = $parse(attrs.name)($scope);
                return form && form.$dirty;
            }
        }


        $window.onbeforeunload = () => {
            if (check()) {
                return message;
            }
            return undefined;
        };

        var watch = $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (check()) {

                event.preventDefault();

                dialogs.confirm(title, message).result
                    .then(() => {
                        $window.onbeforeunload = null;
                        watch();
                        $state.transitionTo(toState, toParams);
                    });
            }
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}]


angular
    .module('app')
    .directive('confirmOnExit', ConfirmOnExitDirectiveFactory);