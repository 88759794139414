import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController'
import { ApplicationStatus } from '../ApplicationStatus'

class EditHostFamilyController extends EditApplicationController {
    
    //applicationId: number

    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    showHostFamilyDetails: boolean;

    hostFamilyDetails: any;
    selectedHostFamily: any;

    static override $inject = ['$scope', 'applicationObservable', 'applicationId', 'ApplicationPlacement','ApplicationReviewItemsAny'];
    constructor($scope, applicationObservable, applicationId, ApplicationPlacement, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);

        this.applicationId = applicationId;

        applicationObservable.subscribe(applicationInstance => {
            this.showHostFamilyDetails = (applicationInstance.status !== ApplicationStatus.Registered || applicationInstance.status !== ApplicationStatus.Online || applicationInstance.status !== ApplicationStatus.AwaitingAssessment || applicationInstance.status !== ApplicationStatus.DidNotQualify);

            if (this.showHostFamilyDetails) {
                ApplicationPlacement.allHostFamilyDetails({ applicationId: this.applicationId })
                    .$promise
                    .then((results) => {
                        if (results.length > 0) {
                            this.selectedHostFamily = results[0];
                        }

                        this.hostFamilyDetails = results;
                    });
            }
        })
    }
}

angular
    .module('app')
    .controller('EditHostFamilyController', EditHostFamilyController)