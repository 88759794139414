 
import { debug } from 'console'
import { ReviewItemId } from '../../models/ReviewItem'
import { SectionChangedEvent } from '../../models/SectionChangedEvent'
import { NoteStatus, ReviewService } from '../../services/ReviewService'


class StickyNoteController {

    review: ReviewService
    conf
    applicationStatus: string
    approveChange: boolean

    note
    message
    noteTitle: string = 'Enter a new note';

    assignId: number
    visible: boolean = false

    static $inject = ['$scope', '$element', 'dialogs', 'StickyNote', 'Auth', 'RevertSection']
    constructor(private $scope, private $element, private dialogs, private StickyNote, private Auth, private RevertSection) {
    }

    $onInit() {
        if (!this.review)
            throw 'You need to specified "review" attribute.'
        this.$scope.$watch(() => this.applicationStatus, () => {
            let user = this.Auth.user;
            if (user.isAgent || user.isBranchManager || user.isInterviewer) {
                this.visible = this.applicationStatus === 'OLA';
            } else if (user.isOfficer) {
                this.visible = this.applicationStatus === 'REG' || this.applicationStatus === 'OLA' || this.applicationStatus === 'AWA' || this.applicationStatus === 'TBM' || this.applicationStatus === 'PLC';
            }
        });

        this.$scope.$on('reviewService:pageNotesChange', (page) => {
            this.refreshMessage();
        });

        this.$scope.$on(`sectionChanged`, (event, data: SectionChangedEvent) => {
            if (this.note && data.reviewItemId === this.note.itemId && (!data.assignId || data.assignId === this.conf.assignId)) {

                if (this.note.status === 0 && this.Auth.user.isApplicant) {
                    this.dialogs
                        .confirm('Save Complete', `You have made changes in a section <strong>${data.sectionName}</strong> within which changes have been requested. Have you completed the changes as requested?`)
                        .result
                        .then(() => {
                            this.done();
                        });
                }
            }
        });

        this.refreshMessage();
    }

    refreshMessage() {
        if (this.conf) {
            // add watch for note 
            var type = ReviewItemId[<string>this.conf.type] // TODO use strong enum
            var reviewNote = this.review.getReviewableItemNote(type, this.conf.assignId)

            if (reviewNote) {
                this.approveChange = reviewNote.approveChange;
                this.setNoteTitle();
                if (reviewNote.note) {
                    this.note = reviewNote.note;
                    this.message = this.note.text;
                    return;
                }
            }
        }

        this.note = undefined;            
    }

    isNew() {
        return !this.note || this.note.status == NoteStatus.Dismissed
    }

    isDirty() {
        return this.message && (!this.note || this.note.text != this.message) 
    }

    showDeclineButton() {
        //interviewReport >=100 ReviewItemId not >100
        var type = ReviewItemId[<string>this.conf.type];

        return this.isDirty() &&
            this.approveChange && (
                type != ReviewItemId.Media &&
                type != ReviewItemId.FuturePlans &&
                type != ReviewItemId.References &&
                type < ReviewItemId.InterviewReport_AboutTheInterview
            );
    }
    setNoteTitle() {
        //interviewReport >=100 ReviewItemId not >100
        var type = ReviewItemId[<string>this.conf.type];

        if (this.approveChange
            && (type != ReviewItemId.Media && type != ReviewItemId.FuturePlans && type != ReviewItemId.References
                    && type < ReviewItemId.InterviewReport_AboutTheInterview)) {
            this.noteTitle = 'Enter a new note or start typing to decline changes in this section';
        }
        
    }
    status() {

        if (this.note) {
            if (this.note.status == NoteStatus.Raised) {
                return 'todo'
            } else if (this.note.status == NoteStatus.ChangesMade) {
                return 'done'
            }
        }
        return 'remove'
    }

    private setPristine() {
        var element = angular.element('[ng-model="noteCtrl.message"]', this.$element)
        var controller = element.controller('ngModel')
        if (controller) {
            var form = controller.$$parentForm
            if (form) {
                form.$setPristine()
            }
        }
    }



    saveNote() {
        var type = ReviewItemId[<string>this.conf.type]
        this.StickyNote.save({
            itemId: type,
            authorId: this.review.userId, //NOTE:: current user's Id should be read from the token on the server side
            noteText: this.message,
            assignId: this.conf.assignId,
            applicationId: this.review.applicationId
        }, (note) => {
            this.setPristine()
            this.note = {
                id: note.noteId,
                author: note.noteAuthor,
                text: this.message,
                date: note.noteDate,
                status: note.status
            }
        })
    }

    saveAndDeclineNote() {
        var type = ReviewItemId[<string>this.conf.type]

        this.RevertSection.decline({
            applicationId: this.review.applicationId,
            itemId: type,
            note: this.message,
            assignId: this.conf.assignId,
        }, (result) => {
            if (result && result.note) {
                var note = result.note;
                this.setPristine();

                this.note = {
                    id: note.noteId,
                    author: note.noteAuthor,
                    text: this.message,
                    date: note.noteDate,
                    status: note.status
                }

                //refresh page
                window.location.reload();
            }

            ////TODO - Do not remove - for future reference, update UI after revert changes.
            //if (result && result.data) {
            //    this.$scope.$emit('personalDetails:refresh', type, result.data);
            //    this.$scope.$emit('profile:refresh', type, result.data);
            //    this.$scope.$emit('travelAndProgram:refresh', type, result.data);
            //    this.$scope.$emit('medicalAndCriminal:refresh', type, result.data);
            //    this.$scope.$emit('educationAndWork:refresh', type, result.data);
            //    this.$scope.$emit('childcare:refresh', type, result.data);
            //}

           
        });
    }

    done() {
        this.StickyNote.complete({
            noteId: this.note.id
        }, {}, () => {
            this.note.status = NoteStatus.ChangesMade;
        });
    }
        
    deleteNote() {
        this.dialogs.confirm('Please Confirm', 'Are you sure that you want to delete this note?')
            .result
            .then(() => {
                this.StickyNote.dismissed({
                    noteId: this.note.id
                }, {}, () => {
                        this.note = undefined//.status = NoteStatus.Dismissed;
                        this.message = undefined;
                    })
            });
        }
        
}

function StickyNoteDirectiveFactory(): ng.IDirective {
    return {
        restrict: 'AE',
        scope: {
            review: '=stickyNote',
            conf: '=stickyNoteConf',
            applicationStatus: '=applicationStatus'
        },
        controller: StickyNoteController,
        controllerAs: 'noteCtrl',
        bindToController: true,
        templateUrl: 'controls/stickyNote/stickyNote.html'
    }
}

angular
    .module('app')
    .directive('stickyNote', StickyNoteDirectiveFactory)
