 
import { collapseOnScrollController } from './collapseOnScroll'
 
class watchScroll implements ng.IDirective {
    restrict = 'A'
    controller = watchScrollController
    controllerAs = 'asCtrl'
    bindToController = true
    scope = {}
    link(scope, element, attrs, applicantInfoCtrl: collapseOnScrollController) {
        element.on('scroll', function (e) {
            scope.asCtrl.broadcast(e.target.scrollTop);
        });
    }
}

    
export class watchScrollController {
    subscribers: Function[] = []

    public subscribe(subscriber: Function) {
        this.subscribers.push(subscriber);
    }

    public unsubscribe(subscriber: Function) {
        var index = this.subscribers.indexOf(subscriber);
        if (index !== -1) {
            this.subscribers.splice(index, 1);
        }
    }

    public broadcast(scrollTop) {
        for (let s of this.subscribers) {
            s(scrollTop);
        }
    }
}

angular
    .module('app')
    .directive('watchScroll', () => new watchScroll())