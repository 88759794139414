
class FlightController {
    displayENFlight: boolean;
    isAWL: boolean;
    displayFlightHome: boolean;

    static $inject = ['$rootScope', '$scope', 'Auth', 'USAuPairs'];
    constructor(private $rootScope, private $scope, private Auth, private USAuPairs) {   

        this.USAuPairs.canAupairExtend({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.isAWL = false;
                this.displayENFlight = result.data.isOkToShowExtensionFlight;
                if (result.data.apstatusCode == 'AWL') {
                    this.isAWL = true;
                }
            });
    }
}
angular
    .module('app')
    .controller('FlightController', FlightController);