
class ManagementController {

    agencyId: number;
    agencyContractAlertInfo: any;

    static $inject = ['$scope', 'agencyId', 'AgencyContract'];
    constructor(private $scope, agencyId, private AgencyContract) {

        this.agencyId = agencyId;

        this.getAgencyContractInformationCount();
            
        $scope.$on('commun:contract-update', () => {
            this.getAgencyContractInformationCount();
        });
    }

    private getAgencyContractInformationCount() {            
        this.AgencyContract.getContractInformationForMenu({ agencyId: this.agencyId }, (result) => {
            this.agencyContractAlertInfo = result;
        });
    }

    public isAnyContractAccess() {
        return this.agencyContractAlertInfo && (this.agencyContractAlertInfo.canViewContract || this.agencyContractAlertInfo.canAcceptContract);
    }
}

angular
    .module('app')
    .controller('ManagementController', ManagementController)