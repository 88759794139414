 
import { MwlEventsGroupDirective } from "./calendarMonthDayEvents";


const calendarMonthDayTemplate: string =
    '<div mwl-droppable\
            on-drop="vm.handleEventDrop(dropData.event, day.date)"\
            class="cal-month-day {{ day.cssClass }}"\
            ng-click="vm.calendarCtrl.viewDate = day.date; $emit(\'selectedDay\', day)"\
            ng-class="{\
            \'sel\': vm.calendarCtrl.viewDate.isSame(day.date),\
            \'selected\': dayIndex === vm.openDayIndex && vm.view[vm.openDayIndex].events.length > 0,\
            \'cal-day-outmonth\': !day.inMonth,\
            \'cal-day-inmonth\': day.inMonth,\
            \'cal-day-weekend\': day.isWeekend,\
            \'cal-day-past\': day.isPast,\
            \'cal-day-today\': day.isToday,\
            \'cal-day-future\': day.isFuture\
        }">\
        <div mwl-events-group="day.events" ></div>\
        <span class="pull-right"\
                data-cal-date\
                ng-bind="day.label"\
                <!--ng-click="vm.calendarCtrl.drillDown(day.date)"-->>\
        </span>\
        <!--<div ng-include="\'calendarMonthEventsList.html\'"></div>-->\
    </div>';

    
var AngularBootstrapCalendarRun = ['$templateCache', function AngularBootstrapCalendarRun($templateCache) {

    $templateCache.put('mwl/calendarMonthCell.html', calendarMonthDayTemplate);
}]



export var calendarModule = angular
    .module('mwl.calendar.custom', ['mwl.calendar'])
    .directive("mwlEventsGroup", () => new MwlEventsGroupDirective())
    .run(AngularBootstrapCalendarRun)