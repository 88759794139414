 
import { watchScrollController } from './watchScroll';
import { collapseOnScrollController } from './collapseOnScroll';

function watchScrollWindowFactory($window): ng.IDirective {
    return {
            
        restrict: 'A',
        controller: watchScrollController,
        controllerAs: 'asCtrl',
        bindToController: true,
        scope: {},
        link: function (scope: any, element, attrs, applicantInfoCtrl: collapseOnScrollController) {
            angular.element($window).on('scroll', function (e) {
                scope.asCtrl.broadcast(element.scrollTop());
            });
        }
    };
}
 

    
    
angular
    .module('app')
    .directive('watchScrollWindow', ['$window', ($window) => watchScrollWindowFactory($window)])

