import { AddBranchModalController } from '../branch/AddBranchModalController'
import _ from 'lodash-es';

class AgencyBranchesController {

    orderBy: string = 'name';

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    static $inject = ['$scope', '$state', 'Auth', '$uibModal', 'Branches', 'agencyId', 'branches'];
    constructor($scope, private $state, private Auth, private $uibModal, private Branches, private agencyId, private branches) {

        this.selections = [];
        this.selectedGridItems = [];

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {

                var url = this.$state.href('app.branch.basicDetails', { branchId: values[0].id });
                if (url) {
                    window.open(url, '_blank');
                }
                this.selectedGridItems = [];
            }
        }, true);
    }

    add() {
        return this.$uibModal
            .open({
                templateUrl: 'areas/agency/branch/AddBranchModal.html',
                controller: AddBranchModalController,
                controllerAs: '$ctrl',
                backdrop: false,
                resolve: {
                    branch: {
                        agencyId: this.agencyId
                    }
                }
            })
            .result
            .then(() => {
                return this.refresh();
            });
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.branches) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.branches) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                id: item.id
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.branches) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }

    refresh() {
        this.Branches.queryForAgency({ agencyId: this.agencyId })
            .$promise
            .then((data) => {
                this.branches = data;

                this.selections = [];
            })
    }
}

angular
    .module('app')
    .controller('AgencyBranchesController', AgencyBranchesController)