
import _ from 'lodash-es'
import { IAction } from '../IAction'

export class FailApplication implements IAction {

    static $inject = ['$uibModal', 'Auth', '$rootScope', 'applicationId']
    constructor(private $uibModal, private Auth, private $rootScope, private applicationId) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/failApplication/FailApplicationModal.html',
            controller: FailApplicationModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId,
                rootScope: () => this.$rootScope
            }
        }).result;
    }
}

class FailApplicationModalController {
    public selectedreason: any;
    public failReasonList: any[];
    public commentText: string;
    public payCommission: boolean;

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'applicationId', 'FailApplication', 'Auth', 'rootScope'];
    constructor(private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private applicationId, private FailApplication, private Auth, private rootScope) {
        this.loadFailList();
    }

    reasonDescription = (inter) => `${inter.statusDescription}`;

    loadFailList() {
        this.FailApplication.query({}, (response) => {
            this.failReasonList = response;
        });
    }

    failApplication() {
        var user = this.Auth.user;

        if (angular.isObject(this.selectedreason)) {
            var data = {
                ApplicationId: this.applicationId,
                UserName: user.firstName + ' ' + user.lastName,
                SubStatusId: this.selectedreason.subStatusId,
                StatusDescription: this.selectedreason.statusDescription,
                CommentText: this.commentText,
                PayCommission: this.payCommission
            };

            this.FailApplication.update({ applicationId: this.applicationId }, data)
                .$promise
                .then((results) => {
                    if (_.some(results, (r: any) => r.success == false)) {
                        this.dialogs.error('Internal error', 'Error occured while failing this application. Please try again after a while.');
                    }
                    this.rootScope.$broadcast('cancelFailApplication', data);
                })
                .finally(() => {
                    this.$uibModalInstance.close();
                });
        }
        else {
            this.dialogs.error('Validation Error', 'Please choose your reason for failing this application, and whether a commission payment should be generated for the agent.');
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
