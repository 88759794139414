export enum InsuranceType {
    None = 0,
    TravelPackage = 1,
    MedicalUpgrade = 2,
    ComprehensiveInsurance = 3,
    ComprehensiveAndCancellationInsurance = 4,
    CancellationInsurance = 5
}


