import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class FinanceFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'finance'
        this.initialize()
    }

    initialize() {
        const resources: Array<Resource> = [
            {
                name: 'Invoice',
                url: 'finance/application/:applicationId/invoices/:invoiceId',
                actions: {
                    current: {
                        method: 'GET',
                        url: 'finance/application/:applicationId/current-invoice'
                    },
                    list: {
                        method: 'GET',
                        url: 'finance/application/:applicationId/invoices',
                        isArray: true
                    },
                    // temporary creating invoices manually for testing purposes
                    create: {
                        method: 'POST',
                        url: 'finance/application/:applicationId/create-invoice',
                        params: { applicationId: '@applicationId' }
                    },
                    cancel: {
                        method: 'POST',
                        url: 'finance/application/:applicationId/invoices/:invoiceId/cancel',
                        params: { applicationId: '@applicationId', invoiceId: '@invoiceId' }
                    },
                    refund: {
                        method: 'POST',
                        url: 'finance/application/:applicationId/invoices/:invoiceId/refund',
                        params: { applicationId: '@applicationId', invoiceId: '@invoiceId' }
                    },
                    recordPayment: {
                        method: 'POST',
                        url: 'finance/application/:applicationId/invoices/:invoiceId/recordPayment',
                        params: { applicationId: '@applicationId', invoiceId: '@invoiceId' }
                    },
                    recordInsurance: {
                        method: 'POST',
                        url: 'finance/application/:applicationId/invoices/:invoiceId/recordInsurance',
                        params: { applicationId: '@applicationId', invoiceId: '@invoiceId' }
                    },
                    recordAccountAdjustment: {
                        method: 'POST',
                        url: 'finance/application/:applicationId/invoices/:invoiceId/recordAccountAdjustment',
                        params: { applicationId: '@applicationId', invoiceId: '@invoiceId' }
                    },
                    availableInsurances: {
                        method: 'GET',
                        url: `finance/application/:applicationId/invoices/:invoiceId/availableInsurances`,
                        isArray: true,
                        transformResponse: this.trans.responseHandler((data) =>
                            data.map((e) => <any>{ code: e.insuranceType, name: `${e.summary} - ${e.amount} ${e.currencyCode}` })
                        )
                    },
                    applicationPromotions: {
                        method: 'GET',
                        url: 'finance/application/:applicationId/promotions',
                        isArray: true
                    }
                }
            },
            {
                name: 'Voucher',
                url: 'finance/application/:applicationId/voucher',
                params: { applicationId: '@applicationId' },
                actions: {
                    available: {
                        method: 'GET',
                        url: `finance/application/:applicationId/voucher/available`,
                        isArray: true
                    }
                }
            },
            {
                name: 'Finance',
                url: 'finance/application/:applicationId',
                actions: {
                    calculateRefundAmount: {
                        method: 'POST',
                        url: 'finance/static/refund/calculateCurrency'
                    },
                    checkIfApplicationHasBalancePayment: {
                        method: 'GET',
                        url: 'finance/application/:applicationId/checkIfApplicationHasBalancePayment',
                        params: { applicationId: '@applicationId' }
                    },
                    proofOfPaymentInformation: {
                        method: 'GET',
                        url: 'finance/application/:applicationId/proofOfPayment',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('paymentDateReceived')
                        )
                    },
                    saveProofOfPayment: {
                        method: 'POST',
                        url: 'finance/application/:applicationId/proofOfPayment',
                        params: { applicationId: '@applicationId' }
                    },
                    approveTransaction: {
                        method: 'POST',
                        url: 'finance/approveTransaction'
                    },
                    removeUnapproveTransaction: {
                        method: 'DELETE',
                        url: 'finance/removeUnapproveTransaction'
                    },
                    approveManualPayment: {
                        method: 'POST',
                        url: 'finance/approveManualPayment'
                    },
                    removeManualPayment: {
                        method: 'DELETE',
                        url: 'finance/removeManualPayment'
                    },
                    approveRefund: {
                        method: 'POST',
                        url: 'finance/approveRefund'
                    },
                    removeUnapproveRefund: {
                        method: 'DELETE',
                        url: 'finance/removeUnapproveRefund'
                    },
                    cancelInvoices: {
                        method: 'DELETE',
                        url: 'finance/cancelInvoices'
                    },
                    approvedTransaction: {
                        method: 'GET',
                        url: 'finance/approvedTransaction/:transactionID',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('dateEntered', 'transactionDate')
                        )
                    },
                    unapprovedTransaction: {
                        method: 'GET',
                        url: 'finance/unapprovedTransaction/:transactionID',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('dateEntered', 'transactionDate')
                        )
                    },
                    hasUnapprovedTransaction: {
                        method: 'GET',
                        url: 'finance/application/:applicationId/hasUnapprovedTransaction',
                        params: { applicationId: '@applicationId' }
                    },
                    voucherInformation: {
                        method: 'GET',
                        url: 'finance/application/:applicationId/voucherInformation',
                        params: { applicationId: '@applicationId' }
                    }
                    //removeVoucher: {
                    //    method: 'DELETE',
                    //    url: 'finance/application/:applicationId/removeVoucher',
                    //    params: { applicationId: '@applicationId' }
                    //}
                }
            },
            {
                name: 'CommissionStatementPaymentDates',
                url: '',
                params: { agencyId: '@agencyId' },
                actions: {
                    query: {
                        method: 'GET',
                        url: `finance/agency/:agencyId/agencyCommissionPaymentDates`,
                        isArray: true
                    }
                }
            },
            {
                name: 'AgencyApexPromotionsDiscounts',
                url: '',
                params: { agencyId: '@agencyId' },
                actions: {
                    query: {
                        method: 'Post',
                        url: `finance/agency/:agencyId/agencyApexPromotionsDiscounts`,
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate', 'availableDiscounts.validFrom', 'availableDiscounts.validTo')
                        )
                    }
                }
            },
            {
                name: 'ProofOfPayment',
                url: 'finance/application/:applicationId/proofOfPayment',
                params: { applicationId: '@applicationId' },
                actions: {
                    query: {
                        method: 'GET',
                        url: `finance/agency/:agencyId/agencyCommissionPaymentDates`,
                        isArray: true
                    }


                }
            },
            {
                name: 'Promotions',
                url: '',
                actions: {
                    all: {
                        method: 'POST',
                        params: {
                            kind: '@kind'
                        },
                        url: `finance/promotions/kind/:kind`,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('promotions.startDate', 'promotions.endDate', 'promotions.startOlaDate', 'promotions.endOlaDate', 'promotions.startApplicableDate', 'promotions.endApplicableDate')
                        )
                    },
                    activeApplicationsPromotions: {
                        method: 'GET',
                        isArray: true,
                        url: `finance/promotions/activeApplicationsPromotions`
                    }
                }
            },
            {
                name: 'PrintInvoiceForm',
                url: '',
                actions: {
                    printInvoiceUrl: {
                        method: 'GET',
                        url: 'finance/invoice/printInvoiceUrl'
                    }
                }
            },
            {
                name: 'AgencyCommission',
                url: '',
                actions: {
                    all: {
                        method: 'POST',
                        url: `finance/agencyCommissions`,
                    }
                }
            },
            {
                name: 'ContactCommission',
                url: 'finance/contactCommissions/:commissionId',
                params: {
                    commissionId: '@commissionId'
                },
                actions: {
                    queryByContact: {
                        method: 'POST',
                        url: 'finance/contact/:contactId/contactCommissions',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('contactCommissions.startDate', 'contactCommissions.endDate')
                        )
                    }
                }
            },
            {
                name: 'CommissionPayments',
                url: '',
                actions: {
                    all: {
                        method: 'POST',
                        url: `finance/commissionPayments`,
                    },
                    approve: {
                        method: 'POST',
                        url: `finance/commissionPayments/approve`,
                        isArray: true
                    },
                    suppress: {
                        method: 'POST',
                        isArray: true,
                        url: `finance/commissionPayments/:paymentId/suppress`,
                    },
                    generate: {
                        method: 'POST',
                        url: `finance/commissionPayments/generate`
                    }
                }
            },
            {
                name: 'AgencyContributions',
                url: 'finance/agencyContributions/:id',
                params: {
                    id: '@id'
                },
                actions: {
                    all: {
                        method: 'GET',
                        url: `finance/agencyContributions`,
                    },
                    getPerYear: {
                        method: 'GET',
                        url: `finance/agency/:agencyId/agencyContributions`,
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('addedAt', 'paidAt')
                        )
                    },
                    approve: {
                        method: 'POST',
                        url: `finance/agencyContributions/approve`,
                        isArray: true
                    },
                    suppress: {
                        method: 'POST',
                        isArray: true,
                        url: `finance/agencyContributions/:paymentId/suppress`,
                    },
                    generate: {
                        method: 'POST',
                        url: `finance/agencyContributions/generate`
                    }
                }
            },
            {
                name: 'PaymentOptions',
                url: 'finance/country/:countryCode/paymentOptions',
                params: { countryCode: '@countryCode' },
                actions: {
                    byCountry: {
                        method: 'GET',
                        url: 'finance/country/:countryCode/paymentOptions',
                        params: { countryCode: '@countryCode' },
                    },
                    byAgency: {
                        method: 'GET',
                        url: 'finance/agency/:agencyId/country/:countryCode/paymentOptions',
                        params: { agencyId: '@agencyId', countryCode: '@countryCode'},
                    }
                }
            },
            {
                name: 'FinanceLookup',
                url: 'finance/lookup',
                actions: {
                    refundCurrenciesInUse: {
                        method: 'GET',
                        url: 'finance/lookup/refund/currencies-in-use',
                        isArray: true,
                        cache: true
                    },
                    refundReasons: {
                        method: 'GET',
                        url: 'finance/lookup/refund/reasons',
                        isArray: true,
                        cache: true
                    },
                    refundAccountCodes: {
                        method: 'GET',
                        url: 'finance/lookup/refund/accountCodes',
                        isArray: true,
                        cache: true
                    },
                    currency: {
                        method: 'GET',
                        url: 'finance/lookup/currency',
                        isArray: true,
                        cache: true
                    },
                    adjustmentAccountCodes: {
                        method: 'GET',
                        url: 'finance/lookup/adjustment/accountCodes',
                        isArray: true,
                        cache: true
                    },
                    manualPaymentsAccountCodes: {
                        method: 'GET',
                        url: 'finance/lookup/manualPayments/accountCodes',
                        isArray: true,
                        cache: true
                    },
                    allCurrency: {
                        method: 'GET',
                        url: 'finance/lookup/allCurrency',
                        isArray: true,
                        cache: true
                    },
                    feeNames: {
                        method: 'GET',
                        url: 'finance/lookup/feeNames',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(this.trans.convertToListboxItems('name', 'feeId'))
                    },
                    paymentFrequencies: {
                        method: 'GET',
                        url: 'finance/lookup/paymentFrequencies',
                        isArray: true,
                        cache: true
                    },
                    paymentRegion: {
                        method: 'GET',
                        url: 'finance/lookup/paymentRegion',
                        isArray: true,
                        cache: true
                    },
                    paymentMethods: {
                        method: 'GET',
                        url: 'finance/lookup/paymentMethods',
                        isArray: true,
                        cache: true
                    },
                    paymentCurrencies: {
                        method: 'GET',
                        url: 'finance/lookup/paymentCurrencies',
                        isArray: true,
                        cache: true
                    }
                }
            },
            {
                name: 'ManualPayment',
                url: 'finance/manualPayment/:accountTransactionId',
            },
            {
                name: 'Payments',
                url: '',
                actions: {
                    agencyInfo: {
                        method: 'GET',
                        url: 'finance/paymentInfo/agency/:agencyId'
                    },
                    contactInfo: {
                        method: 'GET',
                        url: 'finance/paymentInfo/contact/:contactId'
                    },
                    agencyPayments: {
                        method: 'POST',
                        url: 'finance/agency/:agencyId/payments'
                    },
                    contactPayments: {
                        method: 'POST',
                        url: 'finance/contact/:contactId/payments'
                    }
                }
            },
            {
                name: 'FeeRegions',
                url: 'finance/feeRegion/financialPeriod/:financialPeriodId/invoiceRegion/:invoiceRegionId/countries',
                params: {
                    financialPeriodId: '@financialPeriodId',
                    invoiceRegionId: '@invoiceRegionId'
                },
                actions: {
                    financialPeriods: {
                        method: 'GET',
                        url: 'finance/feeRegion/financialPeriods',
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate', 'displayEndDate')
                        )
                    },
                    invoiceRegions: {
                        method: 'GET',
                        url: 'finance/feeRegion/invoiceRegions',
                        isArray: true
                    },
                    available: {
                        method: 'GET',
                        url: 'finance/feeRegion/financialPeriod/:financialPeriodId/invoiceRegion/:invoiceRegionId/availableCountries',
                        isArray: true
                    },
                    import: {
                        method: 'POST',
                        url: 'finance/feeRegion/financialPeriod/:financialPeriodId/import'
                    },
                    query: { method: 'GET', isArray: true }
                }
            },
            {
                name: 'Fee',
                url: 'finance/fee/:id',
                actions: {
                        
                    query: {
                        method: 'GET',
                        url: 'finance/fee/financialPeriod/:financialPeriodId/invoiceRegion/:invoiceRegionId',
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate', 'displayEndDate')
                        )
                    },
                    listByCountry: {
                        method: 'GET',
                        url: 'finance/fee/financialPeriod/:financialPeriodId/countryCode/:countryCode',
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate', 'displayEndDate')
                        )
                    },
                    import: {
                        method: 'POST',
                        url: 'finance/fee/financialPeriod/:financialPeriodId/import',
                        params: {
                            financialPeriodId: '@financialPeriodId'
                        }
                    },
                    saveChanges: {
                        method: 'POST',
                        url: 'finance/fee/update'
                    }
                        
                }
            },
            {
                name: 'Promotion',
                url: 'finance/promotions/:id',
                actions: {
                    query: {
                        url: 'finance/promotions/:promotionId/kind/:kind',
                        params: {
                            promotionId: '@promotionId',
                            kind: '@kind'
                        },
                        method: 'GET',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate', 'startApplicableDate', 'endApplicableDate', 'startOlaDate', 'endOlaDate')
                        )
                    },
                    save: {
                        url: 'finance/promotions',
                        method: 'POST',
                    },
                    remove: {
                        url: 'finance/promotions',
                        method: 'DELETE',
                    },
                    applicableApplicants: {
                        url: 'finance/promotions/applicableApplicants',
                        method: 'POST',
                    },

                    agencyByRegion: {
                        url: 'finance/agencyByRegion/:countryCode',
                        method: 'GET',
                        isArray: true
                    },
                    countryByRegion: {
                        url: 'finance/contactCountryByRegion/:currencyCode',
                        method: 'GET',
                        isArray: true
                    },
                    feesByRegion: {
                        url: 'finance/feesByRegion/:currencyCode',
                        method: 'GET',
                        isArray: true
                    }
                }
            },
            {
                name: 'FinancialPeriods',
                url: '',
                actions: {
                    all: {
                        method: 'POST',
                        url: `finance/financialPeriods/all`,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('financialPeriods.startDate', 'financialPeriods.endDate', 'financialPeriods.displayEndDate')
                        )
                    },
                    query: {
                        url: 'finance/financialPeriods/:id',
                        params: {
                            id: '@id'
                        },
                        method: 'GET',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate', 'displayEndDate')
                        )
                    },
                    save: {
                        url: 'finance/financialPeriods',
                        method: 'POST',
                    }
                    //remove: {
                    //    url: 'finance/financialPeriods',
                    //    method: 'DELETE',
                    //}
                }
            },
            {
                name: 'AgencyPayments',
                url: '',
                actions: {
                    query: {
                        method: 'GET',
                        url: 'finance/agency/:agencyId/agencyPayment',
                        params: {
                            agencyId: '@agencyId'
                        }
                    },
                    save: {
                        method: 'POST',
                        url: 'finance/agencyPayment'
                    }
                }
            },
            {
                name: 'ContactPaymentDetails',
                url: 'finance/contact/:contactId/contactPayment',
                params: {
                    contactId: '@contactId'
                },
                actions: {
                    save: {
                        method: 'POST',
                        url: 'finance/contactPayment'
                    }
                }
            },
            {
                name: 'ApplicationFinanceDetails',
                url: 'finance/application/:applicationId/applicationFinanceDetails',
                actions: {
                    get: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    },
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            }
        ]

        this.process(resources)
    }
}

new FinanceFactory()
