 
import { MultiDocumentUploader, SingleDocumentUploader } from '../controls/documentsUpload/DocumentUploadConfig';

class ContractUploaderProvider implements ng.IServiceProvider {

    $get = ['ContractDocument',
        function (ContractDocument) {

            class ContractUploader implements SingleDocumentUploader, MultiDocumentUploader {
                constructor( private contractId: number, private agencyTerritoryId: number,
                    private category: number, private canViewOnly: boolean) {
                    //canViewOnly is used in hiding delete icon 
                }

                getDocumentType() {
                    return this.getDocumentTypes()
                        .then((documentTypes) => {
                            if (angular.isArray(documentTypes) && documentTypes.length > 0) {
                                return documentTypes[0];                                    
                            }
                        });
                }

                getDocumentTypes() {
                    var data = {
                        categoryId: this.category,
                        contractId: this.contractId,
                        agencyTerritoryId: this.agencyTerritoryId
                    }
                    return ContractDocument.query(data).$promise
                }

                prepareUpload(documentType: number) {
                    return ContractDocument.prepareUpload({
                        categoryId: this.category,
                        documentType: documentType,
                        contractId: this.contractId,
                        agencyTerritoryId: this.agencyTerritoryId
                    }).$promise;
                }

                uploadDescription(documentId: number) {
                    return ContractDocument.uploadDescription({
                        documentId: documentId
                    }).$promise;
                }

                addFile(file) {
                    return ContractDocument
                        .addPage({
                            documentId: file.documentId
                        }, {
                                pageId: file.pageId,
                                path: file.path
                            })
                        .$promise;
                }

                uploadSuccess(document, uploadDescription) {
                    return ContractDocument.uploadSuccess({
                        contractId: this.contractId,
                        agencyTerritoryId: this.agencyTerritoryId,
                        documentId: uploadDescription.documentId,                            
                        files: document.files,
                        description: document.description,
                        categoryId: this.category
                    }).$promise;
                }

                uploadFailed(uploadDescription, error) {
                    return ContractDocument.uploadFailed({
                        documentId: uploadDescription.documentId,
                        errorCode: error.code,
                        errorMessage: error.message
                    }).$promise;
                }
            }

            return ContractUploader;
        }];
}

angular
    .module('app')
    .provider('ContractUploader', ContractUploaderProvider);

