 
import _ from 'lodash-es'

interface TransformationFunction {
    (data: any): any;
}


export class DataTransformation {

    private objectToString(object: any): string {
        return _.reduce(object, function (result, val, key) {
            if (val) result.push(key);
            return result;
        }, []).join(',');
    }

    private stringToObject(text: string): any {
        text = text || ''
        return _.reduce(text.split(','), function (result: any, val) {
            if (val) result[val] = true;
            return result;
        }, {});
    }

    private objectToArray(object: any): any[] {
        return _.reduce(object, function (result, val, key) {
            if (val) result.push(key);
            return result;
        }, []);
    }

    private arrayToObject(array: any[]): any {
        array = array || []
        return _.reduce(array, function (result: any, val) {
            if (val) result[val] = true;
            return result;
        }, {});
    }


    private replaceValue(data: any, property: string, trans: TransformationFunction) {
        if (!data) {
            return;
        }

        if (angular.isArray(data)) {
            for (let item of data) {
                this.replaceValue(item, property, trans);
            }
        } else {
            var part = property.split('.')[0];
            if (part == property) {
                data[property] = trans(data[property]);
            } else {
                property = property.substring(part.length + 1);
                this.replaceValue(data[part], property, trans);
            }
        }
    }

    private getGetter(property): Function {
        return angular.isFunction(property) ? property : (o) => o[property];
    }

    convertCheckboxToString(...properties: string[]) {
        return (data) => {
            if (angular.isUndefined(data)) return data;
            else if (angular.isString(data)) data = JSON.parse(data);
            else data = angular.copy(data);

            _.forEach(properties, (prop) => {
                this.replaceValue(data, prop, this.objectToString)
                //data[prop] = this.objectToString(data[prop]);
            });

            return JSON.stringify(data);
        };
    }

    convertCheckboxToArray(...properties: string[]) {
        return (data) => {
            if (angular.isUndefined(data)) return data;
            else if (angular.isString(data)) data = JSON.parse(data);
            else data = angular.copy(data);

            _.forEach(properties, (prop) => {
                this.replaceValue(data, prop, this.objectToArray)
                //data[prop] = this.objectToString(data[prop]);
            });

            return JSON.stringify(data);;
        };
    }

    exclude(...properties: string[]) {
        return (data) => {
            if (!data) return null;

            if (angular.isUndefined(data)) return data;
            else if (angular.isString(data)) data = JSON.parse(data);
            else data = angular.copy(data);

            _.forEach(properties, function (prop) {
                delete data[prop];
            });

            return JSON.stringify(data);
        };
    }



    responseHandler(...func: Function[]) {
        return (data, headersGetter, status) => {
            if (!data || status != 200) return data;

            if (angular.isString(data)) data = JSON.parse(data);

            for (let fun of func) {
                data = fun(data);
            }
            return data;
        }
    }

    convertStringToCheckbox(...properties: string[]) {
        return (data) => {
               
            _.forEach(properties, (prop) => {
                this.replaceValue(data, prop, this.stringToObject);
            });

            return data;
        };
    }

    convertArrayToCheckbox(...properties: string[]) {
        return (data) => {

            _.forEach(properties, (prop) => {
                this.replaceValue(data, prop, this.arrayToObject);
            });

            return data;
        };
    }

    convertToDate(...properties: string[]) {
        return (data) => {

            _.forEach(properties, (prop) => {
                this.replaceValue(data, prop, (p) => {
                    return angular.isString(p) ? new Date(p) : p;
                });
            });

            return data;
        };
    }

    convertToListboxItems(nameProp: string | Function, codeProp: string | Function) {
        var nameGetter = this.getGetter(nameProp);
        var codeGetter = this.getGetter(codeProp);

        return function (data, headersGetter, status) {
            return _.map(data, function (item, i) {
                return angular.extend({}, item, { name: nameGetter(item, i), code: codeGetter(item, i) } );
            });
        };
    }

    convertToListboxItemsWithTooltip(nameProp: string | Function, codeProp: string | Function, tooltipProp: string | Function) {
        var nameGetter = this.getGetter(nameProp);
        var codeGetter = this.getGetter(codeProp);
        var tooltipGetter = this.getGetter(tooltipProp);

        return function (data, headersGetter, status) {
            return _.map(data, function (item, i) {
                return angular.extend({}, item, { name: nameGetter(item, i), code: codeGetter(item, i), tooltip: tooltipGetter(item, i) });
            });
        };
    }

    // Only used to render dropdowns from US - difference is data.data.items
    convertToListboxItemsUS(nameProp: string | Function, codeProp: string | Function) {
        var nameGetter = this.getGetter(nameProp);
        var codeGetter = this.getGetter(codeProp);

        return function (data, headersGetter, status) {
            return _.map(data.data.items, function (item) {
                return { name: nameGetter(item), code: codeGetter(item) };
            });
        };
    }


}
