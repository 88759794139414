 

class MediaCarouselController {
        
    config;
    maxVideo;
    maxPhoto;

    groups;
    private currentGroup: any[];

    static $inject = ['$scope', '$uibModal', 'ApplicationDocument', 'DocumentUpload'];
    constructor(private $scope, private $uibModal, private ApplicationDocument, private DocumentUpload) {
            
    }

    $onInit() {
        this.loadMedia();
    }

    private addDocument(document) {
        if (this.currentGroup.length >= 3) {
            this.currentGroup = [];
            this.groups.push(this.currentGroup);
        }
        this.currentGroup.push(document);
    }

    private clearDocuments() {
        this.currentGroup = [];
        this.groups = [];
        this.groups.push(this.currentGroup);
    }

    private loadMedia() {
        this.ApplicationDocument.query({
            applicationId: this.config.applicationId,
            filter: this.config.category
        }).$promise.then((documentTypes) => {
            var remaindVideo = this.maxVideo;
            var remaindPhoto = this.maxPhoto;

            this.clearDocuments();

            for (var i = 0; i < documentTypes.documents.length; i++) {
                var documents = documentTypes.documents[i].documents;

                if (documentTypes.documents[i].id === 6) {
                    remaindPhoto -= documents.length;
                } else if (documentTypes.documents[i].id === 35) {
                    remaindVideo -= documents.length;
                }

                for (var j = 0; j < documents.length; j++) {
                    this.addDocument(documents[j]);
                }
            }

            //if (remaindVideo > 0) 
            //    this.addDocument({ isAddVideo: true });

            if (remaindPhoto > 0)
                this.addDocument({ isAddPhoto: true });
        });
    }

    private fileSelected(files, documentType) {
        if (!angular.isArray(files) || files.length === 0)
            return;

        this.$uibModal.open({
            templateUrl: 'controls/documentsUpload/documentModal/singlePage/documentUploadModal.html',
            controller: 'DocumentUploadModalController as dumCtrl',
            backdrop: false,
            resolve: {
                uploadDescription: () => {
                    var data = {
                        documentType: documentType,
                        applicationId: this.config.applicationId,
                        contactId : this.config.contactId
                    };
                    return this.ApplicationDocument.prepareUpload(data).$promise;
                },
                files: () => files,
                hideDescription: () => false
            }
        }).result.then((document) => {
            return this.ApplicationDocument.uploadSuccess(document).$promise;
        }).then((document) => {
            var addPhotoEl = this.currentGroup.pop();

            this.addDocument(document);
            this.addDocument(addPhotoEl);

            //return this.loadMedia();
        });
    }

    videoSelected(files) {
        this.fileSelected(files, 35);
    }

    photoSelected(files) {
        this.fileSelected(files, 6);
    }

    openDialog(document) {
        this.$uibModal.open({
            templateUrl: 'controls/documentsUpload/documentModal/singlePage/documentDisplayModal.html',
            controller: 'DocumentDisplayModalController as ddmCtrl',
            backdrop: false,
            resolve: {
                documentType: () => document.documentType,
                documentDefinition: () => this.DocumentUpload.documentDefinition({ documentType: document.documentType }),
                uploader: () => this.config.uploader,

                document: () => document
            }
        }).result.then(() => {
            this.loadMedia();
        });
    }
}

    
var MediaCarouselDirectiveFactory = [function MediaCarouselDirectiveFactory (): ng.IDirective {
    return {
        restrict: 'AE',
        scope: {
            config: '=mediaCarousel',
            maxVideo: '=',
            maxPhoto: '='
        },
        controller: MediaCarouselController,
        controllerAs: 'mcCtrl',
        bindToController: true,
        templateUrl: 'controls/documentsUpload/mediaCarousel/mediaCarousel.html'
    };
}]


angular
    .module('app')
    .directive('mediaCarousel', MediaCarouselDirectiveFactory);

