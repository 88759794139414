 

class AgencyTerritoryFeeSheetDirective implements ng.IDirective {
    restrict = 'AE'
    controller = AgencyTerritoryFeeSheetController
    controllerAs = 'ctrl'
    bindToController = true
    templateUrl = 'controls/feeSheet/agencyTerritoryFeeSheet.html'
    scope = {
        data: '='
    }
}

class AgencyTerritoryFeeSheetController {
    data: any;
    localAgreementDetails: any;
    currencyList: any[];

    agencyId: number;
    contractId: number;
    $dirty: boolean = false;

    static $inject = ['$rootScope', '$scope', 'Auth', '$q', 'FinanceLookup', 'AgencyContract', 'dialogs'];
    constructor(private $rootScope, private $scope, private Auth, private $q, FinanceLookup, private AgencyContract, private dialogs) {
 
    }

    $onInit() {
        this.data.currencyCode = this.data.currencyCode || "";
    }

}

angular
    .module('app')
    .directive('agencyTerritoryFeeSheet', () => new AgencyTerritoryFeeSheetDirective());