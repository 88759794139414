 

var ResourceHelpTextDirectiveFactory = ['$q', '$compile', '$parse', 'ResourcesLoader', function ResourceHelpTextDirectiveFactory($q: ng.IQService, $compile, $parse, ResourcesLoader): ng.IDirective {

    function compile(elem, attr) {

        var parts = attr.resourceHelpText.split('.');
        if (parts.length !== 2) {
            console.log(`resourceHelpText - wrong value: ${attr.resourceHelpText}`);
            return undefined;
        }
        var moduleName = parts[0];
        var key = parts[1];

        var promise = ResourcesLoader.getModule(moduleName).then(function (module) {
            return module && module[key];
        });

        return function (scope, elem) {
            if (!promise) return

            promise.then(function (value) {
                var helpText = angular.element('<div class="helpText"></div>');
                helpText.html(value);
                elem.after(helpText)

                //if (value) {
                //    elem.addClass('fa fa-info-circle');
                //    elem.attr('uib-tooltip', value);
                //    elem.attr('tooltip-placement', 'bottom');
                //}


                $compile(helpText)(scope);
            });
        };
    }
    return {
        restrict: 'AE',
        priority: 1001,
        terminal: true,
        compile: <ng.IDirectiveCompileFn>compile
    };
}]

angular
    .module('app')
    .directive('resourceHelpText', ResourceHelpTextDirectiveFactory);
