import { ResourceGroupEnum } from '../../../models/ResourceGroup';


export class NewsArchiveController {
    static $inject = ['SystemAnnouncements'];

    systemAnnouncements: any[];

    constructor(private SystemAnnouncements) {
        this.systemAnnouncements = SystemAnnouncements.current();
    }

    announcementChange(announcement) {
        if (announcement.readByUser) {
            this.SystemAnnouncements.markAsRead({}, { id: announcement.id })
        } else {
            this.SystemAnnouncements.markAsUnread({}, { id: announcement.id })
        }
    }
}

angular
    .module('app')
    .controller('NewsArchiveController', NewsArchiveController);
