import { BusinessArea } from '../../services/AuthService';

class AgencyPaymentsListController {
        
    canViewContract: boolean;
    canAcceptContract: boolean;

    financeArea: boolean;

    agencyPayments: any[];
    years: any[];
    selectedYear: string;
        
    static $inject = ['$uibModal', '$scope', 'Auth', 'ManualPayment', 'agencyId', 'agency', 'Payments', 'dialogs', 'paymentInfo'];
    constructor(private $uibModal, private $scope, Auth, private ManualPayment, private agencyId, private agency, private Payments, private dialogs, private paymentInfo) {

        this.financeArea = Auth.isInBusinessArea(BusinessArea.FINANCE);
        if (this.financeArea) {
            this.loadPayments(true);
        }
    } 

    loadPayments(isSetYear) {
        this.Payments.agencyPayments({ agencyId: this.agencyId }, { agencyId: this.agencyId, selectedYear: this.selectedYear })
            .$promise
            .then((res) => {
                this.agencyPayments = res.payments;
                    
                if (!this.years && isSetYear) {
                    this.selectedYear = res.selectedYear;
                    this.years = res.years;
                }
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while load payment for agency. Please try again after a while.')
            });
    }

    manualPayment() {
        if (!this.paymentInfo.creditorId) {
            return;
        }

        this.$uibModal.open({
            templateUrl: 'areas/payments/manualPaymentModal/manualPaymentModal.html',
            controller: 'ManualPaymentController',
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                paymentInfo: () => this.paymentInfo,
                data: () => <any>{
                    agencyId: this.agencyId,
                    agency: `${this.agencyId} - ${this.agency.agencyName}`
                },
                title: () => ''
            }
        }).result.then(() => {
            this.loadPayments(true);
        })
    }

    refresh() {
        this.loadPayments(true);
    }
}

angular
    .module('app')
    .controller('AgencyPaymentsListController', AgencyPaymentsListController)
