import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class ReportFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {
            query: { method: 'GET', params: this.queryParams },
            update: { method: 'PUT', params: { applicantId: '@applicantId' } },
            remove: { method: 'DELETE', params: { applicantId: '@applicantId' } }
        }
        this.server = 'report'
        this.initialize()
    }


    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'Report',
                url: 'report/:reportId',
                actions: {
                    query: {
                        method: 'GET',
                        isArray: true
                    },
                    getUrl: {
                        url: 'report/:reportId/URL',
                        method: 'GET'
                    },
                    downloadUrl: {
                        url: 'report/:reportId/downloadUrl',
                        method: 'GET'
                    }
                }
            }
        ]

        this.process(resources)
    }
}

new ReportFactory()

