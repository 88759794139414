 

class scrollToInvalidController {

    isCollapse: boolean

    static $inject = ['$scope', '$state', 'SearchApplicant', 'Auth', 'dialogs'];
    constructor(private $scope, private $state, private SearchApplicant, private Auth, private dialogs) {

    }
}

var ScrollToInvalidDirectiveFactory = ['$location', '$urlRouter', '$anchorScroll', function ScrollToInvalidDirectiveFactory($location, $urlRouter, $anchorScroll): ng.IDirective {

    function link(scope, elem, attr, ctrls) {

        function temp() {
            var question = $(elem)
                .closest("form")
                .find(".question:not(.ng-hide) .ng-invalid")
                .first()
                .closest(".question");
            var id = question.length && question[0].id;
            $location.hash(id);
            $urlRouter.update(true);
            $anchorScroll();
        }
            
        elem.on('click.invalidScroll', temp);
        scope.$on('$destroy', function () {
            elem.off('click.invalidScroll');
        });
    }

    return {
        restrict: 'A',
        controller: scrollToInvalidController,
        controllerAs: 'collapsCtrl',
        require: ['^form'],
        link: link
    };
}]

    


angular
    .module('app')
    .directive('scrollToInvalid', ScrollToInvalidDirectiveFactory);