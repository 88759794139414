 import _ from 'lodash-es'

class AccessLevels {

    has(level) {
        return Object.keys(this).indexOf(level) >= 0;
    }

    firstMatch(obj) {
        var keys = Object.keys(this);

        // reorder by priority
        var el: any = _.chain(obj)
            .filter((item, key: string) => keys.indexOf(key) >= 0)
            .sortBy((item: any) => angular.isDefined(item.priority) ? -item.priority : 0)
            .first();
        return  el.value();
    }
}

export class AccessLevelsManager {
    public userLevels;
    private userLevelsPromise;

    constructor(private $injector, private $q, private levels) {
        this.userLevels = new AccessLevels();
    }


    apply(role: string) {
        var promises = [];

        //clean old roles
        for (let name of Object.keys(this.userLevels)) {
            delete this.userLevels[name];
        }

        for (let name of Object.keys(this.levels)) {
            let level = this.levels[name];
                
            // roles not included
            if (level.roles instanceof Array) {
                if (level.roles.indexOf(role) < 0) {
                    continue;
                }
            } else if (angular.isString(level.roles)) {
                if (level.roles != role) {
                    continue;
                }
            }

            // check condition
            if (angular.isDefined(level.condition)) {
                if (angular.isFunction(level.condition) || angular.isArray(level.condition)) {
                    var promise = this.$q
                        .when(this.$injector.invoke(level.condition, null, {}))
                        .then((value) => {
                            if (value) {
                                this.userLevels[name] = level;
                            }
                        });
                    promises.push(promise);
                    continue;
                } else if (!level.condition) {
                    continue;
                }
            }

            this.userLevels[name] = level;
        };

        return this.userLevelsPromise = this.$q
            .all(promises)
            .then(() => this.userLevels);
    }

    promise() {
        return this.userLevelsPromise;
    }
}


class AccessLevelProvider implements ng.IServiceProvider {
    private levels = {};
    private accessLevarsManager;

    accesslevel(name, level) {
        this.levels[name] = level;
    }

    $get = ['$injector', '$q', ($injector, $q) => {
        if (!this.accessLevarsManager)
            this.accessLevarsManager = new AccessLevelsManager($injector, $q, this.levels)

        return this.accessLevarsManager;
    }]
}

angular
    .module('app')
    .provider('AccessLevel', AccessLevelProvider);

