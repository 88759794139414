
class FlightOptionController {   
    canExtend: boolean;
    displayENFlight: boolean;
    isAWL: boolean;

    static $inject = ['Auth', 'USAuPairs', 'Spinner']
    constructor(private Auth, private USAuPairs, private Spinner) {
        this.Spinner.show();

        this.USAuPairs.canAupairExtend({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.canExtend = result.data.isEligibleToExtend;
                this.displayENFlight = result.data.isOkToShowExtensionFlight;
                if (result.data.apstatusCode == 'AWL') {
                    this.isAWL = true;
                }
            })
            .finally(() => {
                this.Spinner.hide();
            });
    }      
}
angular
    .module('app')
    .controller('FlightOptionController', FlightOptionController);