export enum ApplicationStatus {
    Registered = 'REG',
    Online = 'OLA',
    AwaitingAssessment = 'AWA',
    ToBeMatched = 'TBM',
    InRematch = 'REM',
    Active = 'ACT',
    Placed = 'PLC',
    DidNotQualify = 'DNQ',
    Departed = 'APD',
    Inactive = 'INA',
    AbsentWithoutLeave = 'AWL',
    Cancelled = 'CAN',
    Failed = 'FAI',
    Rejected = 'REJ',
    TimeExpired = 'TXP',
    OnHold = 'HLD',
    Archive = 'ARC'
}