 

function FullNameFilter(item) {
    //TODO - add nickname?
    if (item.middleName) {
        return `${item.firstName} ${item.middleName} ${item.lastName}`
    }
    else {
        return `${item.firstName} ${item.lastName}`
    }
}
    
function FullNameFiterFactory(): Function {
    return <Function>FullNameFilter
}

angular
    .module('app')
    .filter('fullName', [FullNameFiterFactory])