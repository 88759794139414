 

function BoolFiter(value, whenTrue, whenFalse) {
    if (angular.isDefined(value)) {
        return value ? whenTrue : whenFalse;
    }

    return;
}

function BoolFiterFactory(): Function {
    return BoolFiter;
}

angular
    .module('app')
    .filter('bool', [BoolFiterFactory]);

