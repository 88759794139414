import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'
import _ from 'lodash-es'


class AgencyFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'agency'
        this.initialize()
    }

    initialize() {
        const resources: Array<Resource> = [
            {
                name: 'AgencyAddressPage',
                url: 'agency/:agencyId/agencyAddress',
                actions: {}
            },
            {
                name: 'AgencyContact',
                url: '',
                actions: {
                    getAllData: {
                        url: 'agency/agencyContactList',
                        method: 'Post',
                        params: { contactId: '@contactId', contactName: '@contactName', contactEmailAddress: '@contactEmailAddress', contactStatus: '@contactStatus' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('result.dateCreated', 'result.lastLogin')
                        )
                    },
                    getAll: {
                        url: 'agency/:agencyId/agencyContactList',
                        method: 'Post',
                        params: { agencyId: '@agencyId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('result.dateCreated')
                        )
                    },
                    getAllActiveContacts: {
                        url: 'agency/:agencyId/allActiveAgencyContacts',
                        method: 'GET',
                        isArray: true
                    },
                    getAgencyMainContacts: {
                        url: 'agency/:agencyId/selectedContacts',
                        method: 'GET'
                    },
                    saveSelectedContacts: {
                        url: 'agency/:agencyId/selectedContacts',
                        method: 'POST'
                    },
                    saveAgencyContact: {
                        url: 'agency/:agencyId/agencyContacts',
                        method: 'POST'
                    },
                    approveContact: {
                        method: 'POST',
                        url: 'interviewer/approveContact',
                        isArray: true
                    },
                    queryForBranch: {
                        url: '/branches/:branchId/agencyContact',
                        isArray: true
                    }
                }
            },
            {
                name: 'AgencyLookup',
                url: '',
                actions: {
                    agencyTimeZone: {
                        url: `${this.server}/agencyTimeZone`,
                        method: 'GET',
                        isArray: true,
                        cache: true
                    },
                    targetTypes: {
                        url: `${this.server}/getTargetTypes`,
                        method: 'GET',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('description', 'targetType'),
                            )
                    },
                    agencyTerritories: {
                        url: `${this.server}/:agencyId/getAgencyTerritories`,
                        method: 'GET',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('countryName', 'countryCode'),
                        )
                    },
                    whenPayable: {
                        url: `${this.server}/whenPayable`,
                        method: 'GET',
                        isArray: true,
                        cache: true
                    },
                    agenciesParentAgency: {
                        url: `${this.server}/:agencyId/agencyParentCompany`,
                        method: 'GET',
                        isArray: true,
                        //cache: false
                    },
                    agencyBranches: {
                        url: `${this.server}/:agencyId/allAgencyBranches`,
                        method: 'GET',
                        isArray: true,
                        cache: false
                    },
                    statuses: {
                        url: `${this.server}/statuses`,
                        method: 'GET',
                        isArray: true,
                        cache: true
                    },
                    editableTargetYears: {
                        url: `${this.server}/editableTargetYears`,
                        method: 'GET',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('description', 'targetYear'),
                        )
                    },
                    agencies: {
                        url: `${this.server}/lookup/agency`,
                        method: 'GET',
                        isArray: true,
                        cache: true
                    },
                    sponsors: {
                        url: `${this.server}/lookup/sponsors`,
                        method: 'GET',
                        isArray: true,
                        cache: true
                    }
                }
            },
            {
                name: 'AgencyBasicDetailsPage',
                url: 'agency/:agencyId/agencyBasicDetails',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('deactivationDate')
                        )
                    }
                }
            },
            {
                name: 'AgencyBasicDetails',
                url: 'agency/:agencyId/agencyBasicDetails',
                params: { agencyId: '@agencyId', }
            },
            {
                name: 'AgencyAddress',
                url: '',
                actions: {
                    saveAgencyAddress: {
                        url: 'agency/:agencyId/agencyAddress',
                        method: 'POST'
                    }
                }
            },
            {
                name: 'AgencyTarget',
                url: '',
                actions: {
                    saveAgencyTarget: {
                        url: 'agency/:agencyId/saveAgencyTarget',
                        method: 'POST'
                    },
                    getAll: {
                        url: 'agency/:agencyId/getAllAgencyTargets',
                        method: 'GET',
                        isArray: true
                    },
                }
            },
            {
                name: 'AgencyContract',
                url: '',
                actions: {
                    create: {
                        url: 'agency/:agencyId/contract',
                        params: { agencyId: '@agencyId' },
                        method: 'POST'
                    },
                    getScheduledAndOfferedContracts: {
                        url: 'agency/:agencyId/scheduledAndOfferedContracts',
                        method: 'GET',
                        isArray: true
                    },
                    getScheduledOfferedAndNewContracts: {
                        url: 'agency/:agencyId/scheduledOfferedAndNewContracts',
                        method: 'GET',
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate')
                        )
                    },
                    getLatestAgencyContract: {
                        url: 'agency/:agencyId/getLatestAgencyContract',
                        params: { agencyId: '@agencyId' },
                        method: 'GET'
                    },
                    getContractById: {
                        url: 'agency/:agencyId/contractById',
                        params: { agencyId: '@agencyId'},
                        method: 'POST'
                    },
                    getContractInformationForMenu: {
                        url: 'agency/:agencyId/contractInformationForMenu',
                        params: { agencyId: '@agencyId' },
                        method: 'GET'
                    },
                    getAgencyContract: {
                        url: 'agency/:agencyId/contract/:contractId/agencyContractInformation',
                        params: { agencyId: '@agencyId', contractId:'@contractId' },
                        method: 'POST',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate')
                        )
                    },
                    checkAgencyHasAnyContractYet: {
                        url: 'agency/:agencyId/checkAgencyHasAnyContractYet',
                        params: { agencyId: '@agencyId' },
                        method: 'GET'
                    },
                    saveAgencyContract: {
                        url: 'agency/:agencyId/contract/:contractId/agencyContract',
                        params: { agencyId: '@agencyId', contractId: '@contractId' },
                        method: 'POST'
                    },
                    getAgencyTermsOfBusiness: {
                        url: 'agency/:agencyId/agencyTermsOfBusiness',
                        params: { agencyId: '@agencyId'},
                        method: 'POST'
                    },
                    acceptAgencyContract: {
                        method: 'POST',
                        url: 'agency/:agencyId/acceptAgencyContract',
                        params: { agencyId: '@agencyId' }
                    },
                    offerAgencyContract: {
                        method: 'POST',
                        url: 'agency/:agencyId/contract/:contractId/offerAgencyContract',
                        params: { agencyId: '@agencyId', contractId: '@contractId' }
                    },
                    allowResubmitContract: {
                        method: 'POST',
                        url: 'agency/:agencyId/contract/:contractId/allowResubmitContract',
                        params: { agencyId: '@agencyId', contractId: '@contractId' }
                    },
                    getAgencyContractFees: {
                        url: 'agency/:agencyId/agencyContractFees/:contractId',
                        method: 'GET',
                        isArray: true,
                        //params: { agencyId: '@agencyId' }
                    },
                    saveagencyContractFees: {
                        method: 'PUT',
                        url: 'agency/:agencyId/agencyContractFees/:contractId',
                        params: { agencyId: '@agencyId' }
                    },
                    createContracts: {
                        method: 'POST',
                        url: 'agency/createContracts',
                    },
                    terminate: {
                        method: 'POST',
                        url: 'agency/terminateContract',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('startDate', 'endDate')
                        )
                    }
                }
            },
            {
                name: 'AgencyContractFees',
                url: 'agency/:agencyId/agencyContractFees/:contractId',
                actions: {
                    query: {
                        method: 'GET',
                        isArray: true,
                        //params: { agencyId: '@agencyId' }
                    },
                    queryByApplication: {
                        method: 'POST',
                        isArray: true,
                        params: { agencyId: '@agencyId' },
                        url: 'agency/:agencyId/agencyContractFees',
                    },
                    save: {
                        method: 'POST',
                        //params: { agencyId: '@agencyId' }
                    },
                    submitFees: {
                        url: 'agency/:agencyId/agencyContractFees/:contractId/submitFees',
                    }
                }
            },
            {
                name: 'Agency',
                url: `agency/:agencyId`,
                actions: {
                    byCountry: {
                        method: 'GET',
                        url: `agency/country/:country`,
                        isArray: true
                    },
                    create: {
                        method: 'POST',
                        url: `agency/create`
                    },
                    agenciesByCountries: {
                        method: 'POST',
                        url: `agency/agenciesByCountries`,
                        isArray: true
                    },
                    assign: {
                        method: 'POST',
                        url: 'agency/:agencyId/assign',
                        params: { id: '@Id' },
                        isArray: true
                    },
                    agencyComments: {
                        url: `${this.server}/:agencyId/agencyComments`,
                        method: 'GET',
                        isArray: true,
                        cache: false
                    },
                    saveAgencyComment: {
                        url: 'agency/:agencyId/agencyComment',
                        params: { agencyId: '@agencyId' },
                        method: 'POST'
                    }
                    
                }
            }, ,
            {
                name: 'AgencyTerritory',
                url: `agency/:agencyId`,
                actions: {
                    query: {
                        method: 'GET',
                        url: 'agency/:agencyId/territories',
                        params: { agencyId: '@agencyId' },
                        isArray: true,
                        cache: false
                    },
                    territory: {
                        method: 'GET',
                        isArray: true,
                        cache: false
                    },
                    save: {
                        url: 'agency/:agencyId/territories',
                        params: { agencyId: '@agencyId' },
                        method: 'POST'
                    },
                    remove: {
                        url: 'agency/:agencyId/territories',
                        params: { agencyId: '@agencyId' },
                        method: 'DELETE'
                    },
                    completedFeesEntry: {
                        method: 'POST',
                        url: 'agency/:agencyId/completedFeesEntry',
                        params: { agencyId: '@agencyId' }
                    }
                }
            },
            {
                name: 'Interviewer',
                url: 'interviewer/:id',
                actions: {
                    get: {
                        method: 'GET',
                        params: { interviewerId: '@Id' },
                    },
                    query: {
                        isArray: true
                    },
                    requiredInterview: {
                        url: 'interviewer/:interviewerId/applicants/interview_required',
                        isArray: true
                    },
                    assign: {
                        method: 'POST',
                        url: 'interviewer/:id/assign',
                        params: { id: '@Id' },
                        isArray: true
                    },
                    agency: {
                        url: 'interviewer/agency/:agencyId',
                        params: { agencyId: '@agencyId' },
                        isArray: true
                    },
                    agencyActive: {
                        url: 'interviewer/agency/:agencyId/active',
                        params: { agencyId: '@agencyId' },
                        isArray: true
                    },
                    branchActive: {
                        url: 'interviewer/branch/:branchId/active',
                        params: { branchId: '@branchId' },
                        isArray: true
                    },
                    englishSpeaking: {
                        url: 'interviewer/agency/:agencyId/english_speaking',
                        params: { agencyId: '@agencyId' },
                        isArray: true
                    }
                }
            },
            {
                name: 'InterviewerApplicant',
                url: 'interviewer/:interviewerId/applicants/interview_required',
                actions: {
                    'requiredInterview': {
                        isArray: true
                    }
                }
            },
            {
                name: 'SearchApplicant',
                url: 'agency/search_applicant',
                actions: {
                    query: {
                        method: 'POST',
                        //params: {
                        //    agencyId: '@agencyId',
                        //    interviewerId: '@interviewerId',
                        //    contactId: '@contactId'
                        //},
                        isArray: false
                    }
                }
            },
            {
                name: 'ProfileData',
                url: 'interviewer/:interviewerId',
                actions: {
                    query: {
                        method: 'GET',
                        params: { interviewerId: '@interviewerId' } // We are sending contactId to get information                           
                    },
                    update: {
                        method: 'PUT',                          
                        params: { interviewerId: '@interviewerId' }
                    }                    
                }
            },
            {
                name: 'AgencyData',
                url: 'agency/:agencyId',
                actions: {
                    query: {
                        method: 'GET',
                        params: { agencyId: '@agencyId' }                          
                    },
                    update: {
                        method: 'PUT',
                        params: { agencyId: '@agencyId' }
                    }
                }
            },
            {
                name: 'ContactLondonOffice',
                url: 'application/contactLondonOffice',
                actions: {
                    send: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }

                }
            },
            {
                name: 'Branches',
                url: 'branches/:branchId',
                params: { branchId: '@branchId' },
                actions: {
                    queryForAgency: {
                        method: 'GET',
                        url: 'agency/:agencyId/branches',
                        //params: { agencyId: '@agencyId' },
                        isArray: true
                    }
                }
            },
            {
                name: 'InterviewerTraining',
                url: 'interviewer/:interviewerId/training',
                params: { interviewerId: '@interviewerId' },
                actions: {
                    query: {
                        method: 'GET',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox('overviewAupairPrograms')
                        )
                    },
                    save: {
                        method: 'POST',
                        transformRequest: _.flow(
                            this.trans.convertCheckboxToString('overviewAupairPrograms')
                        )
                    }
                }
            },
            {
                name: 'InterviewerLookup',
                url: '',
                actions: {
                    aupairProgramAndRegulations: {
                        url: 'interviewer/aupairProgramAndRegulations',
                        method: 'GET',
                        isArray: true,
                        cache: true
                    }
                }
            },
            {
                name: 'Competitors',
                url: '',
                actions: {                    
                    queryForAgency: {
                        method: 'GET',
                        url: 'competitors/agency/:agencyId',
                        params: { agencyId: '@agencyId' },
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('result.dateCreated', 'result.dateSubmitted')
                        )
                    },
                    query: {
                        method: 'GET',
                        url: 'competitors/:competitorId',
                        params: { competitorId: '@competitorId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('dateCreated', 'dateSubmitted')
                        )
                    },
                    validate: {
                        url: 'competitors/validate/canAdd',
                        method: 'POST'
                    },
                    addCompetitors: {
                        url: 'competitors/addCompRequest',
                        method: 'POST'
                    },
                    save: {
                        url: 'competitors',
                        method: 'POST'
                    },
                    submit: {
                        url: 'competitors/submit',
                        method: 'POST'
                    },
                    approve: {
                        url: 'competitors/approve',
                        method: 'POST'
                    }
                }
            }
        ]

        this.process(resources)
    }

}

new AgencyFactory()