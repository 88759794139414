export enum ReviewDocumentId {
    CriminalRecordCheckOriginal = 1,
    DrivingLicence = 2,
    EducationCertificateOriginal = 3,
    PassportCopy = 4,
    ProfilePhoto = 5,
    ChildcarePhoto = 6,
    MedicalReportOriginal = 13,
    Video = 35,
    FeeSheet = 37,
    RedCrossCertificate = 53,
    ProofOfCovid19Vaccination = 54
}

export interface DocumentMenuItem {
    name: string
}

export var Documents: { [index: number]: DocumentMenuItem } = {}
Documents[ReviewDocumentId.CriminalRecordCheckOriginal] = { name: 'Criminal Record Check Original' }
Documents[ReviewDocumentId.DrivingLicence] = { name: 'Driving License' }
Documents[ReviewDocumentId.PassportCopy] = { name: 'Passport Copy' }
Documents[ReviewDocumentId.ProfilePhoto] = { name: 'Profile Picture' }
Documents[ReviewDocumentId.ChildcarePhoto] = { name: 'Childcare Picture' }
Documents[ReviewDocumentId.EducationCertificateOriginal] = { name: 'Education Certificate Original' }
Documents[ReviewDocumentId.MedicalReportOriginal] = { name: 'Original Medical Report' }
Documents[ReviewDocumentId.Video] = { name: 'Video' }
Documents[ReviewDocumentId.FeeSheet] = { name: 'Fee Sheet' }
Documents[ReviewDocumentId.RedCrossCertificate] = { name: 'Red Cross Certificate' }
Documents[ReviewDocumentId.ProofOfCovid19Vaccination] = { name: 'Proof of Covid-19 Vaccination' }