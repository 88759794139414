
export class ReportController {

    trustReportUrl: string

    static $inject = ['$sce', 'reportId', 'reportUrl'];
    constructor(private $sce, private reportId, private reportUrl) {
        this.trustReportUrl = this.$sce.trustAsResourceUrl(reportUrl);
    }
}

angular
    .module('app')
    .controller('ReportController', ReportController);