
class ContactAgencyController {
    applicationId;
    agencyId;
    contactAgencyDetail: any;

    static $inject = ['$scope', '$q', '$state', 'AgencyData', 'Application', 'applicationId'];
    constructor(private $scope, private $q, private $state, private AgencyData, private Application, applicationId) {
        this.applicationId = applicationId;

        this.Application.get({ applicationId: this.applicationId }, (response) => {
            if (response.agencyId && response.agencyId > 0) {
                this.AgencyData.get({ agencyId: response.agencyId }, (agencyDetail) => {  
                    this.contactAgencyDetail = agencyDetail;
                });
            }
        });       
    }
}
angular
    .module('app')
    .controller('ContactAgencyController', ContactAgencyController);