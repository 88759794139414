 

var NavigationDirectiveFactory = ['$window', function NavigationDirectiveFactory($window): ng.IDirective {

    function link(scope, elem, attr, ctrl) {
        angular.element($window).bind('scroll', function () {
            var isTop = this.pageYOffset < attr.navigation;

            elem.toggleClass('navbar-static-top', isTop);
            elem.toggleClass('navbar-fixed-top', !isTop);
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}]

angular
    .module('app')
    .directive('navigation', NavigationDirectiveFactory);

