import { ActionType, ActionTypeItem, ActionTypeItems} from './ActionType'
import { UserAction } from '../../services/dataAccess/UserFactory'
// Class for right-hand-side action menu
export class ActionItem {
    actionType: ActionType
    actionTypeItem: ActionTypeItem
    name: string
    description: string
    performable: boolean
    errorMessages: Array<string>

    constructor(private data: UserAction) {
        this.actionType = ActionType[data.name]
        this.actionTypeItem = ActionTypeItems[this.actionType]
        this.name = data.name
        this.description = data.description
        this.performable = data.performable
        this.errorMessages = data.errorMessages
    }
}
