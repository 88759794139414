
class ExtensionController {
    displayFullMenu: boolean;
    extensionData: any;
    educationStatus: string;
    isEXSUser: boolean;

    static $inject = ['$rootScope', '$scope', 'Auth', 'USAuPairs'];
    constructor(private $rootScope, private $scope, private Auth, private USAuPairs) {  

        this.getAPData();

        // get called on every 'Submit to Office' click
        $scope.$on("update:extension", (event, data) => {                   
            this.displayFullMenu = true;
            this.updateExtensionMenu();    
        });

        // get called when user says 'Extension Same'
        $scope.$on("update:extensionEXS", (event, data) => {
            this.getAPData();
        });

        // This is ONLY called when user clicks from Option page to start the extension process
        $scope.$on("start:extension", (event, data) => {
            this.displayFullMenu = true;
        });            
    }

    getAPData() {
        this.USAuPairs.canAupairExtend({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.displayFullMenu = result.data.hasApplicationStarted;
                this.isEXSUser = (result.data.programYearReasonCode && result.data.programYearReasonCode == 'EXS');

                if (result.data.hasApplicationStarted) {
                    this.updateExtensionMenu();
                }
            });
    }

    updateExtensionMenu() {
        // Make a call to US service to check the status of each Menu item
        this.USAuPairs.getExtensionStatus({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.extensionData = result.data.extensionAppReview.extensionApplicationReview;
                this.educationStatus = result.data.extensionAppReview.y1EducationStatus;
            });
    }
}
angular
	.module('app')
    .controller('ExtensionController', ExtensionController);