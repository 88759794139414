import { DataAccessFactory } from './DataAccessFactory'
import { Auth, Resource } from './dataAccess'


class MessageFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'messages'
        this.initialize()
    }

    initialize() {
        const resources: Array<Resource> = [
            {
                name: 'MaintenanceMessages',
                url: 'maintenanceMessages/:messageId',
                params: {
                    messageId: '@messageId'
                },
                actions: {
                    query: {
                        isArray: false,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('messages.validFrom', 'messages.validUntil')
                        )
                    },
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('validFrom', 'validUntil')
                        )
                    },
                    current: {
                        auth: Auth.None,
                        url: 'maintenanceMessages/current',
                        method: 'GET',
                    },
                }
            },
            {
                name: 'SystemAnnouncements',
                url: 'systemAnnouncements/:id',
                params: {
                    id: '@id'
                },
                actions: {
                    current: {
                        method: 'GET',
                        url: `systemAnnouncements/current`,
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('validFrom', 'validTo')
                        )
                    },
                    get: {
                        method: 'GET',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('validFrom', 'validTo')
                        )
                    },
                    query: {
                        method: 'GET',
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('validFrom', 'validTo')
                        )
                    },
                    markAsRead: {
                        method: 'POST',
                        url: `systemAnnouncements/:id/markAsRead`,
                    },
                    markAsUnread: {
                        method: 'POST',
                        url: `systemAnnouncements/:id/markAsUnread`,
                    }
                }
            },
            {
                name: 'Conversations',
                url: 'conversations/:id',
                params: {
                    id: '@id'
                },
                actions: {
                    clearUnreadCount: {
                        method: 'POST',
                        url: 'conversations/:id/clearUnreadCount'
                    },
                    query: { method: 'GET', isArray: true }
                }
            },
            {
                name: 'Messages',
                url: 'messages/:id',
                params: {
                    id: '@id'
                },
                actions: {
                    queryForConversation: {
                        method: 'GET',
                        isArray: true,
                        url: 'conversations/:conversationId/messages'
                    }
                }
            }
        ];


        this.process(resources)
    }
}

new MessageFactory();