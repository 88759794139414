import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class ShareFactory extends DataAccessFactory {

    constructor() {
        super();

        this.server = 'share'

        this.initialize()
    }

    initialize() {
        const resources: Array<Resource> = [
            {
                name: 'PlacementDetails',
                url: 'shareIntegration/application/:applicationId/placement',
                actions: {
                    get: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'matchStartDate',
                                'matchEndDate'
                            )
                        )
                    }
                }
            },
            {
                name: 'PlacementLetter',
                url: 'shareIntegration/placementLetter',
                actions: {
                    signedURL: {
                        method: 'GET',
                        url: 'shareIntegration/placementLetter/signedURL'
                    }
                }
            },
            {
                name: 'InterestedHostFamiliesDetails',
                url: 'shareIntegration/application/:applicationId/interestedHostFamilies',
                actions: {
                    query: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        isArray: true
                    },
                    count: {
                        url: 'shareIntegration/application/:applicationId/interestedHostFamilies/count',
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    },
                }
            },
            {
                name: 'PlacementPackDetails',
                url: 'shareIntegration/application/:applicationId/placement/:timeFrame',
            },
            {
                name: 'PlacementMatchesDetails',
                url: 'shareIntegration/application/:applicationId/placement/matches',
                actions: {
                    getMatches: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'matchStartDate',
                                'matchEndDate'
                            )
                        ),
                        isArray: true
                    }
                }
            },
            {
                name: 'DeclineInterestedHostFamilyReasons',
                url: 'shareIntegration/lookup/declineInterestedHFReasons',
                actions: {
                    query: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        isArray: true
                    }
                }
            },
            {
                name: 'InterestedHostFamilyFeedback',
                url: 'shareIntegration/application/:applicationId/interestedHostFamilies/interestedHFProposal/:proposalId/request/:requestId/feedback',
                actions: {
                    save: {
                        method: 'POST',
                        params: {
                            applicationId: '@applicationId', proposalId: '@proposalId', requestId: '@requestId'
                        }
                    }
                }
            },
            {
                name: 'InterestedHostFamilyDocuments',
                url: 'shareIntegration/application/:applicationId/interestedHostFamilies/interestedHFProposal/:proposalId/request/:requestId/',
                actions: {
                    query: {
                        method: 'GET',
                        params: {
                            applicationId: '@applicationId', proposalId: '@proposalId', requestId: '@requestId'
                        }
                    }
                }
            },
            {
                name: 'ExternalResource',
                url: 'shareIntegration/external',
                actions: {
                    flightPaymentURL: {
                        method: 'GET',
                        url: 'shareIntegration/external/:applicationId/flightPaymentURL',
                        params: { applicationId: '@applicationId' },
                    },
                    preArrivalInsuranceURL: {
                        method: 'GET',
                        url: 'shareIntegration/external/:applicationId/preArrivalInsuranceURL',
                        params: { applicationId: '@applicationId' },
                    }
                }
            },
            {
                name: 'ExtensionInsuranceToken',
                url: 'shareIntegration/getInsurancePaymentToken'
            },
        ]

        this.process(resources)
    }
}

new ShareFactory()
