 

class MainMenuDirective implements ng.IDirective {
    restrict = 'AEC';
    controllerAs = 'ctrl';
    controller = MainMenuController;
    bindToController = true;
    templateUrl = 'controls/mainMenu/mainMenu.html';
}


class MainMenuController {
        
    isApplicant;
    unreadMessages;
    displayName;
    applicationId;
    isSocial;
    isOfficeUser;
    isLoggedIn;
    isPreLoggedIn;
    acceptedTC;
    isNavCollapsed = true;
    href;

    static $inject = ['$scope', 'Communication', 'Auth', 'AccessLevel'];
    constructor(private $scope, private Communication, private Auth, private AccessLevel) {

        this.href = $scope.href;

        AccessLevel
            .promise()
            .then((userLevels) => {
                // When user has just registered, we dont have applicant userLevel but role is 'Pre'
                if (userLevels.has('applicant') || Auth.user.role == 'PRE' || Auth.user.role == 'PRE_INT') {
                    this.isApplicant = true;
                    this.isPreLoggedIn = Auth.user.role == 'PRE'; //it appears only on registeration->agency selection step 
                }
            });



        $scope.$on('auth:user-update', () => {
            this.updateUserData(Auth.user);
        });

        this.updateUserData(Auth.user);

        this.isLoggedIn = Auth.isLoggedIn();
    }

    private updateUserData(user) {
        this.displayName = `${user.firstName} ${user.lastName}`;
        if (user.roleDetails && user.roleDetails.applicationId)
            this.displayName += ` (${user.roleDetails.applicationId})`;

        this.isSocial = user.isSocial;
        this.isOfficeUser = user.isOfficer;

        if (user.roleDetails) {
            this.acceptedTC = user.roleDetails.hasLatestTermsAndConditions;
        }
    }

}



angular
    .module('app')
    .directive('mainMenu', () => new MainMenuDirective());