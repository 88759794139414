
export class PromotionDiscountModalController {

    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'title', 'promotion']
    constructor(private $scope, private dialogs, private $uibModalInstance, private title: string, private promotion) {

    }

    public close() {
        this.$uibModalInstance.close();
    }
}