export interface Action {
    url?: string
    method?: string
    isArray?: boolean
    params?: { [id: string]: string }
    transformResponse?: Function
    transformRequest?: Function
    cache?: boolean
    interceptor?: Object
    auth?: Auth
    responseType?: string
}

export interface Resource {
    name: string
    url: string
    actions?: { [id: string]: Action }
    params?: { [id: string]: string }
    auth?: Auth
}

export enum Auth {
    None = 1,
    AuPair,
    US
}