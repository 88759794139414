import moment from 'moment'
import { IAction } from '../IAction'
import { ActionType, ActionTypeItems } from '../../../models/userActions/ActionType'
import { ITransaction } from '../../../models/finance/ITransaction'

export class RecordPayment implements IAction{

    static $inject = ['$uibModal', 'Invoice', 'ApplicantProfile', 'applicationId', 'invoiceId']
    constructor(private $uibModal, private Invoice, private ApplicantProfile, private applicationId, private invoiceId) {
            
    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/recordPayment/recordPaymentModal.html',
            controller: RecordPaymentActionController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: this.applicationId,
                invoice: () => this.Invoice.get({ applicationId: this.applicationId, invoiceId: this.invoiceId }).$promise,
                title: () => ActionTypeItems[ActionType.RECORD_PAYMENT].name,
                countryCode: () => this.ApplicantProfile
                    .get({ applicationId: this.applicationId })
                    .$promise
                    .then(p => p.nationalityCountryCode)
            }
        }).result
    }
}

class RecordPaymentActionController {

    public selectedTransaction: ITransaction
    public data: any

    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'Invoice', 'title', 'Finance', 'applicationId', 'invoice', 'countryCode']
    constructor(private $scope, private dialogs, private $uibModalInstance, private Invoice, private title: string, private Finance, applicationId: number, private invoice, countryCode: string) {

        this.data = {
            applicationId: applicationId,
            invoiceId: invoice.id,
            dateEntered: new Date(),
            transactionDate: moment().utc().startOf('day').toDate(),
            receivedDate: moment().utc().startOf('day').toDate(),
            autoCalculate: false,
            transactionCurrencyCode: invoice.currencyCode,
            issuingCountryCode: countryCode,
            method: 'BNK'
        };
    }

    public doAction() {
        this.$scope.$broadcast('submitted', true)
            
        if (this.data.$validation()) {
            if (this.data.method == 'INT') {
                this.dialogs.confirm('Payment Retained By Interviewer', `Are you sure you want to deduct ${this.data.transactionAmount} ${this.data.transactionCurrencyCode} from agency commissions payment?`)
                    .result
                    .then(() => this.recordPayment())
            } else {
                this.recordPayment();
            }
        }
    }

    private recordPayment() {
        this.Invoice.recordPayment(this.data).$promise
            .then((results) => {
                this.$uibModalInstance.close();
            }, () => {
                this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
            })
    }

    close() {
        this.$uibModalInstance.dismiss()
    }
}
        