const expandCollapseItemlocalStorageServiceKey = 'expand-collapse-branch-item';

interface IExpandCollapse {
    leftSideBar: boolean;
}

export class BranchController {

    expandCollapse: IExpandCollapse;

    static $inject = ['localStorageService', 'branch'];
    constructor(private localStorageService, private branch) {
        this.getSetExpandCollapseItem();
    }

    getSetExpandCollapseItem() {
        this.expandCollapse = this.localStorageService.get(expandCollapseItemlocalStorageServiceKey);

        if (!this.expandCollapse) {
            this.expandCollapse = { leftSideBar: true };
            this.localStorageService.set(expandCollapseItemlocalStorageServiceKey, this.expandCollapse);
        }
    }

    expandCollapseItem() {
        this.expandCollapse.leftSideBar = !this.expandCollapse.leftSideBar;
        this.localStorageService.set(expandCollapseItemlocalStorageServiceKey, this.expandCollapse);
    }
}    

