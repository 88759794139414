
import { ISpinner } from "../../services/SpinnerService";

class SpinnerDirective implements ng.IDirective {
    restrict = 'AE';
    controllerAs = 'ctrl';
    controller = SpinnerController;
    replace = true;
    bindToController = true;
    templateUrl = 'controls/spinner/spinner.html';
}


class SpinnerController implements ISpinner {
        
    private showClass = 'show'; 

    sidebar;
    expanded: boolean;

    static $inject = ['$element', 'Spinner'];
    constructor(private $element, private Spinner) {
        Spinner.register(this);
    }

    show() {
        this.$element.addClass(this.showClass);
    }

    hide() {
        this.$element.removeClass(this.showClass)
    }
}



angular
    .module('app')
    .directive('spinner', () => new SpinnerDirective());