import { BusinessArea } from "../../services/AuthService";

const expandCollapseAgencyItemlocalStorageServiceKey = 'expand-collapse-agency-item';
    
interface IExpandCollapse {
    leftSideBar: boolean;
}

class AgencyController {

    agencyId: number;
    expandCollapse: IExpandCollapse;
    isInCompetitorArea: boolean;

    static $inject = ['$scope', 'agencyId', 'localStorageService','Auth'];
    constructor(private $scope, agencyId, private localStorageService, Auth) {
        this.agencyId = agencyId;
        this.isInCompetitorArea = Auth.isInBusinessArea(BusinessArea.AGENCY_COMPETITOR);
        this.getSetExpandCollapseAgencyItem();
    }

    getSetExpandCollapseAgencyItem() {
        this.expandCollapse = this.localStorageService.get(expandCollapseAgencyItemlocalStorageServiceKey);

        if (!this.expandCollapse) {
            this.expandCollapse = { leftSideBar: true };
            this.localStorageService.set(expandCollapseAgencyItemlocalStorageServiceKey, this.expandCollapse);
        }
    }

    expandCollapseAgencyItem() {
        this.expandCollapse.leftSideBar = !this.expandCollapse.leftSideBar;
        this.localStorageService.set(expandCollapseAgencyItemlocalStorageServiceKey, this.expandCollapse);
    }
}    

angular
    .module('app')
    .controller('AgencyController', AgencyController)
