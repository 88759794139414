 

var ResourceTooltipDirectiveFactory = ['$q', '$compile', '$parse', 'ResourcesLoader', function ResourceTooltipDirectiveFactory($q: ng.IQService, $compile, $parse, ResourcesLoader): ng.IDirective {

    function compile(elem, attr) {
        var deferred = $q.defer();
        elem.removeAttr('resource-tooltip');

        ResourcesLoader.load().then(function (data) {
            var value = $parse(attr.resourceTooltip)(data);
            deferred.resolve(value);
        });

        return function (scope, elem) {
            deferred.promise.then(function (value) {

                if (value) {
                    elem.attr('uib-tooltip-html', `'${value}'`);
                    elem.attr('tooltip-placement', 'bottom');
                }
                $compile(elem)(scope);

            });
        };
    }
    return {
        restrict: 'AE',
        priority: 1001,
        terminal: true,
        compile: <ng.IDirectiveCompileFn> compile
    };
}]

angular
    .module('app')
    .directive('resourceTooltip', ResourceTooltipDirectiveFactory);