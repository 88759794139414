import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService';
import { ContactCommissionsModalController } from './ContactCommissionsModalController';

import _ from 'lodash-es';

interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}

export class ContactCommissionsController {
    contactCommissions;

    editFrom;

    selectedContactCommission;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public contactCommissionsPage: { contactCommissions: any[], totalCount:number};
    params: WorkQueueParams;
    loading: boolean = false;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;
    financeArea: boolean;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;
    canAddCommission = false;
    title: string;

    static $inject = ['$scope', 'Auth', '$q', '$state', 'dialogs', '$uibModal', 'GridExport', 'ContactCommission', 'contactId', 'contactPaymentDetails'];
    constructor(private $scope, private Auth, private $q, private $state, private dialogs, private $uibModal, private GridExport, private ContactCommission, private contactId, contactPaymentDetails) {

        this.financeArea = Auth.isInBusinessArea(BusinessArea.FINANCE_ADMINISTRATOR);
        this.selections = [];
        this.selectedGridItems = [];
        this.title = 'Payment Details for this Contact have not been entered, please complete these details before adding commissions';

        //check if contactPaymentDetails.contactId 
        if (contactPaymentDetails && contactPaymentDetails.contactId === contactId && this.financeArea) {
            this.canAddCommission = true;
            this.title = 'Add';
        }

        this.params = {
            page: 0,
            pageSize: 50,
            orderBy: 'commissionId',
            descending: false
        };

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {

                this.showModal(angular.copy(values[0]));
                this.selectedGridItems = [];
            }
        }, true); 
    }

    getContactCommissions(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'commissionId';
            this.params.descending = false;
        }

        this.refresh();
    }

    refresh() {
        this.loading = true;

        new this.ContactCommission.queryByContact({ contactId: this.contactId }, this.params, (result) => {
            this.contactCommissionsPage = result;

            this.lastPage = Math.floor(this.contactCommissionsPage.totalCount / this.params.pageSize);

            this.selections = [];

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
        }, () => {
            this.loading = false;
        });
    }

    add() {
        this.showModal(<any>{ contactId: this.contactId });
    }

    edit() {
        if (this.selections && this.selections.length === 1) {
            this.showModal(angular.copy(this.selections[0]));
        }
    }

    showModal(commission) {
        this.$uibModal.open({
            templateUrl: 'areas/contact/ContactDetails/contactCommissionsModal.html',
            controller: ContactCommissionsModalController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                commission: () => commission
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    public export() {
        this.GridExport.saveAsCSV('.contactCommissionsTable', 'export.csv', this.selections.length !== 0)
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.contactCommissionsPage.contactCommissions) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.contactCommissionsPage.contactCommissions) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                commissionId: item.commissionId
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.contactCommissionsPage.contactCommissions) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.commissionId === item.commissionId);
        }
    }

    changePageSize() {
        this.lastPage = Math.floor(this.contactCommissionsPage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }
}