export enum ItemGroupId {
    Profile = 1,
    PersonalDetails = 2,
    TravelAndProgram = 3,
    ChildcareExperience = 4,
    EducationAndWorkHistory = 5,
    MedicalAndCriminalHistory = 6,
    References = 7,
    FeeSheet = 8,
    InterviewReport = 10,
    MyUploads = 11,
    PreInterview = 12,
    HealthAndWellBeing = 13,
    Declarations = 14
}
export enum NoteItemId {
    ChildcareExperience = 14,
    EducationAndWorkHistory = 16,
    References = 24
}

export interface ItemGroupMenuItem {
    name: string
    state: string

    applicantOrderNum: number // order in the applicant menu // 0 defines hidden groups
}

export const ItemGroups: { [index: number]: ItemGroupMenuItem } = {}
ItemGroups[ItemGroupId.PreInterview] = { name: 'Pre-Interview Form', state: 'app.applications.inst.preInterview', applicantOrderNum: 0 }
ItemGroups[ItemGroupId.Profile] = { name: 'Profile', state: 'app.applications.inst.highlights', applicantOrderNum: 2 }
ItemGroups[ItemGroupId.PersonalDetails] = { name: 'Personal Details', state: 'app.applications.inst.personalDetails', applicantOrderNum: 3 }
ItemGroups[ItemGroupId.TravelAndProgram] = { name: 'Travel & Program', state: 'app.applications.inst.travelAndProgram', applicantOrderNum: 4 }
ItemGroups[ItemGroupId.ChildcareExperience] = { name: 'Childcare Experience', state: 'app.applications.inst.childcare', applicantOrderNum: 5 }
ItemGroups[ItemGroupId.EducationAndWorkHistory] = { name: 'Education & Work History', state: 'app.applications.inst.educationAndWork', applicantOrderNum: 6 }
ItemGroups[ItemGroupId.MedicalAndCriminalHistory] = { name: 'Medical & Declarations', state: 'app.applications.inst.medicalAndCriminal', applicantOrderNum: 7 }
ItemGroups[ItemGroupId.HealthAndWellBeing] = { name: 'Health & Well-being', state: 'app.applications.inst.healthAndWellBeing', applicantOrderNum: 7 }
ItemGroups[ItemGroupId.References] = { name: 'References', state: 'app.applications.inst.references', applicantOrderNum: 8 }
ItemGroups[ItemGroupId.MyUploads] = { name: 'My Uploads', state: 'app.applications.inst.myUploads', applicantOrderNum: 9 }
ItemGroups[ItemGroupId.Declarations] = { name: 'Declarations', state: 'app.applications.inst.declarations', applicantOrderNum: 10 }
ItemGroups[ItemGroupId.InterviewReport] = { name: 'Interview Report', state: 'app.applications.inst.interviewReport', applicantOrderNum: 20}
ItemGroups[ItemGroupId.FeeSheet] = { name: 'Fee Sheet', state: 'app.applications.applicationPayments.invoiceDetails', applicantOrderNum: 0 }

export const StaffItemGroups: { [index: number]: ItemGroupMenuItem } = {}
StaffItemGroups[ItemGroupId.PreInterview] = { name: 'Pre-Interview Form', state: 'app.applications.inst.preInterview', applicantOrderNum: 1 }
StaffItemGroups[ItemGroupId.Profile] = { name: 'Profile', state: 'app.applications.inst.highlights', applicantOrderNum: 2 }
StaffItemGroups[ItemGroupId.PersonalDetails] = { name: 'Personal Details', state: 'app.applications.inst.personalDetails', applicantOrderNum: 3 }
StaffItemGroups[ItemGroupId.TravelAndProgram] = { name: 'Travel & Program', state: 'app.applications.inst.travelAndProgram', applicantOrderNum: 4 }
StaffItemGroups[ItemGroupId.ChildcareExperience] = { name: 'Childcare Experience', state: 'app.applications.inst.childcare', applicantOrderNum: 5 }
StaffItemGroups[ItemGroupId.EducationAndWorkHistory] = { name: 'Education & Work History', state: 'app.applications.inst.educationAndWork', applicantOrderNum: 6 }
StaffItemGroups[ItemGroupId.MedicalAndCriminalHistory] = { name: 'Medical & Declarations', state: 'app.applications.inst.medicalAndCriminal', applicantOrderNum: 7 }
StaffItemGroups[ItemGroupId.HealthAndWellBeing] = { name: 'Health & Well-being', state: 'app.applications.inst.healthAndWellBeing', applicantOrderNum: 7 }
StaffItemGroups[ItemGroupId.References] = { name: 'References', state: 'app.applications.inst.references', applicantOrderNum: 8 }
StaffItemGroups[ItemGroupId.MyUploads] = { name: 'My Uploads', state: 'app.applications.inst.myUploads', applicantOrderNum: 9 }
StaffItemGroups[ItemGroupId.Declarations] = { name: 'Declarations', state: 'app.applications.inst.declarations', applicantOrderNum: 10 }
StaffItemGroups[ItemGroupId.InterviewReport] = { name: 'Interview Report', state: 'app.applications.inst.interviewReport', applicantOrderNum: 20 }
StaffItemGroups[ItemGroupId.FeeSheet] = { name: 'Fee Sheet', state: 'app.payments.invoiceDetails', applicantOrderNum: 0 }
