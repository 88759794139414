
class ContactInformationController {

    contactId: number
    contactName: any;
    showContactCommissions: boolean;

    static $inject = ['$rootScope', '$scope', 'contactId', 'Interviewer', 'Auth', 'contactPaymentDetails'];
    constructor(private $rootScope, private $scope, contactId, Interviewer, Auth, contactPaymentDetails) {

        this.contactId = contactId;
            
        this.contactName = $rootScope.$stateParams.contactName;

        Interviewer.get({ id: contactId })
            .$promise
            .then((result) => {
                this.showContactCommissions = (result && result.isActive && contactPaymentDetails.creditorId);
            });
    }
}

angular
    .module('app')
    .controller('ContactInformationController', ContactInformationController)