
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { AccessLevelView } from '../accessLevel';
import { RoutingProvider, state } from '../../services/RoutingService';

import { AltosController } from '../../areas/altos/AltosController';
import { AltosQueueController } from '../../areas/altos/AltosQueueController';
import { AltosStatusController } from '../../areas/altos/altosDetails/AltosStatusController';
import { AltosPersonalController } from '../../areas/altos/altosDetails/AltosPersonalController';
import { AltosHostFamilyController } from '../../areas/altos/altosDetails/AltosHostFamilyController';

var altosTitle = (subsection) => ['applicantId', (applicantId) => `${subsection} - ${applicantId}`];

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        var altosList: state<AccessLevelView> = {
            name: 'altos',
            url: '/altos',
            views: {
                content: {
                    controllerAs: '$ctrl',
                    staff: {
                        controller: AltosQueueController,
                        template: 'areas/altos/queue.html'
                    }
                }
            },
            resolve: {
                $title: () => 'Altos'
            }
        };

        RoutingProvider.addState(altosList, 'app');

        var altos: state<AccessLevelView> = {
            name: 'altosInformation',
            url: '/altos/{applicantId:int}',
            //abstract: true,
            views: {
                content: {
                    controllerAs: '$ctrl',
                    office: {
                        controller: AltosController,
                        template: 'areas/altos/altos.html'
                    }
                }
            },
            resolve: {
                applicantId: ['$stateParams', ($stateParams) => $stateParams.applicantId],
                $title: ['applicantId', (applicantId) => `Altos - ${applicantId}`]
            },
            states: [
                //{
                //    name: 'status',
                //    url: '',
                //    views: {
                //        '': {
                //            controllerAs: '$ctrl',
                //            office: {
                //                controller: AltosController,
                //                template: 'areas/altos/altos.html'
                //            }
                //        }
                //    },
                //    resolve: {
                //        $title: ['altos', (applicantId) => `Altos - ${applicantId}`]
                //    }
                //},
                {
                    name: 'status',
                    url: '/status',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            office: {
                                controller: AltosStatusController,
                                template: 'areas/altos/altosDetails/altosStatus.html'
                            }
                        }
                    },
                    resolve: {
                        altosStatusPage: ['AltosStatusPage', 'applicantId', function (AltosStatusPage, applicantId) {
                            return AltosStatusPage.get({ applicantId: applicantId }).$promise;
                        }],
                        $title: altosTitle('Status')
                    }
                },
                {
                    name: 'personal',
                    url: '/personal',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            office: {
                                controller: AltosPersonalController,
                                template: 'areas/altos/altosDetails/altosPersonal.html'
                            }
                        }
                    },
                    resolve: {
                        altosPersonalPage: ['AltosPersonalPage', 'applicantId', function (AltosPersonalPage, applicantId) {
                            return AltosPersonalPage.get({ applicantId: applicantId }).$promise;
                        }],
                        $title: altosTitle('Personal')
                    }
                },
                {
                    name: 'hostFamily',
                    url: '/hostFamily',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            office: {
                                controller: AltosHostFamilyController,
                                template: 'areas/altos/altosDetails/altosHostFamily.html'
                            }
                        }
                    },
                    resolve: {
                        altosHostFamilyPage: ['AltosHostFamilyPage', 'applicantId', function (AltosHostFamilyPage, applicantId) {
                            return AltosHostFamilyPage.get({ applicantId: applicantId }).$promise;
                        }],
                        $title: altosTitle('Host Family')
                    }
                }
            ]
        };

        RoutingProvider.addState(altos, 'app');
       
    }
}

angular
    .module('app')
    .config(RoutingConfig);