
class PreDepartureController {
    isOnlineOrAwaiting: boolean;
    isPlaced: boolean;
    isPlacedActiveOrRematch: boolean;

    static $inject = ['$scope', 'applicationObservable']
    constructor(private $scope, applicationObservable) {
            
        applicationObservable.subscribe(applicationInstance => {
            this.isOnlineOrAwaiting = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA');
            this.isPlaced = applicationInstance.status === 'PLC';
            this.isPlacedActiveOrRematch = (applicationInstance.status === 'PLC' || applicationInstance.status === 'ACT' || applicationInstance.status === 'REM');
        });
    }
}

angular
    .module('app')
    .controller('PreDepartureController', PreDepartureController);