var USRequestInterceptorFactory = ['$q', '$injector', 'buildVersion', function USRequestInterceptorFactory($q, $injector, buildVersion) {

    var _auth;

    function getAuth() {
        if (!_auth) {
            _auth = $injector.get('Auth');
        }
        return _auth;
    }

    return {
        request: function (config) {

            let auth = getAuth();
            if (auth.isLocked) {
                return $q.reject();
            }

            var user = auth.user;
            if (!user || !user.isLoggedIn) {
                return $q.reject();
                //return config;
            }

            if (!config.headers) config.headers = {};

            return auth
                .getUSAccessToken()
                .then((token) => {
                    if (token) {
                        config.headers.Authorization = 'bearer ' + token;
                    }
                    return config;
                });
        },
        responseError: function (response) {
            var method = response.config.method || '';
            var url = response.config.url || '';
            (console.error || console.log)(`Request failed.\nMethod:${method}\nUrl: ${url}\nTime: ${new Date().toJSON()}`);

            //Auth.signout();
            return $q.reject(response);
        }
    }
}]


angular
    .module('app')
    .factory('USRequestInterceptor', USRequestInterceptorFactory);

