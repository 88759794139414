import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class GdprFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {}
        this.server = 'gdpr'
        this.initialize()
    }


    initialize() {
        const resources: Array<Resource> = [
            {
                name: 'GdprStatus',
                url: 'gdprStatus/:userId'
            }
        ]

        this.process(resources)
    }

}

new GdprFactory()
