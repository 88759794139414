import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { IServiceProvider } from "angular";

class ApplicationInsightsProvider implements IServiceProvider {

    private instance: any;

    static $inject = [];
    constructor() {
    }

    $get = ['environment', function (environment) {

        const appInsights = new ApplicationInsights({
            config: {
                connectionString: environment.appInsightsConnectionString
            }
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();

        return appInsights;
    }];
}

angular
    .module('app')
    .provider('ApplicationInsights', ApplicationInsightsProvider);