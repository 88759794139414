import { IAction } from '../IAction'

export class SendBackToAgency implements IAction {

    static $inject = ['$uibModal', 'applicationId']
    constructor(private $uibModal, private applicationId) {
	}

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/sendBack/sendBackToAgencyModal.html',
            controller: SendBackToAgencyModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId
            }
        }).result;
    }
}
    
class SendBackToAgencyModalController {
    public commentText: string;

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'applicationId', 'SendBackToAgency', 'Auth', '$rootScope'];
    constructor(private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private applicationId, private SendBackToAgency, private Auth, private $rootScope) {
            
    }

    sendBack() {
        var user = this.Auth.user;

        var data = {
            applicationId: this.applicationId,
            userId: user.userId,
            author: user.firstName + ' ' + user.lastName,
            comment: this.commentText
        };

        this.SendBackToAgency.update({ applicationId: this.applicationId }, data)
            .$promise
            .then((results) => {
                this.$rootScope.$broadcast('changeApplicationStatus', false);
            }, () => {
                return this.dialogs
                    .error('Issue occur during sending back', 'The applicant could not be send back to agency, please try again shortly')
                    .result;
            })
            .finally(() => {
                this.$uibModalInstance.close();
            });
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}