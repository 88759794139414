 
import moment from 'moment' 

function AgeFiter(value, whenTrue, whenFalse) {
    if (!value)
        return value;

    return moment().diff(value, 'years');
}

function AgeFiterFactory(): Function {
    return AgeFiter;
}

angular
    .module('app')
    .filter('age', [AgeFiterFactory]);