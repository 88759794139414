import _ from 'lodash-es'
import { IAction } from '../IAction'

export class ConfirmMeeting implements IAction {

    static $inject = ['$uibModal', 'applicationIds']
    constructor(private $uibModal, private applicationIds) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/confirmMeeting/confirmMeetingModal.html',
            controller: ConfirmMeetingModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationIds: () => this.applicationIds
            }
        }).result;
    }
}

class ConfirmMeetingModalController {
    public metInPersonDate: Date;
    public maxDate: Date;


    static $inject = ['$uibModalInstance', 'dialogs', 'applicationIds', 'InterviewReport'];
    constructor(private $uibModalInstance, private dialogs, private applicationIds, private InterviewReport) {
        this.maxDate = new Date();
    }

    save() {
        if (!this.metInPersonDate) {
            this.dialogs.error('Validation Error', 'Please choose date.');
            return;
        }

        if (this.metInPersonDate > new Date()) {
            this.dialogs.error('Validation Error', 'The entered dates must be in the past.');
            return;
        }

        this.InterviewReport.confirmMeeting({ applicationIds: this.applicationIds, metInPersonDate: this.metInPersonDate})
            .$promise
            .then((results) => {
                if (_.some(results, (r: any) => r.success == false)) {
                    this.dialogs.error('Internal error', 'Error occured while failing this application. Please try again after a while.');
                }
            })
            .finally(() => {
                this.$uibModalInstance.close();
            });
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
