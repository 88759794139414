 
import { ActionType } from '../../models/userActions/ActionType'
import { ActionItem } from '../../models/userActions/ActionItem'
import { InvalidActionCall } from '../../areas/action/invalidActionCall/InvalidActionCall'
import { SubmitApplicationToAgency } from '../../areas/action/submitApplicationToAgency/submitApplicationToAgency'
import { SubmitApplicationToOffice } from '../../areas/action/submitApplicationToOffice/submitApplicationToOffice'
import { UserAction } from '../../services/dataAccess/UserFactory'



class ApplicationMenuDirective implements angular.IDirective {
    restrict = 'AE'
    controllerAs = 'ctrl'
    controller = ApplicationMenuController
    bindToController = true
    templateUrl = 'controls/applicationMenu/applicationMenu.html'
    scope = {
        applicationId: '='
    }
}
    

class ApplicationMenuController {
        
    applicationId: any

    actions: Array<ActionItem>

    static $inject = ['$scope', 'Action', '$injector', '$q', '$uibModal']
    constructor(private $scope: angular.IScope, private Action, private $injector: ng.auto.IInjectorService, private $q: angular.IQService, private $uibModal) {

        $scope.$watch(() => this.applicationId, (entryId) => {
            this.updateActions()
        });
    }

    $onInit() {
        this.updateActions();
    }

    private updateActions() {
        if (!this.applicationId) {
            this.actions = [];
            return;
        }

        this.Action.available({ entryId: this.applicationId, entryType: 'APPLICATION' }).$promise
            .then(data => {
                this.actions = data
                    .filter((action: UserAction) => this.getAction(ActionType[action.name]))
                    .map((action: UserAction) => new ActionItem(action));
            })
    }

    actionSelected(action: ActionItem) {

        if (!action.performable) {
            var invalidActionCall = new InvalidActionCall(this.$uibModal, action.actionTypeItem.name, action.errorMessages);
            invalidActionCall.execute();
            return this.$q.reject();
        }

            
        return this.performAction(action).then(() => {
            this.updateActions()
        })
    }

    actionTooltip(action: ActionItem) {
        if (action.errorMessages && action.errorMessages.length > 0
            && (action.actionType == ActionType.SUBMIT_TO_OFFICE || action.actionType == ActionType.SUBMIT_TO_AGENCY)) {

            return action.errorMessages.join(" </br> ");
        }
        return '';
    }

    performAction(action: ActionItem) {
        var def = this.getAction(action.actionType);
        if (!def) {
            return this.$q.reject();
        }

        var inst:any = this.$injector.instantiate(def,
            {
                applicationId: this.applicationId
            });
        return inst.execute();
    }

    getAction(type: ActionType):any {
        switch (type) {
            case ActionType.SUBMIT_TO_AGENCY:
                return SubmitApplicationToAgency;
            case ActionType.SUBMIT_TO_OFFICE:
                return SubmitApplicationToOffice;
        }
    }
}

angular
    .module('app')
    .directive('applicationMenu', () => new ApplicationMenuDirective())
