
class SignOutController {
        
    static $inject = ['$state', 'Auth'];
    constructor($state, Auth) {
        Auth.signout();
        $state.transitionTo('app.login', {}, { reload: true, inherit: true, notify: true });
    }

}

angular
    .module('app')
    .controller('SignOutController', SignOutController);