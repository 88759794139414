 

class ApplicationProfileAndAtlasDirective implements ng.IDirective {
        restrict = 'AE'
        controller = ApplicationProfileAndAtlasController
        controllerAs = 'acCtrl'
        bindToController = true
        templateUrl = 'controls/applicationProfileAndAtlas/applicationProfileAndAtlas.html'    
        scope = {
            applicationId: '=',
            profileUpdated: '='
        }               
}

class ApplicationProfileAndAtlasController {
    applicationId: number
    profileUpdated: boolean
    profileDescription: string
    atlasComment: string
    showSuccessText : boolean = false

    static $inject = ['$scope', 'ProfileAndAtlasDetail', 'Auth', 'dialogs', '$timeout'];
    constructor(private $scope, private ProfileAndAtlasDetail, private Auth, private dialogs, private $timeout) {            
            
    }


    $onInit() {

        this.ProfileAndAtlasDetail.query(
            { applicationId: this.applicationId },
            (data) => {
                this.profileDescription = data.profileDescription;
                this.atlasComment = data.atlasComment;
            }
        );  
    }


    saveProfileAndAtlasDetail() {  
        var data = {
            applicationId: this.applicationId,
            profileDescription: this.profileDescription,
            atlasComment: this.atlasComment,
            author: this.Auth.user.firstName + ' ' + this.Auth.user.lastName
        };

        this.ProfileAndAtlasDetail.update({ applicationId: this.applicationId }, data )
        .$promise
            .then(() => {                    
                this.$scope.$parent.$broadcast('profileUpdated', true);
                this.showSuccessText = true;
                this.$timeout(()=> { this.showSuccessText = false; }, 3000);   
        }, () => {
            this.dialogs.error('Internal error', 'Error occured while saving profile details. Please try again after a while.');
        });
    }  
}


angular
    .module('app')
    .directive('applicationProfileAndAtlas', () => new ApplicationProfileAndAtlasDirective());