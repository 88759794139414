
export class AgreementController {

    static $inject = ['agreement'];
    constructor(public agreement) {

        
    }
}

angular
    .module('app')
    .controller('AgreementController', AgreementController);