AifsRadioListController.$inject = ['$scope', '$element', '$attrs', 'MetaForm']
function AifsRadioListController($scope, $element, $attrs, MetaForm) {
    var self = this;
    var formCtrl = $element.controller('aifsForm');
    //var questionCtrl = $element.controller('aifsForm');
    var metaForm = new MetaForm(formCtrl.Id);

    metaForm.getDataSource($attrs.aifsRadioList, function (dataSource) {
        if (dataSource) {
            self.dataSource = dataSource;

            var updateOptions = function () {
                //TODO get question and check if is valid 
                //if(questionCtrl.$isVisible()){

                    metaForm.getDropdownOptions(dataSource, $scope.model, function (data) {
                        $scope.options = data;
                    });
                //}
            };

            if (dataSource.dependencies && dataSource.dependencies.length > 0) {
                angular.forEach(dataSource.dependencies, function (dependecy) {
                    formCtrl.subscribeField(dependecy, updateOptions);
                });
            } else {
                updateOptions();
            }
        }
    });

    this.$name = $attrs.name;
}

angular
    .module("aifs.metaform")
    .directive("aifsRadioList", function () {
        return {
            restrict: 'A',
            require: ["^form", '?ngModel', 'aifsComponent'],
            controller: AifsRadioListController,
            
        };
    });