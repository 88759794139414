
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************

import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        var data: state<AccessLevelView> = {
            name: 'reference',
            url: '/references',
            abstract: true,
            views: {
                content: {
                    everyone: {
                        template: 'areas/reference/interviewer.html'
                    }
                }
            },
            states: [
                {
                    name: 'overrideViews',
                    url: '',
                    abstract: true,
                    views: {
                        '@': {
                            everyone: {
                                template: 'areas/layout/guest.html'
                            }
                        }
                    },
                    states: [
                        {
                            name: 'referee',
                            url: '/referee/{token}',
                            views: {
                                content: {
                                    everyone: {
                                        controller: 'ReferenceRefereeController as refCtrl',
                                        template: 'areas/reference/referee/referee.html'
                                    }
                                }
                            },

                            resolve: {
                                token: ['$stateParams', function ($stateParams) {
                                    return $stateParams.token;
                                }],
                                referenceResponse: ['Reference', 'token', function (Reference, token) {
                                    return Reference.getForReferee({ token: token }).$promise
                                        .then(function (data) {
                                            return data;
                                        }, function (res) {
                                            return res.data;
                                        });
                                }]
                            }
                        },
                        {
                            name: 'refereeComplete',
                            url: '/refereeComplete',
                            views: {
                                content: {
                                    everyone: {
                                        template: 'areas/reference/referee/refereeComplete.html'
                                    }
                                }
                            },
                        }
                    ]
                }
            ]
        };

        RoutingProvider.addState(data, 'app');
    }
}

angular
    .module('app')
    .config(RoutingConfig);