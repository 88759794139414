import moment from 'moment';
import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class ChangeVisaController {
    emailAddress: string;
    name: string;
    extension: string;
    visaStatus: string;
    visaChangeSubmitBy: string;
    details: any;
    alerts: IAlertConfig[] = [];
    showMessageText: boolean = false;
    itineraryRcvd: boolean;

    static $inject = ['$rootScope', '$q', '$timeout', '$scope', 'Auth', 'USAuPairs', 'Spinner', 'dialogs']
    constructor(private $rootScope, private $q, private $timeout, private $scope, private Auth, private USAuPairs, private Spinner, private dialogs) {
        this.getData();
    }

    getData() {
        this.Spinner.show();

        this.USAuPairs
            .getVisaStatus({
                aupairId: this.Auth.user.usAuPairId
            })
            .$promise
            .then((result) => {
                this.details = result.data;
                this.visaStatus = (result.data.auPairVisaStatus.eoyVisaChangeProofStatus || 'E'); // using E as empty
                this.emailAddress = result.data.pcAdmin.email;
                this.name = result.data.pcAdmin.firstName + ' ' + result.data.pcAdmin.lastName;
                this.extension = result.data.pcAdmin.officeExtension;
                this.visaChangeSubmitBy = moment(result.data.visaChangeSubmitBy).format('MMMM D, YYYY');
            });

        this.USAuPairs
            .getFlightHomeData({
                aupairId: this.Auth.user.usAuPairId
            })
            .$promise
            .then((result) => {
                this.itineraryRcvd = result.data.obItineraryRcvd == 'Y';
            });

        this.Spinner.hide(); 
    }

    submit() {
        this.dialogs.confirm('Confirmation', 'Are you sure you want to let Au Pair in America know you will be changing your visa status? Click Yes to proceed or No to stay on the page.')
            .result
            .then(() => {
                var promises = [];
                this.alerts = [];

                this.Spinner.show();
                this.details.aupairId = this.Auth.user.usAuPairId;

                promises.push(this.USAuPairs.saveVisaStatus({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

                this.$q.all(promises)
                    .then(() => {
                        // Refresh content on the page
                        this.getData();
                        this.alerts.push({
                            type: 'success',
                            msg: 'Save complete',
                            dismissOnTimeout: 3000
                        })
                        this.showMessageText = true;
                        this.$timeout(() => { this.showMessageText = false; }, 3000);
                    }, () => {
                        this.alerts.push({
                            type: 'danger',
                            msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk"> info@aupairamerica.co.uk</a>',
                            dismissOnTimeout: 3000
                        })
                        this.showMessageText = true;
                        this.$timeout(() => { this.showMessageText = false; }, 3000);
                    })
                    .finally(() => {
                        this.Spinner.hide();
                    });
            });
    }
}
angular
    .module('app')
    .controller('ChangeVisaController', ChangeVisaController);