import { DataAccessFactory } from './DataAccessFactory'
import { Auth, Resource } from './dataAccess'

class RegistrationFactory extends DataAccessFactory {

    constructor() {
        super()
        this.server = 'registration'

        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'RegistrationAgency',
                url: `${this.server}/agency/:agencyId`,
                auth: Auth.None,
                actions: {
                    query: {
                        method: 'GET',
                        url: `${this.server}/country/:country/agencies`,
                        isArray: true
                    }
                }
            },
            {
                name: 'RegistrationInterviewer',
                url: `${this.server}/agency/:agencyId/interviewer`,
                auth: Auth.None,
                actions: {
                    query: {
                        method: 'GET',
                        url: `${this.server}/agency/:agencyId/interviewer`,
                        isArray: true
                    },
                    get: {
                        method: 'GET',
                        url: `${this.server}/interviewer/:interviewerId`
                    }
                }
            },
            {
                name: 'Registration',
                url: `${this.server}/register`,
                auth: Auth.None,
                actions: {
                    get: {
                        method: 'GET' ,
                        auth: Auth.AuPair
                    },
                    emailValid: {
                        method: 'POST',
                        url: `${this.server}/email-valid`
                    },
                    voucherValid: {
                        method: 'POST',
                        url: `${this.server}/voucher-valid`
                    },
                    applyVoucher: {
                        method: 'POST',
                        url: `${this.server}/applyVoucher`
                    }
                }
            },
            {
                name: 'Brochure',
                url: `${this.server}/brochure`,
                auth: Auth.None
            },
            {
                name: 'RegistrationLookup',
                auth: Auth.None,
                url: '',
                actions: {
                    dialingCodes: {
                        method: 'GET',
                        url: `${this.server}/country/dialingCodes/:countryCode`,
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('description', 'iddCode')
                        )
                    },
                    nationality: {
                        method: 'GET',
                        url: `${this.server}/country/nationality`,
                        isArray: true,
                        cache: true
                    },
                    residence: {
                        method: 'GET',
                        url: `${this.server}/country/residence`,
                        isArray: true,
                        cache: true
                    },
                    agencyCountryList: {
                        method: 'GET',
                        url: `${this.server}/country/agencyCountryList`,
                        isArray: true
                    },
                    marketingSources: {
                        method: 'GET',
                        url: `${this.server}/marketing/howheard`,
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('name', 'sourceCode')
                        )
                    },
                    agencies: {
                        method: 'GET',
                        url: `${this.server}/country/:countryCode/agencies`,
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('name', 'id')
                        )
                    }
                }
            },
            {
                name: 'Referral',
                url: `${this.server}/user/:userId/referrals`,
                actions: {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }
            }
        ]

        this.process(resources)
    }
}

new RegistrationFactory()
