import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { Kind } from './kind'

export class PromotionModalController {

    Kind = Kind
    alerts: IAlertConfig[] = []
    countriesOptions: any[];
    allCountriesOptions: any[];
    applicableAgenciesOptions: any[];
    allApplicableAgenciesOptions: any[];
    canAddPromotionFee: boolean = false;
    //promotion: any = {};
    editMode: boolean;
    submitted: boolean;

    title: string;

    static $inject = ['$uibModalInstance', 'dialogs', '$scope', 'Promotion', 'promotion', 'Agency'];
    constructor(private $uibModalInstance, private dialogs, private $scope, private Promotion, private promotion, private Agency) {
        var currentDate = new Date();
        promotion.startDate_mindate = function () {
            return currentDate;
        }

        this.title = this.getTitle();

        if (promotion.promotionId > 0) {
            this.promotion.promotionTypeId = promotion.promotionTypeId;
            var countries = this.promotion.countries;
            var applicableAgencies = this.promotion.applicableAgencies;

            this.Promotion.countryByRegion({ currencyCode: promotion.currencyCode })
                .$promise
                .then((countryResult) => {
                    
                    var filterCountry = _.filter(countryResult, ({ code }) => _.indexOf(countries, code) >= 0);
                    this.countriesOptions = filterCountry;
                    this.promotion.countries = countries;

                    var filterExceptSelectedCountry = _.filter(countryResult, ({ code }) => _.indexOf(countries, code) === -1);
                    this.allCountriesOptions = filterExceptSelectedCountry;

                    this.Agency.agenciesByCountries({}, this.promotion.countries)
                        .$promise
                        .then((agencyResult) => {
                            var filterAgency = _.filter(agencyResult, ({ code }) => _.indexOf(applicableAgencies, code) >= 0);

                            this.applicableAgenciesOptions = filterAgency;
                            this.promotion.applicableAgencies = applicableAgencies;

                            var filterExceptSelectedAgency = _.filter(agencyResult, ({ code }) => _.indexOf(applicableAgencies, code) === -1);
                            this.allApplicableAgenciesOptions = filterExceptSelectedAgency;
                        });
                });

            $scope.$watch(() => this.promotion.newCountries, (newVal, oldVal) => {
                if (this.promotion.newCountries.length == 0) {
                    this.allApplicableAgenciesOptions = [];
                    this.promotion.newApplicableAgencies = [];
                    return;
                }

                if (newVal || oldVal !== newVal) {
                    this.Agency.agenciesByCountries({}, this.promotion.newCountries)
                        .$promise
                        .then((agencies) => {
                            this.allApplicableAgenciesOptions = agencies;
                        });
                }
            })  
        }
        else {
            this.promotion.promotionFees = [];

            $scope.$watch(() => this.promotion.currencyCode, (newVal, oldVal) => {
                this.promotion.promotionFees = [];
                this.promotion.amount = 0;

                if (!this.promotion.currencyCode) {
                    this.countriesOptions = [];
                    this.promotion.countries = [];

                    this.applicableAgenciesOptions = [];
                    this.promotion.applicableAgencies = [];
                    return;
                }

                this.addPromotionFee();

                if (newVal || oldVal !== newVal) {
                    this.Promotion.countryByRegion({ currencyCode: this.promotion.currencyCode }, (result) => {
                        this.countriesOptions = result;
                    });
                }
            })

            $scope.$watch(() => this.promotion.countries, (newVal, oldVal) => {
                if (this.promotion.countries.length == 0) {
                    this.applicableAgenciesOptions = [];
                    this.promotion.applicableAgencies = [];
                    return;
                }

                if (newVal || oldVal !== newVal) {
                    this.Agency.agenciesByCountries({}, this.promotion.countries)
                    .$promise
                        .then((agencies) => {
                        this.applicableAgenciesOptions = agencies;
                    });
                }
            })  

            $scope.$on('amount-changed', (event) => {
                this.setTotalAmount();
            })

            $scope.$watch(() => this.promotion.promotionFees, (newVal, oldVal) => {
                if (this.promotion.promotionFees.length < 4) {
                    this.canAddPromotionFee = true;
                }
                else {
                    this.canAddPromotionFee = false;
                }
            })
        }
    }

    getTitle() {
        if (!this.promotion.editMode) {
            if (this.promotion.kind == Kind.Promotion) return "Add Promotion";
            if (this.promotion.kind == Kind.Discount) return "Add Discount";
        } else {
            if (this.promotion.kind == Kind.Promotion) return "Edit Promotion"; //ctrl.promotion.promotionName
            if (this.promotion.kind == Kind.Discount) return "Edit Discount";
        }
        return '';
    }

        

    setTotalAmount() {
        var totalAmount = 0;
        //totalAmount = this.promotion.promotionFees.map(fee => fee.amount)
        //    .reduce((total, current) => function sumAmount(total, current) {
        //        //return total + current
        //        return total += parseFloat(current);
        //    });

        angular.forEach(this.promotion.promotionFees, function (fee) {
            if (fee.feeId) {
                totalAmount += parseFloat(fee.amount);
            }
        });
        this.promotion.amount = totalAmount;//.toFixed(2);
    }

        

    addPromotionFee() {
        if (this.canAddPromotionFee && this.promotion.promotionFees && this.promotion.promotionFees.length < 4) {
            this.promotion.promotionFees.push( 
                {
                    feeId: '',
                    amount: 0
                });
        }
    }
    removeFee(index) {
        if (!this.editMode) {
            this.promotion.promotionFees.splice(index, 0);
            //this.promotion.promotionFees.remove(promotionFee);                
        }
    }
    removePromotionFee(promotionFee) {
        if (!this.editMode) {
            var index = this.promotion.promotionFees.indexOf(promotionFee);
            this.promotion.promotionFees.splice(index, 0);
            //this.promotion.promotionFees.remove(promotionFee);                
        }
    }
    savePromotion() {
        this.$scope.$broadcast('submitted', true);
        this.submitted = true;

        if (!this.promotion.programAP && !this.promotion.programEX && !this.promotion.programED) {
            return;
        }

        if (this.promotion.$validation()) {
            if (this.promotion.promotionTypeId == 2) {
                this.Promotion.applicableApplicants(this.promotion)
                    .$promise.then(
                        (result) => {
                            var message;
                            if (result.applicableApplicantsCount == 1) {
                                message = `[${result.applicableApplicantsCount}] applicant will receive "${this.promotion.promotionName}". Do you want to confirm?`;
                            }
                            else {
                                message = `[${result.applicableApplicantsCount}] applicants will receive "${this.promotion.promotionName}". Do you want to confirm?`;
                            }

                            this.dialogs.confirm('Promotion', message)
                                .result
                                .then(() => this.save());
                        },
                        () => {
                            this.dialogs.error('Internal error', 'Error occured. Please try again after a while.');
                        }
                    );
            }
            else {
                this.save();
            }
        }
    }

    save() {
        this.Promotion.save(this.promotion)
            .$promise.then(
                () => {
                    this.$uibModalInstance.close();
                },
                () => {
                    this.dialogs.error('Internal error', 'Error occured. Please try again after a while.');
                }
            );
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}
