
class ShareBackgroundQuestionsController {

    htmldata: string;

    static $inject = ['$sce', 'backgroundQuestions']
    constructor($sce, public backgroundQuestions) {
        //this.htmldata = $sce.trustAsHtml(backgroundQuestions);//  "<b>Test</b>";    

        var str = '';
        for (var p in backgroundQuestions) {
            if (backgroundQuestions.hasOwnProperty(p)) {
                str += backgroundQuestions[p];
            }
        }

        this.htmldata = str;
    }        
}

angular
    .module('app')
    .controller('ShareBackgroundQuestionsController', ShareBackgroundQuestionsController)