
class HighlightsController {
        
    saved: boolean;

    static $inject = ['$scope', '$q', 'dialogs', 'HelpTexts', 'aboutYou'];
    constructor(private $scope, private $q, private dialogs, private HelpTexts, private aboutYou) {
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form['AboutYou'].$dirty) {
            promisses.push(
                this.HelpTexts.save({ module: 'AboutYou' }, this.aboutYou).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                $('html, body').animate({ scrollTop: 860 }, 200);
                this.saved = true;
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
            });
    }
}

angular
    .module('app')
    .controller('HelpText.HighlightsController', HighlightsController);