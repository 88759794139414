 


function noCharacter(value) {
    if (angular.isString(value) || angular.isArray(value))
        return value.length;
    return 0;
}

function noWords(value) {
    if (angular.isString(value)) {
        var result = value
            .replace(/[,.?!"`()\[\]{}:;]/gi, ' ')   // remove punctuation
            .trim()
            .split(/\s+/);

        return (result.length === 1 && result[0] === '') ? 0 : result.length;
    }
    return 0;
}

interface ITextCountAttributes extends ng.IAttributes {
    textCount: string;
    textCountType: string;
}

class TextCountDirective implements ng.IDirective {
    restrict = 'A';

    link(scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ITextCountAttributes) {
        scope.$watch(attrs.textCount, (newValue, oldValue) => {

            var counter;
            switch (attrs.textCountType) {
                case 'words':
                    counter = noWords;
                    break;
                default:
                    counter = noCharacter;
            }
            element.text(counter(newValue));
        });
    }
}

   

angular
    .module('app')
    .directive('textCount',() => new TextCountDirective());

