 

var TranslationDirectiveFactory = ['$parse', 'Translation', function TranslationDirectiveFactory($parse, Translation): ng.IDirective {

    function link(scope, elem, attr, ctrl) {
        var defaultValue = elem.text();

        var unbind = Translation(attr.translation, function (value) {
            elem.text(value || defaultValue);
        });

        scope.$on('$destroy', function () {
            unbind();
        });
    }

    return {
        restrict: 'AE',
        link: link,
    };
}]

angular
    .module('app')
    .directive('translation', TranslationDirectiveFactory);