AifsQuestionController.$inject = ['$scope', '$element', '$attrs', 'MetaForm']
function AifsQuestionController($scope, $element, $attrs, MetaForm) {
    var self = this;
    var components = [];
    var metaForm = new MetaForm($scope.$parent.formId);

    metaForm.getQuestionRule($attrs.id, function (questionRule) {
        if (questionRule) {
            self.questionRule = questionRule;
            $scope.show = questionRule.isValid.call(questionRule);
        }
        $element.controller('aifsForm').$addQuestion(self);
    });

    this.$id = $attrs.id;

    this.$addComponent = function (component) {
        components.push(component);
    };
    this.$removeComponent = function (component) {
        for(var i = 0; i < components.length; i++) {
            if(components[i] === component) {
                components.splice(i, 1);
            }
        }
    };

    this.$isValid = function () {
        if (!self.questionRule || self.questionRule.isValid()) {
            for(var i = 0; i < components.length; i++ ) {
                if(!components[i].$isValid())
                    return false;
            }
        }
        return true;
    };

    this.$isVisible = function () {
        return $scope.show;
    };

    this.$updateRule = function () {
        $scope.show = this.questionRule.isValid.call(this.questionRule);
    };

    this.$error = function (){
        var result = {};

        for (var i = 0; i < components.length ; i++) {
            angular.forEach(components[i].$error(), function(value, key){
                if(value === true)
                    result[key] = true;
            });
        }

        return result;
    };

    this.$anyPropertySetToTrue = function (obj) {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                if(obj[property])
                    return false;
            }
        }
        return true;
    };

    $scope.show = true;
}

function AifsQuestionLink (scope, element, attrs, ctrls, transclude) {
    var newScope = scope.$parent.$new();
    var questionCtrl = ctrls[0];
    newScope.error = questionCtrl.$error;
    newScope.isValid = questionCtrl.$isValid;
    newScope.anyPropertySetToTrue  = questionCtrl.$anyPropertySetToTrue ;
    transclude(newScope, function (clone, scope) {
        element.find('[my-transclude]').replaceWith(clone);
    });
}


angular
    .module('aifs.metaform')
    .directive('aifsQuestion', function () {
        return {
            restrict: 'A',
            scope: {
                id: '@',
                caption: '@'
            },
            replace: true,
            transclude: true,
            require: ['^aifsQuestion'],
            link: AifsQuestionLink,
            controller: AifsQuestionController,
            templateUrl: 'templates/metaform/question.html'
        };
    });
