 
import _ from 'lodash-es'
import { IAction } from '../IAction'

export class AssignCPI implements IAction{

    static $inject = ['$uibModal', 'applicationId', 'cpiStatusId']
    constructor(private $uibModal, private applicationId, private cpiStatusId) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/assignCPI/AssignCPIModal.html',
            controller: AssignCPIModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId,
                cpiStatusId: () => this.cpiStatusId
            }
        }).result;
    }
}

class AssignCPIModalController {
    public selectedcpi: any;
    public cpiList: any[];

    static $inject = ['dialogs', '$q', '$sce', '$uibModalInstance', 'applicationId', 'AssignCPI', 'cpiStatusId', 'Auth', '$rootScope'];
    constructor(private dialogs, private $q: ng.IQService, private $sce, private $uibModalInstance, private applicationId, private AssignCPI, private cpiStatusId, private Auth, private $rootScope) {
        this.loadCPIList();
    }

    cpiDescription = (inter) => `${inter.cpiStatusDescription}`;

    loadCPIList() {            
        this.AssignCPI.query({ }, (response) => {
            this.cpiList = response;

            if (this.cpiStatusId > 0) {
                var filterCPIStatus = _.filter(response, { cpiStatusId: this.cpiStatusId });
                if (filterCPIStatus && filterCPIStatus.length > 0) {
                    this.selectedcpi = filterCPIStatus[0];
                }                    
            }

            });           
    }

    assignCPI() {
        var user = this.Auth.user;

        if (angular.isObject(this.selectedcpi)) {
            var data = {
                applicationId: this.applicationId,
                author: user.firstName + ' ' + user.lastName,
                CPIStatusId: this.selectedcpi.cpiStatusId,
                CPIStatusDescription: this.selectedcpi.cpiStatusDescription
            };

            this.AssignCPI.update({ applicationId: this.applicationId }, data)
                .$promise
                .then((results) => {
                    if (_.some(results, (r: any) => r.success == false)) {
                        this.dialogs.error('Internal error', 'Error occured while saving your CPI selection. Please try again after a while.');
                    }

                    this.$rootScope.$broadcast('changeCPIStatus', data);

                    return results;
                })
                .finally(() => {
                    this.$uibModalInstance.close();
                });
        }
        else {
            this.dialogs.error('Validation Error', 'You must select an option from the list.');
        }         
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
