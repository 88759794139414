 

class LocalFeeSheetDirective implements ng.IDirective {
    restrict = 'AE'
    controller = LocalFeeSheetController
    controllerAs = 'fee'
    bindToController = true
    templateUrl = 'controls/feeSheet/localFeeSheet.html'
    scope = {
        agencyId: '=',
        territoryInfo:'=',
        localFeeData: '='
    }
}

class LocalFeeSheetController {
    agencyId: number
    localFeeData: any;
    territoryInfo: any;
    whenPayableList: any[];
    isEditable: boolean;

    static $inject = ['$scope', 'Auth', '$q','AgencyLookup'];
    constructor(private $scope, private Auth, private $q, AgencyLookup) {
            
    }

    displayAmount() {
        //if (this.localFeeData && !this.localFeeData.isVaryAmount && this.localFeeData.amountText && this.localFeeData.amountText !== 'Varies') {
        //    return this.localFeeData.amountText;
        //}
        if (this.localFeeData.amount > 0) {
            return this.localFeeData.amount + ' ' + this.territoryInfo.currencyCode;
        }
        else if (this.localFeeData.isVaryAmount || this.localFeeData.amount == 0) {
            return '';
        }
        else {
            return this.localFeeData.amount;
        }
    }
}

angular
    .module('app')
    .directive('localFeeSheet', () => new LocalFeeSheetDirective());