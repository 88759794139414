import { DataAccessFactory } from './DataAccessFactory'
import { Auth, Resource } from './dataAccess'

class ReferenceFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {
            query: { method: 'GET' },
            update: { method: 'PUT', params: { referenceId: '@id' } },
            remove: { method: 'DELETE', params: { referenceId: '@id' } }
        };
        this.server = 'resources'
        this.initialize()
    }

    initialize() {
        var resources: Array<Resource> = [
            {
                name: 'HelpTexts',
                url: 'resources/helpText/:module',
                auth: Auth.None,
                actions: {
                    save: { method: 'POST', params: { module: '@module' } }
                }
            },
            {
                name: 'Resource',
                url: 'resources/resource/:id',
                actions: {
                    query: {
                        method: 'GET',
                        params: { groupId: '@groupId' },
                        url: 'resources/resource/group/:groupId',
                        isArray: false,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'categories.resources.createDate'
                            )
                        )
                    },

                    prepareUpload: {
                        method: 'POST',
                        url: 'resources/resource/prepareUpload',
                        params: { name: '@name', isPrivate: '@isPrivate', extension: '@extension' }
                    },
                    uploadSuccess: { method: 'POST', url: 'resources/resource/uploadSuccess' },
                    uploadFailed: { method: 'POST', url: 'resources/resource/uploadFailed' }
                }
            },
            {
                name: 'AmazonResource',
                url: 'resources/amazon/:applicationId/group/:groupId',
                actions: {
                    query: {
                        method: 'GET',
                        params: { applicationId: '@applicationId', groupId: '@groupId' },
                        url: 'resources/amazon/:applicationId/group/:groupId',
                        isArray: false
                    }
                }
            },
            {
                name: 'ResourcesLookup',
                url: '',
                actions: {
                    resourceTypes: {
                        method: 'GET',
                        url: `resources/lookup/resourceTypes`,
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('name', 'id')
                        )
                    }
                }
            },
            {
                name: 'ResourceSigner',
                url: '',
                actions: {
                    preSign: {
                        url: 'resources/signedUrl',
                        method: 'POST',
                        cache: true,
                        isArray: false
                    }
                }
            }
        ]

        this.process(resources)
    }
}

new ReferenceFactory()