export enum ReviewItemId {
    Media = 1,
    AboutYou = 3,
    YourAvailability = 6,
    ContactDetails = 7,
    EmergencyContactDetails = 8,
    DrivingDetails = 9,
    YourPassport = 10,
    Visa = 11,
    ChildcareExperience = 12,
    PreviousExperience = 13,
    DocumentedChildcareExperience = 14,
    FuturePlans = 15, // not used any more as we removed whole section
    EducationAndWorkHistory = 16,
    MedicalAndHealth = 17,
    YourPoliceRecord = 18,
    SmokingDeclaration = 19,
    Program = 20,
    PreMatch = 21,
    Hobbies = 22,
    References = 24,
    PreExistingMedicalConditions = 25,
    CovidVaccination = 26,

    Allergies = 27,
    PhysicalHealth = 28,
    MentalHealth = 29,
    MedicalTreatments = 30,
    OtherHealthConditions = 31,
    RestrictedHealthInformation = 32,
    Vaccinations = 33,

    PreExistingMedicalConditions_new = 34,
    SmokingDeclaration_new = 35,
    YourPoliceRecord_new = 36,

    InterviewReport_AboutTheInterview = 100,
    InterviewReport_ChildcareExperience = 102,
    InterviewReport_Declaration = 103,
    InterviewReport_EnglishSkills = 104,
    InterviewReport_Evaluation = 105,
    InterviewReport_MedicalCriminal = 106,
    InterviewReport_Motivation = 107,
    InterviewReport_Personality = 108,
    InterviewReport_PreviousExperience = 109,
    FeeSheet = 110,
    MyUploads_SkillsAndInterests = 111,
    MyUploads_DrivingDetails = 112,
    MyUploads_YourPassport = 113,
    MyUploads_MedicalReport = 114,
    MyUploads_Media = 115,
    MyUploads_Covid19Vaccination = 116,
    MyUploads_YourPoliceRecord = 117,
    MyUploads_EducationCertificates = 118,

    MyContactDetails = 201,
    MyPassport = 202,
    MyDrivingLicense = 203,
    MyAvailability = 204,
    MyProgrammeChoice = 205,
    MyPrematchHostFamily = 206,
    MyPreviousUSTravelVisa = 207,
    MyChildcareExperience = 208,
    MyMedicalAndHealth = 209,
    MyHealthAndWellbeing = 210
}

export interface ItemMenuItem {
    name: string
    snapshotFormName?: string
}

export var Items: { [index: number]: ItemMenuItem } = {}
Items[ReviewItemId.Media] = { name: 'Media' }
Items[ReviewItemId.Hobbies] = { name: 'Skills and Interests' , snapshotFormName: 'Hobbies'}
Items[ReviewItemId.AboutYou] = { name: 'About You', snapshotFormName: 'AboutYou' }
Items[ReviewItemId.YourAvailability] = { name: 'Your Availability', snapshotFormName: 'YourAvailability' }
Items[ReviewItemId.ContactDetails] = { name: 'Contact Details', snapshotFormName: 'ContactDetails' }
Items[ReviewItemId.EmergencyContactDetails] = { name: 'Emergency Contact Details', snapshotFormName: 'EmergencyContactDetails' }
Items[ReviewItemId.DrivingDetails] = { name: 'Driving Details', snapshotFormName: 'DrivingDetails' }

Items[ReviewItemId.Program] = { name: 'Program', snapshotFormName: 'Program' }
Items[ReviewItemId.PreMatch] = { name: 'Host Family', snapshotFormName: 'PreMatch' }
Items[ReviewItemId.YourPassport] = { name: 'Your Passport', snapshotFormName: 'Passport' }
Items[ReviewItemId.Visa] = { name: 'Visa', snapshotFormName: 'Visa' }

Items[ReviewItemId.ChildcareExperience] = { name: 'Childcare Experience', snapshotFormName: 'ChildcareExperience' }
Items[ReviewItemId.PreviousExperience] = { name: 'Previous Experience', snapshotFormName: 'PreviousExperience' }
Items[ReviewItemId.DocumentedChildcareExperience] = { name: 'Documented Childcare Experience', snapshotFormName: 'DocumentedChildcareExperience' }

Items[ReviewItemId.EducationAndWorkHistory] = { name: 'Educational History', snapshotFormName: 'EducationalHistory' }

Items[ReviewItemId.MedicalAndHealth] = { name: 'Medical & Health', snapshotFormName: 'MedicalAndHealth' }
Items[ReviewItemId.PreExistingMedicalConditions] = { name: 'Congenital Conditions', snapshotFormName: 'PreExistingMedicalConditions' }
Items[ReviewItemId.SmokingDeclaration] = { name: 'Smoking Declaration', snapshotFormName: 'SmokingDeclaration' }
Items[ReviewItemId.YourPoliceRecord] = { name: 'Your Police Record', snapshotFormName: 'YourPoliceRecord' }
Items[ReviewItemId.CovidVaccination] = { name: 'Covid-19 Vaccination', snapshotFormName: 'CovidVaccination' }

Items[ReviewItemId.Allergies] = { name: 'Allergies', snapshotFormName: 'Allergies' }
Items[ReviewItemId.PhysicalHealth] = { name: 'Physical Health', snapshotFormName: 'PhysicalHealth' }
Items[ReviewItemId.MentalHealth] = { name: 'Mental Health/Wellbeing', snapshotFormName: 'MentalHealth' }
Items[ReviewItemId.MedicalTreatments] = { name: 'Medical Treatments & Medications', snapshotFormName: 'MedicalTreatments' }
Items[ReviewItemId.OtherHealthConditions] = { name: 'Other Conditions', snapshotFormName: 'OtherHealthConditions' }
Items[ReviewItemId.RestrictedHealthInformation] = { name: 'Restricted Health Information', snapshotFormName: 'RestrictedHealthInformation' }
Items[ReviewItemId.Vaccinations] = { name: 'Vaccinations', snapshotFormName: 'Vaccinations' }

Items[ReviewItemId.PreExistingMedicalConditions_new] = { name: 'Congenital Conditions', snapshotFormName: 'PreExistingMedicalConditions' }
Items[ReviewItemId.SmokingDeclaration_new] = { name: 'Smoking Declaration', snapshotFormName: 'SmokingDeclaration' }
Items[ReviewItemId.YourPoliceRecord_new] = { name: 'Your Police Record', snapshotFormName: 'YourPoliceRecord' }


Items[ReviewItemId.References] = { name: 'References', snapshotFormName: 'References' }

Items[ReviewItemId.InterviewReport_AboutTheInterview] = { name: 'Interview', snapshotFormName: 'InterviewReportAbout' }
Items[ReviewItemId.InterviewReport_ChildcareExperience] = { name: 'Childcare Experience', snapshotFormName: 'InterviewReportChildcareExperience' }
Items[ReviewItemId.InterviewReport_Declaration] = { name: 'Declaration', snapshotFormName: 'InterviewReportDeclaration' }
Items[ReviewItemId.InterviewReport_EnglishSkills] = { name: 'English Skills', snapshotFormName: 'InterviewReportEnglishSkills' }
Items[ReviewItemId.InterviewReport_Evaluation] = { name: 'Evaluation', snapshotFormName: 'InterviewReportEvaluation' }
Items[ReviewItemId.InterviewReport_MedicalCriminal] = { name: 'Medical and criminal', snapshotFormName: 'InterviewReportMedicalCriminal' }
Items[ReviewItemId.InterviewReport_Motivation] = { name: 'Motivation & Expectations', snapshotFormName: 'InterviewReportMotivation' }
Items[ReviewItemId.InterviewReport_Personality] = { name: 'Personality & Appearance', snapshotFormName: 'InterviewReportPersonality' }
Items[ReviewItemId.InterviewReport_PreviousExperience] = { name: 'Previous Experience', snapshotFormName: 'InterviewReportPreviousExperience' }

Items[ReviewItemId.FeeSheet] = { name: 'Fee Sheet', snapshotFormName: 'FeeSheetForm' }


Items[ReviewItemId.MyUploads_SkillsAndInterests] = { name: 'Skills and Interests', snapshotFormName: '' }
Items[ReviewItemId.MyUploads_DrivingDetails] = { name: 'Driving Details', snapshotFormName: '' }
Items[ReviewItemId.MyUploads_YourPassport] = { name: 'Your Passport', snapshotFormName: '' }
Items[ReviewItemId.MyUploads_MedicalReport] = { name: 'Medical Report', snapshotFormName: '' }
Items[ReviewItemId.MyUploads_Media] = { name: 'Media', snapshotFormName: '' }
Items[ReviewItemId.MyUploads_Covid19Vaccination] = { name: 'Covid-19 Vaccination', snapshotFormName: '' }
Items[ReviewItemId.MyUploads_YourPoliceRecord] = { name: 'Your Police Record', snapshotFormName: '' }
Items[ReviewItemId.MyUploads_EducationCertificates] = { name: 'Education Certificates', snapshotFormName: '' }

Items[ReviewItemId.MyContactDetails] = { name: 'My Contact Details', snapshotFormName: 'PreInterviewContactDetails' }
Items[ReviewItemId.MyPassport] = { name: 'My Passport', snapshotFormName: 'PreInterviewPassport' }
Items[ReviewItemId.MyDrivingLicense] = { name: 'My Driving License', snapshotFormName: 'PreInterviewDrivingDetails' }
Items[ReviewItemId.MyAvailability] = { name: 'My Availability', snapshotFormName: 'PreInterviewAvailability' }
Items[ReviewItemId.MyProgrammeChoice] = { name: 'My Programme Choice', snapshotFormName: 'PreInterviewProgram' }
Items[ReviewItemId.MyPrematchHostFamily] = { name: 'My Prematch Host Family', snapshotFormName: 'PreInterviewPreMatch' }
Items[ReviewItemId.MyPreviousUSTravelVisa] = { name: 'My Previous US Travel Visa', snapshotFormName: 'PreInterviewVisa' }
Items[ReviewItemId.MyChildcareExperience] = { name: 'My Childcare Experience', snapshotFormName: 'PreInterviewChildcareExperience' }
Items[ReviewItemId.MyMedicalAndHealth] = { name: 'My Medical And Health', snapshotFormName: 'PreInterviewMedicalAndHealth' }
Items[ReviewItemId.MyHealthAndWellbeing] = { name: 'My Health And Wellbeing', snapshotFormName: 'PreInterviewHealthAndWellbeing' }
