class AifsCheckboxListController {

    $name
    dataSource

    static $inject = ['$scope', '$element', '$attrs', 'MetaForm']
    constructor($scope, $element, $attrs, MetaForm) {
        var formCtrl = $element.controller('aifsForm');
        var metaForm = new MetaForm(formCtrl.Id);

        metaForm.getDataSource($attrs.aifsCheckboxList, (dataSource) => {
            if (dataSource) {
                this.dataSource = dataSource;

                var updateOptions = function () {
                    //TODO get question and check if is valid 
                    //if(questionCtrl.$isVisible()){

                    metaForm.getDropdownOptions(dataSource, $scope.model, function (data) {
                        $scope.options = data;
                    });
                    //}
                };

                if (dataSource.dependencies && dataSource.dependencies.length > 0) {
                    angular.forEach(dataSource.dependencies, function (dependecy) {
                        formCtrl.subscribeField(dependecy, updateOptions);
                    });
                } else {
                    updateOptions();
                }
            }
        });

        this.$name = $attrs.name;

    }
    //var questionCtrl = $element.controller('aifsForm');
}

angular
    .module("aifs.metaform")
    .directive("aifsCheckboxList", function () {
        return {
            restrict: 'A',
            require: ["^form", '?ngModel', 'aifsComponent'],
            controller: AifsCheckboxListController
        };
    });