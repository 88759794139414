import { ApplicantRole } from '../../../services/siteUser';

const voucherStorageKey = 'register-voucher';

export class PreloggedinController {

    reason: any;
    terms: any;
    termsText: any;

    selectedAgency: any;
    selectedInterviewer: any;
    agencies: any;
    interviewers: any;
    voucherAvailable: boolean;
    voucherCode: string;
    voucherValid: boolean;
    displayVoucherValidMessage: boolean;




    static $inject = ['$scope', '$sce', 'Auth', 'RegistrationAgency', 'RegistrationInterviewer', 'Registration', '$timeout', 'localStorageService'];
    constructor($scope, private $sce, private Auth, private RegistrationAgency, private RegistrationInterviewer, private Registration, private $timeout, private localStorageService) {
        if (this.Auth.user.isUserQualified) {
            this.updateReason();
        }
        else {
            this.Auth.forDNQ()
        }

        var voucherText = localStorageService.get(voucherStorageKey);
        if (voucherText) {
            this.voucherCode = voucherText;
        }
    }

    checkVoucherNumberValid() {
        if (this.selectedAgency && this.voucherCode) {
            var data = {
                agencyId: this.selectedAgency.id,
                userId: this.Auth.user.userId,
                voucherCode: this.voucherCode
            }
            this.Registration.voucherValid(data, (result) => {
                this.voucherValid = result.isValid;
            });
        }
    }

    updateReason() {
        var roleDetails = <ApplicantRole>this.Auth.user.roleDetails;
        if (roleDetails) {
            if (roleDetails.agencySelected === false) {
                this.reason = 'selectAgency';
                this.getAgencies();
            } else if (roleDetails.hasLatestTermsAndConditions === false) {
                this.reason = 'termsAndConditions';
                this.getTermsAndConditions();
            }
        } else {
            delete this.reason;
        }
    }

    getTermsAndConditions() {
        this.Auth.termsAndConditions().then((terms) => {
            this.terms = terms
            this.termsText = this.$sce.trustAsHtml(terms.text);
        });
    }

    acceptTerms() {
        this.Auth.acceptTermsAndConditions(this.terms.id).then(() => {
            this.updateReason();
        });
    }




    getAgencies() {
        this.Registration.get((registrant) => {
            if (registrant.agencyId) {
                this.RegistrationAgency.get({ agencyId: registrant.agencyId }, (agency) => {
                    this.agencies = [agency];
                    this.selectedAgency = agency;
                    this.voucherAvailable = agency.voucherAvailable
                });
            } else {
                this.RegistrationAgency.query({ country: registrant.residenceCountry }, (agencies) => {
                    let directAgency = agencies.filter(a => a.isDirectAgency)
                    this.agencies = directAgency.length > 0 ? directAgency : agencies;
                    if (agencies.length === 1) {
                        this.selectedAgency = agencies[0];
                        this.voucherAvailable = agencies.some(a => a.voucherAvailable);
                        if (this.selectedAgency.allowSelectInterviewer) {
                            //get interviewer if agency is preselected
                            this.agencySelected(this.selectedAgency);
                        }
                    }
                });
            }
        });
    }

    agencySelected(agency) {
        this.selectedAgency = agency;
        if (agency.allowSelectInterviewer) {
            this.interviewers = this.RegistrationInterviewer.query({
                agencyId: agency.id
            });
        } else {
            this.interviewers = undefined;
        }
    }

    interviewerSelected(interviewer) {
        this.selectedInterviewer = interviewer;
    }

    needToSelectInterviewer() {
        return (this.selectedAgency && this.interviewers) && this.selectedAgency.allowSelectInterviewer && this.interviewers.length > 0;
    }

    selectAgency() {
        if (this.selectedAgency && this.voucherCode) {
            var data = {
                agencyId: this.selectedAgency.id,
                userId: this.Auth.user.userId,
                voucherCode: this.voucherCode
            }
            this.Registration.voucherValid(data, (result) => {
                this.voucherValid = result.isValid;
                this.displayVoucherValidMessage = true;

                if (this.voucherValid) {
                    //voucher applied successfully
                    this.$timeout(() => {
                        this.displayVoucherValidMessage = false;
                        this.startApplication();
                    }, 2000);


                }
            });
        }
        else {
            this.startApplication();
        }
    }

    startApplication() {
        let interviewerId;
        if (this.selectedInterviewer && this.interviewers && this.interviewers.some(i => i.id === this.selectedInterviewer.id)) {
            interviewerId = this.selectedInterviewer.id
        }
        this.Auth.selectAgency(this.selectedAgency.id, interviewerId, this.voucherCode).then(() => {
            this.localStorageService.set(voucherStorageKey, '');
            this.updateReason();
        });
    }
}

angular
    .module('app')
    .controller('PreloggedinController', PreloggedinController);