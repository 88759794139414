import { BusinessArea } from "../../services/AuthService";

const gridSettingslocalStorageServiceKey = 'queue-grid-settings';
const filterAccordionlocalStorageServiceKey = 'queue-filter-accordion';

interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
    filters: {
    }
}

class ContactQueueController {

    selectedContact: any[];
    params: WorkQueueParams;

    visibleForJunoUser: boolean;
    visibleForAgency: boolean;
    contactStatusList: any[];
    trainingStatusList: any[];

    agencies: any[];
    country: any[];
    roles: any[];
    loading: boolean = false;
    agencyTotalCount: number;
    agencyPage: any;
    selections: any[];
    filterAccordion: any;
    contactId: number;
    contactFirstName: any;
    contactLastName: any;
    contactEmailAddress: any;
    contactCountry: any;
    contactStatus: any = { A: true };
    trainingStatus: any = { '1': true, '0': true };
    isChecked: number;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;

    static $inject = ['$rootScope', '$scope', '$q', '$state', 'Auth', 'AgencyContact', 'localStorageService', 'ApplicationLookup', 'AgencyLookup', 'GridExport'];
    constructor(private $rootScope, private $scope, private $q, private $state, private Auth, private AgencyContact, private localStorageService, ApplicationLookup, private AgencyLookup, private GridExport) {

        this.selectedContact = [];

        this.params = {
            page: 0,
            pageSize: 100,
            orderBy: 'contactId',
            descending: true,
            filters: {
                contactId: this.contactId,
                contactFirstName: this.contactFirstName,
                contactLastName: this.contactLastName,
                contactEmailAddress: this.contactEmailAddress,
                contactCountry: this.contactCountry,
                contactStatus: this.contactStatus,
                trainingStatus: this.trainingStatus
            }
        };

        this.visibleForJunoUser = this.Auth.user.isOfficer;
        this.visibleForAgency = this.Auth.user.isAgent;

        $scope.$watch(() => this.selectedContact[0], (values) => {
            if (values) {
                var url = this.$state.href('^.contactInformation.contactBasicDetails', { contactId: values.contactId });
                window.open(url, '_blank');
            }
        }, true);

        $scope.$watch(() => this.filterAccordion, (values) => {
            localStorageService.set(filterAccordionlocalStorageServiceKey, this.filterAccordion);
        }, true);

        ApplicationLookup.country((data) => {
            this.country = data;
        });   

        AgencyLookup.agencies((data) => {
            this.agencies = data;
        });

        this.roles = [
            { code: 'AGY', name: 'Agency User' },
            { code: 'BRA', name: 'Branch Manager' },
            { code: 'INT', name: 'Interviewer' },
            { code: 'OFF', name: 'Office User' }
        ]

        this.contactStatusList = [{ 'statusCode': 'A', 'agencyStatus': 'Active' }, { 'statusCode': 'I', 'agencyStatus': 'Inactive' }];

        this.trainingStatusList = [
            { code: '1', name: 'Yes' },
            { code: '0', name: 'No' }
        ];

    }

    getContacts(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;

        this.refresh();
    }

    refresh() {
        this.loading = true;
        new this.AgencyContact.getAllData(this.params, (result) => {
            this.agencyPage = result;
            this.lastPage = Math.floor(this.agencyPage.totalCount / this.params.pageSize);
            this.agencyTotalCount = result.totalCount;

            this.selections = [];

            this.loading = false;
        }, () => {
            this.loading = false;
            });
    }

    getGridTemplate() {
        var rootPath = 'areas/contact/grid/';
        return `${rootPath}Default.html`;
    }

    applyFilters(contactStatusList, index) {
            
        this.refresh();
    }

    changePageSize() {
        this.lastPage = Math.floor(this.agencyPage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }

    public export() {
        this.GridExport.saveAsCSV('.applicationsTable', 'export.csv', this.selections.length != 0)
    }
}
angular
    .module('app')
    .controller('ContactQueueController', ContactQueueController);
