 
import { watchScrollController } from './watchScroll'

class collapseOnScroll implements ng.IDirective {
    restrict = 'A'
    controller = collapseOnScrollController
    controllerAs = 'collapsCtrl'
    bindToController = true
    transclude = true
    templateUrl = 'controls/watchScroll/collapseOnScroll.html'
    require = ['?^watchScroll', '?^watchScrollWindow']
    scope = {
        collapseOnScroll: '='
    }
    link(scope, element, attrs, ctrls: watchScrollController[]) {
        var ctrl = ctrls[0] || ctrls[1];
        ctrl.subscribe(function (scrollTop) {

            var newValue = scrollTop > scope.collapsCtrl.collapseOnScroll;
            if (scope.collapsCtrl.isCollapse !== newValue) {
                scope.$apply(() => {
                    scope.collapsCtrl.isCollapse = newValue;
                });
            }
        });
    }
}
    


export class collapseOnScrollController {

    isCollapse: boolean

    static $inject = ['$scope', '$state', 'SearchApplicant', 'Auth', 'dialogs'];
    constructor(private $scope, private $state, private SearchApplicant, private Auth, private dialogs) {

    }
}

angular
    .module('app')
    .directive('collapseOnScroll', () => new collapseOnScroll())