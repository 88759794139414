function AifsAnswerLink(scope, element, attrs, ctrls, transclude) {
    var newScope = scope.$parent.$new();
    var answerCtrl = ctrls[0];
    newScope['error'] = answerCtrl.$error;
    newScope['isValid'] = answerCtrl.$isValid;
    transclude(newScope, function (clone, scope) {
        element.find('[my-transclude]').replaceWith(clone);
    });
}


class AifsAnswerController {

    $id
    components = [];
    questionRule;

    static $inject = ['$scope', '$element', '$attrs', 'MetaForm']
    constructor(private $scope, $element, $attrs, MetaForm) {

        this.$id = $attrs.id;

        var metaForm = new MetaForm($scope.$parent.formId);
        metaForm.getQuestionRule($attrs.id, (questionRule) => {
            if (questionRule) {
                this.questionRule = questionRule;
                $scope.show = questionRule.isValid.call(questionRule);
            }
            $element.controller('aifsDisplayForm').$addQuestion(this);
        });

        $scope.show = true;
    }

    $addComponent (component) {
        this.components.push(component);
    };

    $isValid () {
        if (!this.questionRule || this.questionRule.isValid()) {
            for (var i = 0; i < this.components.length; i++) {
                if (!this.components[i].$isValid())
                    return false;
            }
        }
        return true;
    };

    $updateRule () {
        this.$scope.show = this.questionRule.isValid.call(this.questionRule);
    };

    $isVisible () {
        return this.$scope.show;
    };

}


angular
    .module('aifs.metaform')
    .directive('aifsAnswer', function () {
        return {
            restrict: 'A',
            scope: {
                id: '@',
                caption: '@'
            },
            replace: true,
            transclude: true,
            require: ['^aifsAnswer'],
            link: AifsAnswerLink,
            controller: AifsAnswerController,
            templateUrl: 'templates/metaform/answer.html'
        };
    });
