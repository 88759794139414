import { AgencyCompetitorModalController } from '../AgencyDetails/modals/AgencyCompetitorModalController'
import _ from 'lodash-es';
import { BusinessArea } from '../../../services/AuthService';


class AgencyCompetitorsController {

    orderBy: string = 'dateCreated';

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;
    isAgencyUser: boolean = false;

    static $inject = ['$scope', '$state', 'Auth', '$uibModal', 'Competitors', 'agencyId', 'competitors','dialogs', 'competitorId'];
    constructor($scope, private $state, private Auth, private $uibModal, private Competitors, private agencyId, private competitors, private dialogs, private competitorId) {
        
        this.selections = [];
        this.selectedGridItems = [];
        this.isAgencyUser = this.Auth.user.isAgent;

        this.showSelectedOne();

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.viewOrEdit(values[0]);
                this.selectedGridItems = [];
            }
        }, true);
    }

    add() {
        return this.$uibModal
            .open({
                templateUrl: 'areas/agency/AgencyDetails/modals/agencyCompetitorModal.html',
                controller: AgencyCompetitorModalController,
                controllerAs: '$ctrl',
                backdrop: false,
                size: 'lg',
                resolve: {
                    competitor: {
                        agencyId: this.agencyId,
                        pricing: {},
                        supportAndApplicationProcess: {},
                        marketing: {}
                    }
                }
            })
            .result
            .then(() => {
                return this.refresh();
            });
    }

    copyCompetitor() {
        if (this.selections[0]) {
            this.Competitors.query({ competitorId: this.selections[0].id }).$promise
                .then((selectedComp) => {
                    selectedComp.id = 0;
                    selectedComp.status = 0;
                    selectedComp.name = selectedComp.name + " copy";

                    return this.$uibModal
                        .open({
                            templateUrl: 'areas/agency/AgencyDetails/modals/agencyCompetitorModal.html',
                            controller: AgencyCompetitorModalController,
                            controllerAs: '$ctrl',
                            backdrop: false,
                            size: 'lg',
                            resolve: {
                                competitor: () => selectedComp
                            }
                        })
                        .result
                        .then(() => {
                            return this.refresh();
                        });
                });

        }
    }

    viewOrEdit(competitorItem) {
        return this.$uibModal
            .open({
                templateUrl: 'areas/agency/AgencyDetails/modals/agencyCompetitorModal.html',
                controller: AgencyCompetitorModalController,
                controllerAs: '$ctrl',
                backdrop: false,
                size: 'lg',
                resolve: {
                    competitor: () => this.Competitors.query({ competitorId: competitorItem.id }).$promise
                }
            })
            .result
            .then(() => {
                return this.refresh();
            });
    }

    submitCompetitor() {
        if (this.selections.length > 0)
            var submitCompetitorRequest = {
                competitorId: this.selections[0].id,
                agencyId: this.selections[0].agencyId
            };

            this.dialogs.confirm('Submit competitor report', `Are you sure that you want to submit this competitor report?`)
                .result
                .then(() => this.Competitors.submit(submitCompetitorRequest).$promise)
                .then(() => {
                    return this.refresh();
                });
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.competitors) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.competitors) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                id: item.id
            };
            if (!_.includes(this.selections, serach)) {
                if (this.selections.length > 0) {
                    var lastSelectedCompetitor = this.competitors.find(x => x.id == this.selections[0].id);

                    if (lastSelectedCompetitor) {
                        lastSelectedCompetitor.checked = false;
                        this.selections.pop();
                    }
                }

                this.selections.push(item);

                for (let entry of this.competitors) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }

    showSelectedOne() {
        if (this.competitorId && this.competitorId > 0) {
            var selectedComp = this.competitors.find(c => c.id == this.competitorId);
            if (selectedComp) {
                selectedComp.checked = true
                this.viewOrEdit(selectedComp);
            }
        }
    }

    refresh() {
        this.Competitors.queryForAgency({ agencyId: this.agencyId })
            .$promise
            .then((data) => {
                this.competitors = data;

                if (this.competitorId && this.competitorId > 0) {
                    this.selectedGridItems = this.competitors.find(c => c.id === this.competitorId);
                }

                this.selections = [];
            })
    }
}

angular
    .module('app')
    .controller('AgencyCompetitorsController', AgencyCompetitorsController)