import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class ContractualAgreementController {

    agencyForGettingParent: any
    selectedContract: any;
    agencyId: number
    contactId: number
    userId: number
    userName: string
    contractualAgreementReportId: number = 16
        
    alerts: IAlertConfig[] = []
    scheduledAndOfferedContractsDates: any[];
    selectedContractId: any;
    showSuccessText: boolean = false;

    static $inject = ['$scope', 'Auth', 'dialogs', 'agencyId', 'contactId', '$timeout', 'AgencyContract', 'Report', '$sce', '$state', '$window'];
    constructor(private $scope, private Auth, private dialogs, agencyId, contactId, private $timeout, private AgencyContract, private Report, private $sce, private $state, private $window) {
        this.agencyId = agencyId;
        this.contactId = contactId;
        this.userId = Auth.user.userId;
        this.userName = `${Auth.user.firstName} ${Auth.user.lastName}`;

        AgencyContract.getContractInformationForMenu({ agencyId}, (result) => {
        //if user does not have any view/ accept permission 
            if (!result.canViewContract && !result.canAcceptContract) {
                if (this.$state.current.name == "app.management.agencyContracts.contractualAgreement") {
                    this.$state.transitionTo('app.management.agencyContracts.commissionStatement', {}, { reload: false, inherit: true, notify: true });
                } else {
                    this.$state.transitionTo('app.agencyContracts.commissionStatement', {}, { reload: false, inherit: true, notify: true });
                }
            }
        });

        this.AgencyContract.getScheduledAndOfferedContracts({ agencyId: this.agencyId }, (result) => {
            this.scheduledAndOfferedContractsDates = result;
            if (this.scheduledAndOfferedContractsDates.length > 0) {
                this.selectedContractId = "" + this.scheduledAndOfferedContractsDates[0].contractId;
            }
        });

        $scope.$watch(() => this.selectedContractId, () => {
            this.loadSelectedContract();
        });
    }

    downloadContractualAgreementReport() {
        this.Report.downloadUrl({ reportId: this.contractualAgreementReportId, agencyId: this.agencyId }, (data) => {
            window.open(this.$sce.trustAsResourceUrl(data.reportURL), '_blank');
        })
    }

    loadSelectedContract() {

        if (this.selectedContractId) {
            var data = {
                agencyId: this.agencyId,
                contactId: this.contactId,
                contractId: this.selectedContractId,
                userId: this.userId
            };

            this.AgencyContract.getContractById({ agencyId: this.agencyId }, data)
                .$promise
                .then((results) => {
                    this.selectedContract = results;
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured while Getting contract. Please try again after a while.')
                });
        } else {
            this.selectedContract = {};
        }
    }

    acceptAgencyContract() {
        this.$scope.$broadcast('submitted', true);

        var data = {
            agencyId: this.agencyId,
            scheduleAcceptedBy: this.contactId,
            contractId: this.selectedContractId,
            userId: this.userId,
            agencyAgreement: this.selectedContract.agencyAgreement,
        };

        this.AgencyContract.acceptAgencyContract({ agencyId: this.agencyId }, data)
            .$promise
            .then((results) => {
                this.showSuccessText = true;
                this.$timeout(() => { this.showSuccessText = false; window.location.reload();}, 2000);
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while saving your Agency Agreement. Please try again after a while.')
            });
    }

    print() {
        this.$window.print();
    }
}

angular
    .module('app')
    .controller('ContractualAgreementController', ContractualAgreementController)