import { BusinessArea } from '../../../services/AuthService';

export class AltosStatusController {
    altosStatus: any

    static $inject = ['$scope', 'applicantId', 'altosStatusPage'];
    constructor(private $scope, private applicantId, altosStatusPage) {
        this.altosStatus = altosStatusPage;
    }
}

angular
    .module('app')
    .controller('AltosStatusController', AltosStatusController)