 

var ImagePreviewDirectiveFactory = ['$window', function ImagePreviewDirectiveFactory($window): ng.IDirective {

    var support = !!($window.FileReader && $window.CanvasRenderingContext2D);

    function isFile(item) {
        return angular.isObject(item) && item instanceof $window.File;
    }

    function isImage(file) {
        var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
    }

    function link(scope, element, attributes) {

        var file = scope.$eval(attributes.imagePreview);

        if (!isFile(file) || !isImage(file)) {
            element.attr('src', 'img/no_preview.png');
            return;
        }

        var reader = new FileReader();

        reader.onload = onLoadFile;
        reader.readAsDataURL(file);

        function onLoadFile(event) {
            element.attr('src', event.target.result);
        }
    }

    return {
        restrict: 'A',
        link: link
    };
}]


angular
    .module('app')
    .directive('imagePreview', ImagePreviewDirectiveFactory);

