import { IAlertConfig } from '../../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../../services/AuthService';
import { MaintenanceMessagesModalController } from './MaintenanceMessagesModalController';
import _ from 'lodash-es';


interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}

export class MaintenanceMessagesController {
    messages;

    editFrom;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public messagePage: {messages: any[], totalCount:number};
    params: WorkQueueParams;
    loading: boolean = false;
    agencyId: number;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;


    static $inject = ['$scope', 'Auth', '$q', '$state', 'MaintenanceMessages', 'dialogs', '$uibModal', 'Agency', 'GridExport'];
    constructor(private $scope, private Auth, private $q, private $state, private MaintenanceMessages, private dialogs, private $uibModal, private Agency, private GridExport) {

        this.selectedGridItems = [];

        this.params = {
            page: 0,
            pageSize: 50,
            orderBy: 'messageId',
            descending: true
        };

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.editMessage(values[0]);
                this.selectedGridItems = [];
            }
        }, true); 



    }

    getMessages(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'messageId';
            this.params.descending = true;
        }

        this.refresh();
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.messagePage.messages) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.messagePage.messages) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                messageId: item.messageId
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.messagePage.messages) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.messageId === item.messageId);
        }
    }


    refresh() {
        this.loading = true;

        new this.MaintenanceMessages.query(this.params).$promise.then((result) => {
            this.messagePage = result;

            this.lastPage = Math.floor(this.messagePage.totalCount / this.params.pageSize);

            this.selections = [];

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
        }, () => {
            this.loading = false;
        });
    }

    addMessage() {
        this.$uibModal.open({
            templateUrl: 'areas/tools/communications/maintenanceMessages/maintenanceMessagesModal.html',
            controller: MaintenanceMessagesModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                message: { messageId: 0, editMode: false },
                preFilled: false
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    addPreFilledMessage() {
        var messageId = this.selections[0].messageId;

        this.$uibModal.open({
            templateUrl: 'areas/tools/communications/maintenanceMessages/maintenanceMessagesModal.html',
            controller: MaintenanceMessagesModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                message: this.MaintenanceMessages.get({ messageId: messageId }).$promise,
                preFilled: true
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    editMessage(message) {

        this.$uibModal.open({
            templateUrl: 'areas/tools/communications/maintenanceMessages/maintenanceMessagesModal.html',
            controller: MaintenanceMessagesModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                message: this.MaintenanceMessages.get({ messageId: message.messageId }).$promise,
                preFilled: false
            }
        }).result.then(() => {
            this.refresh();
        });            
    }

    removeMessage() { 
        if (this.selections.length !== 1 || this.selections[0].canDelete) {
            var messageId = this.selections[0].messageId;
            this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this message?')
                .result
                .then(() => {
                    this.MaintenanceMessages
                        .remove({ messageId: messageId}, { messageId: messageId })
                        .$promise
                        .then(() => {
                            this.refresh();
                        }, () => {
                            this.dialogs.error('Error', 'We are sorry but message cannot be deleted. Please try again in 5 minutes. If the problem persists, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
                        });
                });
        }
    }

    public export() {
        this.GridExport.saveAsCSV('.applicationsTable', 'export.csv', this.selections.length != 0)
    }

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }

    changePageSize() {
        this.lastPage = Math.floor(this.messagePage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }
}


angular
    .module('app')
    .controller('MaintenanceMessageController', MaintenanceMessagesController);