import _ from 'lodash-es'

export const allowTypes = [1, 2, 3, 4, 7, 8, 9, 10, 13, 15, 16, 23, 27, 28, 31, 36, 38, 45, 46, 47, 48, 53, 54];

export class ChangeDocumentTypeController {

    childcareReference;
    childcareReferences;

    characterReference;
    characterReferences;

    static $inject = ['$rootScope', '$scope', 'dialogs', '$uibModalInstance', 'ApplicationDocument', 'Reference', 'document', 'showRedCrossCertDoc']
    constructor(private $rootScope, private $scope, private dialogs, private $uibModalInstance, private ApplicationDocument, private Reference, private document, private showRedCrossCertDoc) {
        //this.categories = ApplicationDocument.queryAll({ applicationId: document.applicationId });
        Reference.query({ applicationId: document.applicationId }, (data) => {
            this.childcareReferences = data.filter(r => r.referenceType === 3);
            this.characterReferences = data.filter(r => r.referenceType === 1);
        });
        if (document.documentType === 7 || document.documentType === 8) {
            this.childcareReference = document.extraReference
        }
        if (document.documentType === 9 || document.documentType === 10) {
            this.characterReference = document.extraReference
        }
    }

    change() {
        if (this.document.documentType === 7 || this.document.documentType === 8) {
            this.document.extraReference = this.childcareReference;
        }
        else if (this.document.documentType === 9 || this.document.documentType === 10) {
            this.document.extraReference = this.characterReference;
        }
        else {
            this.document.extraReference = 0;
        }

        this.ApplicationDocument
            .changeType({ documentId: this.document.id }, {
                documentType: this.document.documentType,
                extraReference: this.document.extraReference
            })
            .$promise
            .then(() => {
                this.$uibModalInstance.close();
            }, () => {
                this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
            });

    }

    close() {
        this.$uibModalInstance.dismiss()
    }
}


