 


interface Request {
    name: string;
    promise: ng.IPromise<any>;
}

interface Dictionary<T> {
    [name: string]: T
}

interface ModuleDictionary {

}

class ResourcesLoader {
    private requests: Dictionary<Request>;
    private modules: Dictionary<any>;

    constructor(private $q, private Auth, private HelpTexts) {
        this.requests = {};
        this.modules = {};
    }

    public getModule(name) {
        var promise;
        var request = this.requests[name] || this.requests[''];

        // Check if already asked for data
        if (request) {
            promise = request.promise;
        } else {
            var agencyId = this.Auth.user && this.Auth.user.roleDetails && this.Auth.user.roleDetails.agencyId;

            if (name) {
                //Get single module
                promise = this.HelpTexts
                    .get({ module: name, agencyId: agencyId })
                    .$promise
                    .then((d) => {
                        this.modules[name] = d;
                    });
            } else {
                // Get all modules
                promise = this.HelpTexts
                    .query({ agencyId: agencyId })
                    .$promise
                    .thne((d) => {
                        angular.extend(this.modules, d);
                    });
            }
            // save request 
            this.requests[name] = <Request>{
                name: name,
                promise: promise
            };
        }

        return promise.then(() => name ? this.modules[name] : this.modules );
    }
        
}

class ResourcesLoaderProvider implements ng.IServiceProvider  {

    private resourcesLoader: ResourcesLoader;

    $get = ['$q', 'Auth', 'HelpTexts', function ($q, Auth, HelpTexts) {

        if (!this.resourcesLoader)
            this.resourcesLoader = new ResourcesLoader($q, Auth, HelpTexts);

        return this.resourcesLoader;
    }];
}

angular
    .module('app')
    .provider('ResourcesLoader', ResourcesLoaderProvider);

