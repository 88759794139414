
function CloudImgFiter(url, settings) {
    if (!url)
        return url;

    var parameters = ''
    if (settings.res && settings.res != 'crop') {
        parameters += `&func=${settings.res}`;
    }

    if (settings.w > 0) {
        parameters += `&width=${settings.w}`;
    }

    if (settings.h > 0) {
        parameters += `&height=${settings.h}`;
    }

    return url.replace('{params}', parameters)
}

function CloudImgFiterFactory(): Function {
    return CloudImgFiter;
}

angular
    .module('app')
    .filter('cloudImg', [CloudImgFiterFactory]);


