
class ResourceController {
    static $inject = [];
    constructor() {

    }
}

angular
    .module("app")
    .controller("ResourceController", ResourceController);