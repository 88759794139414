import _ from 'lodash-es'
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { PromotionModalController } from './PromotionModal';
import { Kind } from './kind';


interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}
//const gridSettingsStorageKey = 'promotion-grid-settings';

export enum PromotionType {
    VoucherPromotion = 1,
    QualifyingPromotion = 2,
    SpecialOffer = 3,
    Discount = 4
}



export class PromotionsController {
    Kind = Kind

    promotions;

    editFrom;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public promotionPage: {promotions: any[], totalCount:number};
    params: WorkQueueParams;
    loading: boolean = false;
    agencyId: number;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;

    columns;


    static $inject = ['$scope', '$q', '$state', 'Promotion', 'Promotions', 'GridExport', 'dialogs', '$uibModal', 'Agency', 'kind'];
    constructor(private $scope, private $q, private $state, private Promotion, private Promotions, private GridExport, private dialogs, private $uibModal, private Agency, private kind: Kind) {

        this.selections = [];
        this.selectedGridItems = [];

        this.params = {
            page: 0,
            pageSize: 50,
            orderBy: 'isActive',
            descending: true
        };

        if (kind == Kind.Promotion) {
            this.columns = [
                'check',
                'promotionType',
                'promotionName',
                'amount',
                'startOlaDate',
                'endOlaDate',
                'startDate',
                'endDate',
                'startApplicableDate',
                'endApplicableDate',
                'promotionDescription',
                'voucherNumber',
                'isActive'
            ];
        }
        else if (kind == Kind.Discount) {
            this.columns = [
                'check',
                'promotionName',
                'amount',
                'startOlaDate',
                'endOlaDate',
                'promotionDescription'
            ];
        }

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.showPromotion(values[0]);
                this.selectedGridItems = [];
            }
        }, true);
    }

    getPromotions(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'isActive';
            this.params.descending = true;
        }

        this.refresh(false);
    }

    refresh(isShowLastSelectedPromotion) {
        this.loading = true;

        new this.Promotions.all({ kind: this.kind }, this.params, (result) => {
            this.promotionPage = result;

            this.lastPage = Math.floor(this.promotionPage.totalCount / this.params.pageSize);

            if (!isShowLastSelectedPromotion && this.promotionPage.promotions) {
                this.selections = [];
            }
            else {
                this.selections = result.promotions.filter(p => this.selections.some(s => s.promotionId === p.promotionId));
            }

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
        }, () => {
            this.loading = false;
        });
    }

    addPromotion() {
        var data:any = {
            promotionId: 0,
            editMode: false,
            amount: 0,
            kind: this.kind
        }

        if (this.kind == Kind.Discount) {
            data.promotionTypeId = PromotionType.Discount;
        }


        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainPromotions/promotionModal.html',
            controller: PromotionModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                promotion: () => data
            }
        }).result.then(() => {
            this.refresh(false);
        }, () => { });
    }

    editPromotion() {
        var promotionId = this.selections[0].promotionId;
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainPromotions/promotionModal.html',
            controller: PromotionModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                promotion: this.Promotion.query({ promotionId: promotionId, kind: this.kind }).$promise
            }
        }).result.then(() => {
            this.refresh(true);
        }, () => { });            
    }

    deletePromotion() {
        var promotion = this.selections[0];
        var data = { promotionId: promotion.promotionId };
        if (promotion.promotionTypeId == 1) {
            this.dialogs.confirm('Delete Promotion', `Are you sure that you want to delete this promotion <strong>${promotion.promotionName}</strong>?`)
                .result
                .then(() => {
                    this.Promotion.remove(data)
                        .$promise.then(
                            (result) => {
                                this.refresh(false);
                            },
                            () => {
                                this.dialogs.error('Internal error', 'Error occured. Please try again after a while.');
                            }
                        )
                    }
                );
        }
    }

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.promotionPage.promotions) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.promotionPage.promotions) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {
                promotionId: item.promotionId 
            };
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.promotionPage.promotions) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.promotionId === item.promotionId);
        }
    }

    changePageSize() {
        this.lastPage = Math.floor(this.promotionPage.totalCount / this.params.pageSize);

        this.refresh(false);
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }

    showPromotion(promotion) {
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainPromotions/promotionModal.html',
            controller: PromotionModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                promotion: this.Promotion.query({ promotionId: promotion.promotionId, kind: this.kind }).$promise
            }
        }).result.then(() => {
            this.refresh(true);
        });            
    }

    public export() {
        this.GridExport.saveAsCSV('.applicationsTable', 'export.csv', this.selections.length != 0)
    }
}


angular
    .module('app')
    .controller('PromotionsController', PromotionsController);