import _ from 'lodash-es'
import moment from 'moment';
function CretateLink(directiveName, compareDate) {

    return function link(scope, elem, attrs, ctrls) {
        var formCtrl = ctrls[0]; // Will have all the element of form
        var ngModelCtrl = ctrls[1]; // will have all the

        var compareFieldName = attrs[directiveName]; // Get element name we need to compare to
        var compareField = formCtrl[compareFieldName]; // Get element data
        var formats = attrs.dateConverter.split('|');
              
        ngModelCtrl.$validators[directiveName] = function (modelValue, viewValue) {
            if (compareField) {
                var from = compareField.$viewValue;
                var to = viewValue;
                if (!moment.isDate(from)) from = moment(from, formats).toDate();
                if (!moment.isDate(to)) to = moment(to, formats).toDate();

                return ngModelCtrl.$isEmpty(modelValue) ||
                    _.isNaN(from.getTime()) ||
                    _.isNaN(to.getTime()) ||
                    compareDate(from, to);
            }
        };

        compareField.$parsers.unshift(function (value) {
            ngModelCtrl.$validate();
            return value;
        });
    };
}

// Declare module
var module = angular.module('aifs.metaform');

// Create an array of directive
var directives = [
    { name: 'dateEq', compare: function (from, to) { return from === to; } }, // angular doesn't allow (-) hyphen (ex date-eq) in directives name, but if we do dateEq(check the capital E). angular changes this dorective to date-eq
    { name: 'dateLt', compare: function (from, to) { return from > to; } },
    { name: 'dateGt', compare: function (from, to) { return from < to; } },
    { name: 'dateLe', compare: function (from, to) { return from >= to; } },
    { name: 'dateGe', compare: function (from, to) { return from <= to; } }
];

// Iterate through all the directive created above and create them

angular.forEach(directives, function (directive) {
    module.directive(directive.name, function () {
        return {
            require: ['^form', 'ngModel'],
            restrict: 'A',
            link: CretateLink(directive.name, directive.compare)
        };
    });
});
