 
import _ from 'lodash-es'

class applicantSearchDirective implements ng.IDirective {
    restrict = 'AE'
    controller = applicantSearchController
    controllerAs = 'asCtrl'
    bindToController = true
    templateUrl = 'controls/applicantSearch/applicantSearch.html'
}

class applicantSearchController {

    lastSearch: string;

    static $inject = ['$scope', '$state', 'SearchApplicant', 'Auth', 'dialogs'];
    constructor(private $scope, private $state, private SearchApplicant, private Auth, private dialogs) {
    }

    quickSearch(val) {
        this.lastSearch = val.trim();
        return this.SearchApplicant
            .query({
                contactId: this.Auth.user.roleDetails.contactId,
                searchTerm: this.lastSearch
            })
            .$promise
            .then((response) => {
                var items: any[] = _.chain(response) // TODO - use proper type instead of any
                    .filter((r: any) => r.length)
                    //.map((r) => { r[0].first = true; return r; })
                    .flatten()
                    .value();
                        
                if (!items.length) // No result
                {
                    items.push({ resultType: 'Dummy' })
                    //items.push({
                    //    message: 'No Match found', // Add dummy entry
                    //    resultType: 'Message'
                    //})
                } else {
                    items.push({ resultType: 'all' })
                }
                //else {
                //    items.splice(0, 0, {
                //        fullName: items[0].resultType, // Add Applicant or Interviewr
                //        displayResultType: true,
                //        displayMessage:false,
                //        applicationId: null,
                //        interviewerId: null
                //    })
                //}

                return items;
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while searching for applicant. Please try again after a while.');
            });
    }

    openApplication(item) {
        if (item.applicationId) {
            this.$state.go('app.applications.inst.highlights', { applicationId: item.applicationId });
        } else if (item.interviewerId) {
            //TODO change onece we have interviewer website 
            this.$state.go('app.applications.inst.highlights', { applicationId: item.applicationId });
        } else {
            if (this.$state.params.quickSerch == this.lastSearch) {
                this.$state.params.quickSerch = '';
            }
            let queueName = this.Auth.user.role == 'OFF' ? 'AllApplications' : 'AllApplications_REG_OLA_AWA_TBM_PLC_ACT';
                
            this.$state.go('app.workQueue', { queueName: queueName, quickSerch: this.lastSearch });
        }
    }
}

angular
    .module('app')
    .directive('applicantSearch', () => new applicantSearchDirective());