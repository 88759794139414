import { EventManager } from './EventManager';

export class CalendarDirective implements ng.IDirective {
    restrict = 'E';
    controllerAs = 'ctrl';
    controller = CalendarController;
    bindToController = true;
    templateUrl = 'controls/calendar/calendar.html';
    scope = {
        eventManager: '='
    };
}

class CalendarController {

    static $inject = [];
    eventManager: EventManager;

    constructor() { }

}

angular
    .module('app')
    .directive('apCalendar', () => new CalendarDirective())