
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
    
export class MenuConfig {
    static $inject = ['MenuProvider'];
    constructor(MenuProvider) {

        MenuProvider.AddMenu('everyone', 'payment', {
            items: [
                {
                    name: 'Invoice',
                    state: 'app.payments.invoiceDetails'
                },
                {
                    name: 'Fee Sheet',
                    state: 'app.payments.feeSheet'
                },
                {
                    name: 'US Insurance',
                    state: 'app.payments.insuranceDetails'
                }
            ]
        });
        MenuProvider.AddMenu('everyone', 'paymentOffice', {
            items: [
                {
                    name: 'Invoice',
                    state: 'app.applications.applicationPayments.invoiceDetails'
                },
                {
                    name: 'Fee Sheet',
                    state: 'app.applications.applicationPayments.feeSheet'
                },
                {
                    name: 'US Insurance',
                    state: 'app.applications.applicationPayments.insuranceDetails'
                }
            ]
        });
    }
}

    angular
        .module('app')
        .config(MenuConfig);